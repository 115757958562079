/*
 * Copyright 2017, Torque IT Solutions Ltd
 * www.torque-its.com
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';

/**
 * InputBlock wrapper component. Adds only div block of proper size.
 * Because currently, not all input components correspond to the same definition,
 * we better to have this wrapper block alone, later on would be the plan to avoid having this.
 */
export default class InputBlock extends PureComponent {
    render() {
        const {
            className,
            xsColCount,
            ...restOfProps
        } = this.props;

        const classNameForWrapper = xsColCount ? `col-xs-${xsColCount}` : null;
        const classNameWithMandatory = classnames(classNameForWrapper, className);


        return (
            <div
                {...restOfProps}
                className={classNameWithMandatory}
            />
        );
    }
}

InputBlock.propTypes = {
    className: PropTypes.string,
    xsColCount: PropTypes.number
};

InputBlock.defaultProps = {
    xsColCount: 8
};
