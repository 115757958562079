import * as React from 'react';
import classnames from 'classnames';
import map from 'lodash/fp/map';
import filter from 'lodash/fp/filter';
import flattenDeep from 'lodash/fp/flattenDeep';
import flow from 'lodash/fp/flow';
import includes from 'lodash/fp/includes';
import { getKeyField, hasAKeyField, mapChildrenToColumns } from './column';
import { customTotalFactory, pageButtonRenderer } from './pagination';
import { expandColumnRenderer, expandHeaderColumnRenderer } from './expand';
import FontAwesomeIcon from '@torque-common-ui/fontawesome-icon';

export const defaultOnRowClick = () => {};

/**
 * Prepare the initial state of the component. It contains data that should not be reprocessed on each rendering.
 */
export function prepareState({ tableData, expandableRow, alwaysExpand, expanding, children }) {
    // The table component may have a datafield that is explicitely marked as key (in some cases, it makes sense to take control over it)
    // It's also possible to not define any key property and let the Table component auto generate one (based on the row index).
    let keyField;
    if (hasAKeyField(children)) {
        keyField = getKeyField(children);
    } else {
        keyField = 'autoGeneratedKey';
    }

    let adjustedTableData = tableData;

    // generate a new key for each table entry if no specific
    // key has been provided
    if (keyField === 'autoGeneratedKey') {
        let rowIndex = 0;
        adjustedTableData = map(d => ({
            ...d,
            [keyField]: rowIndex++ 
        }))(tableData);
    }

    const allRowKeys = map(keyField)(adjustedTableData);
    let nonExpandableRows = [];
    let expandedRows = [];
    if (expandableRow) {
        nonExpandableRows = flow(
            filter((row) => !expandableRow(row)),
            map(keyField)
        )((adjustedTableData));
        expandedRows = filter(rowKey => !includes(rowKey)(nonExpandableRows))(alwaysExpand ? allRowKeys : [].concat(expanding));
    }

    return {
        expandedRows,
        nonExpandableRows,
        keyField,
        tableData: adjustedTableData
    };
}

export function prepareProps({ isLoading, striped, maxHeight, bordered, wrapperClasses, extraClasses, noDataIcon, noDataText, 
    showPagination, totalTranslation, showSearch, minimumSearchRows, pageSize, expandComponent, alwaysExpand, showHeader,
    expandableRow, onRowClick, onRowRightClick, children, loadingDataText, searchText, page, sortField, sortOrder, data, stickyColumnCount, stickyColumnWidths, onPageChange }, state) {
    const originalData = data; // contains all items (no matter whether to be shown or not)
    const tableData = state.getData(); // contains all items to be shown (e.g. matching the current search)
    const remote = {
        filter: false,
        search: true,
        pagination: true,
        sort: true,
        cellEdit: false,
    };
    // just keep the children that are real React Component (e.g. have a type)
    // this is to ensure that the table feature is not broken is something else
    // is provided by accident
    children = flattenDeep(children).filter(child => child && child.type);
    const columns = mapChildrenToColumns(children, stickyColumnCount, stickyColumnWidths);
    
    const dataLength = tableData ? tableData.length : 0;
    let isPaginatedTable;
    if (showPagination !== undefined) {
        isPaginatedTable = dataLength > 0 && showPagination;
    } else {
        isPaginatedTable = !!pageSize && pageSize < dataLength;
    }

    /**
     * The table search is shown if:
     *  - the `showSearch` flag is set to true
     *  - or the table uses pagination
     *  - or the table does not use pagination but the total number of items in the table (independent from the number of search results)
     *    is bigger than the wanted mininum
     */
    let displaySearch;
    if (showSearch === undefined) {
        displaySearch = isPaginatedTable || (showPagination !== false && (originalData && originalData.length > minimumSearchRows));
    } else {
        displaySearch = showSearch;
    }

    const paginationOptions = {
        page,
        custom: true,
        sizePerPage: pageSize,
        pageStartIndex: 1,
        firstPageText: <FontAwesomeIcon icon="fa-angle-double-left" className="pagination-button-v3" />,
        prePageText: <FontAwesomeIcon icon="fa-angle-left" className="pagination-button-v3" />,
        nextPageText: <FontAwesomeIcon icon="fa-angle-right" className="pagination-button-v3" />,
        lastPageText: <FontAwesomeIcon icon="fa-angle-double-right" className="pagination-button-v3" />,
        alwaysShowAllBtns: true,
        showTotal: true,
        pageButtonRenderer: pageButtonRenderer,
        paginationTotalRenderer: customTotalFactory(pageSize, totalTranslation),
        onPageChange: onPageChange,
        totalSize: dataLength
    };

    const showExpand = !!expandableRow;
    const expandRow = {
        showExpandColumn: !alwaysExpand && showExpand,
        expandByColumnOnly: true,
        renderer: expandComponent,
        expanded: state.getExpandedRows(),
        className: stripedExpandRowClassName,
        nonExpandable: state.getNonExpandableRows(),
        expandColumnRenderer,
        expandHeaderColumnRenderer,
        onExpand: onExpand(state.getExpandedRows, state.updateExpandedRows, state.getKeyField())
    };

    const allWrapperClasses = classnames(wrapperClasses, extraClasses, {
        'no-header': !showHeader,
        'row-click': onRowClick !== defaultOnRowClick
    });

    const rowEvents = {
        onClick: onRowClick,
        onContextMenu: onRowRightClick
    };

    const currentIndex = (page - 1) * pageSize;
    const tableProps = {
        remote,
        keyField: state.getKeyField(),
        data: isLoading ? [] : isPaginatedTable ? tableData.slice(currentIndex, currentIndex + pageSize) : tableData,
        onTableChange: state.onTableChange,
        columns,
        loading: isLoading,
        maxHeight,
        noDataIcon,
        noDataIndication: isLoading ? loadingDataText : noDataText,
        sort: { dataField: sortField, order: sortOrder },
        defaultSorted: sortField && sortOrder ? [{ dataField: sortField, order: sortOrder }] : [],
        search: displaySearch ? { defaultSearch: searchText } : {},
        expandRow: showExpand ? expandRow : undefined,
        rowClasses: striped ? stripedRowClasses : undefined,
        rowEvents: onRowClick ? rowEvents : undefined,
        wrapperClasses: 'table-v3',
        headerClasses: 'table-header-v3',
    };

    return {
        tableProps,
        showPagination: isPaginatedTable,
        showSearch: displaySearch,
        striped,
        bordered,
        paginationOptions,
        wrapperClasses: allWrapperClasses,
    };
}

/**
 * When there is a click on the expand button, the row will expand or collapse. The list of expanded rows saved in the component state needs to be updated.
 */
const onExpand = (getExpandedRows, updateExpandedRows, keyField) => (row, isExpand) => {
    let expandedRows = [];
    if (isExpand) {
        expandedRows = [...getExpandedRows(), row[keyField]];
    } else {
        expandedRows = getExpandedRows().filter(x => x !== row[keyField]);
    }
    updateExpandedRows(expandedRows);
};

// Define the background color of a row
const stripedRowClasses = (_row, rowIndex) => ((rowIndex % 2) ? 'even-row' : 'odd-row');
// Define the background color of an expanded row
const stripedExpandRowClassName = (_isExpanded, row, rowIndex) => stripedRowClasses(row, rowIndex);
