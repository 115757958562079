/**
 * Number formatting for percentages
 * For more documentation on formatting please refer to
 * https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat#Using_options
 *
 * @param  {number} value   input value, must be number
 * @return {string}         string represents number formatted according to locale
 */
export const numberToPercentage = (value: number): string => {
	return new Intl.NumberFormat('en', {
		style: 'percent',
		minimumFractionDigits: 2,
		maximumFractionDigits: 4,
	}).format(value);
};

/**
 * Number formatting according to defined locale and provided currency
 * according to ISO 4217 standard
 * For more documentation on formatting please refer to
 * https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat#Using_options
 *
 * @param  {number} value                    input value, must be number
 * @param  {string} [currencyCode=EUR] currency string according to ISO 4217 standard
 * @return {string}                          price formatted as string
 */
export const priceToString = (value: number, currencyCode = 'EUR'): string => {
	return new Intl.NumberFormat('en', {
		style: 'currency',
		currency: currencyCode,
		compactDisplay: 'short',
		currencyDisplay: 'narrowSymbol',
	}).format(value || 0);
};
