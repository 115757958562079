import React from 'react';
import classNames from 'classnames';

/**
 * Custom total renderer
 *
 * @param {*} pageSize
 * @param {*} t translation function, that accepts to values to displayed as arguments
 */
export const customTotalFactory = (pageSize, t) => (from, to, totalCount) => {
    return (
        <span className="react-bootstrap-table-pagination-total">{t(from, to, totalCount)}</span>
    );
};

const handleClick = (page, onPageChange) => (e) => {
    e.preventDefault();
    onPageChange(page);
};

export const pageButtonRenderer = ({ page, active, disabled, title, onPageChange }) => {
    return (
        <li className={classNames('page-item', { active, disabled })} title={title}>
            <a href="#" className="page-link" onClick={handleClick(page, onPageChange)}>
                {page}
            </a>
        </li>
    );
};
