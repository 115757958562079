import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Identifiable, IEntityVariant, IVariant } from '../../types/Common.types';
import { v4 } from 'uuid';
import { useEffect, useMemo } from 'react';
import { FetchType, IComplianceEntityApi } from '../../types/Api';
import { useLocation, useParams } from 'react-router-dom';
import {
	IComplianceEntityVersion,
	ICreateComplianceEntity,
	ICreateComplianceEntityVersion,
	IUpdateComplianceEntityVersion,
} from '../../types/Compliance.types';

export interface ICachedNestedItem<TNestedItem extends Identifiable = Identifiable> {
	nestedItem: TNestedItem;
	internalId: string;
	isNew: boolean;
	isDirty: boolean;
	isFull: boolean;
}

interface IConfigurationState {
	nestedItemConfigByParentId: Record<string, Record<string, Record<ICachedNestedItem['internalId'], ICachedNestedItem>>>;
	parentById: Record<string, IComplianceEntityVersion>;
}

const initialState: IConfigurationState = {
	nestedItemConfigByParentId: {},
	parentById: {},
};

const configurationState = createSlice({
	name: 'configuration-template',
	initialState,
	reducers: {
		cacheParent: (state, action: PayloadAction<{ entity: IComplianceEntityVersion }>) => {
			state.parentById[action.payload.entity.parentId] = {
				...((state.parentById[action.payload.entity.parentId] as object) || {}),
				...action.payload.entity,
			};
		},
		initNestedItemsState: (
			state,
			action: PayloadAction<{ parentId: string; nestedItemName: string; nestedItems: Identifiable[]; isFull: boolean }>
		) => {
			const { parentId, nestedItemName, nestedItems = [], isFull } = action.payload;

			if (!state.nestedItemConfigByParentId[parentId]) {
				state.nestedItemConfigByParentId[parentId] = {};
			}
			const nestedItemsMap = state.nestedItemConfigByParentId[parentId];
			if (!nestedItemsMap[nestedItemName]) {
				nestedItemsMap[nestedItemName] = {};
			}
			const nestedItemsConfigMap = nestedItemsMap[nestedItemName];

			nestedItems.forEach(
				(nestedItem) =>
					(nestedItemsConfigMap[nestedItem.id] = { nestedItem, internalId: nestedItem.id, isDirty: false, isNew: false, isFull })
			);
		},
		createNestedItem: (state, action: PayloadAction<{ parentId: string; nestedItemName: string; nestedItem: Identifiable }>) => {
			const { parentId, nestedItem, nestedItemName } = action.payload;
			if (!state.nestedItemConfigByParentId[parentId]) {
				state.nestedItemConfigByParentId[parentId] = {};
			}
			const nestedItemsMap = state.nestedItemConfigByParentId[parentId];
			if (!nestedItemsMap[nestedItemName]) {
				nestedItemsMap[nestedItemName] = {};
			}
			const nestedItemsConfigMap = nestedItemsMap[nestedItemName];

			const internalId = v4();
			const newNestedItem = {
				internalId,
				isDirty: true,
				isNew: true,
				nestedItem: { ...nestedItem, id: undefined },
				isFull: true,
			};
			nestedItemsConfigMap[newNestedItem.internalId] = newNestedItem;
		},
		updateNestedItem: (state, action: PayloadAction<{ parentId: string; nestedItemName: string; nestedItem: Identifiable }>) => {
			const { parentId, nestedItem, nestedItemName } = action.payload;
			const nestedItemsMap = state.nestedItemConfigByParentId[parentId];
			const nestedItemsConfigMap = nestedItemsMap[nestedItemName];
			const cachedNestedItem = nestedItemsConfigMap[nestedItem.id];
			nestedItemsConfigMap[nestedItem.id] = {
				internalId: cachedNestedItem.internalId,
				isNew: cachedNestedItem.isNew,
				isDirty: true,
				nestedItem,
				isFull: true,
			};
		},
		removeNestedItem: (state, action: PayloadAction<{ parentId: string; nestedItemName: string; nestedItemId: string }>) => {
			const { parentId, nestedItemName, nestedItemId } = action.payload;
			delete state.nestedItemConfigByParentId[parentId][nestedItemName][nestedItemId];
		},
		resetConfigurationCache: (state) => {
			state.nestedItemConfigByParentId = {};
			state.parentById = {};
		},
	},
});

export const { initNestedItemsState, createNestedItem, updateNestedItem, removeNestedItem, cacheParent, resetConfigurationCache } =
	configurationState.actions;

export const CONFIGURATION_REDUCER_PATH = configurationState.name;

export const useEntity = <
	TEntity extends IComplianceEntityVersion,
	TCreateEntity extends ICreateComplianceEntity,
	TCreateEntityVersion extends ICreateComplianceEntityVersion,
	TUpdateEntityVersion extends IUpdateComplianceEntityVersion,
	TVariantEntity extends IVariant = IEntityVariant,
>(
	api: IComplianceEntityApi<TEntity, TCreateEntity, TCreateEntityVersion, TUpdateEntityVersion, TVariantEntity>,
	parentId?: string,
	versionId?: string
) => {
	const cachedParent = useSelector((state: RootState): TEntity => state[CONFIGURATION_REDUCER_PATH].parentById[parentId] as TEntity);
	const { data: entityData } = api.useGetActiveVersion(parentId, {
		skip: !parentId || parentId === 'new' || !!cachedParent || !!versionId,
	});
	const { data: entityVersionData } = api.useGetVersion(
		{ parentId, versionId },
		{ skip: !parentId || parentId === 'new' || !!cachedParent || !versionId }
	);
	const data = entityData || entityVersionData;

	const dispatch = useDispatch();
	useEffect(() => {
		if (!parentId || cachedParent || !data) return;
		dispatch(cacheParent({ entity: data }));

		api.nestedItems.forEach(({ name, type }) => {
			if (!(name in data)) return;

			dispatch(initNestedItemsState({ parentId, nestedItemName: name, nestedItems: data[name], isFull: type === 'eager' }));
		});
	}, [data, parentId]);

	return cachedParent;
};

export const useEntityNestedItems = (parentId: string) => {
	const cachedNestedItemsMapByType = useSelector(
		(state: RootState): Record<string, Record<string, ICachedNestedItem>> =>
			state[CONFIGURATION_REDUCER_PATH].nestedItemConfigByParentId[parentId] ?? {},
		(prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
	);
	const { pathname } = useLocation();
	const isClone = pathname.includes('clone');
	const isCreate = pathname.includes('new');

	const toNestedItemRequest = (propertyName: string, nestedItem: Identifiable, action: 'create' | 'update') => {
		const cachedNestedItem = cachedNestedItemsMapByType[propertyName][nestedItem.id];
		if (!cachedNestedItem.isFull) return;

		const { id, ...nestedItemRequest } = nestedItem;
		if (!isClone && !isCreate && !cachedNestedItem.isNew) {
			return { ...nestedItemRequest, id };
		}
		return nestedItemRequest;
	};

	const getNestedItemsByName = (nestedItemName: string) =>
		Object.values(cachedNestedItemsMapByType[nestedItemName] ?? {}).map((cachedNestedItem) => ({
			...cachedNestedItem.nestedItem,
			id: cachedNestedItem.internalId,
		}));

	const nestedItemsByName = useMemo(
		() =>
			Object.entries(cachedNestedItemsMapByType).reduce((accum, [key, value]) => {
				accum[key] = Object.values(value).map((cachedNestedItem) => ({ ...cachedNestedItem.nestedItem, id: cachedNestedItem.internalId }));
				return accum;
			}, {}),
		[cachedNestedItemsMapByType]
	);
	return { getNestedItemsByName, toNestedItemRequest, nestedItemsByName };
};

export type EntityNestedItemHookProps = {
	api: IComplianceEntityApi<
		IComplianceEntityVersion,
		ICreateComplianceEntity,
		ICreateComplianceEntityVersion,
		IUpdateComplianceEntityVersion
	>;
	nestedItemName: string;
	fetchConfig: {
		type: FetchType;
		useFetcher?: (args: { parentId: string; versionId: string; nestedItemId: string; isNew: boolean }) => Identifiable | undefined;
	};
};
export const useEntityNestedItem = ({ api, nestedItemName, fetchConfig }: EntityNestedItemHookProps) => {
	const { id: parentId, versionId, nestedItemId } = useParams();

	const parentVersionId = useSelector((state: RootState): string => state[CONFIGURATION_REDUCER_PATH].parentById[parentId]?.versionId);
	useEntity(api, parentId, versionId);

	const cachedNestedItem = useSelector(
		(state: RootState): ICachedNestedItem =>
			(state[CONFIGURATION_REDUCER_PATH].nestedItemConfigByParentId[parentId]?.[nestedItemName] || {})[nestedItemId]
	);

	const data = fetchConfig.useFetcher?.({
		parentId,
		versionId: parentVersionId,
		nestedItemId,
		isNew: parentId === 'new' || !parentVersionId || cachedNestedItem?.isNew || nestedItemId === 'new',
	});
	const dispatch = useDispatch();

	useEffect(() => {
		if ((cachedNestedItem && cachedNestedItem.isFull) || !data) return;
		dispatch(initNestedItemsState({ parentId, nestedItemName, nestedItems: [data], isFull: true }));
	}, [data]);

	return cachedNestedItem;
};

export const useVariantsFetcher = <
	TEntity extends IComplianceEntityVersion,
	TCreateEntity extends ICreateComplianceEntity,
	TCreateEntityVersion extends ICreateComplianceEntityVersion,
	TUpdateEntityVersion extends IUpdateComplianceEntityVersion,
	TVariantEntity extends IVariant = IEntityVariant,
>(
	api: IComplianceEntityApi<TEntity, TCreateEntity, TCreateEntityVersion, TUpdateEntityVersion, TVariantEntity>,
	entity?: TEntity
) => {
	const [fetchVariant] = api.useLazyGetVariant?.() || [];
	const dispatch = useDispatch();
	const cachedNestedItemsMapByType = useSelector(
		(state: RootState): Record<string, Record<string, ICachedNestedItem>> =>
			entity ? state[CONFIGURATION_REDUCER_PATH].nestedItemConfigByParentId[entity.parentId] ?? {} : {},
		(prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
	);

	useEffect(() => {
		if (!entity?.parentId) return;

		const areNestedItemsFull = (nestedItemName: string) => {
			const nestedItems = cachedNestedItemsMapByType[nestedItemName] || [];
			return Object.values(nestedItems).every((nestedItem) => nestedItem.isFull);
		};

		if (api.nestedItems.some((nestedItem) => nestedItem.name === 'variants') && !areNestedItemsFull('variants')) {
			const getVariant = async (variant: IVariant) =>
				fetchVariant({ parentId: entity.parentId, versionId: entity.versionId, variantId: variant.id });
			Promise.all(entity.variants?.map(getVariant)).then((results) => {
				dispatch(
					initNestedItemsState({
						parentId: entity.parentId,
						nestedItemName: 'variants',
						nestedItems: results.flatMap((result) => result.data),
						isFull: true,
					})
				);
			});
		}
	}, [entity?.parentId]);
};

export default configurationState.reducer;
