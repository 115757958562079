import PropTypes from 'prop-types';
import React, { PureComponent, Children, cloneElement } from 'react';
import classnames from 'classnames';
import MultiValueOption from './MultiValueOption';
import flow from 'lodash/fp/flow';
import filter from 'lodash/fp/filter';
import map from 'lodash/fp/map';
import isUndefined from 'lodash/fp/isUndefined';

/**
 * Component-switcher between specified values.
 */
export default class MultiValueSwitch extends PureComponent {
    /**
     * In order to not specify by consumer onClick handler to each option,
     * we require only onChange handler from switch wrapper and then copy-over
     * that function to each child. Also, defining which option is active.
     */
    addOnClickEventListenerToChildren = () => {
        const { children, onChange, value, readOnly, disabled } = this.props;
        const attachOnClick = (child) => (event) => {
            if (!readOnly && !disabled) {
                onChange(event, child);
            }
        };
        return flow(
            Children.toArray,
            filter((child) => !!child),
            map((child) =>
                cloneElement(child, {
                    key: child.props.value,
                    readOnly,
                    disabled: isUndefined(child.props.disabled) ? disabled : child.props.disabled,
                    className: classnames(child.props.className, {
                        [child.props.activeClassName]: child.props.value === value,
                    }),
                    onClick: attachOnClick(child),
                })
            )
        )(children);
    };
    render() {
        const { align, className } = this.props;
        const multiValueSwitchClassName = classnames('multi-value-switch-wrapper-v2', className, {
            'left-aligned': align === 'left',
            'centred': align === 'center',
            'right-aligned': align === 'right',
        });
        const customChildren = this.addOnClickEventListenerToChildren();

        return <div className={multiValueSwitchClassName}>{customChildren}</div>;
    }
}

MultiValueSwitch.propTypes = {
    align: PropTypes.oneOf(['left', 'right', 'center']),
    readOnly: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    className: PropTypes.string,
    value: PropTypes.node,
    onChange: PropTypes.func.isRequired,
    children: PropTypes.node,
};

MultiValueSwitch.defaultProps = {
    align: 'left',
    readOnly: false,
    disabled: false,
};

MultiValueSwitch.Option = MultiValueOption;
