import { useGetRecentContractsQuery } from '../../../api';
import { useRecentContractActivationsSelector } from './recentContractActivations.store';
import { useEffect } from 'react';
import { useAuth } from '../../User/useAuth';
import { CONTRACT_ACTIVATION_PERMISSION } from '../../../config/constants';
import { PermissionsCheckFactory } from '../../../utils/permissionsCheckFactory';

const REQUIRED_PERMISSION = PermissionsCheckFactory.CONTRACT_ACTIVATION_PERMISSION(CONTRACT_ACTIVATION_PERMISSION.VIEW_CONTRACT_ACTIVATION);
export const useRecentContractActivations = () => {
	const { hasAuthority } = useAuth();
	const isAllowedToFetch = hasAuthority(REQUIRED_PERMISSION);

	const { refetch } = useGetRecentContractsQuery(undefined, { skip: !isAllowedToFetch });
	const store = useRecentContractActivationsSelector();

	useEffect(() => {
		if (isAllowedToFetch) void refetch();
	}, [store.current]);

	return { recent: store.recent };
};
