/*
 * Copyright 2020, Torque IT Solutions Ltd
 * www.torque-its.com
 */

import DOMPurify from 'dompurify';

/**
 * DOMPurify configuration.
 * DOMPurify is working with white lists instead of black lists,
 * which means that we have to manually define which html scripts
 * and attributes will be kept untouched by the sanitisation.
 * Everything which is not in the lists will be removed.
 * @type {{ALLOWED_TAGS: string[], ALLOWED_ATTR: string[]}}
 */
const DOMPurifyConfig = {
    ALLOWED_TAGS: ['a','h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'div', 'span', 'blockquote', 'pre', 'b', 'i', 'strong', 'em', 'tt', 'code', 'ins', 'del', 'sup', 'sub', 'kbd', 'samp', 'q',
        'var', 'cite', 'strike', 'center', 'hr', 'br', 'wbr', 'ul', 'ol', 'li', 'dd', 'dt', 'dl', 'body', 'html', 'table', 'caption', 'tbody', 'thead', 'tfoot', 'td', 'th', 'tr', 'colgroup', 'col', 'img'],
    ALLOWED_ATTR: ['id', 'name', 'title', 'dir', 'lang', 'href', 'data-json', 'border', 'cellpadding', 'cellspacing', 'colspan', 'nowrap', 'height', 'width', 'align', 'align', 'valign',
        'charoff', 'colspan', 'rowspan', 'span', 'width', 'cite', 'class', 'start', 'style', 'src', 'alt', 'target']
};

// Clean HTML string
export function sanitiseHtml(content) {
    return DOMPurify.sanitize(content, DOMPurifyConfig);
}
