import { sortByField } from '../../utils/common';
import { IAssetField, IAssetSectionViewVersion } from '../../types/Asset.types';
import { buildFieldCode, buildFieldName, isActivationCustomField } from '../../utils/fieldUtils';
import { IMetadataField } from '../../types/MetaData.types';

const getActive = <TField extends IAssetField | IMetadataField>(field: TField) => {
	if (field.fieldType === 'CUSTOM') return field.referenceDataValue?.active;
	if (isActivationCustomField(field)) return field.activationCustomField?.active;
	return undefined;
};

const buildFieldTypeAlias = (field: IMetadataField) =>
	field.fieldType === 'CUSTOM' &&
	'referenceDataValue' in field &&
	'referenceDataType' in field.referenceDataValue &&
	field.referenceDataValue.referenceDataType === 'ASSET_TAX_TYPE'
		? field.referenceDataValue.referenceDataType
		: field.fieldType;

const toAssetSectionViewField = <TField extends IMetadataField>(field: TField) => ({
	...field,
	code: buildFieldCode(field),
	name: buildFieldName(field, true),
	active: getActive(field),
	fieldTypeAlias: buildFieldTypeAlias(field),
});

const toAssetSectionViewVersion = (response: IAssetSectionViewVersion): IAssetSectionViewVersion => ({
	...response,
	fields: response.fields
		.map((field) => ({
			...field,
			code: buildFieldCode(field),
			name: buildFieldName(field, true),
			active: getActive(field),
		}))
		.sort(sortByField('position', 'asc')),
	extraColumns: response.extraColumns
		?.map((column) => ({ ...column, code: buildFieldCode(column), name: buildFieldName(column, true) }))
		.sort(sortByField('position', 'asc')),
});

const toAssetSectionViewVersions = (versions: IAssetSectionViewVersion[]): IAssetSectionViewVersion[] =>
	versions.map(toAssetSectionViewVersion);

export const AssetSectionViewMapper = {
	toAssetSectionViewVersion,
	toAssetSectionViewVersions,
	toAssetSectionViewField,
};
