import { camApi } from '../api';
import { ContractActivationActivityType } from '../../types/Contract.types';

export const ContractActivationActivityApi = camApi.injectEndpoints({
	endpoints: (build) => ({
		getActivityTypes: build.query<ContractActivationActivityType[], void>({
			providesTags: ['contract-activation-activity-types'],
			query: () => ({
				url: 'api/contract-activation/activity/types',
				method: 'get',
			}),
			// activity types can't be modified unless redeployed
			// keep this data forever
			keepUnusedDataFor: Number.MAX_SAFE_INTEGER,
		}),
	}),
});
