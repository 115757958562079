/*
 * Copyright 2018, Torque IT Solutions Ltd
 * www.torque-its.com
 */
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

class Toggle extends PureComponent {

    render() {
        const {
            componentType,
            componentClass: Component,
            expanded,
            className,
            children,
            ...props
        } = this.props;

        return (
            <Component
                {...props}
                role="button"
                className={classnames(
                    className,
                    'sidenav-toggle'
                )}
                aria-expanded={expanded}
            >
                <span className={'icon-bar'} />
                <span className={'icon-bar'} />
                <span className={'icon-bar'} />
                {children}
            </Component>
        );
    }
}

Toggle.propTypes = {
    componentType: PropTypes.func,
    // A custom element for this component.
    componentClass: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ]),
    // Whether the navigation toggle is disabled.
    disabled: PropTypes.bool,
    // Whether the side navigation is expanded or collapsed.
    expanded: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node
};

Toggle.defaultProps = {
    componentClass: 'button',
    disabled: false,
    expanded: false,
    componentType: Toggle
};

export default Toggle;