import PropTypes from 'prop-types';
import React, { PureComponent, Children, cloneElement } from 'react';
import classnames from 'classnames';
import contains from 'lodash/fp/contains';
import flow from 'lodash/fp/flow';
import filter from 'lodash/fp/filter';
import map from 'lodash/fp/map';
import isEmpty from 'lodash/fp/isEmpty';
import isUndefined from 'lodash/fp/isUndefined';
import MultiValueOption from './MultiValueOption';
import Button from '@torque-common-ui/button-v2';

/**
 * Component-switcher between specified values.
 */
export default class MultiValueToggle extends PureComponent {
    /**
     * In order to not specify by consumer onClick handler to each option,
     * we require only onChange handler from toggle wrapper and then copy-over
     * that function to each child. Also, defining which option is active.
     */
    addOnClickEventListenerToChildren = () => {
        const { children, onChange, value, readOnly, disabled } = this.props;
        const attachOnClick = (child) => (event) => {
            if (!readOnly && !disabled) {
                onChange(event, child);
            }
        };
        return flow(
            Children.toArray,
            filter((child) => !!child),
            map((child) =>
                cloneElement(child, {
                    key: child.props.value,
                    readOnly,
                    disabled: isUndefined(child.props.disabled) ? disabled : child.props.disabled,
                    className: classnames(child.props.className, {
                        [child.props.activeClassName]: contains(child.props.value)(value),
                    }),
                    onClick: attachOnClick(child),
                })
            )
        )(children);
    };

    handleCustomButtonClick = (event) => {
        const { customOptionSelect, onChange, readOnly, disabled } = this.props;
        if (!readOnly && !disabled) {
            if (customOptionSelect) {
                customOptionSelect(event);
            } else {
                onChange(event);
            }
        }
    };

    renderCustomOption = () => {
        const { customOptionLabel, customOptionActiveClassName, readOnly, disabled, value, children } =
            this.props;
        if (!customOptionLabel || !customOptionActiveClassName) {
            return null;
        }

        return (
            <Button
                disabled={disabled}
                readOnly={readOnly}
                title={customOptionLabel}
                className={classnames('multi-value-option-v2', {
                    [`${customOptionActiveClassName}`]: isEmpty(value),
                    'custom-value-option': !isEmpty(children) // add custom option styles if it is not the only option
                })}
                onClick={this.handleCustomButtonClick}
            >
                {customOptionLabel}
            </Button>
        );
    };

    render() {
        const { align, className, customOptionLabel, customOptionActiveClassName, separateLastOption } = this.props;
        const multiValueToggleClassName = classnames('multi-value-toggle-wrapper-v2', className, {
            'left-aligned': align === 'left',
            centred: align === 'center',
            'right-aligned': align === 'right',
            'separate-last-option': separateLastOption
        });
        const customChildren = this.addOnClickEventListenerToChildren();

        return (
            <div className={multiValueToggleClassName}>
                <div
                    className={classnames('multi-value-options', {
                        'has-custom-option': customOptionLabel && customOptionActiveClassName,
                    })}
                >
                    {customChildren}
                    {this.renderCustomOption()}
                </div>
            </div>
        );
    }
}

MultiValueToggle.propTypes = {
    align: PropTypes.oneOf(['left', 'right', 'center']),
    readOnly: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    className: PropTypes.string,
    value: PropTypes.node,
    onChange: PropTypes.func.isRequired,
    children: PropTypes.node,
    customOptionLabel: PropTypes.string,
    customOptionActiveClassName: PropTypes.string,
    customOptionSelect: PropTypes.func,
    seperateLastOption: PropTypes.bool
};

MultiValueToggle.defaultProps = {
    align: 'left',
    readOnly: false,
    disabled: false,
    customOptionLabel: '',
    customOptionActiveClassName: 'active',
    separateLastOption: false
};

MultiValueToggle.Option = MultiValueOption;
