import React from 'react';
import classnames from 'classnames';
import FontAwesomeIcon from '@torque-common-ui/fontawesome-icon';

/**
 * Renderer for sorting indicator
 * 
 * @param {*} order 
 */
export function caretRenderCreator(order) {
    return (
        <span className="icon-group">
            <FontAwesomeIcon icon="fa-caret-up" className={classnames('table-v3-sort-icon', {'inactive': order && order !== 'asc'})} />
            <FontAwesomeIcon icon="fa-caret-down" className={classnames('table-v3-sort-icon', {'inactive': order && order !== 'desc'})} />
        </span>
    );
}
