/*
 * Copyright 2018, Torque IT Solutions Ltd
 * www.torque-its.com
 */
const NavText = () => {
    throw new Error('should not render NavText component');
};

NavText.defaultProps = {
    ...NavText.defaultProps,
    componentType: NavText
};

export default NavText;