import { IReferenceData } from '../../types/ReferenceData';
import { buildNameFromCode } from '../../utils/fieldUtils';
import { sortByField } from '../../utils/common';
import { tOrUndefined } from '../../assets/i18n/translation';

const toReferenceData = (referenceData: IReferenceData): IReferenceData => {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const parentId = referenceData.id;
	return {
		...referenceData,
		name: tOrUndefined(`configuration.metadata.fields.code.enum.${referenceData.type}`) ?? buildNameFromCode(referenceData.type),
		parentId,
		values: referenceData.values?.sort(sortByField('name', 'asc')),
	};
};

const toReferenceDataList = (referenceDataList: IReferenceData[] = []): IReferenceData[] => referenceDataList.map(toReferenceData);

export const ReferenceDataMapper = {
	toReferenceData,
	toReferenceDataList,
};
