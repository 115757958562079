import find from 'lodash/find';
import has from 'lodash/has';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import reduce from 'lodash/reduce';
import flattenDeep from 'lodash/fp/flattenDeep';

import { compareTwoValues, getTitleString } from '../util';
import { defaultRenderValue } from './column';

export function sortEvaluated(evaluateFunc, defaultFunc, a, b, order) {
    const valueA = evaluateFunc(a);
    const valueB = evaluateFunc(b);
    if ((isNumber(valueA) || isString(valueA)) && (isNumber(valueB) || isString(valueB))) {
        return compareTwoValues(valueA, valueB, order);
    }
    const dataA = defaultFunc(a);
    const dataB = defaultFunc(b);
    return compareTwoValues(dataA, dataB, order);
}

export const getSortFuncs = (children, sortField) => {
    const matchingChild = find(children, child => child.props && child.props.dataField === sortField && !child.props.hidden);
    if (matchingChild) {
        const instance = matchingChild.type.prototype;
        if (instance.sortFunc) {
            return {
                sortFunc: instance.sortFunc.bind(matchingChild),
                custom: true,
            };
        }
        if (instance.renderValue) {
            return {
                sortFunc: compareTwoValues,
                renderValue: instance.renderValue.bind(matchingChild),
            };
        } else if (matchingChild.props.renderValue) {
            return {
                sortFunc: compareTwoValues,
                renderValue: matchingChild.props.renderValue
            };
        } else {
            return {
                sortFunc: sortEvaluated.bind('', matchingChild.props.renderValue || defaultRenderValue, defaultRenderValue),
            };
        }
    }
    return {
        sortFunc: compareTwoValues
    };
};

const getDataFields = (children) => {
    const dataFields = {};
    return reduce(children, (fields, child) => {
        const dataField = get(child, 'props.dataField');
        const hidden = get(child, 'props.hidden', false);
        if (dataField && !hidden && !has(fields, dataField)) {
            return {
                ...fields,
                [`${dataField}`]: child.props
            };
        }
        return fields;
    }, dataFields);
};

export const getTableData = (children, data, state) => {
    const flatChildren = flattenDeep(children);
    const { sortField, sortOrder, searchText } = state;
    if (!data) {
        return [];
    }

    const dataFields = getDataFields(flatChildren);
    let newData = [...data];

    if (searchText) {
        const targetText = searchText.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        newData = newData.filter((row, index) => {
            const hasMatching = find(row, (column, key) => {

                if (!has(dataFields, key)) {
                    return false;
                }
                let textValue = column;
                if (has(dataFields[key], 'renderValue')) {
                    const dom = dataFields[key].renderValue(column, row, index);
                    textValue = getTitleString(dom);
                }
                if (!textValue) {
                    return false;
                }
                if (typeof textValue === 'object') {
                    const found = find(textValue, value => {
                        if (!value) {
                            return false;
                        }
                        return typeof value !== 'object' ? value.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(targetText) !== -1 : false;
                    }) !== undefined;
                    return typeof column === 'string' ? column.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(targetText) !== -1 || found : found;
                } else {
                    return textValue.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(targetText) !== -1;
                }
            });
            return hasMatching !== undefined;
            
        });
    }
    if (sortField && sortOrder) {
        const sortFuncs = getSortFuncs(flatChildren, sortField);
        if (sortFuncs.renderValue) {
            newData = newData.sort((a, b) => {
                const valueA = sortFuncs.renderValue(a[sortField], a);
                const valueB = sortFuncs.renderValue(b[sortField], b);
                if ((isNumber(valueA) || isString(valueA)) && (isNumber(valueB) || isString(valueB))) {
                    return sortFuncs.sortFunc(valueA, valueB, sortOrder);
                }
                return sortFuncs.sortFunc(a[sortField], b[sortField], sortOrder);
            });
        } else {
            newData = newData.sort((a, b) => sortFuncs.sortFunc(sortFuncs.custom ? a : a[sortField], sortFuncs.custom ? b : b[sortField], sortOrder));
        }
    }
    return newData;
};
