import { ComplianceApi } from '../../api';
import { ComplianceSectionLevelType, ComplianceSectionType } from '../../types/Compliance.types';
import { useApplicationUserSelector } from '../User/user.store';

export const useComplianceSections = () => {
	const { account, permissions } = useApplicationUserSelector();
	const arePermissionsLoaded = Boolean(account && permissions);

	const { data: sections = [] } = ComplianceApi.useGetAllActiveVersions(undefined, {
		skip: !arePermissionsLoaded || !permissions.systemPermissions.length,
	});
	const levelBySection: Map<ComplianceSectionType, ComplianceSectionLevelType> = sections.reduce((acc, section) => {
		acc.set(section.complianceSection, section.complianceLevel);
		return acc;
	}, new Map()) as Map<ComplianceSectionType, ComplianceSectionLevelType>;

	return {
		areSectionsAvailable: Boolean(arePermissionsLoaded && permissions.systemPermissions.length),
		sections,
		levelBySection,
	};
};
