import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import Button from '@torque-common-ui/button-v2';

/**
 * Single option of multi-value switch component wrapper
 */
export default class MultiValueOption extends PureComponent {
    render() {
        const { className, readOnly } = this.props;
        const multiValueSwitchClassName = classnames('multi-value-option-v2', className, { 'read-only': readOnly });
        return (
            <Button
                {...this.props}
                title={this.props.children}
                className={multiValueSwitchClassName}
            />
        );
    }
}

MultiValueOption.propTypes = {
    className: PropTypes.string,
    readOnly: PropTypes.bool,
    value: PropTypes.node
};

MultiValueOption.defaultProps = {
    activeClassName: 'active'
};
