/*
 * Copyright 2021, Torque IT Solutions Ltd
 * www.torque-its.com
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Link from '@torque-common-ui/link';

class NavLink extends PureComponent {

    render() {
        const { id, className, title, to, children, disabled, target, tabIndex, ...props } = this.props;
        const isExternalLink = to && (to.toUpperCase().startsWith('HTTP') || to.toUpperCase().startsWith('WWW'));

        return (
            isExternalLink ? 
                <a id={id} href={to} className={classnames('trq-link', className, { disabled })}
                    target={target ? target : '_blank'}
                >
                    {children}
                </a> : 
                <Link
                    id={id}
                    title={title}
                    className={className}
                    target={target}
                    to={to}
                    tabIndex={tabIndex}
                    {...props}
                >
                    {children}
                </Link>
        );
    }
}

NavLink.propTypes = {
    id: PropTypes.string,
    to: PropTypes.string,
    target: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    tabIndex: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.string]),
};

export default NavLink;