import { camApi } from './api';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

// this hook is created to check is there any pending request between backend and frontend
export const useApiPendingState = () => {
	return useSelector<RootState, boolean>(
		(state) =>
			Object.values(state[camApi.reducerPath].mutations).some((mutation) => mutation && mutation.status === 'pending') ||
			Object.values(state[camApi.reducerPath].queries).some((query) => query && query.status === 'pending')
	);
};
