import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import Sticky from 'react-sticky-el';

export default class ReactStickyEl extends React.Component {
    state = {
        boundaryElement: 'document'
    }
    
    componentDidMount() {
        this.findContainer();
    }

    findContainer = () => {
        let element = ReactDOM.findDOMNode(this);
        let found = false;
        while (!found && element.parentNode) {
            const parent = element.parentNode;

            if (parent.id && parent.id.startsWith('sticky-container-id-')) {
                found = true;
                this.setState({ boundaryElement: `#${parent.id}`});
            } else {
                element = parent;
            }
        }
    }

    render() {
        const { topOffset, bottomOffset, scrollElement, stickyStyle, stickyClassName, onFixedToggle, children, className } = this.props;
        return (
            <Sticky
                topOffset={topOffset}
                bottomOffset={bottomOffset}
                boundaryElement={this.state.boundaryElement}
                scrollElement={scrollElement ? scrollElement : this.state.boundaryElement}
                stickyStyle={stickyStyle}
                stickyClassName={stickyClassName}
                onFixedToggle={onFixedToggle}
                className={className}
            >
                {children}
            </Sticky>
        );
    }
}

ReactStickyEl.propTypes = {
    // Sticky state will be triggered when the top of the element is topOffset pixels from the top of the scrollElement
    topOffset: PropTypes.number,
    // Sticky state will be triggered when the bottom of the element is bottomOffset pixels from the bottom of the scrollElement.
    bottomOffset: PropTypes.number,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    // Selector to define a (parent) scrollElement. All position checks will be performed according to this element,
    // also it will be listened for 'scroll' event.
    scrollElement: PropTypes.string,
    // Style object to be applied. This overrides the default styles for a sticky element
    // Note: Likely want to avoid messing with the following attributes in your stickyStyle: left, top, and width.
    stickyStyle: PropTypes.shape({}),
    // Class name to be applied when element becomes sticky
    stickyClassName: PropTypes.string,
    // This handler will be called right before changing fixed state.
    onFixedToggle: PropTypes.func,
    className: PropTypes.string
};
