import { sortByField } from '../../utils/common';
import { buildFieldCode, buildFieldName, isActivationCustomField } from '../../utils/fieldUtils';
import { IMetadataField } from '../../types/MetaData.types';
import { IApplicationDetailsField, IApplicationDetailsSectionViewVersion } from '../../types/ApplicationDetails.types';

const buildLoanFieldName = (field: IMetadataField) => buildFieldName({ ...field, code: field?.code?.replace(/^(LOAN\.)/, '') }, true);

const buildFieldTypeAlias = (field: IMetadataField) =>
	field.fieldType === 'CUSTOM' &&
	'referenceDataValue' in field &&
	'referenceDataType' in field.referenceDataValue &&
	field.referenceDataValue.referenceDataType === 'ASSET_TAX_TYPE'
		? field.referenceDataValue.referenceDataType
		: field.fieldType;

const getActive = <TField extends IApplicationDetailsField | IMetadataField>(field: TField) => {
	if (field.fieldType === 'CUSTOM') return field.referenceDataValue?.active;
	if (isActivationCustomField(field)) return field.activationCustomField?.active;
	return undefined;
};

const toApplicationDetailsSectionViewField = <TField extends IMetadataField>(field: TField) => {
	const newFieldState = { ...field, active: getActive(field) };

	return {
		...newFieldState,
		code: buildFieldCode(newFieldState),
		name: buildLoanFieldName(newFieldState),
		fieldTypeAlias: buildFieldTypeAlias(field),
	};
};

const toApplicationDetailsSectionViewVersion = (
	response: IApplicationDetailsSectionViewVersion
): IApplicationDetailsSectionViewVersion => ({
	...response,
	fields: response.fields
		.map((field) => {
			const newFieldState = { ...field, active: getActive(field) };

			return {
				...newFieldState,
				code: buildFieldCode(newFieldState),
				name: buildLoanFieldName(newFieldState),
			};
		})
		.sort(sortByField('position', 'asc')),
});

const toApplicationDetailsSectionViewVersions = (
	versions: IApplicationDetailsSectionViewVersion[]
): IApplicationDetailsSectionViewVersion[] => versions.map(toApplicationDetailsSectionViewVersion);

export const ApplicationDetailsSectionViewMapper = {
	toApplicationDetailsSectionViewVersion,
	toApplicationDetailsSectionViewVersions,
	toApplicationDetailsSectionViewField,
};
