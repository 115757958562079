import { camApi } from './api';
import { IAccount, IAuthParams, IPermissionsResponse } from '../types/Authentication.types';
import { resetSession } from '../utils/axiosUtils';
import { useDispatch } from 'react-redux';
import { LocalStorageCache } from '../utils/localStorageCache';

export const authApi = camApi.injectEndpoints({
	endpoints: (build) => ({
		login: build.mutation<IAccount, IAuthParams>({
			query: (auth) => {
				const request = { ...auth, password: window.btoa(auth.password) };
				return {
					url: 'api/authenticate',
					method: 'post',
					data: request,
					_isAuthEndpoint: true,
				};
			},
		}),
		loginWithToken: build.mutation<IAccount, { pluginIdentity: string; authInfo: object }>({
			query: (auth) => {
				return {
					url: `api/token/${auth.pluginIdentity}`,
					method: 'post',
					data: auth.authInfo,
					_isAuthEndpoint: true,
				};
			},
		}),
		getPermissions: build.query<IPermissionsResponse, void>({
			providesTags: ['user-permissions'],
			query: () => ({
				url: 'api/currentuser/permissions',
				method: 'get',
			}),
		}),
	}),
});

export const { useLoginMutation, useGetPermissionsQuery, useLoginWithTokenMutation } = authApi;

export const useLogin = () => {
	const dispatch = useDispatch();

	const [login, { isLoading, data: account = LocalStorageCache.getUser() }] = useLoginMutation({
		fixedCacheKey: 'project-auth-LoginPage',
	});
	const { isLoading: isLoadingPermissions } = useGetPermissionsQuery(undefined, { skip: !account });

	const handleLogout = () => {
		resetSession();
		dispatch({ type: 'store/reset' });
	};

	return { login, logout: handleLogout, isLoading: isLoading || isLoadingPermissions };
};
