import PropTypes from 'prop-types';
import { PureComponent } from 'react';

/**
 * Table Column, is used for configuration purpose. Everytime field needs to be represented in table, 
 * new column as child of Table component should be defined. After migration to greater version of underlying
 * BootstrapTable component, this is still required for us to support legacy table definition.
 */
export default class TableColumn extends PureComponent {
    shouldComponentUpdate() {
        // avoid any re-render of this component since it's not really being rendered ever, because returns null
        return false;
    }
    render() {
        // This component is not really rendered by Table component, because is only for configuration purpose
        // thus return nothing. All logic related to column is in helper/column.js
        return null;
    }
}

TableColumn.propTypes = {
    // not used by component, but should be present for lookup by Table component
    isKey: PropTypes.bool.isRequired,
    isDummyField: PropTypes.bool.isRequired,
    dataSort: PropTypes.bool.isRequired,
    dataField: PropTypes.string,
    children: PropTypes.node,
    width: PropTypes.string,
    title: PropTypes.bool.isRequired,
    dataAlign: PropTypes.string,
    headerAlign: PropTypes.string,
    verticalAlign: PropTypes.string,
    headerText: PropTypes.string,
    headerTitle: PropTypes.bool,
    columnClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.bool)]),
    hidden: PropTypes.bool,
    // how value from onject under dataField should rendered
    renderValue: PropTypes.func,
    // renderLabel by default uses renderValue
    renderLabel: PropTypes.func,
    // renderTitle by default uses renderValue
    renderTitle: PropTypes.func,
    // sortFunc by default uses value from renderValue
    sortFunc: PropTypes.func,
    // if content should be contained in a cell (being cut-off if overflows)
    wrapContent: PropTypes.bool,
    // adds text-overflow ellipsis, so that text is nicely cut-off
    textOverflow: PropTypes.bool,
    // preserve contained line-breaks and wrap over multiple lines as required to show the whole content
    multiLine: PropTypes.bool,
    // State data should not be used within the formatter function but instead
    // be passed via formatExtraData. This prevents issues where the table 
    // component rerendering strategy conflicts with the table cell components 
    // rerendering.
    // Known example: the MultiSelect component will close itself everytime
    // one option is selected because the table gets rerendered when a new
    // option is selected. The solution is to keep the selected options in a
    // separate state and provide them as extra data
    formatExtraData: PropTypes.shape(),
    headerStyle: PropTypes.shape(),
};

TableColumn.defaultProps = {
    isKey: false,
    isDummyField: false,
    wrapContent: true,
    textOverflow: true,
    multiLine: false,
    title: true,
    headerTitle: false,
    hidden: false,
    dataSort: true,
    verticalAlign: 'middle',
    headerStyle: null,
};
