import * as React from 'react';
import FontAwesomeIcon from '@torque-common-ui/fontawesome-icon';
import SideNavMenu from '@torque-common-ui/side-nav-menu';
import '@torque-common-ui/side-nav-menu/dist/bundle.css';

import './SideMenu.scss';
import { IMenu, IMenuItem, useMenu } from './useMenu';
import { memo } from 'react';

const retrieveMenuItemIds = (menu: IMenuItem): string[] => {
	const children = menu.children || [];
	return [menu.id, ...children.map(retrieveMenuItemIds).reduce((acc, ids) => [...acc, ...ids], [])];
};

const retrieveMenuIds = (menu: IMenu): string[] => {
	const children = menu.children || [];
	return children.map(retrieveMenuItemIds).reduce((acc, ids) => [...acc, ...ids], []);
};

export const SideMenu = memo(() => {
	const menu = useMenu();
	const menuIds = retrieveMenuIds(menu).join('::');

	const renderNavItem = (menuItem: IMenuItem) => (
		<SideNavMenu.NavItem id={menuItem.id} key={menuItem.id} to={menuItem.to}>
			{menuItem.icon && (
				<SideNavMenu.NavIcon>
					<FontAwesomeIcon icon={menuItem.icon} />
				</SideNavMenu.NavIcon>
			)}
			{menuItem.label && <SideNavMenu.NavText>{menuItem.label}</SideNavMenu.NavText>}
			{menuItem.children && menuItem.children.map(renderNavItem)}
		</SideNavMenu.NavItem>
	);

	const renderNav = ({ defaultSelected, children }: IMenu) => (
		<SideNavMenu.Nav key={menuIds} defaultSelected={defaultSelected}>
			{children && children.map(renderNavItem)}
		</SideNavMenu.Nav>
	);

	return (
		<SideNavMenu id="side-nav-menu" calculateContentMargins={false}>
			<SideNavMenu.Toggle />
			{menu && renderNav(menu)}
		</SideNavMenu>
	);
});

SideMenu.displayName = 'SideMenu';
