import React from 'react';
import { NotificationsContainer } from '../NotificationsHandler';

import './PageContainer.scss';

export const PageContainer: React.FC<{
	children: React.ReactNode | React.ReactNode[];
	title?: string | React.ReactNode;
	footer?: React.ReactNode | React.ReactNode[];
	className?: string;
	mainClassName?: string;
}> = ({ children, className = 'configuration-page', mainClassName = 'configuration-page-content', title, footer }) => {
	const header = (() => {
		if (typeof title === 'string') {
			return (
				<div className="configuration-page-header">
					<h4>{title}</h4>
				</div>
			);
		}
		return title;
	})();
	return (
		<div className={className}>
			{header}
			<main className={mainClassName}>
				<NotificationsContainer />
				{children}
			</main>
			{footer && <div className="bottom-section">{footer}</div>}
		</div>
	);
};
