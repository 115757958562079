/*
 * Copyright 2021, Torque IT Solutions Ltd
 * www.torque-its.com
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import Glyphicon from '@torque-common-ui/glyphicon';
import FontAwesomeIcon from '@torque-common-ui/fontawesome-icon';

/**
 * Generic Button Component.
 * If "icon" prop is provided, glyphicon will be shown.
 */
export default class Button extends PureComponent {
    handleOnClick = (e) => {
        const { onClick, preventDefault } = this.props;
        if (preventDefault) {
            e.preventDefault();
        }
        if (onClick) {
            onClick(e);
        }
    }

    renderIcon() {
        const { fontAwesome, icon, iconClassName } = this.props;
        return fontAwesome ? <FontAwesomeIcon icon={icon} className={iconClassName} /> : <Glyphicon icon={icon} />;
    }

    render() {
        const {
            adornmentIcon,
            id,
            className,
            fontAwesome,
            title,
            disabled,
            readOnly,
            children,
            hideInMobile,
            mobileOnly,
            noText,
            icon,
            iconButton,
            iconOnly,
            iconPosition,
            noHover,
            smallIcon,
            type,
            style,
            ...passProps
        } = this.props;
        const btnClassName = classnames('trq-btn-v2 btn btn-default', className, {
            'icon-on-left': !iconOnly && iconPosition === 'left',
            'icon-on-right': !iconOnly && iconPosition === 'right',
            'icon-only': iconOnly,
            'icon-button': iconButton,
            'adorment-icon': adornmentIcon,
            'hide-in-mobile': hideInMobile,
            'mobile-only': mobileOnly,
            'small-icon': smallIcon,
            'glyphicons': !fontAwesome,
            'no-text': noText,
            'no-hover': noHover
        });
        // sometimes we want to pass just empty string for title
        // that's why we should check for null or undefined explicitly
        const buttonTitle = title === null || title === undefined ? null : title;
        return (
            <button
                id={id}
                type={type}
                title={buttonTitle}
                className={btnClassName}
                style={style}
                disabled={disabled}
                readOnly={readOnly}
                onClick={this.handleOnClick}
                {...passProps}
            >
                {icon && iconPosition === 'left' && this.renderIcon()}
                {!iconOnly && children}
                {icon && iconPosition === 'right' && this.renderIcon()}
            </button>
        );
    }
}

Button.propTypes = {
    adornmentIcon: PropTypes.bool,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
    type: PropTypes.string,
    icon: PropTypes.string,
    iconButton: PropTypes.bool,
    iconClassName: PropTypes.string,
    iconOnly: PropTypes.bool,
    smallIcon: PropTypes.bool,
    noHover: PropTypes.bool,
    iconPosition: PropTypes.oneOf(['left', 'right', '']),
    hideInMobile: PropTypes.bool,
    mobileOnly: PropTypes.bool,
    fontAwesome: PropTypes.bool.isRequired,
    title: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    // defines whether the default behaviour of the button
    // should be prevented or not.
    preventDefault: PropTypes.bool.isRequired,
    noText: PropTypes.bool
};

Button.defaultProps = {
    adornmentIcon: false,
    hideInMobile: false,
    mobileOnly: false,
    iconPosition: 'left',
    iconOnly: false,
    iconButton: false,
    fontAwesome: false,
    type: 'button',
    disabled: false,
    readOnly: false,
    preventDefault: false,
    noText: false
};
