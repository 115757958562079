/*
 * Copyright 2017, Torque IT Solutions Ltd
 * www.torque-its.com
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';

/**
 * Label wrapper component.
 */
export default class Label extends PureComponent {
    render() {
        const {
            className,
            xsColCount,
            wrapperClassName,
            ...restOfProps
        } = this.props;

        const classNameWithMandatory = classnames('control-label', className);
        const classNameForWrapper = xsColCount ? classnames(`col-xs-${xsColCount}`, wrapperClassName) : wrapperClassName;

        return (
            <div className={classNameForWrapper}>
                <label
                    {...restOfProps}
                    className={classNameWithMandatory}
                />
            </div>
        );
    }
}

Label.propTypes = {
    className: PropTypes.string,
    wrapperClassName: PropTypes.string,
    xsColCount: PropTypes.number,
    // if false - use bootstrap grid size css class
    noSize: PropTypes.bool
};

Label.defaultProps = {
    xsColCount: 4
};
