import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { IReferenceData } from '../../types/ReferenceData';
import { ReferenceDataApi } from '../../api';

interface IReferenceDataStore {
	referenceDataByType: Record<string, IReferenceData>;
	referenceDataTypes: string[];
}

const initialState: IReferenceDataStore = {
	referenceDataByType: {},
	referenceDataTypes: [],
};

const referenceDataStore = createSlice({
	name: 'referenceDataStore',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(
			// RTK Query API typification is too complex so we'll just ignore it for TS
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			ReferenceDataApi.__getNativeApi()?.endpoints.getAllActiveVersionsForReferenceData.matchFulfilled,
			(state, action) => {
				const referenceDataByType: Record<string, IReferenceData> = action.payload.reduce((accum, referenceData) => {
					accum[referenceData.type] = referenceData;
					return accum;
				}, {});
				state.referenceDataByType = referenceDataByType;
				state.referenceDataTypes = Object.keys(referenceDataByType);
			}
		);
	},
});

export const REFERENCE_DATA_REDUCER_PATH = referenceDataStore.name;
export const useReferenceDataStoreSelector = (): IReferenceDataStore =>
	useSelector((state: RootState) => state[REFERENCE_DATA_REDUCER_PATH]);
export const useReferenceData = ({ type }: { type: string }): IReferenceData =>
	useSelector((state: RootState) => state[REFERENCE_DATA_REDUCER_PATH].referenceDataByType[type]);
export const useReferenceDataList = () =>
	useSelector((state: RootState) => Object.values(state[REFERENCE_DATA_REDUCER_PATH].referenceDataByType));

export default referenceDataStore.reducer;
