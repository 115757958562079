import TableColumn from './TableColumn';
import { sortNumberForField } from './util';

export default class NumberColumn extends TableColumn {
    sortFunc(...args) {
        // when sortField is not defined - use dataField
        const { dataField, sortField = dataField } = this.props;
        return sortNumberForField(sortField, ...args);
    }
}

NumberColumn.defaultProps = {
    ...TableColumn.defaultProps,
    dataAlign: 'right',
    headerAlign: 'right',
    wrapContent: true,
    dataSort: true,
    searchable: true,
    columnSelectorDisplay: false,
    columnSelectorFixed: false,
    columnSelectorDefaultDisplay: true
};
