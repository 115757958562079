import { JsCamConfigType, CamConfigTypes } from '../types/CamConfig.types';
import preval from 'preval.macro';

/**
 * Singleton instance of configuration
 *
 * @type {Object}
 */
let configInstance: JsCamConfigType;

/**
 * Check if configuration object was initialised, redirect to respective page otherwise.
 *
 * @param  {Object} history
 * @param  {Object} context = window
 */
export function failOnMissingConfiguration(history, context = window) {
	if (!context.TORQUE_ITS_CAM_CONFIG) {
		history.push('/missingConfiguration');
	}
}

/**
 * Initialise only a part of configuration responsible for Raygun setup.
 *
 * @param  {Object} [context=window]
 */
export function preInitConfig(context = window) {}

/**
 * Initialise configuration
 *
 * @param  {Object} [context=window]
 */
function initConfig(context = window) {
	const source = context.TORQUE_ITS_CAM_CONFIG;
	const CONTEXT_PATH = getContextPath();
	const config = {
		API_URL_ROOT: getApiUrlRoot(source, context),
		CONTEXT_PATH,
		AUTHENTICATION_PROVIDERS: source.AUTHENTICATION_PROVIDERS,
		RAYGUN_API_KEY: getRaygunKey(source),
		RAYGUN_PULSE_SUPPORT: getRaygunPulseSupport(source),
	};

	configInstance = {
		...configInstance,
		...source,
		...config,
	};
}

/**
 * Getter for configuration singleton.
 * Be aware to call initConfig() first!
 */
/*export default function() {
    if (!configInstance) {
        initConfig();
    }
    return configInstance;
}*/
export default function (): CamConfigTypes {
	if (!configInstance) {
		initConfig();
	}
	return {
		getApiUrlRoot: () => configInstance.API_URL_ROOT,
		getAuthenticationProviders: () => configInstance.AUTHENTICATION_PROVIDERS,
		getRaygunApiKey: () => configInstance.RAYGUN_API_KEY,
		getRaygunPulseSupport: () => configInstance.RAYGUN_PULSE_SUPPORT,
	};
}

/**
 * Build the API url from the location information
 */
function buildApiUrl(context) {
	const { protocol, hostname, port } = context.location;
	return `${protocol}//${hostname}:${port}/cam-core/`;
}

/**
 * Ensure that the url ends with a /
 */
function appendEndingSlash(url) {
	if (!url.endsWith('/')) {
		return url.concat('/');
	}
	return url;
}

/**
 * Get API url or compose default.
 *
 * @param  {Object} config
 * @param  {Object} context
 */
function getApiUrlRoot(config, context) {
	if (config && config.API_URL_ROOT) {
		return appendEndingSlash(config.API_URL_ROOT);
	}
	return buildApiUrl(context);
}

/**
 * Get context path of application from html base tag.
 */
function getContextPath() {
	// get first occurance of <base /> tag
	const baseTag = document.getElementsByTagName('base').item(0);
	// if it has anything in href attribute, it will be context path we need.
	if (baseTag && baseTag.getAttribute('href')) {
		return appendEndingSlash(baseTag.getAttribute('href'));
	}
	return '/';
}

function getRaygunKey(config) {
	if (config && config.RAYGUN_CONFIG && config.RAYGUN_CONFIG.API_KEY) {
		return config.RAYGUN_CONFIG.API_KEY;
	}
	return null;
}

function getRaygunPulseSupport(config) {
	if (config && config.RAYGUN_CONFIG && config.RAYGUN_CONFIG.PULSE_SUPPORT) {
		return config.RAYGUN_CONFIG.PULSE_SUPPORT;
	}
	return false;
}

// returns the TORQUE_ITS_CAM_WEB_VERSION and TORQUE_ITS_CAM_WEB_BUILD if they are defined
// If not (e.g. file is missing, or the variable has been named incorrectly),
// we catch the exception and return undefined values.
// Not having the version of the frontend application should not disturb the
// rest of the application, that's why we catch the exception here.
export function getWebappVersion() {
	let version;
	let buildNumber;
	try {
		version = (window as any).TORQUE_ITS_CAM_WEB_VERSION;
	} catch (err) {
		// if the version number is not given, we fail silently by letting
		// the version variable undefined
	}

	try {
		buildNumber = (window as any).TORQUE_ITS_CAM_WEB_BUILD;
	} catch (err) {
		// if the build number is not given, we fail silently by letting
		// the buildNUmber variable undefined
	}

	return { version, buildNumber };
}

// preval is a babel macro that allows us to run code at build time
const REACT_APP_BUILD_DATE: number = preval`module.exports = new Date().getFullYear()`;
export function getCurrentYear() {
	return REACT_APP_BUILD_DATE;
}
