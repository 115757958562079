import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { t } from '../../assets/i18n/translation';
import { Control, FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import React from 'react';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { LiteralUnion } from 'react-hook-form/dist/types/utils';
import { InputType } from 'reactstrap/types/lib/Input';
import './InputFormField.scss';
// eslint-disable-next-line import/no-extraneous-dependencies
import classnames from 'classnames';
export interface IInputFormFieldProps<TFormFields extends FieldValues> {
	name: FieldPath<TFormFields>;
	control: Control<TFormFields>;
	rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
	type?: InputType;
	icon?: React.ReactElement;
	alias?: string;
	disabled?: boolean;
	containerClassName?: string;
	defaultValue?: UseControllerProps<TFormFields>;
	changeListener?: <TValue>(name: string, value: TValue) => void;

	translations?: {
		label?: string;
		error?: Partial<Record<LiteralUnion<keyof RegisterOptions, string>, string>>;
	};
}

export const InputFormField = <TFormFields extends FieldValues>({
	name,
	control,
	rules,
	translations = {},
	icon,
	containerClassName,
	alias,
	defaultValue,
	changeListener,
	...rest
}: IInputFormFieldProps<TFormFields>) => {
	const {
		field: { onChange, value, ...field },
		fieldState: { error, invalid, isDirty },
	} = useController<TFormFields>({
		name,
		control,
		rules,
		defaultValue: defaultValue as never,
	});

	const fieldClassName = classnames({
		field: true,
		'is-invalid': invalid,
	});

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange({ ...e, target: { ...e.target, name } });
	const handleBlur = (event) => {
		if (changeListener && isDirty) changeListener(name, event.target.value);
		field.onBlur();
	};

	return (
		<FormGroup className={containerClassName}>
			<Label className="field-label">{t(translations.label)}:</Label>
			<div className={fieldClassName} style={{ display: 'flex', alignItems: 'center' }}>
				<Input
					invalid={invalid}
					value={(value || defaultValue) as unknown as string}
					className="form-control field-input"
					{...rest}
					{...field}
					onChange={handleChange}
					onBlur={handleBlur}
					name={alias || name}
				/>
				{icon && <span className="input-group-addon">{icon}</span>}
			</div>
			<FormFeedback className="field-error">{error?.message || translations?.error?.[error?.type]}</FormFeedback>
		</FormGroup>
	);
};
