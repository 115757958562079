import { API_TAGS, camApi } from './api';
import {
	ICheckpointEvaluationStatus,
	IContract,
	IContractActivation,
	IContractActivationAction,
	IContractActivationActionProjection,
	IContractActivationActivityItem,
	ICreateContractActivationComment,
	IRecentContractActivation,
} from '../types/Contract.types';
import { IGenericList } from 'src/types/Generic.types';
import { NotificationPayload, pushSuccess } from '../components/NotificationsHandler';
import { MutationExtraOptions } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { GenericBaseQuery } from '../types/Api';
import { t } from '../assets/i18n/translation';
import { LocalStorageCache } from '../utils/localStorageCache';
import { IContractActivationView } from '../types/ContractActivationView.types';
import { ContractActivationViewMapper } from '../domains/ContractActivation/ContractActivationViewMapper';

const handleSuccess: <T>(
	getNotification: (arg: T) => NotificationPayload
) => MutationExtraOptions<(typeof API_TAGS)[number], unknown, T, GenericBaseQuery>['onCacheEntryAdded'] =
	(getNotification) => async (arg, api) => {
		try {
			await api.cacheDataLoaded;

			// timeout is set in order to wait until notification containers change
			// For example when comment or document uploaded we open dialog window with it's own notification container
			// once action is successful dialog will be closed along with success message,
			// so we need to wait some time until dialog is closed and notification container is changed
			setTimeout(() => api.dispatch(pushSuccess(getNotification(arg))), 100);
		} catch (err) {
			// error is ignored and has another place to be handled
		}
	};

export const contractsApi = camApi.injectEndpoints({
	endpoints: (build) => ({
		getAllContracts: build.query<IGenericList<IContract>, { filters: Record<string, unknown>; key?: string }>({
			query: ({ filters }) => ({
				url: `api/contractactivation/workflow`,
				method: 'post',
				data: filters,
			}),
			serializeQueryArgs: (args) => args.queryArgs.key,
		}),
		getContractView: build.query<IContractActivationView, { applicationId: string | number; version: string | number }>({
			providesTags: (result, error, { applicationId }) => [{ type: 'contract-activation' as const, id: applicationId }],
			query: ({ applicationId, version }) => ({
				url: `api/contractactivation/${applicationId}/versions/${version}/view`,
				method: 'get',
			}),
			transformResponse: (result): IContractActivationView => {
				const view = result as unknown as IContractActivationView;
				return ContractActivationViewMapper.toContractActivationView(view);
			},
		}),
		getRecentContracts: build.query<IRecentContractActivation[], void>({
			providesTags: ['contract-activation-recent'],
			query: () => ({ url: `api/contractactivation/recent`, method: 'get' }),
		}),
		applyContractActivationAction: build.mutation<
			IContractActivation,
			{
				action: IContractActivationAction | IContractActivationActionProjection;
				contract: IContractActivation;
			}
		>({
			invalidatesTags: (result, error, { contract }) => [
				'contract-activation-workflow',
				{ type: 'contract-activation' as const, id: contract.applicationId },
				{
					type: 'contract-activation-activity' as const,
					id: contract.applicationId,
				},
			],
			query: ({ action, contract }) => ({
				url: `api/contractactivation/${contract.applicationId}/versions/${contract.versionNumber}/actions/${action.id}/apply`,
				method: 'post',
			}),
			onCacheEntryAdded: handleSuccess((request) => ({
				id: 'action',
				message: t('contract.activation.action.message.success', request.action.name),
			})),
		}),

		uploadDocuments: build.mutation<void, { files: FileList; contract: IContractActivation }>({
			invalidatesTags: (result, error, { contract }) => [
				'contract-activation-workflow',
				{ type: 'contract-activation' as const, id: contract.applicationId },
				{
					type: 'contract-activation-activity' as const,
					id: contract.applicationId,
				},
			],
			query: ({ contract: { applicationId, versionNumber }, files }) => {
				const form = new FormData();
				Array.from(files).forEach((file) => form.append('documents', file));

				return {
					url: `api/contractactivation/${applicationId}/versions/${versionNumber}/files`,
					method: 'put',
					data: form,
				};
			},
			onCacheEntryAdded: handleSuccess(() => ({
				id: 'documents',
				message: t('contract.activation.documents.message.success'),
			})),
		}),
		createComment: build.mutation<
			void,
			{
				request: ICreateContractActivationComment;
				contract: IContractActivation;
			}
		>({
			invalidatesTags: (result, error, { contract }) => [
				'contract-activation-workflow',
				{ type: 'contract-activation' as const, id: contract.applicationId },
				{
					type: 'contract-activation-activity' as const,
					id: contract.applicationId,
				},
			],
			query: ({ contract: { applicationId, versionNumber }, request }) => {
				return {
					url: `api/contractactivation/${applicationId}/versions/${versionNumber}/comments`,
					method: 'put',
					data: request,
				};
			},
			onCacheEntryAdded: handleSuccess(() => ({
				id: 'comment',
				message: t('contract.activation.comment.message.success'),
			})),
		}),
		updateCheckpoint: build.mutation<
			void,
			{
				contract: IContractActivation;
				checkpointCode: string;
				checkpointItemCode: string;
				status: ICheckpointEvaluationStatus;
			}
		>({
			invalidatesTags: (result, error, { contract }) => [
				'contract-activation-workflow',
				{ type: 'contract-activation' as const, id: contract.applicationId },
				{
					type: 'contract-activation-activity' as const,
					id: contract.applicationId,
				},
			],
			query: ({ contract: { applicationId, versionNumber }, checkpointCode, checkpointItemCode, status }) => {
				return {
					url: `api/contractactivation/${applicationId}/versions/${versionNumber}/checkpoint`,
					method: 'post',
					data: {
						status,
						itemCode: checkpointItemCode,
						checkpointCode,
					},
				};
			},
		}),
		selfAssignment: build.mutation<void, { contract: IContractActivation | IContract }>({
			invalidatesTags: (result, error, { contract }) => [
				'contract-activation-workflow',
				{ type: 'contract-activation' as const, id: contract.applicationId },
				{
					type: 'contract-activation-activity' as const,
					id: contract.applicationId,
				},
			],
			query: ({ contract }) => {
				const versionNumber = 'versionNumber' in contract ? contract.versionNumber : contract.activationVersionNumber;
				return {
					url: `api/contractactivation/${contract.applicationId}/versions/${versionNumber}/assignments/me`,
					method: 'put',
				};
			},
			onCacheEntryAdded: handleSuccess(() => ({
				id: 'assignment',
				message: t('contract.activation.assignment.message.success', LocalStorageCache.getUser().fullName),
			})),
		}),
		selfUnassignment: build.mutation<void, { contract: IContractActivation | IContract }>({
			invalidatesTags: (result, error, { contract }) => [
				'contract-activation-workflow',
				{ type: 'contract-activation' as const, id: contract.applicationId },
				{
					type: 'contract-activation-activity' as const,
					id: contract.applicationId,
				},
			],
			query: ({ contract }) => {
				const versionNumber = 'versionNumber' in contract ? contract.versionNumber : contract.activationVersionNumber;
				return {
					url: `api/contractactivation/${contract.applicationId}/versions/${versionNumber}/unassignments/me`,
					method: 'put',
				};
			},
			onCacheEntryAdded: handleSuccess(() => ({
				id: 'assignment',
				message: t('contract.activation.unassignment.message.success', LocalStorageCache.getUser().fullName),
			})),
		}),
		unlockContractActivation: build.mutation<void, { contract: IContractActivation | IContract; statusCode: string }>({
			invalidatesTags: (result, error, { contract }) => [
				'contract-activation-workflow',
				{ type: 'contract-activation' as const, id: contract.applicationId },
				{
					type: 'contract-activation-activity' as const,
					id: contract.applicationId,
				},
			],
			query: ({ contract, statusCode }) => {
				const versionNumber = 'versionNumber' in contract ? contract.versionNumber : contract.activationVersionNumber;
				return {
					url: `api/contractactivation/${contract.applicationId}/versions/${versionNumber}/unlock/?statusCode=${statusCode}`,
					method: 'post',
				};
			},
			onCacheEntryAdded: handleSuccess(() => ({
				id: 'unlock',
				message: t('contract.activation.action.message.success', t('action.unlock')),
			})),
		}),
		getContractActivationActivity: build.query<
			IGenericList<IContractActivationActivityItem>,
			{ applicationId: string | number; version: string | number }
		>({
			providesTags: (result, error, { applicationId }) => [{ type: 'contract-activation-activity' as const, id: applicationId }],
			query: ({ applicationId, version }) => ({
				url: `api/contractactivation/${applicationId}/versions/${version}/activity`,
				method: 'get',
			}),
		}),
	}),
});

export const {
	useLazyGetAllContractsQuery,
	useGetContractViewQuery,
	useApplyContractActivationActionMutation,
	useGetContractActivationActivityQuery,

	useCreateCommentMutation,
	useGetRecentContractsQuery,
	useUploadDocumentsMutation,

	useSelfAssignmentMutation,
	useSelfUnassignmentMutation,

	useUpdateCheckpointMutation,
} = contractsApi;
