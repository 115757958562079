import { camApi } from '../api';
import { JsLanguageType } from '../../types/CamConfig.types';

export const LanguageExtraApi = camApi.injectEndpoints({
	endpoints: (build) => ({
		getLocale: build.query<{ localeDisplayName: string; locale: string }, string>({
			query: (locale) => {
				return {
					url: `api/configuration/language/locale/${locale}`,
					method: 'get',
				};
			},
		}),
		getAvailableLanguages: build.query<JsLanguageType[], void>({
			providesTags: ['available-languages'],
			query: () => ({
				url: 'api/configuration/language/available',
				method: 'get',
			}),
		}),
	}),
});
