/*
 * Copyright 2018, Torque IT Solutions Ltd
 * www.torque-its.com
 */
import chainedFunction from 'chained-function';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent, cloneElement } from 'react';
import uncontrollable from 'uncontrollable';
import { castArray, flow, forEach } from 'lodash/fp';
import Toggle from './Toggle';
import Nav from './Nav';
import NavItem from './NavItem';
import NavIcon from './NavIcon';
import NavText from './NavText';
import match from './match-component';
import { domUtils, widthHeighUtils } from '@torque-common-ui/utils';

const { getViewport } = domUtils;
const EXPANDED_WIDTH = widthHeighUtils.SIDE_NAV_EXPANDED_WIDTH;
const COLLAPSED_WIDTH = widthHeighUtils.SIDE_NAV_COLLAPSED_WIDTH;
const MEDIUM_SCREEN_RESOLUTION = widthHeighUtils.MEDIUM_SCREEN_RESOLUTION;
const SMALL_SCREEN_RESOLUTION = widthHeighUtils.SMALL_SCREEN_RESOLUTION;

class SideNav extends PureComponent {

    componentDidMount() {
        this.updateContentComponent(this.props.expanded);
        // Add the left margin setter for resize event as well
        window.addEventListener('resize', this.updateContentComponentWrapper);
    }

    componentDidUpdate() {
        if (this.props.contentId && getViewport().width > 1280) {
            flow(
                castArray,
                forEach(contentElement => {
                    const contentComponent = document.getElementById(contentElement);
                    // Force the margin left in case it's not set
                    if (contentComponent && !contentComponent.style.marginLeft) {
                        this.updateContentComponent(this.props.expanded);
                    }
                })
            )(this.props.contentId);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateContentComponentWrapper);
        if (this.props.contentId) {
            flow(
                castArray,
                forEach(contentElement => {
                    const contentComponent = document.getElementById(contentElement);
                    if (contentComponent) {
                        this.resetStyles(contentComponent);
                    }
                })
            )(this.props.contentId);
        }
    }

    isToggle = match(Toggle);
    isNav = match(Nav);

    child = {
        toggle: null,
        nav: null
    };

    handleToggleClick = () => {
        if (this.props.disabled) {
            return;
        }
        const expanded = !this.props.expanded;
        if (this.props.onToggle) {
            this.props.onToggle(expanded);
            this.updateContentComponent(expanded);
        }
        // Set the session storage value
        if (window.sessionStorage) {
            window.sessionStorage.setItem('TRQ_SHOW_EXPANDED_MENU', expanded);
        }
    };

    handleNavItemClick = () => {
        // Makes the menu to collapse after selecting item. It always happens when responsive mode
        let viewport = getViewport();
        if (this.props.onToggle && (this.props.collapseAfterSelect || !this.props.expanded || viewport.width <= 1280)) {
            this.props.onToggle(false);
            this.updateContentComponent(false);
            // Add a style class to force the menu items panel to not be displayed
            if (this.navRef) {
                this.navRef.classList.add('hide-sidenav-subnav');
            }
        }
    }

    updateContentComponentWrapper = () => {
        this.updateContentComponent(this.props.expanded);
    }

    updateContentComponent = (expanded) => {
        // Push/Pull the content component when the menu is extended/collapsed
        if (this.props.contentId) {
            const pageWidth = getViewport().width;
            flow(
                castArray,
                forEach(contentElement => {
                    const contentComponent = document.getElementById(contentElement);
                    if (contentComponent) {
                        this.setContentMarginsAndClassNames(contentComponent, expanded, pageWidth);
                    }
                })
            )(this.props.contentId);
        }
    }

    resetStyles = (contentComponent) => {
        if (this.props.calculateContentMargins) {
            contentComponent.style.marginLeft = 'auto';
        }
        contentComponent.classList.remove('expanded-by-sidenav-menu');
        contentComponent.classList.remove('collapsed-by-sidenav-menu');
    }

    setContentMarginsAndClassNames = (contentComponent, expanded, pageWidth) => {
        if (this.props.calculateContentMargins) {
            const contentTotalMaxWidth = this.props.contentMaxWidth + this.props.contentPaddingWidth;
            // Header left margin is calculated by using the page width minus the max allowed width as it's only necessary when page exceeds width max limit.
            // Result is divided by 2 because the first calculation get's both right and left margins.
            const headerLeftMargin = Math.ceil((pageWidth - contentTotalMaxWidth) / 2);
            if (expanded) {
                // Set the max width and margin for cases where the header exceeds the width limit but content width cannot be set as 'auto'.
                if (headerLeftMargin > 0 && EXPANDED_WIDTH + contentTotalMaxWidth > headerLeftMargin + contentTotalMaxWidth) {
                    contentComponent.style.maxWidth = (headerLeftMargin + this.props.contentMaxWidth - EXPANDED_WIDTH) + 'px';
                    contentComponent.style.marginLeft = EXPANDED_WIDTH + 'px';
                } else if (headerLeftMargin > 0 && headerLeftMargin + contentTotalMaxWidth > EXPANDED_WIDTH + contentTotalMaxWidth || pageWidth <= SMALL_SCREEN_RESOLUTION) {
                    // Set the max width and margin for cases where the header exceeds the width limit and content width can be set to 'auto'.
                    contentComponent.style.maxWidth = this.props.contentMaxWidth + 'px';
                    contentComponent.style.marginLeft = 'auto';
                } else {
                    // Set the max width and margin for cases where the header doesn't exceed the width limit.
                    contentComponent.style.maxWidth = this.props.contentMaxWidth - EXPANDED_WIDTH;
                    contentComponent.style.marginLeft = EXPANDED_WIDTH + 'px';
                }
            } else {
                // Set the max width and margin for cases where the header exceeds the width limit but content width cannot be set as 'auto'.
                if (headerLeftMargin > 0 && COLLAPSED_WIDTH + contentTotalMaxWidth > headerLeftMargin + contentTotalMaxWidth) {
                    contentComponent.style.maxWidth = (headerLeftMargin + this.props.contentMaxWidth - COLLAPSED_WIDTH) + 'px';
                    contentComponent.style.marginLeft = COLLAPSED_WIDTH + 'px';
                } else if (headerLeftMargin > 0 && headerLeftMargin + contentTotalMaxWidth > COLLAPSED_WIDTH + contentTotalMaxWidth || pageWidth <= MEDIUM_SCREEN_RESOLUTION) {
                    // Set the max width and margin for cases where the header exceeds the width limit and content width can be set to 'auto'.
                    contentComponent.style.maxWidth = this.props.contentMaxWidth + 'px';
                    contentComponent.style.marginLeft = 'auto';
                } else {
                    // Set the max width and margin for cases where the header doesn't exceed the width limit.
                    contentComponent.style.maxWidth = this.props.contentMaxWidth - COLLAPSED_WIDTH;
                    contentComponent.style.marginLeft = COLLAPSED_WIDTH + 'px';
                }
            }
        }
        // Add class names in the component in order to know it was modified by SideNav component
        if (expanded) {
            contentComponent.classList.add('expanded-by-sidenav-menu');
            contentComponent.classList.remove('collapsed-by-sidenav-menu');
        } else {
            contentComponent.classList.add('collapsed-by-sidenav-menu');
            contentComponent.classList.remove('expanded-by-sidenav-menu');
        }
    }

    renderToggle(child, props) {
        let ref = c => {
            this.child.toggle = c;
        };

        if (typeof child.ref !== 'string') {
            ref = chainedFunction(child.ref, ref);
        }

        return cloneElement(child, {
            ...props,
            ref,
            onClick: chainedFunction(
                child.props.onClick,
                this.handleToggleClick
            )
        });
    }

    renderNav(child, { onSelect, ...props }) {
        let ref = c => {
            this.child.toggle = c;
        };

        if (typeof child.ref !== 'string') {
            ref = chainedFunction(child.ref, ref);
        }

        return cloneElement(child, {
            ...props,
            ref,
            // if it's internal link and no action handler defined - only handle after click should be called
            onSelect: (props.internalLink && !props.action) ? this.handleNavItemClick :
                chainedFunction(
                    child.props.onSelect,
                    onSelect,
                    this.handleNavItemClick
                )
        });
    }

    setNavRef = (el) => {
        this.navRef = el;
    }

    onSidenavHover = () => {
        if (this.navRef) {
            // Remove the style class responsible to force the menu items panel to not be displayed
            this.navRef.classList.remove('hide-sidenav-subnav');
        }
    }

    render() {
        const {
            id,
            componentType,
            disabled,
            expanded,
            onToggle,
            onSelect,
            className,
            children,
            contentId,
            collapseAfterSelect,
            contentMaxWidth,
            contentPaddingWidth,
            calculateContentMargins,
            ...passProps
        } = this.props;

        let disabledStyle;
        if (disabled) {
            disabledStyle = 'disabled';
        }
        let expandedStyle;
        if (expanded) {
            expandedStyle = 'expanded';
        } else {
            expandedStyle = 'collapsed';
        }

        return (
            <nav
                id={id}
                ref={this.setNavRef}
                {...passProps}
                className={classnames(
                    className,
                    'sidenav',
                    disabledStyle,
                    expandedStyle
                )}
                onMouseOver={this.onSidenavHover}
            >
                {React.Children.map(children, child => {
                    if (!React.isValidElement(child)) {
                        return child;
                    }

                    if (this.isToggle(child)) {
                        return this.renderToggle(child, {
                            disabled, expanded
                        });
                    }

                    if (this.isNav(child)) {
                        return this.renderNav(child, {
                            onSelect, expanded
                        });
                    }

                    return child;
                })}
            </nav>
        );
    }
}

SideNav.propTypes = {
    id: PropTypes.string,
    componentType: PropTypes.func,
    // Whether the navigation toggle is disabled.
    disabled: PropTypes.bool,
    // Whether the side navigation is expanded or collapsed.
    expanded: PropTypes.bool,
    // Makes the menu to collapse after item is selected
    collapseAfterSelect: PropTypes.bool,
    // Callback fired when toggling the side navigation between expanded and collapsed state.
    onToggle: PropTypes.func,
    // whether to calculate and enforce certain margins on components identified via "contentId"
    calculateContentMargins: PropTypes.bool.isRequired,
    // Callback fired when a navigation item is selected.
    onSelect: PropTypes.func,
    className: PropTypes.string,
    // The content component ID that will be pushed in case the menu is extended
    contentId: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    // The maximum width allowed for content section
    contentMaxWidth: PropTypes.number,
    // The padding used in the content
    contentPaddingWidth: PropTypes.number,
    children: PropTypes.node
};

SideNav.defaultProps = {
    calculateContentMargins: true,
    collapseAfterSelect: false,
    contentId: 'content',
    contentMaxWidth: 1860,
    contentPaddingWidth: 40,
    componentType: SideNav
};

const UncontrollableSideNav = uncontrollable(SideNav, {
    // Define the pairs of prop/handlers you want to be uncontrollable
    expanded: 'onToggle'
});

UncontrollableSideNav.Toggle = Toggle;
UncontrollableSideNav.Nav = Nav;
UncontrollableSideNav.NavItem = NavItem;
UncontrollableSideNav.NavIcon = NavIcon;
UncontrollableSideNav.NavText = NavText;

export default UncontrollableSideNav;
