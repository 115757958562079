import './Header.scss';

import React from 'react';
import UserMenu from './UserMenu';
import { useBrandingConfig } from '../../domains/System/useBrandingConfig';
import { getHexColour } from '../../utils/colourUtils';

export const Header = () => {
	const branding = useBrandingConfig();
	return (
		<header className="header nav-menu-panel">
			<div className="header-wrapper nav-menu-panel">
				<section
					className={'headerBackground heading nav-menu-panel'}
					style={{ background: getHexColour(branding.headerBackgroundColour) }}
				>
					<div id="heading-container">
						<div className="logoTitle">
							<span className="logo" id="brandingLogo">
								<img src={`data:image/png;base64, ${String(branding.headerLogo.content)}`} alt={branding.headerTitle} />
							</span>
							<div className="headerTitle" style={{ color: getHexColour(branding.headerTitleColour) }}>
								{branding.headerTitle}
							</div>
						</div>
						<div className="header-item user">
							<UserMenu />
						</div>
					</div>
				</section>
			</div>
		</header>
	);
};
