import defaultFavicon from '../../assets/img/favicon.png';
import defaultLogo from '../../assets/img/Torque_logo_final_White.png';
import { APP_NAME } from '../../config/constants';
import { AnyAction, createSlice, isFulfilled, Middleware, PayloadAction } from '@reduxjs/toolkit';
import { Image } from '../../types/File.types';
import { BrandingApi } from '../../api/branding';

export interface IBrandingConfig {
	faviconImage: Image;
	headerBackgroundColour: string;
	headerLogo: Image;
	headerTitle: string;
	headerTitleColour: string;
}

interface BrandingState {
	branding: IBrandingConfig;
}

export const DefaultBrandingConfig: IBrandingConfig = {
	faviconImage: {
		fileName: 'favicon.ico',
		fileMediaType: 'image/png',
		content: String(defaultFavicon).replace(/^.*;base64,/, ''),
	},
	headerBackgroundColour: '#3b3b3b',
	headerLogo: {
		fileName: 'Torque_logo_final_White.png',
		fileMediaType: 'image/png',
		content: String(defaultLogo).replace(/^.*;base64,/, ''),
	},
	headerTitle: APP_NAME,
	headerTitleColour: '#ffffff',
};

const initialState: BrandingState = {
	branding: {
		...DefaultBrandingConfig,
		...Object.entries(window.TORQUE_ITS_CAM_BRANDING_CONFIG ?? {}).reduce((nextBranding, [key, value]) => {
			nextBranding[key] = value ? value : DefaultBrandingConfig[key];
			return nextBranding;
		}, {}),
	},
};

const brandingSlice = createSlice({
	name: 'branding',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(BrandingApi.endpoints.getBrandingConfig.matchFulfilled, (state, action: PayloadAction<IBrandingConfig>) => {
			Object.entries(action.payload).forEach(([key, value]) => {
				state.branding[key] = value ? value : DefaultBrandingConfig[key];
			});
			window.TORQUE_ITS_CAM_BRANDING_CONFIG = state.branding;
		});
	},
});

export const systemSettingsMiddleware: Middleware = (store) => (next) => (action: AnyAction) => {
	if (isFulfilled(action)) {
		const { endpointName } = action.meta.arg as AnyAction['meta']['arg'] & { endpointName?: string };
		// if system configuration updated we need to refetch branding config
		if (['createVersionForSystemConfiguration', 'decideOnVersionForSystemConfiguration'].includes(endpointName)) {
			store.dispatch(BrandingApi.endpoints.getBrandingConfig.initiate() as unknown as AnyAction);
		}
	}
	return next(action);
};

export const BRANDING_REDUCER_PATH = brandingSlice.name;
export default brandingSlice.reducer;
