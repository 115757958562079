import React from 'react';
import Checkbox from '@torque-common-ui/checkbox';
import TableColumn from './TableColumn';
import isNull from 'lodash/fp/isNull';
import isUndefined from 'lodash/fp/isUndefined';

export default class CheckboxColumn extends TableColumn {
    renderLabel(value) {
        if (isNull(value) || isUndefined(value)) {
            return null;
        }

        return (
            <Checkbox
                checked={value}
                disabled
            />
        );
    }
}

CheckboxColumn.defaultProps = {
    ...TableColumn.defaultProps,
    wrapContent: true,
    dataSort: true,
    searchable: true,
    columnSelectorDisplay: false,
    columnSelectorFixed: false,
    columnSelectorDefaultDisplay: true
};
