import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

export interface IFilter {
	[key: string]: unknown;

	name: string;
	label?: string;
	type: 'date' | 'text' | 'select' | 'multiSelect';
	defaultValue?: string;
	transform?: (value?: unknown) => unknown;

	getExtraProps?: (data: Record<string, unknown>) => object;
}

export interface ISelectFilter<TLabel, TValue extends number | string> extends IFilter {
	type: 'select';
	options: Array<{ label: TLabel; value: TValue }>;
}
export interface IMultiSelectFilter<TOption extends { label: string; value: string }> extends IFilter {
	type: 'multiSelect';
	options: TOption[];
	renderOption: (option: TOption) => string | React.ReactElement;
}

type FilterStore = Record<string, unknown>;

interface IFilterStore {
	filterById: Record<string, FilterStore>;
	activeCustomFiltersById: Record<string, IFilter[]>;
}

const initialState: IFilterStore = {
	filterById: {},
	activeCustomFiltersById: {},
};

export const filterSlice = createSlice({
	name: 'filter-storage',
	initialState,
	reducers: {
		setFilters: (state, { payload: { id, filters } }: PayloadAction<{ id: string; filters: FilterStore }>) => {
			state.filterById[id] = filters;
		},
		updateFilter: (state, { payload: { id, name, value } }: PayloadAction<{ id: string; name: string; value: unknown }>) => {
			state.filterById[id][name] = value;
		},
		removeFilter: (state, { payload: { id, name } }: PayloadAction<{ id: string; name: string }>) => {
			delete state.filterById[id][name];
		},
		addCustomFilter: (state, { payload: { id, customFilter } }: PayloadAction<{ id: string; customFilter: IFilter }>) => {
			if (state.activeCustomFiltersById[id]) {
				state.activeCustomFiltersById[id].push(customFilter);
			} else {
				state.activeCustomFiltersById[id] = [customFilter];
			}
		},
		removeCustomFilter: (state, { payload: { id, customFilterName } }: PayloadAction<{ id: string; customFilterName: string }>) => {
			state.activeCustomFiltersById[id] = state.activeCustomFiltersById[id].filter(
				(activeFilter) => customFilterName !== activeFilter.name
			);
			delete state.filterById[id][customFilterName];
		},
		resetFilters: () => initialState,
	},
});

export const FILTER_REDUCER_PATH = filterSlice.name;

export const { setFilters, updateFilter, resetFilters, removeCustomFilter, addCustomFilter } = filterSlice.actions;

export const useFilter = (id: string) => {
	const filter = useSelector((state) => state[FILTER_REDUCER_PATH].filterById[id]);
	return filter || {};
};

export const useActiveFilters = (id: string) => {
	const dispatch = useDispatch();
	const customFilters: IFilter[] = useSelector((state) => state[FILTER_REDUCER_PATH].activeCustomFiltersById[id] || []);

	return {
		activeFilters: customFilters,
		add: (filter: IFilter) => dispatch(addCustomFilter({ id, customFilter: filter })),
		remove: (filterName: IFilter['name']) => dispatch(removeCustomFilter({ id, customFilterName: filterName })),
	};
};

export default filterSlice.reducer;
