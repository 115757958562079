/*
 * Copyright 2018, Torque IT Solutions Ltd
 * www.torque-its.com
 */
import chainedFunction from 'chained-function';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent, cloneElement } from 'react';
import NavItem from './NavItem';
import match from './match-component';
import findComponent from './find-component';
import NavText from './NavText';
import NavIcon from './NavIcon';
import { forEach } from 'lodash';
import 'simplebar';

const noop = () => { };

class Nav extends PureComponent {

    state = {
        expandedNavItem: { item: null, parent: null, grandparent: null },
        selected: this.props.defaultSelected,
        defaultSelected: this.props.defaultSelected
    };

    isNavItem = match(NavItem);

    handleClick = (id, parent, grandparent, totalChildren) => {
        let navItem;
        // When there is no children or it's less than 3 (one for icon and one for text) there's no need to set data into expandedNavItem
        if (!totalChildren || totalChildren < 3) {
            navItem = null;
            parent = null;
            grandparent = null;
        } else if (this.state.expandedNavItem.item) {
            // Level 1
            if (parent === null) {
                navItem = this.state.expandedNavItem.item === id || this.state.expandedNavItem.parent === id || this.state.expandedNavItem.grandparent === id ? '' : id;
            }
            // Level 2
            else if (grandparent === null) {
                if (this.state.expandedNavItem.item === id || this.state.expandedNavItem.parent === id) {
                    navItem = parent;
                    parent = null;
                } else {
                    navItem = id;
                }
            }
        } else {
            navItem = id;
        }
        this.setState({
            expandedNavItem: { item: navItem, parent: parent, grandparent: grandparent }
        });
    }

    thirdLevelResolver = (thirdLevel, secondLevelId, firstLevelId, thirdLevels, changedThirdLevels, onSelect) => {
        if (!thirdLevel) {
            return;
        }
        if (thirdLevel.props && thirdLevel.props.id) {
            let changedThirdLevel = cloneElement(thirdLevel, {
                parent: secondLevelId,
                onClick: chainedFunction(
                    thirdLevel.props.onClick,
                    () => {
                        this.handleClick(thirdLevel.props.id, secondLevelId, firstLevelId, 0);
                    }
                ),
                onSelect: chainedFunction(
                    this.state.defaultSelected ?
                        (selected) => {
                            this.setState({ selected: selected });
                        } : noop,
                    thirdLevel.props.onSelect,
                    onSelect
                )
            });
            if (thirdLevel.props.toBeShown) {
                thirdLevels.push(thirdLevel.props.id);
                changedThirdLevels.push(changedThirdLevel);
            }
        } else {
            changedThirdLevels.push(thirdLevel);
        }
    }

    secondLevelResolver = (secondLevel, firstLevelId, changedSecondLevels, secondLevels, thirdLevelsIds, onSelect) => {
        if (secondLevel && secondLevel.props && secondLevel.props.id && secondLevel.props.toBeShown) {
            // Third Level setup
            let changedThirdLevels = [];
            let thirdLevels = [];
            if (secondLevel.props.children && secondLevel.props.children.length > 0) {
                forEach(secondLevel.props.children, thirdLevel => {
                    if (Array.isArray(thirdLevel)) {
                        forEach(thirdLevel, thirdLevelObject => {
                            this.thirdLevelResolver(thirdLevelObject, secondLevel.props.id, firstLevelId, thirdLevels, changedThirdLevels, onSelect);
                        });
                    } else {
                        this.thirdLevelResolver(thirdLevel, secondLevel.props.id, firstLevelId, thirdLevels, changedThirdLevels, onSelect);
                    }
                });
            } else {
                changedThirdLevels = secondLevel.props.children;
            }

            // if there is only one at third level - replace second level
            if (thirdLevels.length === 1) {
                secondLevel = findComponent(NavItem)(changedThirdLevels);
                const icon = findComponent(NavIcon)(secondLevel.props.children) || findComponent(NavIcon)(changedThirdLevels);
                const text = findComponent(NavText)(secondLevel.props.children);
                changedThirdLevels = [
                    cloneElement(icon),
                    cloneElement(text)
                ];
            }

            const secondLevelExpanded = this.props.expanded && (
                (!!this.state.expandedNavItem && this.state.expandedNavItem.item === secondLevel.props.id) ||
                thirdLevels.includes(this.state.expandedNavItem.item));

            // Second level setup
            let changedSecondLevel = cloneElement(secondLevel, {
                parent: firstLevelId,
                expanded: secondLevelExpanded,
                children: changedThirdLevels,
                onClick: chainedFunction(
                    secondLevel.props.onClick,
                    () => {
                        this.handleClick(secondLevel.props.id, firstLevelId, null, changedThirdLevels.length);
                    }
                ),
                onSelect: chainedFunction(
                    this.state.defaultSelected ?
                        (selected) => {
                            this.setState({ selected: selected });
                        } : noop,
                    secondLevel.props.onSelect,
                    onSelect
                )
            });
            thirdLevelsIds.push.apply(thirdLevelsIds, thirdLevels);
            secondLevels.push(changedSecondLevel);
            changedSecondLevels.push(changedSecondLevel);
        } else {
            changedSecondLevels.push(secondLevel);
        }
    }

    renderNavItem(child, { onSelect, ...props }) {
        const { id, children } = { ...child.props };

        // Children of child. It's used to build the second and third levels
        let changedSecondLevels = [];
        let secondLevels = [];
        let thirdLevelsIds = [];
        if (children) {
            forEach(children, secondLevel => {
                if (secondLevel) {
                    if (Array.isArray(secondLevel)) {
                        forEach(secondLevel, secondLevelObject => {
                            this.secondLevelResolver(secondLevelObject, id, changedSecondLevels, secondLevels, thirdLevelsIds, onSelect);
                        });
                    } else {
                        this.secondLevelResolver(secondLevel, id, changedSecondLevels, secondLevels, thirdLevelsIds, onSelect);
                    }
                }
            });
        }

        if (secondLevels.length === 1) {
            return secondLevels[0];
        }

        const expanded = this.props.expanded && (
            (!!this.state.expandedNavItem && this.state.expandedNavItem.item === child.props.id) ||
            secondLevels.some(o => o.props.id === this.state.expandedNavItem.item) ||
            thirdLevelsIds.includes(this.state.expandedNavItem.item));

        // First level setup
        return cloneElement(child, {
            ...props,
            key: id,
            children: changedSecondLevels,
            secondLevels: secondLevels,
            expanded: expanded,
            onClick: chainedFunction(
                child.props.onClick,
                () => {
                    this.handleClick(id, null, null, changedSecondLevels.length);
                }
            ),
            onSelect: chainedFunction(
                this.state.defaultSelected ?
                    (selected) => {
                        this.setState({ selected: selected });
                    } : noop,
                child.props.onSelect,
                onSelect
            )
        });
    }

    resetSelect = () => {
        this.setState({
            selected: null,
            expandedNavItem: { item: null, parent: null, grandparent: null }
        });
    }

    render() {
        const {
            id,
            componentType,
            onSelect,
            selected,
            defaultSelected,
            // Props passed from SideNav component
            expanded,
            className,
            children,
            ...props
        } = this.props;

        const currentSelected = this.state.defaultSelected
            ? this.state.selected
            : selected;

        let expandedStyle;
        if (expanded) {
            expandedStyle = 'expanded';
        }

        return (
            <div
                id={id}
                {...props}
                role="menu"
                data-simplebar
                className={classnames(
                    className,
                    'sidenav-nav',
                    expandedStyle
                )}
            >
                {React.Children.map(children, child => {
                    if (React.isValidElement(child) && this.isNavItem(child)) {
                        return this.renderNavItem(child, {
                            onSelect,
                            selected: currentSelected,
                            expandedNavItem: this.state.expandedNavItem.item,
                            expandedSideNav: expanded,
                            resetSelect: this.resetSelect
                        });
                    }
                    return child;
                })}
            </div>
        );
    }
}

Nav.propTypes = {
    id: PropTypes.string,
    componentType: PropTypes.func,
    // Callback fired when a navigation item is selected.
    onSelect: PropTypes.func,
    // The selected navigation item.
    selected: PropTypes.func,
    // The initially selected navigation item.
    defaultSelected: PropTypes.string,
    // Whether the side navigation is expanded or collapsed.
    expanded: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node
};

Nav.defaultProps = {
    componentType: Nav
};

export default Nav;