import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { BRANDING_REDUCER_PATH, IBrandingConfig } from './branding.store';

export const useBrandingConfig: () => IBrandingConfig = () => {
	const branding = useSelector((state: RootState) => state[BRANDING_REDUCER_PATH].branding);

	useLayoutEffect(() => {
		// clean up existing favicons to avoid duplicates
		for (const existingLink of document.head.querySelectorAll('link[rel="icon"]')) {
			document.head.removeChild(existingLink);
		}

		const link = document.createElement('link');
		link.type = branding.faviconImage.fileMediaType;
		link.rel = 'icon';
		link.href = `data:${branding.faviconImage.fileMediaType};base64, ${String(branding.faviconImage.content)}`;
		document.head.appendChild(link);
	}, [branding.faviconImage]);

	return branding;
};
