import map from 'lodash/fp/map';
import reduce from 'lodash/reduce';

/**
 * Scramble full name.
 *
 * @param {*} fullName
 */
export function scrambleFullName(fullName) {
    if (!fullName) {
        return null;
    }

    let fragments = reduce(fullName.split(/\s/), (result, value) => {
        if (result.length === 0) {
            result.push(value);
        } else if (value) {
            result.push(value[0] + '.');
        }
        return result;
    }, []);
    return fragments.join(' ');
}

/**
 * Scramble email
 *
 * @param {*} email
 */
export function scrambleEmail(email) {
    if (!email) {
        return null;
    }
    const fragments = email.split(/\@/);
    if (fragments.length < 2) {
        return null;
    }

    return map((subFragment) =>
        map(fragment => {
            if (fragment.length < 4) {
                return fragment;
            }
            return fragment.substr(0, 3) + '*'.repeat(fragment.length - 3);
        })(subFragment.split(/\./)).join('.')
    )(fragments).join('@');
}

/**
 * Scramble login
 *
 * @param {*} login
 */
export function scrambleLogin(login) {
    if (!login) {
        return null;
    }

    return map(fragment => {
        if (fragment.length < 4) {
            return fragment;
        }
        return fragment.substr(0, 3) + '*'.repeat(fragment.length - 3);
    })(login.split(/\./)).join('.');
}