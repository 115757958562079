/*
 * Copyright 2018, Torque IT Solutions Ltd
 * www.torque-its.com
 */
const NavIcon = () => {
    throw new Error('should not render NavIcon component');
};

NavIcon.defaultProps = {
    ...NavIcon.defaultProps,
    componentType: NavIcon
};

export default NavIcon;