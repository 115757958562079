import React, { memo } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import ScrollableContent from 'src/components/ScrollableContent/ScrollableContent';
import AuthenticationProviderElement from './AuthenticationProviderElement';
import { t } from 'src/assets/i18n/translation';
import camConfig from 'src/config/camConfig';
import { ILocationState } from '../../types/Common.types';
import { AuthenticationProviderType } from '../../types/CamConfig.types';
import { useAuth } from '../../domains/User/useAuth';
import { NotificationsContainer } from 'src/components/NotificationsHandler';

export const LoginPage = memo(() => {
	const navigate = useNavigate();
	const { arePermissionsLoaded } = useAuth();

	const location = useLocation();

	const { from } = (location.state as ILocationState) || {
		from: { pathname: '/', search: location.search },
	};
	if (arePermissionsLoaded) {
		return <Navigate to={from} replace />;
	}

	const authProviders = camConfig().getAuthenticationProviders();

	const onAuthProviderSelected = (provider: AuthenticationProviderType) => {
		if (provider.type === 'LOGIN_PASSWORD') {
			navigate('/loginForm');
			return;
		}
		if (provider.redirectUrl) {
			window.location = provider.redirectUrl;
		}
	};

	const renderProvider = (provider: AuthenticationProviderType) => {
		return <AuthenticationProviderElement key={provider.id} code={provider.code} onClick={onAuthProviderSelected} provider={provider} />;
	};

	return (
		<ScrollableContent>
			<NotificationsContainer></NotificationsContainer>
			<div id="loginOptionsScreen">
				<div className="login-options-container">
					<div>
						<div className="ui-panel ui-widget ui-widget-content login-options">
							<p className="title">{t('login.options.title')}</p>
							<p className="title-hint">{t('login.options.title.hint')}</p>
							<div className="login-option-table">{authProviders?.map(renderProvider)}</div>
						</div>
					</div>
				</div>
			</div>
		</ScrollableContent>
	);
});

LoginPage.displayName = 'LoginPage';
