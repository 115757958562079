const returnValidMessage = (defaultMessage: string, message?: string | string[]) => (message && message.length ? message : defaultMessage);

export type ResponseError = { response: { data: { errors?: Array<{ message?: string }> } } } | { response: { data?: string } };
export type ConstraintsError = { constraints?: Record<string, string> };

const isResponseError = (error: object): error is ResponseError => 'response' in error;
const isConstraintsError = (error: object): error is ConstraintsError => 'constraints' in error;

const isError = (error: object): error is Error | { message?: string } => error instanceof Error || 'message' in error;

const buildMessageIfValidationError = (error: object): string => {
	const errors = new Set();
	Object.values(error).forEach((value: unknown) => {
		if (typeof value !== 'object') {
			errors.add(value);
			return;
		}

		if ('ref' in value && 'type' in value && 'message' in value) {
			errors.add(value.message);
		}
	});
	return Array.from(errors).join(' ');
};

export const extractErrorMessage = (error: object | string | undefined, defaultMessage: string) => {
	if (typeof error === 'string') {
		return returnValidMessage(defaultMessage, error);
	}
	if (!error) {
		return returnValidMessage(defaultMessage, undefined);
	}

	if (isResponseError(error)) {
		if (typeof error.response.data === 'string') {
			return returnValidMessage(defaultMessage, error.response.data);
		}
		if (error.response.data && error.response.data.errors) {
			return returnValidMessage(defaultMessage, error.response.data.errors[0].message);
		}
	}
	if (isConstraintsError(error)) {
		return returnValidMessage(
			defaultMessage,
			Object.entries(error.constraints).map((entry) => entry.join(': '))
		);
	}

	if (isError(error)) {
		return returnValidMessage(defaultMessage, error.message);
	}

	if (typeof error === 'object') {
		return Object.values(error).join('; ');
	}

	return returnValidMessage(defaultMessage, buildMessageIfValidationError(error));
};
