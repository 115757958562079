import './Header.scss';
import { MenuContainer, MenuGroup } from 'src/components/SharedLibrary';
import React from 'react';
import { t } from 'src/assets/i18n/translation';
import { useAuth } from '../../domains/User/useAuth';

const UserMenu = () => {
	const { arePermissionsLoaded, account, logout } = useAuth();
	if (!arePermissionsLoaded) return <div />;

	const buildUserMenuItems = () => {
		return [
			{
				name: (
					<span>
						<strong>Email</strong>: {account.email}
					</span>
				),
				toBeShown: true,
			},
			{
				isDivider: true,
				toBeShown: true,
			},
			{
				name: t('header.userMenu.changeLogout'),
				onClick: logout,
				toBeShown: true,
			},
		];
	};

	return (
		<MenuContainer useBurger={false}>
			<MenuGroup title={account.firstName} menuItems={buildUserMenuItems()} menuId="userMenu" icon="user" fontAwesomeIcons />
		</MenuContainer>
	);
};

export default UserMenu;
