/*
 * Copyright 2017, Torque IT Solutions Ltd
 * www.torque-its.com
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Sticky from './component/Sticky';
import classnames from 'classnames';

/**
 * Sticky component.
 */
class StickyComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSticky: false,
            topOffset: 0
        };
    }

    componentDidMount() {
        this.updateTopOffset();
    }

    componentDidUpdate() {
        // scroll to trigger sticky container event
        let container = this.context.getContainer();
        if (container) {
            container.notifySubscribers('scroll');
        }
        this.updateTopOffset();
    }

    updateTopOffset = () => {
        let topOffset = this.refs.stickyWrapper.getBoundingClientRect().top;
        if (!this.context.isInsideDialog) {
            if (window.pageYOffset) {
                topOffset = topOffset + window.pageYOffset;
            }
        } else {
            // always sticky in dialogs
            topOffset = 1;
        }
        if (this.state.topOffset !== topOffset) {
            setTimeout(() => { this.setState({ topOffset }); }, 0);
        }
    };

    handleStickyStateChange = isSticky => {
        if (isSticky !== this.state.isSticky && this.props.onStickyStateChange) {
            setTimeout(() => {
                this.setState({ isSticky });
                this.props.onStickyStateChange(isSticky);
            }, 0);
        }
    };

    render() {
        let relative = this.context.isInsideDialog ? true : false;
        let topOffset = this.state.topOffset;
        return (
            <div id={this.props.id} className={this.props.wrapperClassName} ref="stickyWrapper">
                <Sticky
                    topOffset={topOffset}
                    relative={relative}
                    disableHardwareAcceleration={this.props.disableHardwareAcceleration}
                >
                    {({ style, isSticky }) => {
                        this.handleStickyStateChange(isSticky);
                        let childNode = this.props.children;
                        let childClassName = childNode.props.className;
                        if (isSticky) {
                            childClassName = classnames(childNode.props.className, this.props.stickyClassName);
                            if (!relative) {
                                style = Object.assign({}, style, this.props.stickyStyle);
                            } else {
                                let dialogBody = this.context.getParent();
                                let top = this.props.inDialogStickyStyle.top ? this.props.inDialogStickyStyle.top : 0;
                                if (dialogBody) {
                                    top += dialogBody.getBoundingClientRect().top;
                                }
                                style = Object.assign({}, style, this.props.inDialogStickyStyle, { top });
                            }
                        }
                        childNode = React.cloneElement(this.props.children, {
                            className: childClassName,
                            style: style
                        });
                        return childNode;
                    }}
                </Sticky>
            </div>
        );
    }
}

StickyComponent.propTypes = {
    id: PropTypes.string,
    wrapperClassName: PropTypes.string,
    stickyStyle: PropTypes.shape(),
    stickyClassName: PropTypes.string,
    inDialogStickyStyle: PropTypes.shape(),
    onStickyStateChange: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.func
    ]),
    disableHardwareAcceleration: PropTypes.bool
};

StickyComponent.defaultProps = {
    disableHardwareAcceleration: false
};

StickyComponent.contextTypes = {
    isInsideDialog: PropTypes.bool,
    getContainer: PropTypes.func,
    getParent: PropTypes.func
};

export default StickyComponent;
