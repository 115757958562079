/*
 * Copyright 2019, Torque IT Solutions Ltd
 * www.torque-its.com
 */
import { getViewport } from './domUtils';

export const SIDE_NAV_EXPANDED_WIDTH = 260;
export const SIDE_NAV_COLLAPSED_WIDTH = 60;
export const SMALL_SCREEN_RESOLUTION = 768;
export const MEDIUM_SCREEN_RESOLUTION = 1280;
export const DEFAULT_MARGIN_SPACE = 22;

/**
 * Calculate the maximun width for a component.
 * It's most likely used for select and multi-select components.
 * 
 * @param {*} wrapper 
 * @param {*} container 
 */
export function calculateMaxWidth(wrapper, container) {
    let maxWidth;
    if (wrapper && wrapper.getBoundingClientRect()) {
        const targetOffset = wrapper.getBoundingClientRect();
        const pageWidth = getViewport().width;
        if (container && container.style && container.style.left === '0px') {
            maxWidth = pageWidth - targetOffset.left - DEFAULT_MARGIN_SPACE;
        } else {
            // Right alignment requires also the menu width to be considered
            const menuWidth = getSideNavWidth();
            maxWidth = pageWidth - (pageWidth - targetOffset.right) - DEFAULT_MARGIN_SPACE - menuWidth;
        }
    }
    return maxWidth;
}

/**
 * Return the SideNav menu current width.
 */
export function getSideNavWidth() {
    let menuWidth = 0;
    const pageWidth = getViewport().width;
    // It can be ignored for small screens as it occupies no space.
    if (pageWidth > SMALL_SCREEN_RESOLUTION) {
        // Find out if there is a Side Nav menu in the page.
        const sideNavMenu = document.getElementsByClassName('sidenav');
        if (sideNavMenu && sideNavMenu.length > 0) {
            // Find out if the Side Nav menu is expanded or collapsed
            const sideNavExpanded = document.getElementsByClassName('sidenav expanded');
            const isExpanded = sideNavExpanded && sideNavExpanded.length > 0;
            // Resolutions between small and medium do not render the collapsed menu, thus it is not necessary to consider extra space.
            if (pageWidth > MEDIUM_SCREEN_RESOLUTION || isExpanded) {
                menuWidth = isExpanded ? SIDE_NAV_EXPANDED_WIDTH : SIDE_NAV_COLLAPSED_WIDTH;
            }
        }
    }
    return menuWidth;
}