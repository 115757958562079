import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Collapse, { Panel as CollapsePanel } from 'rc-collapse';
import classnames from 'classnames';
import animation from './animationHelper';
import ReactDOM from 'react-dom';

// Because rc-collapse can potentially hold multiple panels and we just want one,
// we want to implicitly identify one and only one panel we have in Collapse block
// in order to also give identifier of that one panel to Collapse block, so last
// can make it opened by default
const key = '1';

class CollapsiblePanel extends PureComponent {

    componentDidMount() {
        const { registerPanel } = this.props;
        registerPanel();
    }

    componentWillUnmount() {
        const { restoreState, unregisterPanel } = this.props;
        if (restoreState === false) {
            unregisterPanel();
        }
    }

    addKeyEventToPanelHeader = () => {
        // Add the keyboard navigation
        let collapsePanelComponentRef = ReactDOM.findDOMNode(this.collapsePanelComponent);
        if (collapsePanelComponentRef) {
            for (let i = 0; i < collapsePanelComponentRef.childNodes.length; i++) {
                if (collapsePanelComponentRef.childNodes[i].className === 'rc-collapse-header') {
                    let node = collapsePanelComponentRef.childNodes[i];
                    node.tabIndex = 0; // Makes it to be focusable
                    node.onkeydown = this.onKeyDown;
                    break;
                }
            }
        }
    };

    onKeyDown = e => {
        // When 'enter' key is pressed, it calls the click event
        if (e.keyCode === 13) {
            e.target.click();
        }
    };

    setComponentRef = (e) => {
        this.collapsePanelComponent = e;
        this.addKeyEventToPanelHeader();
    }

    render() {
        let {
            title,
            subTitle,
            header,
            children,
            removeBorders,
            isOpened,
            isRegistered,
            setOpenedState,
            className,
            removeBottomMargin,
            isCollapsible,
        } = this.props;

        // do not render panel, if it was not yet registered
        if (!isRegistered) {
            return null;
        }

        const panelClassName = classnames(className, 'trq-collapsible-panel', { 'remove-borders': removeBorders, 'remove-bottom-margin': removeBottomMargin });

        const activeKey = isOpened ? key : null;

        if (!header) {
            header = (
                <Fragment>
                    <div className="panel-title">
                        <h3>
                            {title}
                        </h3>
                    </div>
                    <div className="panel-sub-title trq-ms-hide">
                        {subTitle}
                    </div>
                </Fragment>
            );
        }
        const headerBlock = (
            <div className="flex-header">
                {header}
            </div>
        );
        const childrenDiv = (
            <div>
                {children}
                <div className="clearfix" />
            </div>
        );
        const onChange = () => setOpenedState(!isOpened);

        return (
            <Collapse openAnimation={animation(this.props.autoScrollIntoView)}
                {...{
                    activeKey,
                    className: panelClassName,
                    onChange
                }}
            >
                <CollapsePanel
                    {...{
                        header: headerBlock,
                        children: childrenDiv,
                        key
                    }}
                    headerClass="glyphicons"
                    showArrow={isCollapsible}
                    disabled={!isCollapsible}
                    ref={this.setComponentRef}
                />
            </Collapse>
        );
    }
}

CollapsiblePanel.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.node,
    subTitle: PropTypes.node,
    header: PropTypes.node,
    children: PropTypes.node.isRequired,
    collapsed: PropTypes.bool,
    removeBorders: PropTypes.bool,
    removeBottomMargin: PropTypes.bool,
    onChange: PropTypes.func,
    restoreState: PropTypes.bool,
    isOpened: PropTypes.bool.isRequired,
    isRegistered: PropTypes.bool.isRequired,
    registerPanel: PropTypes.func.isRequired,
    unregisterPanel: PropTypes.func.isRequired,
    setOpenedState: PropTypes.func.isRequired,
    autoScrollIntoView: PropTypes.bool,
    className: PropTypes.string,    
    isCollapsible: PropTypes.bool,
};

CollapsiblePanel.defaultProps = {
    collapsed: false,
    removeBottomMargin: false,
    isCollapsible: true,
};

export default CollapsiblePanel;
