import { AnyAction, createSlice, Middleware, MiddlewareAPI, PayloadAction } from '@reduxjs/toolkit';
import { compareAsc, formatISO, parseISO } from 'date-fns';

export type NotificationType = 'danger' | 'success' | 'info' | 'warning';

export interface Notification {
	id: string | number;
	title?: string;
	message: string | string[];
	type: NotificationType;
	createdAt: string;
}

export interface NotificationPayload {
	id: string | number;
	title?: string;
	message: string | string[];
}

interface NotificationsState {
	notifications: Notification[];
}

const initialState: NotificationsState = {
	notifications: [],
};

const filterByType: Record<NotificationType, (notification: Notification) => boolean> = {
	danger: (notification) => ['danger', 'warning'].includes(notification.type),
	success: (notification) => notification.type !== 'danger',
	info: () => true,
	warning: () => true,
};

const addNotification = (
	(limit = 5) =>
	(prevNotifications: Notification[], newNotification: NotificationPayload, type: Notification['type']) => {
		if (!newNotification.message || !newNotification.message.length) {
			return prevNotifications;
		}
		let notifications = prevNotifications.filter((notification) => notification.id !== newNotification.id).filter(filterByType[type]);
		if (notifications.length >= limit) {
			const oldestNotificationId = notifications.sort((a, b) => compareAsc(parseISO(a.createdAt), parseISO(b.createdAt)))[0].id;
			notifications = notifications.filter((notification) => notification.id !== oldestNotificationId);
		}

		return [{ ...newNotification, type, createdAt: formatISO(new Date()) }, ...notifications];
	}
)();

const notificationsState = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		pushError: (state, action: PayloadAction<NotificationPayload>) => {
			return { ...state, notifications: addNotification(state.notifications, action.payload, 'danger') };
		},
		pushInfo: (state, action: PayloadAction<NotificationPayload>) => {
			return { ...state, notifications: addNotification(state.notifications, action.payload, 'info') };
		},
		pushSuccess: (state, action: PayloadAction<NotificationPayload>) => {
			return { ...state, notifications: addNotification(state.notifications, action.payload, 'success') };
		},
		pushWarning: (state, action: PayloadAction<NotificationPayload>) => {
			return { ...state, notifications: addNotification(state.notifications, action.payload, 'warning') };
		},
		clear: (state, action: PayloadAction<Notification['id']>) => ({
			...state,
			notifications: state.notifications.filter((notification) => notification.id !== action.payload),
		}),
		clearAll: (state, action: PayloadAction<Notification['id'][]>) => {
			if (action.payload) {
				return {
					...state,
					notifications: state.notifications.filter((notification) => !action.payload.includes(notification.id)),
				};
			}
			return { ...state, notifications: [] };
		},
	},
});

export const { pushError, pushInfo, pushWarning, pushSuccess, clear, clearAll } = notificationsState.actions;

export const NOTIFICATIONS_REDUCER_PATH = notificationsState.name;
export const NOTIFICATIONS_CONTAINER_ROLE = 'notifications-container-portal';

export const errorHandlerMiddleware: (customRules: (store: MiddlewareAPI, action: AnyAction) => void) => Middleware =
	(customRules: (store: MiddlewareAPI, action: AnyAction) => void) => (store) => (next) => (action: AnyAction) => {
		customRules(store, action);
		return next(action);
	};

export default notificationsState.reducer;
