import React from 'react';
import * as ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Notification, NOTIFICATIONS_CONTAINER_ROLE, NOTIFICATIONS_REDUCER_PATH } from './notification.store';
import { findParent } from '../../utils/common';
import { NotificationAlert } from './NotificationAlert';
import { compareDesc, parseISO } from 'date-fns';

const getNotificationsContainer = () => {
	const app = document.body;
	if (!app) return;

	const containers = app.querySelectorAll(`[role=${NOTIFICATIONS_CONTAINER_ROLE}]`);
	if (!containers || !containers.length) return;

	const modalContainer = Array.from(containers).find((container) => findParent(container, (el) => el.role === 'dialog'));
	return modalContainer || containers[0];
};

export const useNotificationsRegistry = () => {
	const notifications = useSelector((state: RootState): Notification[] => state[NOTIFICATIONS_REDUCER_PATH].notifications);

	const notificationsContainer = getNotificationsContainer();

	const renderNotifications = (container: Element) =>
		ReactDOM.createPortal(
			[...notifications]
				.sort((a, b) => compareDesc(parseISO(a.createdAt), parseISO(b.createdAt)))
				.map((notification) => <NotificationAlert key={notification.id} notification={notification} />),
			container
		);

	if (!notificationsContainer) return null;
	return renderNotifications(notificationsContainer);
};
