import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import StickyComponent from './own-react-sticky/StickyComponent';
import NewStickyComponent from './react-sticky-el/ReactStickyEl';

/**
 * Component which takes some children and makes them sticky
 */
export default class Sticky extends PureComponent {
    render() {
        if (this.props.useNewVersion) {
            return <NewStickyComponent {...this.props} />;
        }
        return <StickyComponent {...this.props} />;
    }
}

Sticky.propTypes = {
    topOffset: PropTypes.number,
    bottomOffset: PropTypes.number,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.func
    ]),
    // Whether to use the new implementation based on react-sticky-el
    useNewVersion: PropTypes.bool.isRequired
};

Sticky.defaultProps = {
    topOffset: 0,
    bottomOffset: 0,
    useNewVersion: false
};
