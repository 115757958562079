import set from 'lodash/fp/set';
import unset from 'lodash/fp/unset';
import { REGISTER_PANEL, UNREGISTER_PANEL, SET_OPENED_STATE } from './types';

export const SIZE_PER_PAGE = 20;

const initialSubState = {
    isOpened: true,
    isRegistered: false
};

export const panelReducerName = 'panel';

/**
* Reducer is needed if we want to keep isOpened state when component for it is remounted.
* In order to make more generic, we will register each panel when it get's
* mounted in redux store, by specific id.
* At some point, reducer state will look more like:
* panel: {
*  'id-first-panel': {
*      isOpened: true,
*      isRegistered: false
*  },
*  'id-second-panel': {
*      isOpened: false,
*      isRegistered: true
*  }
 * @param  {Object} [state={}]
 * @param  {Object} action
 * @return
 */
export default function reducer(state = {}, action) {
    const setSubState = (value) => set(action.payload && action.payload.id)(value)(state);
    switch (action.type) {
        case REGISTER_PANEL:
            return setSubState({
                ...initialSubState,
                isOpened: action.payload.isOpened,
                isRegistered: true
            });
        case UNREGISTER_PANEL:
            return unset(action.payload.id)(state);
        case SET_OPENED_STATE:
            return setSubState({
                ...state[action.payload.id],
                isOpened: action.payload.isOpened
            });
        default:
            return state;
    }
}

/**
 * Get part of redux store for panel reducer
 *
 * @param  {Object|Store} state - redux store
 * @return {Object}
 */
function getReducerStore(state) {
    const subState = state[panelReducerName];
    if (subState === undefined) {
        throw new Error(`Reducer ${panelReducerName} was never inititalised properly`);
    }
    return subState;
}

/**
 * Is panel already registered?
 *
 * @param  {Object|Store} state - redux store
 * @return {Object}
 */
export function isReducerStoreForPanel(state, panelId) {
    const reducerState = getReducerStore(state);
    // double exclamation mark for implicit conversion to boolean value
    return !!reducerState[panelId];
}

/**
 * Getter for redux state of one specific panel
 *
 * @param  {Object|Store} state - redux store
 * @return {Object}
 */
export function getReducerStoreForPanelId(state, panelId) {
    const reducerState = getReducerStore(state);
    const subState = reducerState[panelId] || initialSubState;
    return subState;
}
