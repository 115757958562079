import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

/**
 * Sticky component based on react-sticky (6.0.1 version).
 * This component became our internal component for 2 reasons: a) There were some bugs to be fixed. b) The authors stopped supporting it.
 */
export default class Sticky extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSticky: false,
            wasSticky: false,
            style: {}
        };
    }

    componentWillMount() {
        if (!this.context.subscribe) {
            throw new TypeError('Expected Sticky to be mounted within StickyContainer');
        }
        this.context.subscribe(this.handleContainerEvent);
    }

    componentDidUpdate() {
        this.placeholder.style.paddingBottom = this.props.disableCompensation ? 0 : `${this.state.isSticky ? this.state.calculatedHeight : 0}px`;
    }

    componentWillUnmount() {
        this.context.unsubscribe(this.handleContainerEvent);
    }

    handleContainerEvent = ({ distanceFromTop, distanceFromBottom, eventSource }) => {
        const parent = this.context.getParent();

        let preventingStickyStateChanges = false;
        if (this.props.relative) {
            preventingStickyStateChanges = eventSource !== parent;
            distanceFromTop = -(eventSource.scrollTop + eventSource.offsetTop) + this.placeholder.offsetTop;
        }

        const placeholderClientRect = this.placeholder && this.placeholder.getBoundingClientRect();
        const contentClientRect = this.content && this.content.getBoundingClientRect();
        const calculatedHeight = contentClientRect && contentClientRect.height;
        const bottomDifference = distanceFromBottom - this.props.bottomOffset - calculatedHeight;
        const wasSticky = !!this.state.isSticky;
        const isSticky = preventingStickyStateChanges ? wasSticky : (distanceFromTop <= -this.props.topOffset && distanceFromBottom > -this.props.bottomOffset);

        let style;
        if (parent) {
            distanceFromBottom = (this.props.relative ? parent.scrollHeight - parent.scrollTop : distanceFromBottom) - calculatedHeight;
            style = !isSticky ? {} : {
                position: 'fixed',
                top: bottomDifference > 0 ? (this.props.relative ? parent.offsetTop - parent.offsetParent.scrollTop : 0) : bottomDifference,
                left: placeholderClientRect.left,
                width: placeholderClientRect.width
            };
            if (!this.props.disableHardwareAcceleration) {
                style.transform = 'translateZ(0)';
            }
        }

        this.setState({
            isSticky,
            wasSticky,
            distanceFromTop,
            distanceFromBottom,
            calculatedHeight,
            style
        });
    }

    setContent = (content) => {
        this.content = ReactDOM.findDOMNode(content);
    }

    setPlaceHolder = (placeholder) => {
        this.placeholder = placeholder;
    }

    render() {
        const element = React.cloneElement(
            this.props.children({
                isSticky: this.state.isSticky,
                wasSticky: this.state.wasSticky,
                distanceFromTop: this.state.distanceFromTop,
                distanceFromBottom: this.state.distanceFromBottom,
                calculatedHeight: this.state.calculatedHeight,
                style: this.state.style
            }),
            { ref: this.setContent }
        );

        return (
            <div>
                <div ref={this.setPlaceHolder} />
                {element}
            </div>
        );
    }
}

Sticky.propTypes = {
    topOffset: PropTypes.number,
    bottomOffset: PropTypes.number,
    relative: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.func
    ]),
    disableCompensation: PropTypes.bool,
    disableHardwareAcceleration: PropTypes.bool
};

Sticky.defaultProps = {
    relative: false,
    topOffset: 0,
    bottomOffset: 0,
    disableCompensation: false,
    disableHardwareAcceleration: false
};

Sticky.contextTypes = {
    subscribe: PropTypes.func,
    unsubscribe: PropTypes.func,
    getParent: PropTypes.func
};