import React from 'react';
import { sendToRaygun } from 'src/api/raygunErrorHandler';

interface IErrorBoundaryProps {
	readonly enhanceWithCustomData?: (error: Error) => object | undefined;
	readonly children: React.ReactNode;
}

interface IErrorBoundaryState {
	readonly error: unknown;
	readonly hasError: boolean;
}

export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
	readonly state: IErrorBoundaryState = { hasError: undefined, error: undefined };

	static getDerivedStateFromError(error: unknown) {
		return { hasError: true, error };
	}

	renderErrorDetails = () => {
		const { hasError, error } = this.state;
		if (!hasError) return;

		return <details className="preserve-space">{error && error.toString()}</details>;
	};

	componentDidCatch(error: Error) {
		const { enhanceWithCustomData } = this.props;

		let customData: object | undefined;
		try {
			customData = enhanceWithCustomData?.(error);
		} catch (e) {
			console.error(e);
		}
		sendToRaygun(error, customData);
	}

	render() {
		const { hasError } = this.state;
		if (hasError) {
			return (
				<div>
					<h2 className="error">An unexpected error has occurred.</h2>
					{this.renderErrorDetails()}
				</div>
			);
		}
		return this.props.children;
	}
}
