/*
 * Copyright 2021, Torque IT Solutions Ltd
 * www.torque-its.com
 */
import { connect } from 'react-redux';
import Dialog from './Dialog';
import { getLoadingIndicatorHeight } from './reducer';
import { setContentHeight, clearContentHeight } from './actions';

const mapStateToProps = (state, ownProps) => ({
    contentHeight: getLoadingIndicatorHeight(state, ownProps.id)
});

const mapDispatchToProps = (dispatch) => ({
    setContentHeight: (id, contentHeight) => dispatch(setContentHeight(id, contentHeight)),
    clearContentHeight: (id) => dispatch(clearContentHeight(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Dialog);
