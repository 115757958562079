import { camApi } from '../api';
import { IContractActivationStatusIcon } from '../../types/Contract.types';

export const StatusIconsApi = camApi.injectEndpoints({
	endpoints: (build) => ({
		getIcons: build.query<IContractActivationStatusIcon[], void>({
			providesTags: (result) =>
				result
					? [
							...result.map(({ id }) => ({
								type: 'contract-activation-statuses-icons' as const,
								id,
							})),
							'contract-activation-statuses-icons',
						]
					: ['contract-activation-statuses-icons'],
			query: () => ({
				url: 'api/configuration/contract-activation-status/statuses/icons',
				method: 'get',
			}),
		}),
	}),
});
