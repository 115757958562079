import React from 'react';
import { t } from 'src/assets/i18n/translation';
import { AuthenticationProviderType } from '../../types/CamConfig.types';

type IAuthenticationProviderElementProps = {
	code: string;
	provider: AuthenticationProviderType;
	onClick: (provider: AuthenticationProviderType) => void;
};

const AuthenticationProviderElement = ({ onClick, provider, code }: IAuthenticationProviderElementProps) => {
	const onProviderClick = () => {
		onClick(provider);
	};

	const image = provider && provider.logo ? `data:image/png;base64,${atob(provider.logo)}` : '';
	return (
		<div className="login-option-row" onClick={onProviderClick}>
			<div className="img">
				<img src={image} alt={`${provider.displayName} logo`} />
			</div>
			<div className="text">{provider.displayName}</div>
		</div>
	);
};

export default AuthenticationProviderElement;
