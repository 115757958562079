import React from 'react';
import FontAwesomeIcon from '@torque-common-ui/fontawesome-icon';

/**
 * Renderer for header of expand column
 */
export const expandHeaderColumnRenderer = () => null;

export function expandColumnRenderer({ expanded, expandable }) {
    if (expandable) {
        if (expanded) {
            return (<FontAwesomeIcon icon="fa-angle-up" className="expand-button-v3" />);
        }
        return (<FontAwesomeIcon icon="fa-angle-down" className="expand-button-v3" />);
    }

    return '';
}