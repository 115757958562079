/*
 * Copyright 2023, Torque IT Solutions Ltd
 * www.torque-its.com
 */

/* global rg4js */
export function raygunErrorHandler(error) {
	sendToRaygun(error);
}

// named export instead of default for testabillity
export function raygunApiErrorHandler(errorObject) {
	const { payload, error } = errorObject;
	let customData = {};
	// 500 from CAM API not get caught, it should be sent from backend application
	if (payload.code === 500) {
		return;
	}
	// response bodies that have errorCode and errorMessage should be sent as custom data.
	if (error && error.errorCode !== undefined && error.errorMessage !== undefined) {
		customData = { error };
	}
	sendToRaygun(errorObject, customData);
}

// does the magic
export function sendToRaygun(error, customData) {
	if (process.env.NODE_ENV === 'development') {
		// eslint-disable-next-line no-console
		console.error(error);
	}
	if (rg4js) {
		rg4js('send', {
			error,
			customData,
		});
	}
}
