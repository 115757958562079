import flow from 'lodash/fp/flow';
import compact from 'lodash/fp/compact';
import concat from 'lodash/fp/concat';
import pull from 'lodash/fp/pull';
import uniq from 'lodash/fp/uniq';
import indexOf from 'lodash/fp/indexOf';

/**
 * Util function, manages 'next' state which needs to be specified for multiToggle.
 * It either push new element to a stack or removes it, when element was already there.
 *
 * @param  {Array}  [prevState=[]] previous state
 * @param  {Number|String|Object} newValue
 * @return next state
 */
export function onValueChangeInStack(prevState = [], newValue) {
    const doesExist = indexOf(newValue)(prevState) > -1;
    if (doesExist) {
        return pull(newValue)(prevState);
    } else {
        return flow(
            concat(newValue),
            compact,
            uniq
        )(prevState);
    }
}
