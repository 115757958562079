import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { ErrorBoundary } from './components/ErrorBoundary';
import { App } from './App';
import { loadIcons } from './config/iconLoader';
import { BrowserRouter, matchPath } from 'react-router-dom';
import { getStore } from './store';

import { preInitRaygunConfiguration } from './config/configureRaygun';
import { ROUTE_PATH } from './routes';
import { contractsApi } from './api';

const store = getStore();
// method created for handling errors and populating some extra data for Raygun
const handleBoundaryError = (): object | undefined => {
	const match = matchPath('/cam' + ROUTE_PATH.CONTRACT_ACTIVATION, window.location.pathname);
	if (match) {
		const {
			params: { applicationId, version },
		} = match;
		const customData: { applicationId: string; version: string; accessLevel?: string } = { applicationId, version };

		// nothing illegal here; just a way to get the current contract activation from cache
		// in case contract activation is not loaded yet we put path params such as applicationId and version
		const { data } = contractsApi.endpoints.getContractView.select({ applicationId, version })(store.getState());
		customData.accessLevel = data?.accessLevel?.code;
		return customData;
	}
	return undefined;
};

loadIcons();
preInitRaygunConfiguration();

const rootEl = document.getElementById('app');
const root = createRoot(rootEl);

const render = (Component) =>
	root.render(
		<BrowserRouter basename="/cam">
			<Provider store={store}>
				<ErrorBoundary enhanceWithCustomData={handleBoundaryError}>
					<Component />
				</ErrorBoundary>
			</Provider>
		</BrowserRouter>
	);

render(App);
