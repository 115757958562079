import { camApi } from '../api';
import { IPluginInfo } from '../../types/Plugin.types';

export const ExtraPluginsApi = camApi.injectEndpoints({
	endpoints: (build) => ({
		getActionPlugins: build.query<IPluginInfo[], void>({
			providesTags: ['configuration-action-plugins'],
			query: () => ({
				url: 'api/configuration/plugins/type/ACTION',
				method: 'get',
			}),
			transformResponse: (result): IPluginInfo[] => {
				if (result && typeof result === 'object' && 'items' in result) {
					return result.items as IPluginInfo[];
				}
				return result as unknown as IPluginInfo[];
			},
		}),
		getActivationProgressPlugins: build.query<IPluginInfo[], void>({
			providesTags: ['configuration-activation-progress-plugins'],
			query: () => ({
				url: 'api/configuration/plugins/type/ACTIVATION_PROGRESS',
				method: 'get',
			}),
			transformResponse: (result): IPluginInfo[] => {
				if (result && typeof result === 'object' && 'items' in result) {
					return result.items as IPluginInfo[];
				}
				return result as unknown as IPluginInfo[];
			},
		}),
		getDocumentStoragePlugins: build.query<IPluginInfo[], void>({
			providesTags: ['configuration-document-storage-plugins'],
			query: () => ({
				url: 'api/configuration/plugins/type/DOCUMENT_STORAGE',
				method: 'get',
			}),
			transformResponse: (result): IPluginInfo[] => {
				if (result && typeof result === 'object' && 'items' in result) {
					return result.items as IPluginInfo[];
				}
				return result as unknown as IPluginInfo[];
			},
		}),
	}),
});
