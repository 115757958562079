import React, { ButtonHTMLAttributes, CSSProperties } from 'react';
import { Button as LibButton } from 'src/components/SharedLibrary';
import { useDispatch } from 'react-redux';
import { clearAll } from '../NotificationsHandler';

import './Button.scss';

export interface IButtonProps extends Record<string, unknown> {
	title?: string;
	mainButton?: boolean;
	children?: string | React.ReactElement;

	adornmentIcon?: boolean;
	id?: string;
	className?: string;
	style?: string | CSSProperties;
	type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
	icon?: string;
	iconButton?: boolean;
	iconClassName?: string;
	iconOnly?: boolean;
	smallIcon?: boolean;
	noHover?: boolean;
	iconPosition?: 'left' | 'right' | '';
	hideInMobile?: boolean;
	mobileOnly?: boolean;
	fontAwesome?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	onClick?: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
	// defines whether the default behaviour of the button
	// should be prevented or not.
	preventDefault?: boolean;
	noText?: boolean;
}

const defaultProps: Partial<IButtonProps> = {
	adornmentIcon: false,
	hideInMobile: false,
	mobileOnly: false,
	iconPosition: 'left',
	iconOnly: false,
	iconButton: false,
	fontAwesome: false,
	type: 'button',
	disabled: false,
	readOnly: false,
	preventDefault: false,
	noText: false,
};

export const Button = (props: IButtonProps) => {
	const dispatch = useDispatch();
	const { title = '', mainButton = false, children, ...rest } = props;
	const getTitle = () => {
		if (title.length > 0) return title;
		if (typeof children === 'string') return children;
		return '';
	};

	const handleCLick: ButtonHTMLAttributes<HTMLButtonElement>['onClick'] = (e) => {
		if (props.onClick) {
			props?.onClick(e);
			dispatch(clearAll());
		}
	};

	return (
		<LibButton {...defaultProps} {...rest} data-default={mainButton} title={getTitle()} onClick={handleCLick}>
			{children}
		</LibButton>
	);
};
