import camConfig from '../../config/camConfig';
import { FieldValues } from 'react-hook-form';

export const authProviderHandlesCredentials = (authProviderId: string) => {
	const authProvider = camConfig()
		?.getAuthenticationProviders()
		?.find((provider) => provider.id === authProviderId);
	return authProvider?.handleCredentials;
};

export const userAuthHandlesCredentials = (user: FieldValues) => {
	return authProviderHandlesCredentials(user?.authenticationProvider?.id);
};
