import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/react';
import { IAccount, IRequestError } from '../types/Authentication.types';
import { MiddlewareAPI } from '@reduxjs/toolkit';
import { LocalStorageCache } from './localStorageCache';

export interface ICreateAxiosClientProps {
	baseUrl: string;
}

export interface AxiosQueryError<TData> {
	code: number;
	message?: string;
	data: TData;
}

export const isAxiosQueryError = <TData>(value?: unknown): value is AxiosQueryError<TData> =>
	value && typeof value === 'object' && 'code' in value && 'data' in value;

export const resetSession = () => {
	LocalStorageCache.resetUserData();
	location.replace('/cam/login');
};

export const createAxiosClientApi = ({ baseUrl }: ICreateAxiosClientProps) => {
	const axiosClientApi = axios.create({
		timeout: 60_000,
		baseURL: baseUrl,
	});
	axiosClientApi.interceptors.request.use((config) => {
		const token = LocalStorageCache.getToken();
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	});
	axiosClientApi.interceptors.response.use(
		(response) => response,
		(error: AxiosError) => {
			const status = error.status || (error.response ? error.response.status : 0);
			if (status === 401) {
				LocalStorageCache.resetUserData();
			}
			return Promise.reject(error);
		}
	);

	return axiosClientApi;
};

export const axiosBaseQuery =
	({
		axiosClientApi,
	}: {
		axiosClientApi: AxiosInstance;
	}): BaseQueryFn<
		{
			url: string;
			method: AxiosRequestConfig['method'];
			data?: AxiosRequestConfig['data'];
			params?: AxiosRequestConfig['params'];
			_isAuthEndpoint?: boolean;
		},
		{ data: unknown },
		IRequestError
	> =>
	async <TData>(
		{
			url,
			method,
			data,
			params,
			_isAuthEndpoint = false,
		}: { url: string; method: string; data?: TData; params: URLSearchParams; _isAuthEndpoint?: boolean },
		api: MiddlewareAPI
	) => {
		try {
			const result: AxiosResponse<TData> = await axiosClientApi({ url, method, data, params });
			if (_isAuthEndpoint) {
				const account = result.data as IAccount;
				const bearerToken = account.token.tokenValue;
				LocalStorageCache.setUserData(bearerToken, account);
			}

			return { data: result.data };
		} catch (axiosError) {
			let status: number, statusText: string, errorData: string;
			if ('response' in axiosError) {
				status = axiosError.response.status;
				statusText = axiosError.response.statusText;
				errorData = axiosError.response.data;
			} else {
				status = 500;
				statusText = axiosError.code;
				errorData = axiosError.message;
			}

			if (status === 401 && !_isAuthEndpoint) {
				resetSession();
			}

			return {
				error: {
					code: status,
					message: statusText,
					data: errorData,
				},
			};
		}
	};
