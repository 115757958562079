/*
 * Copyright 2018, Torque IT Solutions Ltd
 * www.torque-its.com
 */
import chainedFunction from 'chained-function';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent, cloneElement } from 'react';
import NavIcon from './NavIcon';
import NavText from './NavText';
import findComponent from './find-component';
import match from './match-component';
import NavLink from './NavLink';
import { domUtils } from '@torque-common-ui/utils';
import { forEach } from 'lodash';

const { getViewport } = domUtils;
const noop = () => { };

class NavItem extends PureComponent {    

    findNavIcon = findComponent(NavIcon);
    findNavText = findComponent(NavText);
    isNavItem = match(NavItem);
    isNavIcon = match(NavIcon);
    isNavText = match(NavText);
    menuHeaderHeight = 60;
    bottomGapHeight = 30;

    handleSelect = (event) => {
        const {
            disabled, onSelect, to, id
        } = this.props;

        if (disabled) {
            event.preventDefault();
            return;
        }

        if (this.props.action) {
            this.props.action();
        }

        if (onSelect) {
            onSelect(id, to, event);
        }
    };

    onHover = () => {
        if (!this.props.expandedSideNav && this.navItemRef && this.sideNavSubNavRef) {
            let container = this.navItemRef.getBoundingClientRect();
            let targetOffset = this.sideNavSubNavRef.getBoundingClientRect();
            let viewport = getViewport();
            // If the panel grows down beyond the viewport height, reposition it up.
            if (container && targetOffset && container.top + targetOffset.height > viewport.height) {
                // If repositioning it up goes beyond the page top edge, limit it to where the menu starts
                if (targetOffset.height + this.menuHeaderHeight + this.bottomGapHeight > viewport.height) {
                    this.sideNavSubNavRef.style.top = this.menuHeaderHeight + 'px';
                    this.sideNavSubNavRef.style.bottom = 'auto';
                    this.sideNavSubNavRef.style.maxHeight = viewport.height - (this.menuHeaderHeight + this.bottomGapHeight) + 'px';
                } else {
                    // If there's enough space position it accordingly
                    this.sideNavSubNavRef.style.top = 'auto';
                    this.sideNavSubNavRef.style.bottom = this.bottomGapHeight + 'px';
                }
            } else {
                this.sideNavSubNavRef.style.top = container.top + 'px';
                this.sideNavSubNavRef.style.bottom = 'auto';
            }
        }
    }

    onHoverEnd = () => {
        if (!this.props.expandedSideNav && this.sideNavSubNavRef) {
            this.sideNavSubNavRef.style.maxHeight = '';
            this.sideNavSubNavRef.style.minHeight = '';
            if (this.props.resetSelect) {
                this.props.resetSelect();
            }
        }
    }

    resizeSideNavSubNav = () => {
        if (this.sideNavSubNavRef) {
            if (this.props.expandedSideNav) {
                this.sideNavSubNavRef.style.maxHeight = '';
                this.sideNavSubNavRef.style.minHeight = '';
            } else {
                let targetOffset = this.sideNavSubNavRef.getBoundingClientRect();
                let viewport = getViewport();
                this.sideNavSubNavRef.style.maxHeight = viewport.height - (targetOffset.top + this.bottomGapHeight) + 'px';
                if (targetOffset.height > Number(this.sideNavSubNavRef.style.minHeight.replace('px', ''))) {
                    this.sideNavSubNavRef.style.minHeight = targetOffset.height + 'px';
                }
            }
        }
    }

    setNavItemRef = (el) => {
        this.navItemRef = el;
    }

    setSideNavSubNavRef = (el) => {
        this.sideNavSubNavRef = el;
    }

    render() {
        const {
            id,
            componentType,
            disabled,
            toBeShown,
            expanded,
            onClick,
            onSelect,
            // Nav props
            selected,
            expandedNavItem,
            // Sub navigation item
            subnav,
            thirdLevel,
            expandable,
            // Override className and style
            navitemClassName,
            navitemStyle,
            subnavClassName,
            subnavStyle,
            // Default props
            className,
            style,
            children,
            expandedSideNav,
            resetSelect,
            action,
            secondLevels,
            internalLink,
            target,
            ...props
        } = this.props;

        if (toBeShown === false) {
            return null;
        }
        const navIcon = this.findNavIcon(children);
        const navText = this.findNavText(children);
        const {
            componentType: navIconComponentType,
            navIconClassName,
            ...navIconProps
        } = navIcon ? { ...navIcon.props } : {};
        const {
            componentType: navTextComponentType,
            navTextClassName,
            ...navTextProps
        } = navText ? { ...navText.props } : {};

        if (subnav || thirdLevel) {
            const isNavItemSelected = !!selected && selected === this.props.id;
            const isNavItemExpanded = expandable && isNavItemSelected;

            let itemSelectedStyle;
            if (isNavItemSelected) {
                itemSelectedStyle = 'selected';
            }
            let itemDisabledStyle;
            if (disabled) {
                itemDisabledStyle = 'disabled';
            }
            let itemExpandableStyle;
            if (expandable) {
                itemExpandableStyle = 'expandable';
            }
            let itemExpandedStyle;
            if (isNavItemExpanded) {
                itemExpandedStyle = 'expanded';
            }
            let thirdLevelStyle = thirdLevel ? 'third-level' : '';

            let expandableIcon;
            if (expandable) {
                if (expanded) {
                    expandableIcon = (<i className="fa fa-fw fa-chevron-circle-down navtext-expandable" />);
                } else {
                    expandableIcon = (<i className="fa fa-fw fa-chevron-circle-right navtext-expandable" />);
                }
            }
            return (
                <div
                    role="presentation"
                    className={classnames(className, 'sidenav-subnavitem', itemSelectedStyle, itemDisabledStyle, itemExpandableStyle, itemExpandedStyle)}
                    style={style}
                >
                    <div
                        id={id}
                        {...props}
                        className={classnames(navitemClassName, 'navitem', thirdLevelStyle)}
                        style={navitemStyle}
                        disabled={disabled}
                        role="menuitem"
                        tabIndex="-1"
                        onClick={chainedFunction(
                            onClick,
                            (expandable) ? noop : this.handleSelect
                        )}
                    >
                        {navIcon && !internalLink &&
                            <span {...navIconProps} className={classnames(navIconClassName, 'navicon')} />
                        }
                        {navText && !internalLink &&
                            <span {...navTextProps} className={classnames(navTextClassName, 'navtext')} />
                        }
                        {navText && internalLink &&
                            <NavLink {...navTextProps}
                                to={props.to}
                                className={classnames(navTextClassName, 'navtext')}
                                title=""
                                target={target}
                            >
                                <React.Fragment>
                                    {navIcon && <span {...navIconProps} className={classnames(navIconClassName, 'navicon')} />}
                                    {navTextProps.children}
                                </React.Fragment>
                            </NavLink>
                        }
                        {expandableIcon}
                    </div>
                </div>
            );
        }

        const activeNavItems = [];

        const navItems = [];
        if (children) {
            forEach(children, child => {
                if (React.isValidElement(child) && this.isNavItem(child)) {
                    if (!!selected && selected === child.props.id) {
                        activeNavItems.push(child);
                    }
                    // Populate the third level items list.
                    const thirdLevelItems = [];
                    let hasThirdLevelItems = false;
                    forEach(child.props.children, thirdLevelChild => {
                        if (thirdLevelChild && thirdLevelChild.props && thirdLevelChild.props.id) {
                            hasThirdLevelItems = true;
                            if (child.props.expanded || (expandedNavItem && expandedNavItem === child.props.id && child.props.children)) {
                                thirdLevelItems.push(
                                    cloneElement(thirdLevelChild, {
                                        key: thirdLevelChild.props.id,
                                        thirdLevel: true,
                                        selected,
                                        onSelect: chainedFunction(
                                            thirdLevelChild.props.onSelect,
                                            onSelect
                                        )
                                    })
                                );
                            }
                        }
                    });

                    navItems.push(
                        cloneElement(child, {
                            key: child.props.id,
                            subnav: true,
                            expandable: hasThirdLevelItems,
                            selected,
                            onSelect: chainedFunction(
                                child.props.onSelect,
                                onSelect
                            )
                        })
                    );
                    navItems.push.apply(navItems, thirdLevelItems);
                }
            });
        }

        const others = React.Children.toArray(children)
            .filter(child => {
                if (React.isValidElement(child) && (this.isNavIcon(child) || this.isNavText(child) || this.isNavItem(child))) {
                    return false;
                }
                return true;
            });

        const isNavItemSelected = (!!selected && selected === this.props.id) || activeNavItems.length > 0;
        const isNavItemHighlighted = expanded || isNavItemSelected;
        const isNavItemExpandable = navItems.length > 0;
        const isNavItemExpanded = isNavItemExpandable && expanded;
        const isDirectTopLevelLink = internalLink && (navItems.length === 0);

        let itemSelectedStyle;
        if (isNavItemSelected) {
            itemSelectedStyle = 'selected';
        }
        let itemHighlightedStyle;
        if (isNavItemHighlighted) {
            itemHighlightedStyle = 'highlighted';
        }
        let itemExpandableStyle;
        if (isNavItemExpandable) {
            itemExpandableStyle = 'expandable';
        }
        let itemExpandedStyle;
        if (isNavItemExpanded) {
            itemExpandedStyle = 'expanded';
        }
        let itemDisabledStyle;
        if (disabled) {
            itemDisabledStyle = 'disabled';
        }

        return (
            <div
                role="presentation"
                className={classnames(className, 'sidenav-navitem first-level', itemSelectedStyle, itemHighlightedStyle, itemExpandableStyle, itemExpandedStyle, itemDisabledStyle)}
                style={style}
                onMouseOver={this.onHover}
                onMouseLeave={this.onHoverEnd}
                tabIndex="0"
            >
                <div
                    id={id}
                    {...props}
                    className={classnames(navitemClassName, 'navitem')}
                    style={navitemStyle}
                    disabled={disabled}
                    role="menuitem"
                    tabIndex="-1"
                    ref={this.setNavItemRef}
                    onClick={chainedFunction(
                        onClick,
                        (navItems.length === 0) ? this.handleSelect : noop
                    )}
                >
                    {navIcon && !isDirectTopLevelLink &&
                        <div {...navIconProps} className={classnames(navIconClassName, 'navicon')} />
                    }
                    {navText && !isDirectTopLevelLink &&
                        <div {...navTextProps} className={classnames(navTextClassName, 'navtext')} />
                    }
                    {navText && isDirectTopLevelLink &&
                        <NavLink {...navTextProps}
                            to={props.to}
                            className={classnames(navTextClassName, 'navtext')}
                            // specifically clear the tool-tip to avoid the default one while the menu is expanded
                            title=""
                            tabIndex="-1"
                            target={target}
                        >
                            <React.Fragment>
                                {navIcon && <div {...navIconProps} className={classnames(navIconClassName, 'navicon')} />}
                                <div className={classnames(navTextClassName, 'navtext')}>
                                    {navTextProps.children}
                                </div>
                            </React.Fragment>
                        </NavLink>
                    }
                    {others}
                </div>
                {(navItems.length > 0) &&
                    <div
                        role="menu"
                        className={classnames(subnavClassName, 'sidenav-subnav')}
                        style={subnavStyle}
                        ref={this.setSideNavSubNavRef}
                        data-simplebar
                        onClick={this.resizeSideNavSubNav}
                    >
                        <div>
                            <div
                                role="heading"
                                className={classnames('sidenav-subnavitem', itemHighlightedStyle, itemDisabledStyle)}
                                style={style}
                            >
                                {navText && navText.props ? navText.props.children : null}
                            </div>
                            {navItems}
                        </div>
                    </div>
                }
            </div>
        );
    }
}

NavItem.propTypes = {
    id: PropTypes.string.isRequired,
    componentType: PropTypes.func,
    // Disable the navigation item, making it unselectable.
    disabled: PropTypes.bool,
    // Show or hidden item
    toBeShown: PropTypes.bool,
    // Whether the navigation item is expanded or collapsed.
    expanded: PropTypes.bool,
    // Value passed to the `onSelect` handler, useful for identifying the selected navigation item.
    to: PropTypes.string,
    // Callback fired when the navigation item is clicked.
    onClick: PropTypes.func,
    // Callback fired when a navigation item is selected.
    onSelect: PropTypes.func,
    // The selected navigation item.
    selected: PropTypes.string,
    // The current expanded item.
    expandedNavItem: PropTypes.string,
    // Whether the side navigation is expanded or collapsed.
    expandedSideNav: PropTypes.bool,
    // Whether it is a sub navigation item.
    subnav: PropTypes.bool,
    thirdLevel: PropTypes.bool,
    expandable: PropTypes.bool,
    navitemClassName: PropTypes.string,
    navitemStyle: PropTypes.shape(),
    subnavClassName: PropTypes.string,
    subnavStyle: PropTypes.shape(),
    className: PropTypes.string,
    style: PropTypes.string,
    children: PropTypes.node,
    action: PropTypes.func,
    secondLevels: PropTypes.arrayOf(PropTypes.shape()),
    resetSelect: PropTypes.func,
    target: PropTypes.string,
    // Whether link should be using internal/provided routing system
    internalLink: PropTypes.bool.isRequired
};

NavItem.defaultProps = {
    disabled: false,
    expanded: false,
    toBeShown: true,
    componentType: NavItem,
    internalLink: true
};

export default NavItem;