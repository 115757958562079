import {
	IComplianceWorkflowPermissionCheckRequired,
	IContractActivationPermissionCheckByAlRequired,
	IContractActivationPermissionCheckRequired,
	IPermissionBean,
	IPermissionCheckNotRequired,
	ISystemPermissionCheckRequired,
	UserSystemPermission,
} from '../../types/Authentication.types';
import { ComplianceSectionLevelType, ComplianceSectionType } from '../../types/Compliance.types';

export const SectionToSystemPermissionsMap: Map<ComplianceSectionType, UserSystemPermission> = new Map([
	['APPLICATION_USER', 'USER_CONFIGURATION'],
	['CHECKLIST_CONFIGURATION', 'CHECKLIST_CONFIGURATION'],
	['COMPLIANCE_CONFIGURATION', 'COMPLIANCE_CONFIGURATION'],
	['ROLE_CONFIGURATION', 'ROLE_CONFIGURATION'],
	['ROLE_ASSIGNMENT_CONFIGURATION', 'ROLE_ASSIGNMENT_CONFIGURATION'],
	['USER_GROUP_CONFIGURATION', 'USER_CONFIGURATION'],
	['SECRET_CONFIGURATION', 'SYSTEM_CONFIGURATION'],
	['SYSTEM_CONFIGURATION', 'SYSTEM_CONFIGURATION'],
	['ACCESS_LEVEL_CONFIGURATION', 'CONTRACT_ACCESS_LEVEL_CONFIGURATION'],
	['ACCESS_LEVEL_SCHEDULE_CONFIGURATION', 'CONTRACT_ACCESS_LEVEL_CONFIGURATION'],
	['STATUS_CONFIGURATION', 'CONTRACT_ACTIVATION_PROCESS_CONFIGURATION'],
	['ACTION_CONFIGURATION', 'CONTRACT_ACTIVATION_PROCESS_CONFIGURATION'],
	['GENERIC_DECISION_TABLE_CONFIGURATION', 'GENERIC_DECISION_TABLE_CONFIGURATION'],
	['TAG_CONFIGURATION', 'TAG_CONFIGURATION'],
	['CONTRACT_ACTIVATION_VIEW_CONFIGURATION', 'CONTRACT_ACTIVATION_VIEW_CONFIGURATION'],
]);

export const hasAuthorityFactory = (
	permissionBean: IPermissionBean,
	sectionConfig: { levelBySection: Map<ComplianceSectionType, ComplianceSectionLevelType>; areSectionsAvailable: boolean }
) => {
	const arePermissionsAvailable = permissionBean && permissionBean.systemPermissions && permissionBean.contractActivationsPermissionsPerAL;

	const hasComplianceWorkflowAuthority = () => {
		if (!arePermissionsAvailable) return false;
		if (permissionBean.systemPermissions.includes('COMPLIANCE_WORKFLOW_REVIEWER')) {
			return true;
		}
		const requiredPermissions = new Set(
			Array.from(sectionConfig.levelBySection.entries())
				.filter(([_, level]) => level === 'FOUR_EYES')
				.map(([section]) => section)
				.map((section) => SectionToSystemPermissionsMap.get(section))
		);
		return permissionBean.systemPermissions.some((permission) => requiredPermissions.has(permission));
	};
	const hasSystemAuthority = (permissionCheck?: ISystemPermissionCheckRequired | IPermissionCheckNotRequired) => {
		if (permissionCheck.type === 'no permission') return true;

		if (!arePermissionsAvailable) return false;
		if (!permissionCheck) return true;

		return permissionBean.systemPermissions.includes(permissionCheck.permission);
	};

	const hasContractActivationAuthority = (
		permissionCheck?:
			| IContractActivationPermissionCheckRequired
			| IContractActivationPermissionCheckByAlRequired
			| IPermissionCheckNotRequired
	) => {
		if (permissionCheck.type === 'no permission') return true;
		if (!arePermissionsAvailable) return false;
		if (!permissionCheck) return true;

		if (permissionCheck.accessLevel) {
			const accessLevel = permissionBean.contractActivationsPermissionsPerAL[permissionCheck.accessLevel];
			return accessLevel[permissionCheck.permission] != null;
		}
		const permissions = Object.values(permissionBean.contractActivationsPermissionsPerAL).flatMap(
			(permissionsByLevel) => permissionsByLevel
		);
		return permissions.includes(permissionCheck.permission);
	};

	const hasContractActivationAuthorityByAl = (permissionCheck?: IContractActivationPermissionCheckByAlRequired) => {
		if (!arePermissionsAvailable) return false;
		if (!permissionCheck) return true;

		const accessLevel = permissionBean.contractActivationsPermissionsPerAL[permissionCheck.accessLevel];
		return accessLevel[permissionCheck.permission] != null;
	};

	const hasAuthority = (
		permissionsCheck:
			| IComplianceWorkflowPermissionCheckRequired
			| IPermissionCheckNotRequired
			| ISystemPermissionCheckRequired
			| IContractActivationPermissionCheckRequired
			| IContractActivationPermissionCheckByAlRequired
	) => {
		if (permissionsCheck.type === 'compliance-workflow') {
			return hasComplianceWorkflowAuthority();
		}
		if (permissionsCheck.type === 'system') {
			return hasSystemAuthority(permissionsCheck);
		}
		if (permissionsCheck.type === 'contract-activation') {
			if ('accessLevel' in permissionsCheck) {
				return hasContractActivationAuthorityByAl(permissionsCheck as IContractActivationPermissionCheckByAlRequired);
			}
			return hasContractActivationAuthority(permissionsCheck);
		}
		return permissionsCheck.type === 'no permission';
	};

	return {
		hasAuthority,
		hasSystemAuthority,
		hasComplianceWorkflowAuthority,
		hasContractActivationAuthority,
		hasContractActivationAuthorityByAl,
	};
};
