import { t } from '../../assets/i18n/translation';
import { IFilter } from '../../components/Filters/filter.store';
import { format, parse, subWeeks } from 'date-fns';
import { INTERNAL_INPUT_DATE_FORMAT } from '../../config/constants';

export const DefaultContractsFilters: IFilter[] = [
	{
		name: 'fromDate',
		label: t('contracts.filter.label.fromDate'),
		type: 'date',
		defaultValue: format(subWeeks(new Date(), 4), INTERNAL_INPUT_DATE_FORMAT),
		getExtraProps: (data) => ({ max: parse(data.toDate as string, INTERNAL_INPUT_DATE_FORMAT, new Date()) }),
	},
	{
		name: 'toDate',
		label: t('contracts.filter.label.toDate'),
		type: 'date',
		defaultValue: format(new Date(), INTERNAL_INPUT_DATE_FORMAT),
		getExtraProps: (data) => ({ min: parse(data.fromDate as string, INTERNAL_INPUT_DATE_FORMAT, new Date()) }),
	},
	{
		name: 'statusCodes',
		label: t('contracts.filter.label.status'),
		type: 'multiSelect',
		options: [],
	},
];

export const SearchContractsFilters: IFilter[] = [
	{
		name: 'applicationId',
		label: t('contracts.filter.label.applicationId'),
		type: 'text',
		transform: (value: string) => {
			const rawValue = value ? value.replace('#', '') : undefined;
			return rawValue ? +rawValue : undefined;
		},
	},
	{
		name: 'customerName',
		label: t('contracts.filter.label.customerName'),
		type: 'text',
	},
	{
		name: 'filterByDate',
		label: t('contracts.filter.label.filterByDate'),
		type: 'select',
		options: [
			{ label: t('contracts.filter.label.filterByDate.option.latestActivity'), value: 'LATEST_ACTIVITY' },
			{ label: t('contracts.filter.label.filterByDate.option.latestRequest'), value: 'LATEST_REQUEST' },
		],
	},
	{
		name: 'statusCodes',
		label: t('contracts.filter.label.status'),
		type: 'multiSelect',
		options: [],
	},
	{
		name: 'fromDate',
		label: t('contracts.filter.label.fromDate'),
		type: 'date',
		defaultValue: format(subWeeks(new Date(), 4), INTERNAL_INPUT_DATE_FORMAT),
		getExtraProps: (data) => ({ max: parse(data.toDate as string, INTERNAL_INPUT_DATE_FORMAT, new Date()) }),
	},
	{
		name: 'toDate',
		label: t('contracts.filter.label.toDate'),
		type: 'date',
		defaultValue: format(new Date(), INTERNAL_INPUT_DATE_FORMAT),
		getExtraProps: (data) => ({ min: parse(data.fromDate as string, INTERNAL_INPUT_DATE_FORMAT, new Date()) }),
	},
];
