/*
 * Copyright 2017, Torque IT Solutions Ltd
 * www.torque-its.com
 */
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { Base64 } from 'js-base64';
import { getCurrentTimezoneDateTime } from './dateTimeUtils';

const TEXT_PLAIN = 'text/plain';
export { XLSX as ExcelParser };
export { Papa as CsvParser };

// Function to download data, e.g. decision table, to a file
export function saveAsFile(data, fileName, type) {
    const file = new Blob([data], { type: type });
    if (window.navigator.msSaveOrOpenBlob) {
        // IE10+
        window.navigator.msSaveOrOpenBlob(file, fileName);
    } else {
        // Others
        let a = document.createElement('a');
        const url = URL.createObjectURL(file);
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    }
}

// Function to download data, e.g. decision table, to a file as plain text
export function saveAsTextPlainFile(data, filename) {
    return saveAsFile(data, filename, TEXT_PLAIN);
}

// Function to download data to an excel file
export function saveAsXlsxFile(data, header, fileName, sheetName) {
    // xlsx sheet name cannot exceed 31 characters, and cannot contain \/*?[]
    if (sheetName) {
        sheetName = sheetName.replace(/[\\\/\?\*\[\]]/g, '_').substring(0, 31);
    }
    let worksheet = XLSX.utils.json_to_sheet(data, {
        header
    });
    let newWorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(newWorkBook, worksheet, sheetName);
    XLSX.writeFile(newWorkBook, fileName);
}

// Function to download data to an csv file
export function saveAsCsvFile(data, fields, fileName) {
    let csv = Papa.unparse({
        fields,
        data
    });
    saveAsTextPlainFile(csv, fileName);
}

// Function to download data to a JSON type file
export function saveAsJsonFile(data, fileName, formatted) {
    const jsonContent = formatted ? JSON.stringify(data, null, 1) : JSON.stringify(data);
    saveAsTextPlainFile(jsonContent, fileName);
}

// Function to get content from a local file to browser
export function readLocalFileContent(callback, fileHandle, errorCallback, mode) {
    let reader = new FileReader();
    if (errorCallback) {
        reader.onerror = errorCallback;
    }
    // for IE
    if (window.navigator.msSaveOrOpenBlob) {
        reader.onload = (e) => {
            const binary = getBinaryContent(e.target.result);
            callback(binary);
        };
        reader.readAsArrayBuffer(fileHandle);
    } else if (mode && mode.toLowerCase() === 'binary') {
        reader.readAsBinaryString(fileHandle);
        reader.onload = () => callback(reader.result);
    } else {
        reader.readAsText(fileHandle);
        reader.onload = () => callback(reader.result);
    }
}

export function getFormattedFileSize(bytes, sizes) {
    if (bytes === 0) {
        return '0';
    }
    if (!sizes) {
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    }
    const k = 1024;
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(0)) + ' ' + sizes[i];
}

export function encodeBase64(value) {
    if (!value) {
        return null;
    }
    return Base64.encode(value);
}

export function decodeBase64(value) {
    if (!value) {
        return null;
    }
    return Base64.decode(value);
}

export function getBase64FileContent(fileContent) {
    if (!fileContent) {
        return null;
    }
    const binary = getBinaryContent(fileContent);
    const base64FileContent = window.btoa(binary);
    return base64FileContent;
}

function getBinaryContent(fileContent) {
    let binary = '';
    const bytes = new Uint8Array(fileContent);
    for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return binary;
}

export function convertBase64ToUint8Array(base64String) {
    const base64Index = base64String.indexOf(';base64,') + ';base64,'.length;
    const base64 = base64String.substring(base64Index);
    const raw = window.atob(base64);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));
    for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
    }
    return array;
}

export function getFileName({ prefix = '', fileName = '', suffix = '', fileType, hasDate = true, hasTime = false, timezone }) {
    // Timezone is not required. If empty than UTC is used
    let now = getCurrentTimezoneDateTime(timezone);
    const date = hasDate ? (fileName ? '_' : '') + now.format('YYYYMMDD') : '';
    const time = hasTime ? (fileName || hasDate ? '_' : '') + now.format('HHmmss') : '';
    fileName = fileName ? fileName.replace(/\s+/g, '_') : '';
    return prefix + fileName + date + time + suffix + '.' + fileType;
}