import './Footer.scss';
import React from 'react';
import { IAccount } from '../../types/Authentication.types';
import { useAuth } from '../../domains/User/useAuth';
import { getCurrentYear, getWebappVersion } from 'src/config/camConfig';
import { t } from '../../assets/i18n/translation';
import LoadingIndicator from '@torque-common-ui/loading-indicator';
import { useApiPendingState } from '../../api/useApiPendingState';

const renderUserInfo = (user: IAccount) => <div className="footer-div">{user.login}</div>;

const loadingColour = {
	red: 255,
	green: 255,
	blue: 255,
	alpha: 1,
};

export const Footer = () => {
	const { arePermissionsLoaded, account, isLogging } = useAuth();
	const webappVersion = getWebappVersion().version;
	const isLoading = useApiPendingState();

	return (
		<footer>
			<section className="footer">
				<div className="copyright-container">
					<span>{t('system.name', getCurrentYear())}</span>
					{(isLogging || isLoading) && (
						<div className="footer-div">
							<LoadingIndicator id="footerLoadingIndicator" appearance="small" color={loadingColour} />
						</div>
					)}
				</div>
				<div className="details">
					{arePermissionsLoaded && renderUserInfo(account)}
					<div className="footer-div trq-ms-hide">
						<span>{webappVersion}</span>
					</div>
				</div>
			</section>
		</footer>
	);
};
