import { PageContainer } from '../../components/PageContainer';
import React from 'react';
import { t } from '../../assets/i18n/translation';

export const DashboardPage = () => {
	return (
		<PageContainer title={t('nav.menu.dashboard')}>
			<div />
		</PageContainer>
	);
};
