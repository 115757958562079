import moment from 'moment';
import { get, set, isNumber, isObject } from 'lodash/fp';

function isAnyType(a, b, type) {
    return typeof a === type || typeof b === type;
}

/**
 * Compare two values by given expected order.
 *
 * @param  {String|Number|Boolean} a
 * @param  {String|Number|Boolean} b
 * @param  {String} order [asc, desc]
 * @return
 */
export function compareTwoValues(a, b, order) {
    if (isAnyType(a, b, 'boolean')) {
        return sortBoolean(a, b, order);
    }

    if (isAnyType(a, b, 'number')) {
        return sortNumber(a, b, order);
    }

    return sortString(a, b, order);
}

/**
 * Returns js comparison based on order specified
 *
 * @param  {Number} comparison
 * @param  {String} order
 * @return
 */
export function returnComparisonForOrder(comparison, order) {
    if (order === 'desc') {
        return Math.sign(comparison) * (-1);
    }
    return Math.sign(comparison);
}

/**
 * Function, to evaluate each value before comparison.
 * Our intention here for "default" sorting, is to take provided by Column
 * component dataFormat function, which computes user-friendly string, and sort
 * by those and not by values in certain cases. In other cases we would like
 * to explicitly provide sorting logic.
 *
 * @param  {String|Number} a
 * @param  {String|Number} b
 * @param  {String} order
 * @param  {String} field
 * @param  {Function} evaluateFunc Function that computes a value based on various parameters
 * @return
 */
export function sortEvaluated(evaluateFunc, dataField, a, b, order) {
    const valueA = evaluateFunc(a[dataField], a);
    const valueB = evaluateFunc(b[dataField], b);
    return compareTwoValues(valueA, valueB, order);
}

/**
 * DateTime sorting logic function for tables
 *
 * @param  {String|Number} a
 * @param  {String|Number} b
 * @param  {String} order
 * @return
 */
export function sortDateTime(field) {
    return (a, b, order, rowA, rowB) => {
        const valueA = rowA[field] ? moment.utc(rowA[field]) : -1;
        const valueB = rowB[field] ? moment.utc(rowB[field]) : -1;
        const comparison = valueA - valueB;
        return returnComparisonForOrder(comparison, order);
    };
}

export function sortBoolean(a, b, order) {
    const valueA = typeof a === 'boolean' ? a : -1;
    const valueB = typeof b === 'boolean' ? b : -1;
    const comparison = valueA - valueB;
    return returnComparisonForOrder(comparison, order);
}

export function sortNumber(a, b, order) {
    const valueA = isNumber(a) ? a : -Infinity;
    const valueB = isNumber(b) ? b : -Infinity;
    const comparison = valueA - valueB;
    return returnComparisonForOrder(comparison, order);
}

export function sortNumberForField(sortField, a, b, order, dataField, rowA, rowB) {
    const valueA = rowA[sortField];
    const valueB = rowB[sortField];
    return sortNumber(valueA, valueB, order);
}

export function sortString(a, b, order) {
    const valueA = a || '';
    const valueB = b || '';
    const comparison = valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    return returnComparisonForOrder(comparison, order);
}

export function sortDropdownForField(field, a, b, order) {
    if (isNumber(a) || isNumber(b)) {
        return sortNumber(a, b, order);
    } else if (isObject(a) || isObject(b)) {
        a = a.name || '';
        b = b.name || '';
    }
    const comparison = a.toLowerCase().localeCompare(b.toLowerCase());
    return returnComparisonForOrder(comparison, order);
}

export function sortInputTextForField(field, a, b, order) {
    let valueA = a[field] || '';
    let valueB = b[field] || '';
    return sortString(valueA, valueB, order);
}

export function sortObject(field) {
    return (a, b, order) => {
        const valueA = a ? a[field] : '';
        const valueB = b ? b[field] : '';
        return sortString(valueA, valueB, order);
    };
}

/**
 * Transform value by given transformFunction in given obj.
 * Immutable, compatible with lodash flow() for set of transformation.
 * flow(
 *     transformValue(value => value.name)('introducer'),
 *     transformValue(objectNameGetter)('severityLevel', 'severityLevelName')
 * )
 * @param  {Function} transformFunction - transformation function, has a value as an argument
 * @param  {String} property - property path to get
 * @param  {String} newProperty - property path to set, if not provided prop will be used as target
 * @param  {Object} object - object on which perform transformation for property
 * @return {prop}                   [description]
 */
export function transformValue(transformFunction, property, newProperty) {
    return (object) =>
        set(newProperty || property)(
            transformFunction(
                get(property)(object),
                object
            )
        )(object);
}

export const objectNameGetter = (object) => object ? object.name : null;
