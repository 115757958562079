/*
 * Copyright 2023, Torque IT Solutions Ltd
 * www.torque-its.com
 */
/* global rg4js */
import camConfig, { getWebappVersion } from 'src/config/camConfig';

/**
 * Raygun configuration that does not require specific objects/services to have
 * been already initialised. This should be called at the very beginning of the
 * app setup, to ensure that setup issues will be reported to Raygun.
 * @param  {object} history used history object
 */
export function preInitRaygunConfiguration() {
	if (rg4js && camConfig().getRaygunApiKey()) {
		rg4js('apiKey', camConfig().getRaygunApiKey());
		// TODO read the version from version.js
		rg4js('setVersion', getWebappVersion().version);
		// we do not want to use the default raygun crash reporting,
		// as we take care of sending all errors to raygun ourselves,
		// including in the global window.onerror error handler.
		rg4js('enableCrashReporting', false);
		rg4js('enablePulse', camConfig().getRaygunPulseSupport());
		rg4js('withTags', ['Web']);

		rg4js('onBeforeSend', (payload) => {
			const fixedPayload = {
				...payload,
				Details: {
					...payload.Details,
					Error: {
						...payload.Details.Error,
						StackTrace: payload.Details.Error.StackTrace.map((stackTrace) => ({
							...stackTrace,
							// for some reasons the FileName contained in the
							// StackTrace has a query parameter: something like
							// bundle.123.js?123. This makes the source maps
							// handling more difficult.
							// This seems to come from the way the source maps
							// are built but I wasn't able yet to fix it. The
							// other possible fix is to remove this query
							// parameter before sending the stacktrace to Raygun
							FileName: stackTrace.FileName
								? stackTrace.FileName.split('?')[0]
								: '',
						})),
					},
				},
			};
			return fixedPayload; // Return false here to abort the send
		});
	}
}

/**
 * Raygun configuration that requires an history object to be available.
 * @param  {object} history used history object
 */
export function postInitRaygunConfiguration(history) {
	configureRaygunPulse(history);
}

/**
 * Configure Raygun Pulse to track all page view events from the given history.
 * The given history needs to be configured and ready to use.
 * @param  {object} history used history object providing page view events
 */
function configureRaygunPulse(history) {
	if (camConfig().getRaygunPulseSupport) {
		history.listen(() => {
			// we take the current location from window object in order to
			// have a `ready-to-click` url containing the current basename
			const currentLocation = window.location.pathname;
			rg4js('trackEvent', { type: 'pageView', path: currentLocation });
		});
	}
}
