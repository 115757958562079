import { createAction } from 'redux-actions';
import { isReducerStoreForPanel } from './reducer';
import { REGISTER_PANEL, UNREGISTER_PANEL, SET_OPENED_STATE } from './types';

export const register = createAction(REGISTER_PANEL);
export const unregister = createAction(UNREGISTER_PANEL);

export function registerPanel({ id, isOpened }) {
    return (dispatch, getState) => {
        const isRegistered = isReducerStoreForPanel(getState(), id);
        if (!isRegistered) {
            dispatch(register({ id, isOpened }));
        }
    };
}
export function unregisterPanel({ id}) {
    return (dispatch) => {
        dispatch(unregister({ id}));
    };
}

export const setOpenedState = createAction(SET_OPENED_STATE);
