import React, { lazy, useEffect, useMemo } from 'react';
import { LoginPage } from './pages/LoginPage';
import { LoginFormPage } from './pages/LoginFormPage';
import { LogoutPage } from './pages/LogoutPage';
import { t } from './assets/i18n/translation';
import { useAuth } from './domains/User/useAuth';
import { CONTRACT_ACTIVATION_PERMISSION, SYSTEM_PERMISSION } from './config/constants';
import {
	IComplianceWorkflowPermissionCheckRequired,
	IContractActivationPermissionCheckRequired,
	IPermissionCheckNotRequired,
	ISystemPermissionCheckRequired,
} from './types/Authentication.types';
import { DefaultRedirectionPage } from './pages/DefaultRedirectionPage';
import { PermissionsCheckFactory } from './utils/permissionsCheckFactory';
import { PreloadingPage } from './pages/PreloadingPage';
import { ForbiddenPage } from './pages/ForbiddenPage';
import { DefaultContractsFilters, SearchContractsFilters } from './pages/WorkflowPage/config';
import LoginTokenAuthPage from './pages/LoginTokenAuthPage';
import { DashboardPage } from './pages/DashboardPage';

const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const WorkflowPage = lazy(() => import('./pages/WorkflowPage'));
const ActionConfigPage = lazy(() => import('./pages/ActionConfigurationPage'));
const ContractActivationStatusConfigurationPage = lazy(() => import('./pages/StatusConfigurationPage'));
const ContractActivationPage = lazy(() => import('./pages/ContractActivationPage'));
const SecretConfigurationListPage = lazy(() => import('./pages/SecretConfiguration/SecretListPage'));
const SecretConfigurationPage = lazy(() => import('./pages/SecretConfiguration/SecretPage'));
const PluginListPage = lazy(() => import('./pages/PluginConfigurationPage/PluginListPage'));
const PluginConfigurationPage = lazy(() => import('./pages/PluginConfigurationPage/PluginConfigurationPage/PluginConfigurationPage'));
const UserGroupListPage = lazy(() => import('./pages/UserGroupConfigurationPage/UserGroupListPage'));
const UserGroupConfigurationPage = lazy(() => import('./pages/UserGroupConfigurationPage/UserGroupConfigurationPage'));
const AccessLevelListPage = lazy(() => import('./pages/AccessLevelConfiguration/AccessLevelListPage'));
const AccessLevelConfigurationPage = lazy(() => import('./pages/AccessLevelConfiguration/AccessLevelPage'));
const AccessLevelScheduleListPage = lazy(() => import('./pages/AccessLevelScheduleConfiguration/AccessLevelScheduleListPage'));
const AccessLevelScheduleConfigurationPage = lazy(() => import('./pages/AccessLevelScheduleConfiguration/AccessLevelSchedulePage'));
const ComplianceConfigurationListPage = lazy(() => import('./pages/ComplianceConfiguration/ComplianceConfigurationListPage'));
const ComplianceConfigurationPage = lazy(() => import('./pages/ComplianceConfiguration/ComplianceConfigurationPage'));
const SystemConfigurationListPage = lazy(() => import('./pages/SystemConfigurationPage/SystemConfigurationListPage'));
const SystemConfigurationPage = lazy(() => import('./pages/SystemConfigurationPage/SystemConfigurationPage'));
const GenericDecisionTableListPage = lazy(() => import('./pages/GenericDecisionTableConfiguration/GenericDecisionTableListPage'));
const GenericDecisionTablePage = lazy(() => import('./pages/GenericDecisionTableConfiguration/GenericDecisionTablePage'));
const GenericDecisionTableVariantPage = lazy(() => import('./pages/GenericDecisionTableConfiguration/GenericDecisionTableVariantPage'));
const CheckpointListPage = lazy(() => import('./pages/CheckpointConfiguration/CheckpointListPage'));
const CheckpointPage = lazy(() => import('./pages/CheckpointConfiguration/CheckpointPage'));
const CheckpointVariantPage = lazy(() => import('./pages/CheckpointConfiguration/CheckpointVariantPage'));
const RoleConfigurationList = lazy(() => import('./pages/RoleConfiguration/RoleConfigurationList'));
const RoleConfigurationPage = lazy(() => import('./pages/RoleConfiguration/RoleConfigurationPage'));
const RoleAssignmentConfigurationList = lazy(() => import('./pages/RoleAssignmentConfiguration/RoleAssignmentConfigurationList'));
const RoleAssignmentConfigurationPage = lazy(() => import('./pages/RoleAssignmentConfiguration/RoleAssignmentConfigurationPage'));
const WorkflowConfigPage = lazy(() => import('./pages/WorkflowConfigPage/WorkflowConfigPage'));
const WorkflowConfigEditPage = lazy(() => import('./pages/WorkflowConfigPage/WorkflowConfigEditPage'));
const WorkflowConfigEditItemPage = lazy(() => import('./pages/WorkflowConfigPage/WorkflowConfigEditItemPage'));
const TagListPage = lazy(() => import('./pages/TagConfiguration/TagListPage'));
const TagPage = lazy(() => import('./pages/TagConfiguration/TagPage'));
const TagVariantPage = lazy(() => import('./pages/TagConfiguration/TagVariantPage'));
const ContractActivationViewConfigListPage = lazy(() => import('./pages/ContractActivationViewConfig/ContractActivationViewListPage'));
const ContractActivationViewConfigPage = lazy(() => import('./pages/ContractActivationViewConfig/ContractActivationViewPage'));
const UserListPage = lazy(() => import('./pages/UserConfiguration/UserListPage'));
const UserPage = lazy(() => import('./pages/UserConfiguration/UserPage'));
const ResetPassword = lazy(() => import('./pages/UserConfiguration/ResetPassword'));
const ChecklistScheduleConfigListPage = lazy(() => import('./pages/ChecklistScheduleConfig/ChecklistScheduleListPage'));
const ChecklistSchedulePage = lazy(() => import('./pages/ChecklistScheduleConfig/ChecklistSchedulePage'));
const CustomerSectionViewConfigListPage = lazy(() => import('./pages/CustomerSectionViewConfig/CustomerSectionViewListPage'));
const CustomerSectionViewPage = lazy(() => import('./pages/CustomerSectionViewConfig/CustomerSectionViewPage'));
const CustomerSectionViewGroupPage = lazy(() => import('./pages/CustomerSectionViewConfig/CustomerSectionViewGroupPage'));
const ComputedFieldConfigListPage = lazy(() => import('./pages/ComputedFieldsConfiguration/ComputedFieldsConfigurationListPage'));
const ComputedFieldConfigPage = lazy(() => import('./pages/ComputedFieldsConfiguration/ComputedFieldsConfigurationPage'));
const ConditionTypesConfigurationListPage = lazy(() => import('./pages/ConditionTypesConfiguration/ConditionTypesConfigurationListPage'));
const ConditionTypesConfigurationPage = lazy(() => import('./pages/ConditionTypesConfiguration/ConditionTypesConfigurationPage'));
const LanguageConfigurationListPage = lazy(() => import('./pages/LanguageConfiguration/LanguageConfigurationListPage'));
const LanguageConfigurationPage = lazy(() => import('./pages/LanguageConfiguration/LanguageConfigurationPage'));
const ActivationCustomFieldConfigurationListPage = lazy(
	() => import('./pages/CustomFieldsConfiguration/ActivationCustomFieldConfigurationListPage')
);
const ActivationCustomFieldConfigurationPage = lazy(
	() => import('./pages/CustomFieldsConfiguration/ActivationCustomFieldConfigurationPage')
);
const ReferenceDataListPage = lazy(() => import('./pages/ReferenceDataConfiguration/ReferenceDataListPage'));
const ReferenceDataPage = lazy(() => import('./pages/ReferenceDataConfiguration/ReferenceDataPage'));
const AssetSectionViewConfigListPage = lazy(() => import('./pages/AssetSectionViewConfig/AssetSectionViewConfigListPage'));
const AssetSectionViewPage = lazy(() => import('./pages/AssetSectionViewConfig/AssetSectionViewConfigPage'));
const ApplicationDetailsSectionViewConfigListPage = lazy(
	() => import('./pages/ApplicationDetailsSectionViewConfig/ApplicationDetailsSectionViewConfigListPage')
);
const ApplicationDetailsSectionViewConfigPage = lazy(
	() => import('./pages/ApplicationDetailsSectionViewConfig/ApplicationDetailsSectionViewConfigPage')
);
const ActionsLayoutViewListPage = lazy(() => import('./pages/ActionsLayoutView/ActionsLayoutViewListPage'));
const ActionsLayoutViewPage = lazy(() => import('./pages/ActionsLayoutView/ActionsLayoutViewPage'));

export type PageRouteTitle = string | ((params: Record<string, string>) => string);

export interface PageRoute {
	title?: PageRouteTitle;
	path: string;
	element: React.ReactElement;
	isSecured?: boolean;
	permissionsCheck:
		| ISystemPermissionCheckRequired
		| IContractActivationPermissionCheckRequired
		| IPermissionCheckNotRequired
		| IComplianceWorkflowPermissionCheckRequired;
}

export const ROUTE_PATH = {
	LOGIN: '/login',
	LOGIN_FORM: '/loginForm',
	LOGOUT: '/logout',
	TOKEN_AUTH: '/tokenAuth/:pluginIdentityId',
	DASHBOARD: '/dashboard',

	CONTRACT_ACTIVATION_ACTION_CONFIGURATION: '/contract-activations/action-configuration',
	CONTRACT_ACTIVATION_STATUS_CONFIGURATION: '/contract-activations/status-configuration',
	CONTRACT_ACTIVATION_ACTIVITY: '/contract-activations/:applicationId/versions/:version/activity',
	CONTRACT_ACTIVATION: '/contract-activations/:applicationId/versions/:version/activation',
	CONTRACT_ACTIVATION_WORKFLOW: '/contract-activations/workflow',
	CONTRACT_ACTIVATION_WORKFLOW_SEARCH: '/contract-activations/workflow/search',

	USER_GROUP_CONFIGURATION_LIST: '/configuration/user-group',
	USER_GROUP_CONFIGURATION: '/configuration/user-group/:id',

	SECRETS_CONFIGURATION: '/configuration/secret',
	SECRET_CONFIGURATION: '/configuration/secret/:id',
	PLUGINS_CONFIGURATION_LIST: '/configuration/plugins',
	PLUGIN_CONFIGURATION: '/configuration/plugins/:id',
	ACCESS_LEVEL_CONFIGURATION_LIST: '/configuration/access-levels',
	ACCESS_LEVEL_CONFIGURATION: '/configuration/access-levels/:id',
	ACCESS_LEVEL_SCHEDULE_CONFIGURATION_LIST: '/configuration/access-levels/schedules',
	ACCESS_LEVEL_SCHEDULE_CONFIGURATION: '/configuration/access-levels/schedules/:id',
	ACCESS_LEVEL_SCHEDULE_CONFIGURATION_CLONE: '/configuration/access-levels/schedules/:id/clone',
	ACTIVATION_CUSTOM_FIELDS_CONFIGURATION_LIST: '/configuration/activation-custom-fields',
	ACTIVATION_CUSTOM_FIELDS_CONFIGURATION: '/configuration/activation-custom-fields/:id',
	ACTIVATION_CUSTOM_FIELDS_CONFIGURATION_CLONE: '/configuration/activation-custom-fields/:id/clone',
	COMPLIANCE_CONFIGURATION_LIST: '/configuration/compliance-sections',
	COMPLIANCE_CONFIGURATION: '/configuration/compliance-sections/:id',
	GENERIC_DECISION_TABLE_CONFIGURATION_LIST: '/configuration/decision-tables',
	GENERIC_DECISION_TABLE_CONFIGURATION: '/configuration/decision-tables/:id',
	GENERIC_DECISION_TABLE_CONFIGURATION_CLONE: '/configuration/decision-tables/:id/clone',
	GENERIC_DECISION_TABLE_VARIANT_CONFIGURATION: '/configuration/decision-tables/:id/variants/:nestedItemId',
	GENERIC_DECISION_TABLE_CLONE_VARIANT_CONFIGURATION: '/configuration/decision-tables/:id/clone/variants/:nestedItemId',
	GENERIC_DECISION_TABLE_VARIANT_CONFIGURATION_CLONE: '/configuration/decision-tables/:id/variants/:nestedItemId/clone',
	GENERIC_DECISION_TABLE_CLONE_VARIANT_CONFIGURATION_CLONE: '/configuration/decision-tables/:id/clone/variants/:nestedItemId/clone',
	CHECKPOINT_CONFIGURATION_LIST: '/configuration/checkpoint',
	CHECKPOINT_CONFIGURATION: '/configuration/checkpoint/:id',
	CHECKPOINT_CONFIGURATION_CLONE: '/configuration/checkpoint/:id/clone',
	CHECKPOINT_VARIANT_CONFIGURATION: '/configuration/checkpoint/:id/variants/:nestedItemId',
	CHECKPOINT_CLONE_VARIANT_CONFIGURATION: '/configuration/checkpoint/:id/clone/variants/:nestedItemId',
	CHECKPOINT_VARIANT_CONFIGURATION_CLONE: '/configuration/checkpoint/:id/variants/:nestedItemId/clone',
	CHECKPOINT_CLONE_VARIANT_CONFIGURATION_CLONE: '/configuration/checkpoint/:id/clone/variants/:nestedItemId/clone',
	CHECKLIST_SCHEDULE_CONFIGURATION_LIST: '/configuration/checklist-schedules',
	CHECKLIST_SCHEDULE_CONFIGURATION: '/configuration/checklist-schedules/:id',
	CHECKLIST_SCHEDULE_CONFIGURATION_CLONE: '/configuration/checklist-schedules/:id/clone',
	CONTRACT_ACTIVATION_VIEW_CONFIGURATION_LIST: '/configuration/contract-activation/view',
	CONTRACT_ACTIVATION_VIEW_CONFIGURATION: '/configuration/contract-activation/view/:id',
	CUSTOMER_SECTION_VIEW_CONFIGURATION_LIST: '/configuration/customer-section/view',
	CUSTOMER_SECTION_VIEW_CONFIGURATION: '/configuration/customer-section/view/:id',
	CUSTOMER_SECTION_VIEW_CONFIGURATION_NEW: '/configuration/customer-section/view/:type/:id',
	CUSTOMER_SECTION_VIEW_CONFIGURATION_CLONE: '/configuration/customer-section/view/:id/clone',
	CUSTOMER_SECTION_VIEW_CONFIGURATION_GROUP: '/configuration/customer-section/view/:id/groups/:nestedItemId',
	CUSTOMER_SECTION_VIEW_CONFIGURATION_NEW_GROUP: '/configuration/customer-section/view/:type/:id/groups/:nestedItemId',
	CUSTOMER_SECTION_VIEW_CONFIGURATION_CLONE_GROUP: '/configuration/customer-section/view/:id/clone/groups/:nestedItemId',
	ASSET_SECTION_VIEW_CONFIGURATION_LIST: '/configuration/asset-section/view',
	ASSET_SECTION_VIEW_CONFIGURATION: '/configuration/asset-section/view/:id',
	ACTIONS_LAYOUT_VIEW_CONFIGURATION_LIST: '/configuration/actions-layout/view',
	ACTIONS_LAYOUT_VIEW_CONFIGURATION: '/configuration/actions-layout/view/:id',
	APPLICATION_DETAILS_SECTION_VIEW_CONFIGURATION_LIST: '/configuration/application-details/view',
	APPLICATION_DETAILS_SECTION_VIEW_CONFIGURATION: '/configuration/application-details/view/:id',
	COMPUTED_FIELDS_CONFIGURATION_LIST: '/configuration/computed-fields',
	COMPUTED_FIELDS_CONFIGURATION: '/configuration/computed-fields/:id',
	CONDITION_TYPE_CONFIGURATION_LIST: '/configuration/condition-types',
	CONDITION_TYPE_CONFIGURATION: '/configuration/condition-types/:id',
	LANGUAGE_CONFIGURATION_LIST: '/configuration/language',
	LANGUAGE_CONFIGURATION: '/configuration/language/:id',
	ROLE_CONFIGURATION_LIST: '/configuration/roles',
	ROLE_CONFIGURATION: '/configuration/roles/:id',
	ROLE_CONFIGURATION_NEW: '/configuration/roles/:type/:id',
	ROLE_CONFIGURATION_CLONE: '/configuration/roles/:id/clone',
	ROLE_ASSIGNMENT_CONFIGURATION_LIST: '/configuration/role-assignements',
	ROLE_ASSIGNMENT_CONFIGURATION: '/configuration/role-assignements/:id',
	ROLE_ASSIGNMENT_CONFIGURATION_NEW: '/configuration/role-assignements/:type/:id',
	TAG_CONFIGURATION_LIST: '/configuration/tags',
	TAG_CONFIGURATION: '/configuration/tags/:id',
	TAG_CONFIGURATION_CLONE: '/configuration/tags/:id/clone',
	TAG_VARIANT_CONFIGURATION: '/configuration/tags/:id/variants/:nestedItemId',
	TAG_CLONE_VARIANT_CONFIGURATION: '/configuration/tags/:id/clone/variants/:nestedItemId',
	TAG_VARIANT_CONFIGURATION_CLONE: '/configuration/tags/:id/variants/:nestedItemId/clone',
	TAG_CLONE_VARIANT_CONFIGURATION_CLONE: '/configuration/tags/:id/clone/variants/:nestedItemId/clone',
	USER_CONFIGURATION_LIST: '/configuration/users',
	USER_CONFIGURATION: '/configuration/users/:id',
	USER_CONFIGURATION_CLONE: '/configuration/users/:id/clone',
	RESET_PASSWORD: '/configuration/users/:id/resetPassword',
	REFERENCE_DATA_CONFIGURATION_LIST: '/configuration/reference-data',
	REFERENCE_DATA_CONFIGURATION: '/configuration/reference-data/:id',

	SYSTEM_CONFIGURATION_LIST: '/configuration/system',
	CONFIG_WORKFLOW_LIST: '/configuration/workflow',
	CONFIG_WORKFLOW_EDIT_PAGE: '/configuration/workflow/:section/:id/versions/:versionId',
	CONFIG_WORKFLOW_EDIT_ITEM_PAGE: '/configuration/workflow/:section/:id/versions/:versionId/:nestedItemName/:nestedItemId',
	CONFIG_WORKFLOW_EDIT_ITEM_CLONE_PAGE: '/configuration/workflow/:section/:id/versions/:versionId/:nestedItemName/:nestedItemId/clone',
	SYSTEM_CONFIGURATION: '/configuration/system/:id',
	DEFAULT_REDIRECT: '/',
	NOT_FOUND: '*',
};

export const usePageRoutes: () => PageRoute[] = () => {
	const { hasAuthority, permissions, arePermissionsLoaded, account } = useAuth();

	useEffect(() => {
		// in case user has permissions to access system configuration
		// we will preload configuration page templates in order to reduce the time user spends waiting for the page to load
		// TODO create a ticket for pages preloading by the user permissions
		if (arePermissionsLoaded && hasAuthority(PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.SYSTEM_CONFIGURATION))) {
			import('./views/ConfigurationPageTemplate/ConfigurationListPageTemplate');
			import('./views/ConfigurationPageTemplate/adapters/ConfigurationEditPageAdapter');
			import('./views/ConfigurationPageTemplate/adapters/ConfigurationEditVariantPageAdapter');
		}
	}, [arePermissionsLoaded, account?.id]);

	return useMemo(() => {
		const routes: PageRoute[] = [
			{
				path: ROUTE_PATH.LOGIN,
				element: <LoginPage />,
				isSecured: false,
				title: t('login.options.title'),
				permissionsCheck: PermissionsCheckFactory.NO_PERMISSIONS(),
			},
			{
				path: ROUTE_PATH.DASHBOARD,
				element: <DashboardPage />,
				isSecured: true,
				title: t('nav.menu.dashboard'),
				permissionsCheck: PermissionsCheckFactory.NO_PERMISSIONS(),
			},
			{
				path: ROUTE_PATH.TOKEN_AUTH,
				element: <LoginTokenAuthPage />,
				isSecured: false,
				title: t('login.options.title'),
				permissionsCheck: PermissionsCheckFactory.NO_PERMISSIONS(),
			},
			{
				path: ROUTE_PATH.LOGIN_FORM,
				element: <LoginFormPage />,
				isSecured: false,
				title: t('login.title'),
				permissionsCheck: PermissionsCheckFactory.NO_PERMISSIONS(),
			},
			{
				path: ROUTE_PATH.LOGOUT,
				element: <LogoutPage />,
				isSecured: false,
				permissionsCheck: PermissionsCheckFactory.NO_PERMISSIONS(),
			},
			{
				path: ROUTE_PATH.CONTRACT_ACTIVATION_STATUS_CONFIGURATION,
				element: <ContractActivationStatusConfigurationPage />,
				isSecured: true,
				title: t('configuration.contractActivation.status.page.label'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACTIVATION_PROCESS_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.CONTRACT_ACTIVATION_ACTION_CONFIGURATION,
				element: <ActionConfigPage />,
				isSecured: true,
				title: t('configuration.contractActivation.action.page.label'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACTIVATION_PROCESS_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.CONTRACT_ACTIVATION,
				element: <ContractActivationPage type="activation" />,
				isSecured: true,
				title: ({ applicationId, version }) => `#${applicationId} v${version}`,
				permissionsCheck: PermissionsCheckFactory.CONTRACT_ACTIVATION_PERMISSION(CONTRACT_ACTIVATION_PERMISSION.VIEW_CONTRACT_ACTIVATION),
			},
			{
				path: ROUTE_PATH.CONTRACT_ACTIVATION_ACTIVITY,
				element: <ContractActivationPage type="activity" />,
				isSecured: true,
				title: ({ applicationId, version }) => `#${applicationId} v${version}`,
				permissionsCheck: PermissionsCheckFactory.CONTRACT_ACTIVATION_PERMISSION(CONTRACT_ACTIVATION_PERMISSION.VIEW_CONTRACT_ACTIVATION),
			},
			{
				path: ROUTE_PATH.CONTRACT_ACTIVATION_WORKFLOW,
				element: (
					<WorkflowPage
						key="contract-activation-workflow-page"
						id="contract-activation-workflow-page"
						title={t('contract.activation.workflow.page.label')}
						type="default"
						filters={DefaultContractsFilters}
					/>
				),
				isSecured: true,
				title: t('contract.activation.workflow.page.label'),
				permissionsCheck: PermissionsCheckFactory.CONTRACT_ACTIVATION_PERMISSION(CONTRACT_ACTIVATION_PERMISSION.VIEW_CONTRACT_ACTIVATION),
			},
			{
				path: ROUTE_PATH.CONTRACT_ACTIVATION_WORKFLOW_SEARCH,
				element: (
					<WorkflowPage
						key="contract-activation-search-page"
						id="contract-activation-search-page"
						type="search"
						title={t('contract.activation.workflow.search.page.label')}
						filters={SearchContractsFilters}
					/>
				),
				isSecured: true,
				title: t('contract.activation.workflow.search.page.label'),
				permissionsCheck: PermissionsCheckFactory.CONTRACT_ACTIVATION_PERMISSION(CONTRACT_ACTIVATION_PERMISSION.VIEW_CONTRACT_ACTIVATION),
			},
			{
				path: ROUTE_PATH.CONFIG_WORKFLOW_LIST,
				element: <WorkflowConfigPage />,
				isSecured: true,
				title: t('configuration.workflow.page.title'),
				permissionsCheck: PermissionsCheckFactory.COMPLIANCE_WORKFLOW_PERMISSION(),
			},
			{
				path: ROUTE_PATH.CONFIG_WORKFLOW_EDIT_PAGE,
				element: <WorkflowConfigEditPage />,
				isSecured: true,
				title: t('configuration.workflow.page.title'),
				permissionsCheck: PermissionsCheckFactory.COMPLIANCE_WORKFLOW_PERMISSION(),
			},
			{
				path: ROUTE_PATH.CONFIG_WORKFLOW_EDIT_ITEM_PAGE,
				element: <WorkflowConfigEditItemPage />,
				isSecured: true,
				title: t('configuration.workflow.page.title'),
				permissionsCheck: PermissionsCheckFactory.COMPLIANCE_WORKFLOW_PERMISSION(),
			},
			{
				path: ROUTE_PATH.CONFIG_WORKFLOW_EDIT_ITEM_CLONE_PAGE,
				element: <WorkflowConfigEditItemPage isClone />,
				isSecured: true,
				title: t('configuration.workflow.page.title'),
				permissionsCheck: PermissionsCheckFactory.COMPLIANCE_WORKFLOW_PERMISSION(),
			},

			{
				path: ROUTE_PATH.SECRETS_CONFIGURATION,
				element: <SecretConfigurationListPage />,
				isSecured: true,
				title: t('configuration.system.secret.title'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.SYSTEM_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.SECRET_CONFIGURATION,
				element: <SecretConfigurationPage />,
				isSecured: true,
				title: ({ id }) => (id === 'new' ? t('configuration.system.secret.edit.title') : t('configuration.system.secret.edit.title')),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.SYSTEM_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.ROLE_CONFIGURATION_LIST,
				element: <RoleConfigurationList />,
				isSecured: true,
				title: t('configuration.system.role.list.label'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.ROLE_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.ROLE_CONFIGURATION,
				element: <RoleConfigurationPage />,
				isSecured: true,
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.ROLE_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.ROLE_CONFIGURATION_NEW,
				element: <RoleConfigurationPage />,
				isSecured: true,
				title: ({ type }) => t('configuration.system.role.button.create', t(`configuration.system.role.parameter.type.enum.${type}`)),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.ROLE_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.ROLE_CONFIGURATION_CLONE,
				element: <RoleConfigurationPage updateConfiguration={(config) => ({ ...config, mode: 'clone' })} />,
				isSecured: true,
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.ROLE_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.ROLE_ASSIGNMENT_CONFIGURATION_LIST,
				element: <RoleAssignmentConfigurationList />,
				isSecured: true,
				title: t('configuration.system.role.assignment.list.label'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.ROLE_ASSIGNMENT_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.ROLE_ASSIGNMENT_CONFIGURATION,
				element: <RoleAssignmentConfigurationPage />,
				isSecured: true,
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.ROLE_ASSIGNMENT_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.ROLE_ASSIGNMENT_CONFIGURATION_NEW,
				element: <RoleAssignmentConfigurationPage />,
				isSecured: true,
				title: ({ type }) =>
					t('configuration.system.role.assignment.button.create', t(`configuration.system.role.assignment.parameter.type.enum.${type}`)),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.ROLE_ASSIGNMENT_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.PLUGINS_CONFIGURATION_LIST,
				element: <PluginListPage />,
				isSecured: true,
				title: t('configuration.system.plugin.title'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.SYSTEM_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.PLUGIN_CONFIGURATION,
				element: <PluginConfigurationPage />,
				isSecured: true,
				title: t('configuration.system.plugin.edit.title'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.SYSTEM_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.SYSTEM_CONFIGURATION_LIST,
				element: <SystemConfigurationListPage />,
				isSecured: true,
				title: t('system.configuration.title'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.SYSTEM_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.SYSTEM_CONFIGURATION,
				element: <SystemConfigurationPage />,
				isSecured: true,
				title: t('system.configuration.edit.title'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.SYSTEM_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.ACCESS_LEVEL_CONFIGURATION_LIST,
				element: <AccessLevelListPage />,
				isSecured: true,
				title: t('nav.menu.configuration.system.accessLevel'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACCESS_LEVEL_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.ACCESS_LEVEL_CONFIGURATION,
				element: <AccessLevelConfigurationPage />,
				isSecured: true,
				title: ({ id }) =>
					id === 'new' ? t('configuration.system.accessLevel.create.label') : t('configuration.system.accessLevel.update.label'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACCESS_LEVEL_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.COMPLIANCE_CONFIGURATION_LIST,
				element: <ComplianceConfigurationListPage />,
				isSecured: true,
				title: t('nav.menu.configuration.system.accessLevel'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.COMPLIANCE_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.COMPLIANCE_CONFIGURATION,
				element: <ComplianceConfigurationPage />,
				isSecured: true,
				title: ({ id }) =>
					id === 'new' ? t('configuration.system.accessLevel.create.label') : t('configuration.system.accessLevel.update.label'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.COMPLIANCE_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.ACCESS_LEVEL_SCHEDULE_CONFIGURATION_LIST,
				element: <AccessLevelScheduleListPage />,
				isSecured: true,
				title: t('nav.menu.configuration.system.accessLevelSchedule'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACCESS_LEVEL_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.ACCESS_LEVEL_SCHEDULE_CONFIGURATION,
				element: <AccessLevelScheduleConfigurationPage />,
				isSecured: true,
				title: t('nav.menu.configuration.system.accessLevelSchedule'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACCESS_LEVEL_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.ACCESS_LEVEL_SCHEDULE_CONFIGURATION_CLONE,
				element: <AccessLevelScheduleConfigurationPage updateConfiguration={(config) => ({ ...config, mode: 'clone' })} />,
				isSecured: true,
				title: t('nav.menu.configuration.system.accessLevelSchedule'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACCESS_LEVEL_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.REFERENCE_DATA_CONFIGURATION_LIST,
				element: <ReferenceDataListPage />,
				isSecured: true,
				title: t('configuration.referenceData.displayName'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.REFERENCE_DATA_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.REFERENCE_DATA_CONFIGURATION,
				element: <ReferenceDataPage />,
				isSecured: true,
				title: t('configuration.referenceData.edit'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.REFERENCE_DATA_CONFIGURATION),
			},
			// at least for now, I see this is only the way to define configuration
			// we should cover all the cases for clones
			{
				path: ROUTE_PATH.GENERIC_DECISION_TABLE_CONFIGURATION_LIST,
				element: <GenericDecisionTableListPage />,
				isSecured: true,
				title: t('nav.menu.configuration.system.genericDecisionTable'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.GENERIC_DECISION_TABLE_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.GENERIC_DECISION_TABLE_CONFIGURATION,
				element: <GenericDecisionTablePage />,
				isSecured: true,
				title: ({ id }) =>
					id === 'new'
						? t('configuration.system.genericDecisionTable.create.label')
						: t('configuration.system.genericDecisionTable.update.label'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.GENERIC_DECISION_TABLE_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.GENERIC_DECISION_TABLE_CONFIGURATION_CLONE,
				element: <GenericDecisionTablePage updateConfiguration={(config) => ({ ...config, mode: 'clone' })} />,
				isSecured: true,
				title: t('configuration.system.genericDecisionTable.clone.label'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.GENERIC_DECISION_TABLE_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.GENERIC_DECISION_TABLE_VARIANT_CONFIGURATION,
				element: <GenericDecisionTableVariantPage />,
				isSecured: true,
				title: ({ nestedItemId }) =>
					nestedItemId === 'new'
						? t('configuration.system.genericDecisionTable.variant.create.label')
						: t('configuration.system.genericDecisionTable.variant.update.label'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.GENERIC_DECISION_TABLE_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.GENERIC_DECISION_TABLE_VARIANT_CONFIGURATION_CLONE,
				element: <GenericDecisionTableVariantPage updateConfiguration={(config) => ({ ...config, mode: 'clone' })} />,
				isSecured: true,
				title: t('configuration.system.genericDecisionTable.variant.clone.label'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.GENERIC_DECISION_TABLE_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.GENERIC_DECISION_TABLE_CLONE_VARIANT_CONFIGURATION_CLONE,
				element: <GenericDecisionTableVariantPage updateConfiguration={(config) => ({ ...config, mode: 'clone' })} />,
				isSecured: true,
				title: t('configuration.system.genericDecisionTable.variant.clone.label'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.GENERIC_DECISION_TABLE_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.GENERIC_DECISION_TABLE_CLONE_VARIANT_CONFIGURATION,
				element: <GenericDecisionTableVariantPage />,
				isSecured: true,
				title: t('configuration.system.genericDecisionTable.variant.create.label'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.GENERIC_DECISION_TABLE_CONFIGURATION),
			},

			{
				path: ROUTE_PATH.COMPUTED_FIELDS_CONFIGURATION_LIST,
				element: <ComputedFieldConfigListPage />,
				isSecured: true,
				title: t('nav.menu.configuration.view.computed.field'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACTIVATION_VIEW_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.COMPUTED_FIELDS_CONFIGURATION,
				element: <ComputedFieldConfigPage />,
				isSecured: true,
				title: ({ id }) => (id === 'new' ? t('configuration.computedField.page.create') : t('configuration.computedField.page.edit')),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACTIVATION_VIEW_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.CONDITION_TYPE_CONFIGURATION_LIST,
				element: <ConditionTypesConfigurationListPage />,
				isSecured: true,
				title: t('nav.menu.configuration.workflow.condition.type'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONDITION_TYPE_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.CONDITION_TYPE_CONFIGURATION,
				element: <ConditionTypesConfigurationPage />,
				isSecured: true,
				title: ({ id }) =>
					id === 'new' ? t('configuration.condition.type.page.create.title') : t('configuration.condition.type.page.update.title'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONDITION_TYPE_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.LANGUAGE_CONFIGURATION_LIST,
				element: <LanguageConfigurationListPage />,
				isSecured: true,
				title: t('nav.menu.configuration.localisation.language'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.SYSTEM_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.LANGUAGE_CONFIGURATION,
				element: <LanguageConfigurationPage />,
				isSecured: true,
				title: ({ id }) => (id === 'new' ? t('configuration.language.page.create.title') : t('configuration.language.page.update.title')),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.SYSTEM_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.ACTIVATION_CUSTOM_FIELDS_CONFIGURATION_LIST,
				element: <ActivationCustomFieldConfigurationListPage />,
				isSecured: true,
				title: t('nav.menu.configuration.miscellaneus.activationCustomField'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.ACTIVATION_CUSTOM_FIELD_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.ACTIVATION_CUSTOM_FIELDS_CONFIGURATION,
				element: <ActivationCustomFieldConfigurationPage />,
				isSecured: true,
				title: ({ id }) =>
					id === 'new' ? t('configuration.customfields.page.create.title') : t('configuration.customfields.page.update.title'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.ACTIVATION_CUSTOM_FIELD_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.ACTIVATION_CUSTOM_FIELDS_CONFIGURATION_CLONE,
				element: <ActivationCustomFieldConfigurationPage updateConfiguration={(config) => ({ ...config, mode: 'clone' })} />,
				isSecured: true,
				title: t('configuration.miscellaneus.customfields.clone.label'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.ACTIVATION_CUSTOM_FIELD_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.TAG_CONFIGURATION_LIST,
				element: <TagListPage />,
				isSecured: true,
				title: t('nav.menu.configuration.system.tag'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.TAG_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.TAG_CONFIGURATION,
				element: <TagPage />,
				isSecured: true,
				title: ({ id }) => (id === 'new' ? t('configuration.system.tag.create.label') : t('configuration.system.tag.update.label')),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.TAG_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.TAG_CONFIGURATION_CLONE,
				element: <TagPage updateConfiguration={(config) => ({ ...config, mode: 'clone' })} />,
				isSecured: true,
				title: t('configuration.system.tag.clone.label'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.TAG_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.TAG_VARIANT_CONFIGURATION,
				element: <TagVariantPage />,
				isSecured: true,
				title: ({ nestedItemId }) =>
					nestedItemId === 'new' ? t('configuration.system.tag.variant.create.label') : t('configuration.system.tag.variant.update.label'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.TAG_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.TAG_VARIANT_CONFIGURATION_CLONE,
				element: <TagVariantPage updateConfiguration={(config) => ({ ...config, mode: 'clone' })} />,
				isSecured: true,
				title: t('configuration.system.tag.variant.clone.label'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.TAG_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.TAG_CLONE_VARIANT_CONFIGURATION_CLONE,
				element: <TagVariantPage updateConfiguration={(config) => ({ ...config, mode: 'clone' })} />,
				isSecured: true,
				title: t('configuration.system.tag.variant.clone.label'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.TAG_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.TAG_CLONE_VARIANT_CONFIGURATION,
				element: <TagVariantPage />,
				isSecured: true,
				title: t('configuration.system.tag.variant.create.label'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.TAG_CONFIGURATION),
			},

			{
				path: ROUTE_PATH.CHECKPOINT_CONFIGURATION_LIST,
				element: <CheckpointListPage />,
				isSecured: true,
				title: t('nav.menu.configuration.workflow.checkpoint'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CHECKLIST_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.CHECKPOINT_CONFIGURATION,
				element: <CheckpointPage />,
				isSecured: true,
				title: ({ id }) =>
					id === 'new' ? t('configuration.system.checkpoint.create.label') : t('configuration.system.checkpoint.update.label'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CHECKLIST_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.CHECKPOINT_CONFIGURATION_CLONE,
				element: <CheckpointPage updateConfiguration={(config) => ({ ...config, mode: 'clone' })} />,
				isSecured: true,
				title: t('configuration.system.checkpoint.clone.label'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CHECKLIST_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.CHECKPOINT_VARIANT_CONFIGURATION,
				element: <CheckpointVariantPage />,
				isSecured: true,
				title: ({ nestedItemId }) =>
					nestedItemId === 'new'
						? t('configuration.system.checkpoint.variant.create.label')
						: t('configuration.system.checkpoint.variant.update.label'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CHECKLIST_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.CHECKPOINT_VARIANT_CONFIGURATION_CLONE,
				element: <CheckpointVariantPage updateConfiguration={(config) => ({ ...config, mode: 'clone' })} />,
				isSecured: true,
				title: t('configuration.system.checkpoint.variant.clone.label'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CHECKLIST_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.CHECKPOINT_CLONE_VARIANT_CONFIGURATION_CLONE,
				element: <CheckpointVariantPage updateConfiguration={(config) => ({ ...config, mode: 'clone' })} />,
				isSecured: true,
				title: t('configuration.system.checkpoint.variant.clone.label'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CHECKLIST_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.CHECKPOINT_CLONE_VARIANT_CONFIGURATION,
				element: <CheckpointVariantPage />,
				isSecured: true,
				title: t('configuration.system.checkpoint.variant.create.label'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CHECKLIST_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.CHECKLIST_SCHEDULE_CONFIGURATION_LIST,
				element: <ChecklistScheduleConfigListPage />,
				isSecured: true,
				title: t('nav.menu.configuration.workflow.checklist'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CHECKLIST_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.CHECKLIST_SCHEDULE_CONFIGURATION,
				element: <ChecklistSchedulePage />,
				isSecured: true,
				title: t('nav.menu.configuration.workflow.checklist'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CHECKLIST_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.CHECKLIST_SCHEDULE_CONFIGURATION_CLONE,
				element: <ChecklistSchedulePage updateConfiguration={(config) => ({ ...config, mode: 'clone' })} />,
				isSecured: true,
				title: t('nav.menu.configuration.workflow.checklist'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CHECKLIST_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.CONTRACT_ACTIVATION_VIEW_CONFIGURATION_LIST,
				element: <ContractActivationViewConfigListPage />,
				isSecured: true,
				title: t('nav.menu.configuration.view.contract.activation'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACTIVATION_VIEW_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.CONTRACT_ACTIVATION_VIEW_CONFIGURATION,
				element: <ContractActivationViewConfigPage />,
				isSecured: true,
				title: t('configuration.contract.activation.view.update.label'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACTIVATION_VIEW_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.CUSTOMER_SECTION_VIEW_CONFIGURATION_LIST,
				element: <CustomerSectionViewConfigListPage />,
				isSecured: true,
				title: t('configuration.customer.section.view.displayName'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACTIVATION_VIEW_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.CUSTOMER_SECTION_VIEW_CONFIGURATION,
				element: <CustomerSectionViewPage />,
				isSecured: true,
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACTIVATION_VIEW_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.CUSTOMER_SECTION_VIEW_CONFIGURATION_NEW,
				element: <CustomerSectionViewPage />,
				isSecured: true,
				title: ({ type }) =>
					t(
						'configuration.customer.section.view.create.label',
						t(`contract.activation.page.businessPartner.section.affiliate.customerSegment.${type?.toUpperCase()}`)
					),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACTIVATION_VIEW_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.CUSTOMER_SECTION_VIEW_CONFIGURATION_GROUP,
				element: <CustomerSectionViewGroupPage />,
				isSecured: true,
				title: ({ groupId }) =>
					t(
						groupId === 'new'
							? 'configuration.customer.section.view.groups.page.create'
							: 'configuration.customer.section.view.groups.page.update'
					),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACTIVATION_VIEW_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.CUSTOMER_SECTION_VIEW_CONFIGURATION_NEW_GROUP,
				element: <CustomerSectionViewGroupPage />,
				isSecured: true,
				title: ({ nestedItemId: groupId }) =>
					t(
						groupId === 'new'
							? 'configuration.customer.section.view.groups.page.create'
							: 'configuration.customer.section.view.groups.page.update'
					),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACTIVATION_VIEW_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.CUSTOMER_SECTION_VIEW_CONFIGURATION_CLONE_GROUP,
				element: <CustomerSectionViewGroupPage />,
				isSecured: true,
				title: ({ groupId }) =>
					t(
						groupId === 'new'
							? 'configuration.customer.section.view.groups.page.create'
							: 'configuration.customer.section.view.groups.page.update'
					),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACTIVATION_VIEW_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.CUSTOMER_SECTION_VIEW_CONFIGURATION_CLONE,
				element: <CustomerSectionViewPage updateConfiguration={(config) => ({ ...config, mode: 'clone' })} />,
				isSecured: true,
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACTIVATION_VIEW_CONFIGURATION),
			},

			{
				path: ROUTE_PATH.ASSET_SECTION_VIEW_CONFIGURATION_LIST,
				element: <AssetSectionViewConfigListPage />,
				isSecured: true,
				title: t('configuration.asset.section.view.displayName'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACTIVATION_VIEW_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.ASSET_SECTION_VIEW_CONFIGURATION,
				element: <AssetSectionViewPage />,
				isSecured: true,
				title: t('configuration.asset.section.view.update.label'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACTIVATION_VIEW_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.ACTIONS_LAYOUT_VIEW_CONFIGURATION_LIST,
				element: <ActionsLayoutViewListPage />,
				isSecured: true,
				title: t('configuration.actions.layout.view.displayName'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACTIVATION_VIEW_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.ACTIONS_LAYOUT_VIEW_CONFIGURATION,
				element: <ActionsLayoutViewPage />,
				isSecured: true,
				title: t('configuration.actions.layout.view.displayName'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACTIVATION_VIEW_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.APPLICATION_DETAILS_SECTION_VIEW_CONFIGURATION_LIST,
				element: <ApplicationDetailsSectionViewConfigListPage />,
				isSecured: true,
				title: t('configuration.application.details.section.view.displayName'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACTIVATION_VIEW_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.APPLICATION_DETAILS_SECTION_VIEW_CONFIGURATION,
				element: <ApplicationDetailsSectionViewConfigPage />,
				isSecured: true,
				title: t('configuration.application.details.section.view.update.label'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACTIVATION_VIEW_CONFIGURATION),
			},

			{
				path: ROUTE_PATH.DEFAULT_REDIRECT,
				element: <DefaultRedirectionPage />,
				isSecured: true,
				permissionsCheck: PermissionsCheckFactory.NO_PERMISSIONS(),
			},

			{
				path: ROUTE_PATH.NOT_FOUND,
				element: <NotFoundPage />,
				isSecured: true,
				title: t('notFound.page.label'),
				permissionsCheck: PermissionsCheckFactory.NO_PERMISSIONS(),
			},
			{
				path: ROUTE_PATH.USER_GROUP_CONFIGURATION_LIST,
				element: <UserGroupListPage />,
				isSecured: true,
				title: t('configuration.user.group.title'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.USER_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.USER_GROUP_CONFIGURATION,
				element: <UserGroupConfigurationPage />,
				isSecured: true,
				title: t('configuration.user.group.title'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.USER_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.USER_CONFIGURATION_LIST,
				element: <UserListPage />,
				isSecured: true,
				title: t('configuration.user.title'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.USER_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.USER_CONFIGURATION,
				element: <UserPage />,
				isSecured: true,
				title: t('configuration.user.title'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.USER_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.USER_CONFIGURATION_CLONE,
				element: <UserPage updateConfiguration={(config) => ({ ...config, mode: 'clone' })} />,
				isSecured: true,
				title: t('configuration.user.title'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.USER_CONFIGURATION),
			},
			{
				path: ROUTE_PATH.RESET_PASSWORD,
				element: <ResetPassword updateConfiguration={(config) => ({ ...config, mode: 'create-version' })} />,
				isSecured: true,
				title: t('configuration.user.resetPassword.title'),
				permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.USER_CONFIGURATION),
			},
		];

		// If permissions are not loaded yet show empty page
		// Can be replaced with loading spinner if needed
		if (!arePermissionsLoaded) {
			const preloadingPageRoute: PageRoute = {
				path: '*',
				element: <PreloadingPage />,
				isSecured: false,
				permissionsCheck: PermissionsCheckFactory.NO_PERMISSIONS(),
			};
			return [preloadingPageRoute, ...routes.filter((route) => !route.isSecured)];
		}

		// Replace forbidden pages with forbidden component
		return routes.map((route) => {
			if (hasAuthority(route.permissionsCheck)) {
				return route;
			}
			return { ...route, element: <ForbiddenPage />, title: undefined };
		});
	}, [permissions, arePermissionsLoaded]);
};
