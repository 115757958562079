/*
 * Copyright 2021, Torque IT Solutions Ltd
 * www.torque-its.com
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { filter, forEach, head } from 'lodash';
import NestedMenu from '@torque-common-ui/nested-menu-v2';
import Link from '@torque-common-ui/link';

/**
 * Menu group component. Renders a top level menu element.
 * The menu group will collapse into a single Nested menu element when it only contains one visible menu item.
 */
class MenuGroup extends PureComponent {

    render() {
        const {
            title,
            tooltip,
            menuId,
            icon,
            menuItems,
            preventCollapse,
            isBlocking,
            backLabel,
            iconOnly,
            className
        } = this.props;

        let items = [];
        // filter menu items only have toBeShown is true
        let visibleMenuItems = filter(menuItems, item => { return item.toBeShown; });
        // filter sub menus
        for (let i = 0; i < visibleMenuItems.length; i++) {
            let visibleMenuItem = visibleMenuItems[i];
            if (visibleMenuItem.subMenus) {
                visibleMenuItem.subMenus = filter(visibleMenuItem.subMenus, subMenu => { return subMenu.toBeShown; });
                if (visibleMenuItem.subMenus.length === 0) { // if no sub menus are visible, then set toBeShown in upper level menu to false
                    visibleMenuItem.toBeShown = false;
                } else if (visibleMenuItem.subMenus.length === 1) { // if only one sub menu item, move sub menu to menu
                    let newVisibleMenuItem = {
                        menuId: visibleMenuItem.subMenus[0].menuId,
                        name: visibleMenuItem.subMenus[0].name,
                        to: visibleMenuItem.subMenus[0].to,
                        toBeShown: visibleMenuItem.subMenus[0].toBeShown,
                        isDivider: visibleMenuItem.subMenus[0].isDivider,
                        onClick: visibleMenuItem.subMenus[0].onClick,
                        icon: visibleMenuItem.subMenus[0].icon ? visibleMenuItem.subMenus[0].icon : visibleMenuItem.icon,
                        path: visibleMenuItem.subMenus[0].path,
                        target: visibleMenuItem.subMenus[0].target
                    };
                    visibleMenuItems[i] = newVisibleMenuItem;
                } else {
                    for (let j = 0; j < visibleMenuItem.subMenus.length; j++) {
                        let visibleSubMenu = visibleMenuItem.subMenus[j];
                        if (visibleSubMenu.subMenus) {
                            visibleSubMenu.subMenus = filter(visibleSubMenu.subMenus, (key) => { return key.toBeShown; });
                            if (visibleSubMenu.subMenus.length === 1) { // if only one fourth level menu item, move third level menu to menu
                                let newVisibleSubMenu = {
                                    menuId: visibleSubMenu.subMenus[0].menuId,
                                    name: visibleSubMenu.subMenus[0].name,
                                    to: visibleSubMenu.subMenus[0].to,
                                    toBeShown: visibleSubMenu.subMenus[0].toBeShown,
                                    isDivider: visibleSubMenu.subMenus[0].isDivider,
                                    onClick: visibleSubMenu.subMenus[0].onClick,
                                    icon: visibleSubMenu.subMenus[0].icon ? visibleSubMenu.subMenus[0].icon : visibleSubMenu.icon,
                                    path: visibleSubMenu.subMenus[0].path,
                                    target: visibleMenuItem.subMenus[0].target
                                };
                                visibleMenuItem.subMenus[j] = newVisibleSubMenu;
                            } else {
                                forEach(visibleSubMenu.subMenus, (key) => {
                                    key.level = 3;
                                });
                            }
                        }
                        visibleMenuItem.subMenus[j].level = 2;
                    }
                }
            }
            visibleMenuItems[i].level = 1;
        }
        // render menu items
        if (visibleMenuItems.length > 1 || (preventCollapse && visibleMenuItems.length === 1)) {
            return (
                <NestedMenu
                    title={title}
                    tooltip={tooltip}
                    menuItems={visibleMenuItems}
                    menuId={menuId}
                    icon={icon}
                    isBlocking={isBlocking}
                    backLabel={backLabel}
                    className={className}
                    iconOnly={iconOnly}
                />
            );
        } else if (visibleMenuItems.length === 1) { // if only one menu item, move it to main menu
            let visibleMenuItem = head(visibleMenuItems);
            if (!visibleMenuItem.subMenus) {
                let link;
                let effectiveIcon = visibleMenuItem.icon || icon;
                let glyphiconStyles = effectiveIcon ? `glyphicons ${effectiveIcon}` : null;
                if (visibleMenuItem.path) {
                    link = (
                        <a id={menuId}
                            title={visibleMenuItem.title ? visibleMenuItem.title : ''}
                            className={glyphiconStyles}
                            href={visibleMenuItem.path}
                        >
                            <i /> <span>{visibleMenuItem.name}</span>
                        </a>
                    );
                } else if (visibleMenuItem.onClick) {
                    link = (
                        <a id={menuId}
                            title={visibleMenuItem.title ? visibleMenuItem.title : ''}
                            className={glyphiconStyles}
                            onClick={visibleMenuItem.onClick}
                        >
                            <i /> <span>{visibleMenuItem.name}</span>
                        </a>
                    );
                } else {
                    link = (
                        <Link id={menuId}
                            title={visibleMenuItem.title ? visibleMenuItem.title : ''}
                            className={glyphiconStyles}
                            to={visibleMenuItem.to}
                        >
                            <i /> <span>{visibleMenuItem.name}</span>
                        </Link>
                    );
                }
                return (
                    <li className={`trq-menuItem-v2 ${isBlocking ? 'disabled' : ''}`}>
                        {link}
                    </li>
                );
            } else { // if only one menu items and many sub menus, move sub menus to be menu items
                for (let i = 0; i < visibleMenuItem.subMenus.length; i++) {
                    let subMenu = visibleMenuItem.subMenus[i];
                    let item = {
                        menuId: subMenu.menuId,
                        name: subMenu.name,
                        to: subMenu.to,
                        icon: subMenu.icon,
                        toBeShown: subMenu.toBeShown,
                        isDivider: subMenu.isDivider,
                        onClick: subMenu.onClick,
                        path: subMenu.path
                    };
                    items.push(item);
                }
                return (
                    <NestedMenu
                        title={title}
                        tooltip={tooltip}
                        menuItems={items}
                        menuId={menuId}
                        iconOnly={iconOnly}
                        icon={icon}
                        isBlocking={isBlocking}
                        backLabel={backLabel}
                        className={className}
                    />
                );
            }
        } else {
            return null;
        }
    }
}

MenuGroup.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    tooltip: PropTypes.string,
    menuId: PropTypes.string.isRequired,
    preventCollapse: PropTypes.bool,
    menuItems: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        name: PropTypes.string,
        subMenus: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            name: PropTypes.string,
            to: PropTypes.string, // use for react route handler
            path: PropTypes.string,
            toBeShown: PropTypes.bool.isRequired,
            isDivider: PropTypes.bool,
            onClick: PropTypes.func
        })),
        onClick: PropTypes.func,
        to: PropTypes.string, // use for react route handler
        path: PropTypes.string,
        toBeShown: PropTypes.bool.isRequired,
        icon: PropTypes.string
    })).isRequired,
    isBlocking: PropTypes.bool,
    iconOnly: PropTypes.bool,
    toBeShown: PropTypes.bool,
    toBeShownNextBurgerMenu: PropTypes.bool,
    backLabel: PropTypes.string,
    className: PropTypes.string
};

MenuGroup.defaultProps = {
    iconOnly: false,
    isBlocking: false,
    toBeShown: true,
    toBeShownNextBurgerMenu: false
};

export default MenuGroup;