import { format, parseISO } from 'date-fns';
import { numberToPercentage, priceToString } from './number';
import { t } from '../assets/i18n/translation';
import React from 'react';
import { IApplicationUser } from '../types/User';
import { IViewFieldEntryEvaluation } from '../types/ContractActivationView.types';
import { MetaDataFieldValueType } from '../types/MetaData.types';

export type DateTimeFormatterOptions = { dateFormat: string; locale: Locale };
export const formatDateTime = (dateTimeString: string, { dateFormat, locale }: DateTimeFormatterOptions) =>
	format(parseISO(dateTimeString), `${dateFormat} HH:mm`, { locale });
export function formatDate(dateString: string | undefined, options: DateTimeFormatterOptions): string;
export function formatDate(date: Date | undefined, options: DateTimeFormatterOptions): string;
export function formatDate(date: Date | string | undefined, options: DateTimeFormatterOptions): string {
	if (!date) return '';
	if (typeof date === 'string') {
		date = new Date(date);
	}
	return format(date, options.dateFormat, { locale: options.locale });
}

export type StringFormatterConfig = {
	defaultValue: string;
	locale: Locale;
	dateFormat: string;
	currency: { code: string; sign: string };
};
export const formatString = (
	{ value, code, valueType }: { value: string | number | undefined; code?: string; valueType: MetaDataFieldValueType },
	config: StringFormatterConfig
) => {
	const { dateFormat, locale, defaultValue = '-' } = config;

	if (value == null || value === '') {
		return defaultValue;
	}

	switch (valueType) {
		case 'DATE': {
			if (typeof value !== 'string') return defaultValue;
			const dateFormatterOptions: DateTimeFormatterOptions = { dateFormat, locale };

			console.log('value', value);
			if (value.includes('T')) {
				return formatDateTime(value, dateFormatterOptions);
			}
			return formatDate(value, dateFormatterOptions);
		}
		case 'CURRENCY':
			return +value === 0 ? defaultValue : priceToString(+value, config.currency?.code);
		case 'PERCENTAGE':
			return +value === 0 ? defaultValue : numberToPercentage(+value);
		case 'BOOLEAN':
			return t(`configuration.metadata.fields.boolean.value.enum.${value === 'true' ? 'YES' : 'NO'}`);
		case 'ENUM':
			return t(`configuration.metadata.fields.code.enum.${code}.${value}`);
		default:
			return value;
	}
};

export const formatFieldValue = (entry: IViewFieldEntryEvaluation, config: StringFormatterConfig) => formatString(entry, config);

const formatFileSizeFromKb = (fileSizeInKb: number) => {
	if (fileSizeInKb < 1024) {
		return `${fileSizeInKb} ${t('file.size.kb')}`;
	}
	const fileSizeInMb = Math.round(fileSizeInKb / 1024);
	return `${fileSizeInMb} ${t('file.size.mb')}`;
};

const formatFileSizeFromFile = (file: File) => {
	const round = (number: number) => Math.round(number * 100) / 100;

	const { size } = file;
	return formatFileSizeFromKb(round(size / 1024));
};

export function formatFileSize(file: File): string;
export function formatFileSize(fileSizeInKb: number): string;
export function formatFileSize(input: File | number): string {
	if (typeof input === 'number') {
		return formatFileSizeFromKb(input);
	}
	return formatFileSizeFromFile(input);
}

export const createEnterPressHandler = <T extends Element>(handler: React.KeyboardEventHandler<T>) => {
	return (event: React.KeyboardEvent<T>) => event.key === 'Enter' && handler(event);
};

export const validateFiles = (files: File[], maxSizeInMb: number) => {
	const invalidFiles: File[] = [];
	const validFiles: File[] = [];
	if (files && files.length) {
		// FileList has no map function
		files.forEach((file) => {
			if (file.size > maxSizeInMb * 1024 * 1024) {
				invalidFiles.push(file);
			} else {
				validFiles.push(file);
			}
		});
	}
	return {
		validFiles,
		invalidFiles,
	};
};

export const compareByName = <T extends { name?: string }>(a: T, b: T): number =>
	(a.name || '').toLowerCase() > (b.name || '').toLowerCase() ? 1 : -1;

// accepts field name and entities to compare as well as order
export const compareByField = <K extends string, T extends { [key in K]?: string | number }>(
	fieldName: K,
	a: T,
	b: T,
	order: 'asc' | 'desc' = 'desc'
): number => {
	const field1 = a[fieldName];
	const field2 = b[fieldName];
	if (typeof field1 === 'number' && typeof field2 === 'number') {
		return order === 'asc' ? field1 - field2 : field2 - field1;
	}
	return ((field1 as string) || '').toLowerCase().localeCompare(((field2 as string) || '').toLowerCase()) * (order === 'asc' ? 1 : -1);
};

export function capitalizeFirstLetter(string: string): string {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export const findParent = (element: Element, matcher: (element: HTMLElement) => boolean): HTMLElement | null => {
	let parent = element.parentElement;
	while (parent) {
		if (matcher(parent)) {
			return parent;
		}
		parent = parent.parentElement;
	}
	return null;
};

export const getApplicationUserName = (user: IApplicationUser, onBehalfOf?: string) => {
	const fullName = `${user.firstName} ${user.lastName}`;
	if (!onBehalfOf) {
		return fullName;
	}
	return t('contract.activation.page.onBehalfOf.label', fullName, onBehalfOf);
};

// colour should be provided in six digit hex format (e.g. 'ffeedd')
export const generateRandomColour = (): string => {
	const randomColour = Math.floor(Math.random() * 16777215).toString(16);
	return randomColour.length === 6 ? randomColour : generateRandomColour();
};

export const fixPosition = <TIndex extends keyof T, T extends { [key in TIndex]: number }>(
	items: T[],
	indexField: TIndex,
	shift = 0
): T[] => {
	return items.map((item, index) => ({ ...item, [indexField]: index + shift }));
};

export const mergePosition = <TIndex extends keyof T, T extends { [key in TIndex]: number }>(
	oldItems: T[],
	newItems: T[],
	indexField: TIndex,
	shift = 0
): T[] => {
	const gaps: number[] = [];
	const existingPositions: number[] = oldItems.map((item) => item[indexField]).sort((a, b) => a - b);
	const maxPosition = existingPositions[existingPositions.length - 1] ?? 0;
	for (let i = shift; i <= maxPosition; i++) {
		if (existingPositions.includes(i)) continue;
		gaps.push(i);
	}
	let counter = maxPosition + 1;
	const updatedNewItems = newItems.map((item) => {
		return { ...item, [indexField]: gaps.shift() ?? counter++ };
	});

	return [...oldItems, ...updatedNewItems];
};

export const compareWithInactive =
	<T extends { label: string }>(isActive: (item: T) => boolean) =>
	(a: T, b: T) => {
		if (isActive(a) && !isActive(b)) return -1;
		if (isActive(b) && !isActive(a)) return 1;

		return a.label.localeCompare(b.label);
	};

export const isEmpty = (value: unknown[]) => !value || value.length === 0;

export const sortByField =
	<TFieldName extends string>(field: TFieldName, order: 'asc' | 'desc' = 'desc') =>
	<T extends { [key in TFieldName]: string | number }>(a: T, b: T) =>
		compareByField(field, a, b, order);
