// if you need to specify some parametrisation add '%s' sign on parameter place and put an argument to t() method
const TRANSLATIONS_EN = {
	//contractActivationAction
	'configuration.contractActivationAction.displayName': 'Action',
	'configuration.contractActivation.action.page.label': 'Action Configuration',
	'configuration.contractActivationAction.button.add.action': 'Add Action',
	'configuration.contractActivationAction.modal.header.label': 'Contract Activation Action',
	'configuration.contractActivationAction.modal.form.submit.label': 'Confirm',
	'configuration.contractActivationAction.modal.form.cancel.label': 'Cancel',
	'configuration.contractActivationAction.field.label.code': 'Code:',
	'configuration.contractActivationAction.field.label.name': 'Name:',
	'configuration.contractActivationAction.field.label.type': 'Button',
	'configuration.contractActivationAction.field.required.type': 'Type is mandatory.',
	'configuration.contractActivationAction.field.required.successStatusCode':
		'Selecting a success status is mandatory for actions without plugin.',
	'configuration.contractActivationAction.field.required.name': 'Action Name is mandatory.',
	'configuration.contractActivationAction.field.required.code': 'Code is mandatory.',
	'configuration.contractActivationAction.field.required.pluginOrStatus':
		'Contract activation action must have either success status code or plugin ID.',
	'configuration.contractActivationAction.table.column.code': 'Code',
	'configuration.contractActivationAction.table.column.name': 'Action Name',
	'configuration.contractActivationAction.table.column.type': 'Action Type',
	'configuration.contractActivationAction.table.column.successStatusCode': 'Success Status',
	'configuration.contractActivationAction.table.column.plugin': 'Plugin',

	//contractActivationStatus
	'configuration.contractActivation.status.page.label': 'Activation Status Configuration',
	'configuration.contractActivationStatus.displayName': 'Activation Status',
	'configuration.contractActivationStatus.button.add.action': 'Add Status',
	'configuration.contractActivationStatus.colourMode.enum.NO_COLOUR': 'No Colour',
	'configuration.contractActivationStatus.colourMode.enum.SPECIFIC_COLOUR': 'Specific Colour',
	'configuration.contractActivationStatus.modal.status.label': 'Status Name',
	'configuration.contractActivationStatus.editable.enum.ONLY_STATUS': 'Only Status',
	'configuration.contractActivationStatus.editable.enum.EDITABLE': 'Editable',
	'configuration.contractActivationStatus.editable.enum.LOCKED': 'Locked',
	'configuration.contractActivationStatus.field.required.code': 'Code is mandatory.',
	'configuration.contractActivationStatus.field.required.name': 'Status Name is mandatory.',
	'configuration.contractActivationStatus.field.required.displayOrder': 'Display Order is mandatory.',
	'configuration.contractActivationStatus.field.required.icon': 'Icon is mandatory.',
	'configuration.contractActivationStatus.field.pattern.displayOrder': 'Display Order must be a number.',
	'configuration.contractActivationStatus.field.required.colour': 'Colour is mandatory.',
	'configuration.contractActivationStatus.modal.form.cancel.label': 'Cancel',
	'configuration.contractActivationStatus.modal.form.submit.label': 'Confirm',
	'configuration.contractActivationStatus.modal.header.label': 'Contract Activation Status',
	'configuration.contractActivationStatus.table.column.displayOrder': 'Display Order',
	'configuration.contractActivationStatus.table.column.name': 'Name',
	'configuration.contractActivationStatus.table.column.colour': 'Colour',
	'configuration.contractActivationStatus.table.column.colourMode': 'Colour Mode',
	'configuration.contractActivationStatus.table.column.status': 'Status',
	'configuration.contractActivationStatus.table.column.code': 'Code',
	'configuration.contractActivationStatus.table.column.editable': 'Editable',
	'configuration.contractActivationStatus.table.column.icon': 'Icon',
	'configuration.contractActivationStatus.table.column.start': 'Start',
	'configuration.contractActivationStatus.type.enum.BUTTON': 'Button',
	'configuration.contractActivationStatus.type.enum.DEFAULT': 'Default',

	'contract.activation.page.label': 'Contract Activation',
	'contract.activation.page.activityStream.label': 'Activity Stream',
	'contract.activation.page.onBehalfOf.label': '%s on behalf of %s',
	'contract.activation.page.backToContract.btn.label': 'Back to Activation',
	'contract.activation.page.backToWorkflow.btn.label': 'Back to Workflow',
	'contract.activation.page.selfAssignment.btn.label': 'Assign me',
	'contract.activation.page.selfUnassignment.btn.label': 'Unassign me',
	'contract.activation.page.activityStream.btn.label': 'Activity Stream',
	'contract.activation.page.uploadDocuments.btn.label': 'Upload Documents',
	'contract.activation.page.addComment.btn.label': 'Add Comment',
	'contract.activation.page.uploadDocuments.error.fileSizeLimitExceeded':
		'The following file could not be chosen because its size is more than %s mb: %s with size %s.',
	'contract.activation.page.addComment.modal.title': 'Add Comment',
	'contract.activation.page.addComment.modal.type.label': 'Comment Type',
	'contract.activation.page.addComment.modal.comment.label': 'Comment',
	'contract.activation.page.addComment.modal.comment.error.required.label': 'Comment is mandatory',
	'contract.activation.page.addComment.modal.save.btn.label': 'Save Comment',
	'contract.activation.page.uploadDocuments.modal.title': 'Upload Documents',
	'contract.activation.page.unlock.modal.title': 'Unlock Contract Activation',
	'contract.activation.page.unlock.modal.confirmation.title': 'Warning',
	'contract.activation.page.unlock.modal.warning.text':
		'Unlocking a Contract Activation may affect the activation process or can cause out of synchronisation issues!',
	'contract.activation.page.unlock.modal.confirmation.text':
		'Are you sure you want to change the status of Contract Activation #%s? Changing the status of a locked activation can cause the systems to become out of sync.',
	'contract.activation.page.unlock.modal.statusId.label': 'Change Contract Activation Status to',
	'contract.activation.page.unlock.modal.statusId.required': 'Please select a status.',
	'contract.activation.page.activity.section.label': 'Status History',
	'contract.activation.page.documents.section.label': 'Documents',
	'contract.activation.workflow.page.label': 'Contract Activation Workflow',
	'contract.activation.workflow.search.page.label': 'Contract Activation Search',
	'contract.activation.assignment.message.success': 'Successfully assigned to %s.',
	'contract.activation.unassignment.message.success': 'Successfully unassigned %s.',
	'contract.activation.comment.message.success': 'Successfully added comment.',
	'contract.activation.documents.message.success': 'Successfully uploaded document(s).',
	'contract.activation.action.message.success': '%s was successful.',
	'contract.activity.submissionDateTime.label': 'Submission Date/Time',
	'contract.activity.version.label': 'Contract Activation Version',
	'contract.activity.externalVersion.label': 'Application Version',
	'contract.activity.createdBy.label': 'User',
	'contract.activity.user.label': 'User',
	'contract.activity.accessLevel.label': 'Contract Access Level: %s',
	'contract.activity.statusAfterActivity.label': 'Contract Activation Status',
	'contract.activity.stream.version.label': 'Version',
	'contract.activity.stream.submissionDateTime.label': 'Date/Time',
	'contract.activity.stream.type.label': 'Event',
	'contract.activity.stream.details.label': 'Details',
	'contract.document.fileName.label': 'File Name',
	'contract.document.uploadedAt.label': 'Upload Date / Time',
	'contract.document.versionNumber.label': 'Version',
	'contract.document.uploadedBy.label': 'Uploaded By',
	'contract.document.fileSize.label': 'File Size',

	'contract.asset.description.label': 'Asset Description',
	'contract.asset.registrationNumber.label': 'Registration',
	'contract.asset.registrationDate.label': 'Registration Date',
	'contract.asset.vin.label': 'VIN',

	'contract.businessPartner.label': 'Business Partner',
	'contract.businessPartner.role.label': 'Role',
	'contract.activation.page.businessPartners.section.label': 'Customers',

	'contract.activation.page.businessPartner.section.affiliate.customerSegment.INDIVIDUAL': 'Individual',
	'contract.activation.page.businessPartner.section.affiliate.customerSegment.ORGANISATION': 'Organisation',

	'contract.activation.page.settlement.condition.list.parameter.name': 'Condition',
	'contract.activation.page.settlement.condition.list.parameter.detail': 'Details',

	'contract.activation.page.checkpoints.section.label': 'Checkpoints',
	'contract.activation.page.checkpoints.table.status': 'Status',
	'contract.activation.page.checkpoints.table.checkpoint': 'Checkpoint',
	'contract.activation.page.checkpoints.table.details': 'Details',
	'contract.activation.page.checkpoints.table.previousStatus': 'Previous status',
	'contract.activation.page.outgoing.contract.activation.comments.section.label': 'Outgoing Contract Activation Comments',
	'contract.activation.page.internal.comments.section.label': 'Internal Comments',

	'contract.view.label': 'View Contract Activation',
	'contract.assign.view.label': 'Assign to me and view Contract Activation',
	'contract.activationVersionNumber.label': 'Activation Version',
	'contract.applicationId.label': 'ID',
	'contract.borrowerFullName.label': 'Borrower',
	'contract.currentStatusName.label': 'Status',
	'contract.financialProductName.label': 'Product',
	'contract.icon.label': 'Status',
	'contract.assignments.label': 'Participants',
	'contract.introducerName.label': 'Introducer',
	'contract.status.label.RECEIVED': 'Documents Received',
	'contract.status.label.STARTED': 'Document Control Started',
	'contract.status.label.TRANSMITTED': 'Transmitted to CMS',
	'contract.submissionDateTime.label': 'Submission Date/Time',
	'contracts.filter.label.applicationId': 'Activation ID:',
	'contracts.filter.label.asset': 'Asset:',
	'contracts.filter.label.customerName': 'Customer Name:',
	'contracts.filter.label.fromDate': 'From: ',
	'contracts.filter.label.financialProduct': 'Financial Product:',
	'contracts.filter.label.filterByDate': 'Filter By Date:',
	'contracts.filter.label.filterByDate.option.latestActivity': 'Latest Activity',
	'contracts.filter.label.filterByDate.option.latestRequest': 'Latest Request',
	'contracts.filter.label.filterByDate.option.latestActivated': 'Latest Activated',
	'contracts.filter.label.organisationId': 'ID/Organisation No:',
	'contracts.filter.label.status': 'Status: ',
	'contracts.filter.label.toDate': 'To: ',
	'footer.about': 'About',
	'header.userMenu.changeLogout': 'Logout',
	// LoginPage
	'login.button': 'Login',
	'login.options.title': 'Sign in',
	'login.options.title.hint': 'Please select your login method',
	'login.password': 'Password',
	'login.title': 'Log On',
	'login.userName': 'User Name',
	'login.userPassword': 'Password',
	'login.toForgotPassword.link': 'Forgot Password?',
	'login.authentication.error': 'User name or password is incorrect, or user is locked.',
	'loginWithToken.error.message.default': 'Cannot authenticate request. Please try to use a different login option.',
	'login.authentication.forbidden': 'Access denied. You do not have permission to perform this action or access this resource.',
	// nav
	'nav.menu.workflow': 'Workflow',
	'nav.menu.compliance.workflow': 'Configuration Workflow',
	'nav.menu.contractActivation': 'Contract Activation',
	'nav.menu.contractActivation.search': 'Search',
	'nav.menu.configuration': 'Configuration',
	'nav.menu.configuration.miscellaneus': 'Miscellaneus',
	'nav.menu.configuration.miscellaneus.compliance': 'Compliance',
	'nav.menu.configuration.miscellaneus.activationCustomField': 'Custom Field',
	'nav.menu.configuration.workflow': 'Workflow',
	'nav.menu.configuration.workflow.status': 'Activation Status',
	'nav.menu.configuration.workflow.action': 'Action',
	'nav.menu.configuration.workflow.checkpoint': 'Checkpoint Configuration',
	'nav.menu.configuration.workflow.checklist': 'Checklist Schedule',
	'nav.menu.configuration.workflow.condition.type': 'Condition Type',
	'nav.menu.configuration.view': 'View',
	'nav.menu.configuration.view.contract.activation': 'Contract Activation View',
	'nav.menu.configuration.view.actions.layout': 'Button Layout',
	'nav.menu.configuration.view.customer.section': 'Customer Section View',
	'nav.menu.configuration.view.asset.section': 'Assets Section View',
	'nav.menu.configuration.view.application.details.section': 'Application Details Section View',
	'nav.menu.configuration.view.computed.field': 'Computed Field',
	'nav.menu.configuration.system': 'System',
	'nav.menu.configuration.localisation': 'Localisation',
	'nav.menu.configuration.localisation.language': 'Language',
	'nav.menu.configuration.user': 'User',
	'nav.menu.configuration.system.genericDecisionTable': 'Decision Table',
	'nav.menu.configuration.system.role': 'Role',
	'nav.menu.configuration.system.role.assignment': 'Role Assignment',
	'notification.error.title': 'Unexpected error occurred',
	'notification.error.message': 'Unexpected error!',
	'user.login.username.required': 'Username is mandatory.',
	'user.login.password.required': 'Password is mandatory.',
	// secret
	'nav.menu.configuration.system.secret': 'Secret',
	'configuration.secret.displayName': 'Secret',
	'configuration.system.secret.title': 'Secret Configuration',
	'configuration.system.secret.name.label': 'Name',
	'configuration.system.secret.plugin.label': 'Type',
	'configuration.system.secret.value.label': 'Value',
	'configuration.system.secret.active.label': 'Active',
	'configuration.system.secret.edit.title': 'Edit Secret',
	'configuration.system.secret.view.title': 'View Secret',
	'configuration.system.secret.create.title': 'Create Secret',
	'configuration.system.secret.field.required.name': 'Name is mandatory.',
	'configuration.system.secret.field.required.plugin': 'Type is mandatory.',
	'configuration.system.secret.field.required.value': 'Value is mandatory.',
	'configuration.system.secret.plugin.plain.value.label': 'Plain Value',
	'configuration.system.secret.button.add.action': 'Add Secret',
	'configuration.system.secret.button.save.action': 'Save',
	'configuration.system.secret.button.cancel.action': 'Cancel',
	// Checkpoint
	'nav.menu.checkpoint': 'Checkpoint',
	'configuration.checkpoint.displayName': 'Checkpoint',
	'configuration.system.checkpointLabel.list.label': 'Checkpoint Configuration',
	'configuration.system.checkpointLabel.list.column.effectiveFrom': 'Effective From',
	'configuration.system.checkpointLabel.list.column.active': 'Active',
	'configuration.system.checkpointLabel.button.create': 'Create Checkpoint',
	'configuration.system.checkpoint.create.label': 'Create  Checkpoint',
	'configuration.system.checkpoint.update.label': 'Edit Checkpoint',
	'configuration.system.checkpoint.clone.label': 'Clone Checkpoint',
	'configuration.system.checkpoint.view.label': 'View Checkpoint',
	'configuration.system.checkpoint.button.create': 'Create Checkpoint',
	'configuration.system.checkpoint.button.update': 'Save Checkpoint',
	'configuration.system.checkpoint.button.clone': 'Clone Checkpoint',
	'configuration.system.checkpoint.section.general': 'General',
	'configuration.system.checkpoint.parameter.name': 'Name',
	'configuration.system.checkpoint.parameter.code': 'Code',
	'configuration.system.checkpoint.parameter.details': 'Details',
	'configuration.system.checkpoint.parameter.dependentActions': 'Dependent Actions',
	'configuration.system.checkpoint.parameter.active': 'Active',
	'configuration.system.checkpoint.section.entries': 'Variants',
	'configuration.system.checkpoint.parameter.required.name': 'Name is mandatory',
	'configuration.system.checkpoint.parameter.required.code': 'Code is mandatory',

	'configuration.system.checkpoint.variant.button.create': 'Create Variant',
	'configuration.system.checkpoint.variant.button.update': 'Save Variant',
	'configuration.system.checkpoint.variant.button.clone': 'Clone Variant',
	'configuration.system.checkpoint.variant.create.label': 'Create Checkpoint Variant',
	'configuration.system.checkpoint.variant.update.label': 'Edit Checkpoint Variant',
	'configuration.system.checkpoint.variant.clone.label': 'Clone Checkpoint Variant',
	'configuration.system.checkpoint.variant.view.label': 'View Checkpoint Variant',
	// checklist
	'nav.menu.checklist.schedule': 'Checklist Schedule',
	'configuration.checklist.schedule.displayName': 'Checklist Schedule',
	'configuration.checklist.schedule.list.page': 'Checklist Schedule Configuration',
	'configuration.checklist.schedule.list.parameter.description': 'Description',
	'configuration.checklist.schedule.list.parameter.required.description': 'Description is mandatory',
	'configuration.checklist.schedule.update.label': 'Edit Checklist Schedule',
	'configuration.checklist.schedule.button.create': 'Create Schedule',
	'configuration.checklist.schedule.page.create.label': 'Create Checklist Schedule',
	'configuration.checklist.schedule.page.view.label': 'View Checklist Schedule',
	'configuration.checklist.schedule.page.update.label': 'Edit Checklist Schedule',
	'configuration.checklist.schedule.page.clone.label': 'Clone Checklist Schedule',
	'configuration.checklist.schedule.page.section.entries': 'Schedule Entries',
	'configuration.checklist.schedule.page.parameter.tag.label': 'Tag',
	'configuration.checklist.schedule.page.parameter.tagValues.label': 'Tag Values',
	'configuration.checklist.schedule.page.parameter.financialProducts.label': 'Financial Products',
	'configuration.checklist.schedule.page.parameter.customerSegments.label': 'Customer Segments',
	'configuration.checklist.schedule.page.parameter.checkpoint.label': 'Checkpoint',
	'configuration.checklist.schedule.button.addEntry': 'Add Entry',
	'configuration.checklist.schedule.button.save': 'Save Schedule',
	'configuration.checklist.schedule.entry.parameter.validation.hasRequiredCheckpointField': 'Selecting a Checkpoint is mandatory.',

	// compliance
	'configuration.complianceSection.displayName': 'Compliance Section',
	'configuration.compliance.list.label': 'Compliance Configuration',
	'configuration.compliance.list.column.section': 'Compliance Section',
	'configuration.compliance.list.column.level': 'Compliance Level',
	'configuration.compliance.parameter.complianceLevel': 'Compliance Level',
	'configuration.compliance.update.label': 'Edit Compliance Section',
	'configuration.compliance.view.label': 'View Compliance Section',
	'configuration.compliance.button.save': 'Save Section',
	'enum.complianceLevel.DIRECT': 'Direct',
	'enum.complianceLevel.FOUR_EYES': '4 Eyes',
	'enum.complianceLevel.SEGREGATION_OF_DUTIES': 'Segregation of Duties',
	'enum.complianceSection.ACTIVATION_CUSTOM_FIELD_CONFIGURATION': 'Custom Field Configuration',
	'enum.complianceSection.COMPLIANCE_CONFIGURATION': 'Compliance Configuration',
	'enum.complianceSection.ACTIONS_LAYOUT_VIEW_CONFIGURATION': 'Button Layout Configuration',
	'enum.complianceSection.ACTION_CONFIGURATION': 'Action Configuration',
	'enum.complianceSection.ACCESS_LEVEL_CONFIGURATION': 'Access Level Configuration',
	'enum.complianceSection.ACCESS_LEVEL_SCHEDULE_CONFIGURATION': 'Access Level Schedule Configuration',
	'enum.complianceSection.CHECKLIST_CONFIGURATION': 'Checkpoint Configuration',
	'enum.complianceSection.CONDITION_TYPE_CONFIGURATION': 'Condition Type Configuration',
	'enum.complianceSection.GENERIC_DECISION_TABLE_CONFIGURATION': 'Generic Decision Table Configuration',
	'enum.complianceSection.SECRET_CONFIGURATION': 'Secret Configuration',
	'enum.complianceSection.LANGUAGE_CONFIGURATION': 'Language Configuration',
	'enum.complianceSection.SYSTEM_CONFIGURATION': 'System Configuration',
	'enum.complianceSection.STATUS_CONFIGURATION': 'Status Configuration',
	'enum.complianceSection.ROLE_CONFIGURATION': 'Role Configuration',
	'enum.complianceSection.ROLE_ASSIGNMENT_CONFIGURATION': 'Role Assignment Configuration',
	'enum.complianceSection.COMPUTED_FIELD_CONFIGURATION': 'Computed Field Configuration',
	'enum.complianceSection.APPLICATION_USER': 'User Configuration',
	'enum.complianceSection.USER_GROUP_CONFIGURATION': 'User Group Configuration',
	'enum.complianceSection.TAG_CONFIGURATION': 'Tag Configuration',
	'enum.complianceSection.CONTRACT_ACTIVATION_VIEW_CONFIGURATION': 'Contract Activation View Configuration',
	'enum.complianceSection.CUSTOMER_SECTION_VIEW_CONFIGURATION': 'Customer Section View Configuration',
	'enum.complianceSection.ASSET_SECTION_VIEW_CONFIGURATION': 'Asset Section View Configuration',
	'enum.complianceSection.APPLICATION_DETAILS_SECTION_VIEW_CONFIGURATION': 'Application Details Section View Configuration',
	'enum.complianceSection.CHECKLIST_SCHEDULE_CONFIGURATION': 'Checklist Schedule Configuration',
	'enum.complianceSection.ACTIONS_LAYOUT_VIEW': 'Button Layout View Configuration',

	'enum.status.DONE': 'Done',
	'enum.status.PENDING': 'Pending',

	//activation custom fields
	'configuration.miscellaneus.customfields.list.label': 'Custom Field Configuration',
	'configuration.customfield.page.update.title': 'Edit Custom Field',
	'configuration.customfield.page.clone.title': 'Clone Custom Field',
	'configuration.customfield.displayName': 'Custom Field',
	'configuration.customfield.action.save': 'Save',
	'configuration.customfield.evaluation.inactive.field': '%s is inactive and has been removed.',
	'configuration.customfield.warning.edit':
		'Altering the configuration of the custom field may affect existing Contract Activations where the custom field has been used.',
	'configuration.customfield.action.create': 'Add Custom Field',
	'configuration.customfield.page.create.title': 'Create Custom Field',
	'configuration.customfields.page.update.title': 'Edit Custom Field',
	'configuration.miscellaneus.customfield.parameter.name': 'Name',
	'configuration.miscellaneus.customfield.parameter.code': 'Code',
	'configuration.miscellaneus.customfield.parameter.category': 'Category',
	'configuration.miscellaneus.customfield.parameter.customFieldType': 'Field Type',
	'configuration.miscellaneus.customfield.parameter.valueType': 'Value Type',
	'configuration.miscellaneus.customfield.parameter.active': 'Active',
	'configuration.miscellaneus.customfield.parameter.label': 'Label',
	'configuration.miscellaneus.customfields.clone.label': 'Clone Custom Field',
	'configuration.customfields.page.create.title': 'Create Custom Field',
	'configuration.miscellaneus.customfield.parameter.description': 'Description',
	'configuration.miscellaneus.customfield.parameter.category.enum.ASSET': 'Asset',
	'configuration.miscellaneus.customfield.parameter.category.enum.CUSTOMER': 'Customer',
	'configuration.miscellaneus.customfield.parameter.category.enum.LOAN_APPLICATION': 'Loan/Application',
	'configuration.miscellaneus.customfield.parameter.valueType.enum.DECIMAL': 'Decimal',
	'configuration.miscellaneus.customfield.parameter.valueType.enum.INTEGER': 'Integer',
	'configuration.miscellaneus.customfield.parameter.valueType.enum.DATE': 'Date',
	'configuration.miscellaneus.customfield.parameter.valueType.enum.BOOLEAN': 'Boolean',
	'configuration.miscellaneus.customfield.parameter.valueType.enum.TEXT': 'Text',
	'configuration.miscellaneus.customfield.parameter.valueType.enum.CURRENCY': 'Currency',
	'configuration.miscellaneus.customfield.parameter.valueType.enum.PERCENTAGE': 'Percentage',
	'configuration.miscellaneus.customfield.parameter.valueType.enum.INPUT_FIELD': 'Input Field',
	'configuration.miscellaneus.customfield.parameter.valueType.enum.SINGLE_SELECT': 'Single Select',
	'configuration.miscellaneus.customfield.parameter.customFieldType.enum.INPUT_FIELD': 'Input Field',
	'configuration.miscellaneus.customfield.parameter.customFieldType.enum.SINGLE_SELECT': 'Single Select',

	// plugin
	'configuration.plugin.displayName': 'Plugin Configuration',
	'configuration.system.plugin.title': 'Plugin Configuration',
	'configuration.system.plugin.edit.title': 'Edit Plugin Configuration',
	'nav.menu.configuration.system.plugin': 'Plugin',
	'configuration.system.plugin.table.column.name': 'Plugin Name',
	'configuration.system.plugin.table.column.code': 'Plugin Code',
	'configuration.system.plugin.table.column.version': 'Plugin Version',
	'configuration.system.plugin.table.column.type': 'Plugin Type',
	'configuration.system.plugin.table.column.enabled': 'Enabled',
	'configuration.system.plugin.table.column.status': 'Status',
	'configuration.system.plugin.table.pluginType.enum.ACTION': 'Action',
	'configuration.system.plugin.table.pluginType.enum.AUTHENTICATION': 'Authentication',
	'configuration.system.plugin.table.pluginType.enum.ACTIVATION_PROGRESS': 'Activation Progress',
	'configuration.system.plugin.table.pluginType.enum.DOCUMENT_STORAGE': 'Document Storage',
	'configuration.system.plugin.table.pluginType.enum.FILE_VALIDATION': 'File Validation',
	'configuration.system.plugin.table.pluginStatus.enum.READY': 'Ready',
	'configuration.system.plugin.table.pluginStatus.enum.UNAVAILABLE': 'Unavailable',
	'configuration.system.plugin.table.pluginStatus.enum.INSTALLATION_FAILED': 'Installation Failed',
	'configuration.system.plugin.configuration.enabled': 'Enabled',
	'configuration.system.plugin.configuration.available': 'Available',
	'configuration.system.plugin.configuration.pluginCode': 'Plugin Code',
	'configuration.system.plugin.configuration.pluginName': 'Plugin Name',
	'configuration.system.plugin.configuration.pluginVersion': 'Plugin Version',
	'configuration.system.plugin.configuration.pluginType': 'Plugin Type',
	'configuration.system.plugin.configuration.parameter.table.name': 'Parameter Name',
	'configuration.system.plugin.configuration.parameter.table.value': 'Parameter Value',
	'configuration.system.plugin.configuration.parameter.button.add': 'Add Parameter',
	'configuration.system.plugin.configuration.parameter.button.verify': 'Verify Configuration',
	'configuration.system.plugin.configuration.parameter.button.save': 'Save Configuration',
	'configuration.system.plugin.configuration.parameter.button.cancel': 'Cancel',
	'configuration.system.plugin.configuration.update.success.message': 'Plugin was successfully updated!',
	'configuration.system.plugin.configuration.parameter.validation.hasNoEmptyNames': 'There should be no empty parameter names.',
	// user configuration
	'configuration.user.displayName': 'User',
	'configuration.user.title': 'User Configuration',
	'configuration.user.list.label': 'User Configuration',
	'configuration.user.resetPassword.title': 'Reset Password',
	'configuration.user.resetPassword.success.message': 'Password reset successfully.',
	'configuration.user.parameter.login': 'Login',
	'configuration.user.parameter.login.required': 'Login is mandatory',
	'configuration.user.parameter.email': 'Email',
	'configuration.user.parameter.email.required': 'Email is mandatory',
	'configuration.user.parameter.email.valid': 'Email should be valid',
	'configuration.user.parameter.login.max': 'Login is too long (max 255 characters allowed)',
	'configuration.user.parameter.name': 'Name',
	'configuration.user.parameter.firstName': 'First Name',
	'configuration.user.parameter.firstName.required': 'First Name is mandatory',
	'configuration.user.parameter.firstName.max': 'First Name is too long (max 255 characters allowed)',
	'configuration.user.parameter.lastName': 'Last Name',
	'configuration.user.parameter.lastName.required': 'Last Name is mandatory',
	'configuration.user.parameter.lastName.max': 'Last Name is too long (max 255 characters allowed)',
	'configuration.user.parameter.locked': 'Locked',
	'configuration.user.parameter.preventPasswordExpiry': 'Prevent Password Expiry',
	'configuration.user.parameter.requireChangePassword': 'Force Password Change On Next Login',
	'configuration.user.parameter.password': 'Password',
	'configuration.user.parameter.password.required': 'Password is mandatory',
	'configuration.user.parameter.password.match': 'Password and confirmation should match',
	'configuration.user.parameter.password.confirmation': 'Password Confirmation',
	'configuration.user.parameter.password.confirmation.required': 'Password Confirmation is mandatory',
	'configuration.user.parameter.authenticationProvider': 'Authentication Method',
	'configuration.user.parameter.roleAssignment.active': 'Effective',
	'configuration.user.btn.update.label': 'Edit User',
	'configuration.user.btn.clone.label': 'Clone User',
	'configuration.user.btn.resetPassword.label': 'Reset Password',
	'configuration.user.btn.create.label': 'Create User',
	'configuration.user.btn.save.label': 'Save User',
	'configuration.user.btn.resetPassword.save.label': 'Save',
	'configuration.user.create.header': 'Create User Configuration',
	'configuration.user.update.header': 'Edit User Configuration',
	'configuration.user.view.header': 'View User Configuration',
	'configuration.user.clone.header': 'Clone User Configuration',
	'configuration.user.resetPassword.header': 'Reset Password',
	'configuration.user.userGroups.title': 'User Groups',
	'configuration.user.permissions.title': 'Roles',
	'configuration.user.addUserGroups.btn': 'Add User Groups',
	'configuration.user.addUserGroups.modal.title': 'Add User Group(s)',

	// usergroup configuration
	'configuration.user.group.displayName': 'User Group',
	'configuration.user.group.edit.title': 'Edit User Group',
	'configuration.user.group.view.title': 'View User Group',
	'configuration.user.group.new.title': 'Create User Group',
	'configuration.user.group.name.label': 'Name',
	'configuration.user.group.code.label': 'Code',
	'configuration.user.group.active.label': 'Active',
	'configuration.user.group.field.required.code': 'Code is mandatory.',
	'configuration.user.group.field.required.name': 'Name is mandatory.',
	'configuration.user.group.title': 'User Group Configuration',
	'configuration.user.group.table.column.name': 'Name',
	'configuration.user.group.table.column.code': 'Code',
	'configuration.user.group.table.column.numberOfUsers': 'Number of Users',
	'configuration.user.group.table.column.active': 'Active',
	'configuration.user.group.button.save.action': 'Save User Group',
	'configuration.user.group.button.cancel.action': 'Cancel',
	'configuration.user.group.page.addUser.modal.title': 'Add User',
	'configuration.user.group.modal.button.save.action': 'Add User(s)',
	'user.group.configuration.user.table.column.name': 'Name',
	'user.group.configuration.user.table.column.login': 'Login',
	'user.group.configuration.user.table.column.active': 'Active',
	'user.group.dialog.configuration.user.table.column.name': 'Name',
	'user.group.dialog.configuration.user.table.column.login': 'Login',
	'user.group.dialog.configuration.user.table.column.active': 'Active',
	'configuration.user.group.configuration.user.button.add': 'Add User',
	'configuration.user.group.configuration.update.success.message': 'User Group Successfully Saved',
	'configuration.user.group.button.add.action': 'Create User Group',

	// access level
	'nav.menu.configuration.system.accessLevel': 'Contract Access Level',
	'configuration.accessLevel.displayName': 'Access Level',
	'configuration.system.accessLevel.list.label': 'Contract Access Level Configuration',
	'configuration.system.accessLevel.create.label': 'Create Contract Access Level',
	'configuration.system.accessLevel.update.label': 'Edit Contract Access Level',
	'configuration.system.accessLevel.view.label': 'View Contract Access Level',
	'configuration.system.accessLevel.list.column.name': 'Name',
	'configuration.system.accessLevel.list.column.code': 'Code',
	'configuration.system.accessLevel.parameter.name': 'Name',
	'configuration.system.accessLevel.parameter.code': 'Code',
	'configuration.system.accessLevel.button.create': 'Create Access Level',
	'configuration.system.accessLevel.button.save': 'Save Access Level',
	'configuration.system.accessLevel.button.cancel': 'Cancel',
	'configuration.system.accessLevel.parameter.required.name': 'Access Level Name is mandatory',
	'configuration.system.accessLevel.parameter.required.code': 'Access Level Code is mandatory',
	'configuration.system.accessLevel.create.success.message': 'Access level was successfully created!',
	'configuration.system.accessLevel.update.success.message': 'Access level was successfully updated!',
	// access level schedule
	'nav.menu.configuration.system.accessLevelSchedule': 'Contract Access Level Schedule',
	'configuration.accessLevelSchedule.displayName': 'Access Level Schedule',
	'configuration.system.accessLevelSchedule.create.label': 'Create Contract Access Level Schedule',
	'configuration.system.accessLevelSchedule.update.label': 'Edit Contract Access Level Schedule',
	'configuration.system.accessLevelSchedule.clone.label': 'Clone Contract Access Level Schedule',
	'configuration.system.accessLevelSchedule.view.label': 'View Contract Access Level Schedule',
	'configuration.system.accessLevelSchedule.button.create': 'Create Schedule',
	'configuration.system.accessLevelSchedule.button.update': 'Save Schedule',
	'configuration.system.accessLevelSchedule.button.clone': 'Create Schedule',
	'configuration.system.accessLevelSchedule.list.label': 'Contract Access Level Schedule Configuration',
	'configuration.system.accessLevelSchedule.list.column.effectiveFrom': 'Effective From',
	'configuration.system.accessLevelSchedule.list.column.active': 'Active',
	'configuration.system.accessLevelSchedule.list.column.actions.edit.tooltip': 'Edit Schedule',
	'configuration.system.accessLevelSchedule.list.column.actions.clone.tooltip': 'Clone Schedule',
	'configuration.system.accessLevelSchedule.parameter.effectiveFrom': 'Effective From',
	'configuration.system.accessLevelSchedule.parameter.active': 'Active',
	'configuration.system.accessLevelSchedule.parameter.entries.column.accessLevel': 'Access Level',
	'configuration.system.accessLevelSchedule.parameter.entries.column.financialProduct': 'Financial Product',
	'configuration.system.accessLevelSchedule.parameter.required.effectiveFrom': 'Effective from is mandatory',
	'configuration.system.accessLevelSchedule.parameter.validation.duplicates': 'There are 1 or more duplicate rows.',
	'configuration.system.accessLevelSchedule.parameter.validation.noEmptyFinancialProductEntry':
		'There must be one entry with no Financial Product but a selected Contract Access Level.',
	'configuration.system.accessLevelSchedule.parameter.validation.noAccessLevel': 'Each entry must have a non null Contract Access Level.',
	'configuration.system.accessLevelSchedule.button.addEntry': 'Add Entry',
	'configuration.system.accessLevelSchedule.button.saveEntry': 'Save Schedule',
	'configuration.system.accessLevelSchedule.section.general': 'General',
	'configuration.system.accessLevelSchedule.section.entries': 'Entries',
	// reference data
	'configuration.referenceData.displayName': 'Reference Data',
	'configuration.referenceData.list': 'Reference Data Configuration',
	'configuration.referenceData.edit': 'Edit Reference Data Configuration',
	'configuration.referenceData.view': 'View Reference Data Configuration',
	'configuration.referenceData.actions.save': 'Save Reference Data',
	'configuration.referenceData.parameter.name': 'Reference Data Type',
	'configuration.referenceData.parameter.values': 'Active / Total Values',
	'configuration.referenceData.parameter.visibilities': 'Visibility',
	'configuration.referenceData.parameter.visibilities.enum.CONTRACT_ACTIVATION_SEARCH': 'Activation Search',
	'configuration.referenceData.parameter.visibilities.enum.CONTRACT_ACTIVATION_WORKFLOW': 'Workflow Filter',
	'configuration.referenceData.parameter.visibilities.enum.DO_NOT_SHOW': 'Do Not Show',
	'configuration.referenceData.section.values.add.title': 'Add Value: %s',
	'configuration.referenceData.section.values.add.success': 'Successfully added new value.',
	'configuration.referenceData.section.values.active.success': 'Successfully set existing value to "Active".',
	'configuration.referenceData.section.values.inactive.success': 'Successfully set existing value to "Inactive".',
	'configuration.referenceData.section.values.error.duplicate': 'Value "%s" with Code "%s" already exists',
	'referenceData.type.enum.CAMPAIGN': 'Campaign',
	'referenceData.type.enum.CITIZENSHIP': 'Citizenship',
	'referenceData.type.enum.FINANCIAL_PRODUCT': 'Financial Product',
	'referenceData.type.enum.CUSTOMER_SEGMENT': 'Financial Product',
	// user
	'nav.menu.configuration.user.user': 'User',
	'nav.menu.configuration.user.user.group': 'User Group',
	// dashboard
	'nav.menu.dashboard': 'Dashboard',
	// misc
	'notFound.page.label': 'Not Found',
	'action.unlock': 'Contract Activation Unlock',
	'notFound.page.text': 'The page does not exist.',
	'inProcess.page.text': 'Login in progress',
	'button.cancel': 'Cancel',
	'button.close': 'Close',
	'button.confirm': 'Confirm',
	'button.more': 'More',
	'button.search': 'Search',
	'button.remove': 'Remove',
	'button.contract.unlock': 'Unlock Activation',
	'file.size.kb': 'KB',
	'file.size.mb': 'MB',
	'select.option.empty': 'None Selected',
	'configuration.template.page.section.general': 'General',
	'configuration.template.page.select.option.inactive': '%s (inactive)',
	'configuration.template.page.button.addVariant.label': 'Add Variant',
	'configuration.template.page.field.required': '%s is mandatory.',
	'configuration.template.page.field.maxLength': '%s is too long (max %s characters expected).',
	'configuration.template.parameter.effectiveFrom': 'Effective From',
	'configuration.template.parameter.required.effectiveFrom': 'Effective From is mandatory',
	'configuration.template.list.column.active': 'Active',
	'configuration.template.list.column.name': 'Name',
	'configuration.template.list.column.code': 'Code',
	'configuration.template.list.pending.version': 'Pending',
	'configuration.template.list.approved.version': 'Approved',
	'configuration.template.page.section.values.label': 'Values',
	'configuration.template.page.section.values.addValue': 'Add Value',
	'configuration.template.button.confirm': 'Confirm',
	'system.name': 'Contract Activation Module (CAM) © %s, Torque IT Solutions Ltd.',
	'contract.action.blocked': 'Pending required checks:',
	'contract.tag.evaluation.errorMessage': 'Tag evaluation failed with error: %s Please contact your administrator.',
	'configuration.metadata.fields.fieldType.enum.METADATA': 'Standard',
	'configuration.metadata.fields.fieldType.enum.ACTIVATION_CUSTOM': 'Custom (Activation)',
	'configuration.metadata.fields.fieldType.enum.ASSET_TAX_TYPE': 'Tax',
	'configuration.metadata.fields.fieldType.enum.CUSTOM': 'Custom (External)',
	'configuration.metadata.fields.fieldType.enum.COMPUTED': 'Computed',
	'configuration.metadata.fields.fieldType.enum.COMBINE_TWO_FIELDS': 'Combined',
	'configuration.metadata.fields.fieldType.enum.FIELD_GROUP': 'Group',
	'configuration.metadata.fields.fieldType.label': 'Field Type',
	'configuration.metadata.fields.category.enum.CUSTOMER': 'Customer',
	'configuration.metadata.fields.boolean.value.enum.YES': 'Yes',
	'configuration.metadata.fields.boolean.value.enum.NO': 'No',
	'configuration.metadata.fields.code.enum.ASSET_TAX_TYPE': 'Tax',
	'configuration.metadata.fields.code.enum.ADDRESS.ADDRESS_ADDITIONAL_LINE': 'Address Additional Line',
	'configuration.metadata.fields.code.enum.ADDRESS.ADDRESS_STATUS': 'Address Status',
	'configuration.metadata.fields.code.enum.ADDRESS.ADDRESS_STATUS.CURRENT': 'Current',
	'configuration.metadata.fields.code.enum.ADDRESS.ADDRESS_STATUS.PREVIOUS': 'Previous',
	'configuration.metadata.fields.code.enum.ADDRESS.ADDRESS_TYPE': 'Address Type',
	'configuration.metadata.fields.code.enum.ADDRESS.ADDRESS_TYPE.PHYSICAL': 'Physical',
	'configuration.metadata.fields.code.enum.ADDRESS.ADDRESS_TYPE.POSTAL': 'Postal',
	'configuration.metadata.fields.code.enum.ADDRESS.ADDRESS_USAGE': 'Address Usage',
	'configuration.metadata.fields.code.enum.ADDRESS.ADDRESS_USAGE.HOME': 'Home',
	'configuration.metadata.fields.code.enum.ADDRESS.ADDRESS_USAGE.WORK': 'Work',
	'configuration.metadata.fields.code.enum.CONTACT_PERSON.CONTACT_CHANNEL': 'Contact Person Contact Channel',
	'configuration.metadata.fields.code.enum.CONTACT_PERSON_DESCRIPTION': 'Contact Person',
	'configuration.metadata.fields.code.enum.CONTACT_PERSON.CONTACT_DETAILS': 'Contact Person Details',
	'configuration.metadata.fields.code.enum.CONTACT_PERSON.CONTACT_TYPE': 'Contact Person Contact Type',
	'configuration.metadata.fields.code.enum.CONTACT_PERSON.CONTACT_CHANNEL.LANDLINE_PHONE': 'Landline Phone',
	'configuration.metadata.fields.code.enum.CONTACT_PERSON.CONTACT_CHANNEL.MOBILE_PHONE': 'Mobile Phone',
	'configuration.metadata.fields.code.enum.CONTACT_PERSON.CONTACT_CHANNEL.EMAIL': 'Email',
	'configuration.metadata.fields.code.enum.CONTACT_PERSON.CONTACT_CHANNEL.FAX': 'Fax',
	'configuration.metadata.fields.code.enum.CONTACT_PERSON.CONTACT_TYPE.WORK': 'Work',
	'configuration.metadata.fields.code.enum.CONTACT_PERSON.CONTACT_TYPE.HOME': 'Home',
	'configuration.metadata.fields.code.enum.ASSET_SUPPLIER.AssetSupplier.THIRD_PARTY': 'Third Party',
	'configuration.metadata.fields.code.enum.ASSET_SUPPLIER.AssetSupplier.INTRODUCER': 'Introducer',
	'configuration.metadata.fields.code.enum.ASSET_SUPPLIER.AssetSupplier.LEASE_COMPANY': 'Lease Company',
	'configuration.metadata.fields.code.enum.ADDRESS.CITY': 'City',
	'configuration.metadata.fields.code.enum.ADDRESS.COUNTRY.NAME': 'Country',
	'configuration.metadata.fields.code.enum.ADDRESS.FLAT_UNIT': 'Flat/Unit',
	'configuration.metadata.fields.code.enum.ADDRESS.POBOX': 'PO Box',
	'configuration.metadata.fields.code.enum.ADDRESS.POSTAL_CODE': 'Postal Code',
	'configuration.metadata.fields.code.enum.ADDRESS.REGION': 'Region',
	'configuration.metadata.fields.code.enum.ADDRESS.STREET_NAME': 'Street Name',
	'configuration.metadata.fields.code.enum.ADDRESS.STREET_NUMBER': 'Street Number',
	'configuration.metadata.fields.code.enum.ADDRESS.STREET_SUFFIX': 'Street Suffix',
	'configuration.metadata.fields.code.enum.ADDRESS.SUBURB': 'Suburb',
	'configuration.metadata.fields.code.enum.ADDRESS.TIME_AT_ADDRESS_MONTHS': 'Time at Address (Months)',
	'configuration.metadata.fields.code.enum.ADDRESS.TIME_AT_ADDRESS_YEARS': 'Time at Address (Years)',
	'configuration.metadata.fields.code.enum.MARITAL_STATUS.DIVORCED': 'Divorced',
	'configuration.metadata.fields.code.enum.MARITAL_STATUS.DOMESTIC_PARTNER': 'Domestic Partner',
	'configuration.metadata.fields.code.enum.MARITAL_STATUS.MARRIED': 'Married',
	'configuration.metadata.fields.code.enum.MARITAL_STATUS.SINGLE': 'Single',
	'configuration.metadata.fields.code.enum.MARITAL_STATUS.WIDOWED': 'Widowed',
	'configuration.metadata.fields.code.enum.CAMPAIGN.NAME': 'Campaign',
	'configuration.metadata.fields.code.enum.CITIZENSHIP_TYPE.NAME': 'Citizenship',
	'configuration.metadata.fields.code.enum.CUSTOMER_SEGMENT.NAME': 'Customer Segment',
	'configuration.metadata.fields.code.enum.DATE_OF_BIRTH': 'Date of Birth',
	'configuration.metadata.fields.code.enum.DEPOSIT_AMOUNT_NOMINAL': 'Nominal Deposit',
	'configuration.metadata.fields.code.enum.DEPOSIT_VAT': 'Deposit VAT',
	'configuration.metadata.fields.code.enum.EMPLOYMENT.INDUSTRY_SUB_TYPE.NAME': 'Employment Industry Sub Type',
	'configuration.metadata.fields.code.enum.EMPLOYMENT.INDUSTRY_TYPE.NAME': 'Employment Industry Type',
	'configuration.metadata.fields.code.enum.EMPLOYMENT.TYPE.NAME': 'Employment Type',
	'configuration.metadata.fields.code.enum.EXISTING_CUSTOMER_ID': 'Existing Customer ID',
	'configuration.metadata.fields.code.enum.FINANCIAL_PRODUCT.NAME': 'Financial Product',
	'configuration.metadata.fields.code.enum.CONTACT_INFORMATION.CONTACT_CHANNEL': 'Contact Channel',
	'configuration.metadata.fields.code.enum.CONTACT_INFORMATION.CONTACT_CHANNEL.EMAIL': 'Email',
	'configuration.metadata.fields.code.enum.CONTACT_INFORMATION.CONTACT_CHANNEL.LANDLINE_PHONE': 'Landline Phone',
	'configuration.metadata.fields.code.enum.CONTACT_INFORMATION.CONTACT_CHANNEL.MOBILE_PHONE': 'Mobile Phone',
	'configuration.metadata.fields.code.enum.CONTACT_INFORMATION.CONTACT_TYPE': 'Contact Information Type',
	'configuration.metadata.fields.code.enum.CONTACT_INFORMATION.CONTACT_TYPE.PRIVATE': 'Private',
	'configuration.metadata.fields.code.enum.CONTACT_INFORMATION.CONTACT_TYPE.WORK': 'Work',
	'configuration.metadata.fields.code.enum.APPLICANT_TYPE.BORROWER': 'Borrower',
	'configuration.metadata.fields.code.enum.APPLICANT_TYPE.CO_BORROWER': 'Co-Borrower',
	'configuration.metadata.fields.code.enum.APPLICANT_TYPE.GUARANTOR': 'Guarantor',
	'configuration.metadata.fields.code.enum.APPLICANT_TYPE.AFFILIATE': 'Affiliate',
	'configuration.metadata.fields.code.enum.GENDER.MALE': 'Male',
	'configuration.metadata.fields.code.enum.GENDER.FEMALE': 'Female',
	'configuration.metadata.fields.code.enum.PAYMENT.BIC': 'Payment BIC',
	'configuration.metadata.fields.code.enum.EMPLOYMENT.STATUS.EmploymentStatus.CURRENT': 'Current',
	'configuration.metadata.fields.code.enum.EMPLOYMENT.STATUS.EmploymentStatus.PREVIOUS': 'Previous',
	'configuration.metadata.fields.code.enum.ASSET_ANNUAL_USAGE_ALLOWANCE_AUTOMOTIVE': 'Usage Allowance (Annual)',
	'configuration.metadata.fields.code.enum.ASSET_ANNUAL_USAGE_ALLOWANCE_NON_AUTOMOTIVE': 'Annual Usage Allowance',
	'configuration.metadata.fields.code.enum.ASSET_CC_RATING': 'CC Rating',
	'configuration.metadata.fields.code.enum.ASSET_CONDITION.NAME': 'Asset Condition',
	'configuration.metadata.fields.code.enum.ASSET_DEPOSIT_PERCENTAGE_BASED_ON_NOMINAL_DEPOSIT': 'Deposit (based on Nominal Deposit)',
	'configuration.metadata.fields.code.enum.ASSET_DEPOSIT_VAT': 'Deposit VAT',
	'configuration.metadata.fields.code.enum.ASSET_DESCRIPTION': 'Description',
	'configuration.metadata.fields.code.enum.ASSET_DISCOUNT': 'Discount',
	'configuration.metadata.fields.code.enum.ASSET_DISCOUNT_PERCENTAGE': 'Discount Percentage',
	'configuration.metadata.fields.code.enum.ASSET_DISCOUNT_VAT': 'Discount VAT',
	'configuration.metadata.fields.code.enum.ASSET_DISTRIBUTOR_MODEL_CODE': 'Model Code (Distributor)',
	'configuration.metadata.fields.code.enum.ASSET_ENCUMBRANCE': 'Encumbrance',
	'configuration.metadata.fields.code.enum.ASSET_EMISSIONS_AUTOMOTIVE': 'Emission',
	'configuration.metadata.fields.code.enum.ASSET_COLOUR_AUTOMOTIVE': 'Colour',
	'configuration.metadata.fields.code.enum.ASSET_ENGINE_NUMBER_AUTOMOTIVE': 'Engine Number',
	'configuration.metadata.fields.code.enum.ASSET_ENGINE_NUMBER_NON_AUTOMOTIVE': 'Engine Number',
	'configuration.metadata.fields.code.enum.ASSET_EXTRA_DESCRIPTION': 'Extra',
	'configuration.metadata.fields.code.enum.ASSET_EXTRA_RESIDUAL_VALUE': 'Residual Value',
	'configuration.metadata.fields.code.enum.ASSET_EXTRA_SALES_PRICE': 'Sales Price',
	'configuration.metadata.fields.code.enum.ASSET_EXTRA_FITTER.INTRODUCER': 'Introducer',
	'configuration.metadata.fields.code.enum.ASSET_EXTRA_FITTER.MANUFACTURER': 'Manufacturer',
	'configuration.metadata.fields.code.enum.ASSET_EXTRA_SUPPLIER.INTRODUCER': 'Introducer',
	'configuration.metadata.fields.code.enum.ASSET_EXTRA_SUPPLIER.LEASE_COMPANY': 'Lease Company',
	'configuration.metadata.fields.code.enum.ASSET_EXTRA_SUPPLIER.THIRD_PARTY': 'Third Party',
	'configuration.metadata.fields.code.enum.ASSET_EXTRA_CATEGORY.NAME': 'Category',
	'configuration.metadata.fields.code.enum.ASSET_EXTRA_DISCOUNT': 'Discount',
	'configuration.metadata.fields.code.enum.ASSET_EXTRA_FITTER': 'Fitter',
	'configuration.metadata.fields.code.enum.ASSET_EXTRA_RRP': 'RRP',
	'configuration.metadata.fields.code.enum.ASSET_FIRST_REGISTRATION_DATE_AUTOMOTIVE': 'First Registration Date',
	'configuration.metadata.fields.code.enum.ASSET_FIRST_REGISTRATION_DATE_NON_AUTOMOTIVE': 'First Registration Date',
	'configuration.metadata.fields.code.enum.ASSET_INDUSTRY_MODEL_CODE': 'Model Code (Industry)',
	'configuration.metadata.fields.code.enum.ASSET_MAKE.NAME': 'Make',
	'configuration.metadata.fields.code.enum.ASSET_MODEL_CODE': 'Model Code',
	'configuration.metadata.fields.code.enum.ASSET_MODEL_FAMILY.NAME': 'Model',
	'configuration.metadata.fields.code.enum.ASSET_MODEL_MANUFACTURE_YEAR': 'Model Manufacture Year',
	'configuration.metadata.fields.code.enum.ASSET_MODEL_YEAR': 'Model Year',
	'configuration.metadata.fields.code.enum.ASSET_NUMBER_PREVIOUS_OWNERS': 'Number of Previous Owners',
	'configuration.metadata.fields.code.enum.ASSET_ODOMETER_AUTOMOTIVE': 'Odometer',
	'configuration.metadata.fields.code.enum.ASSET_ODOMETER_NON_AUTOMOTIVE': 'Odometer',
	'configuration.metadata.fields.code.enum.ASSET_REGISTRATION_AUTOMOTIVE': 'Registration',
	'configuration.metadata.fields.code.enum.ASSET_REGISTRATION_EXPIRY_DATE_AUTOMOTIVE': 'Registration Expiry Date',
	'configuration.metadata.fields.code.enum.ASSET_REGISTRATION_EXPIRY_DATE_NON_AUTOMOTIVE': 'Registration Expiry Date',
	'configuration.metadata.fields.code.enum.ASSET_REGISTRATION_NON_AUTOMOTIVE': 'Registration',
	'configuration.metadata.fields.code.enum.ASSET_RRP': 'RRP',
	'configuration.metadata.fields.code.enum.ASSET_RRP_DEFAULT': 'RRP (Default)',
	'configuration.metadata.fields.code.enum.ASSET_RRP_DEFAULT_VAT': 'RRP (Default) VAT',
	'configuration.metadata.fields.code.enum.ASSET_RRP_MANUAL': 'RRP (Manual)',
	'configuration.metadata.fields.code.enum.ASSET_RRP_MANUAL_VALIDATED': 'RRP (Manual) Validated',
	'configuration.metadata.fields.code.enum.ASSET_RRP_MANUAL_VAT': 'RRP (Manual) VAT',
	'configuration.metadata.fields.code.enum.ASSET_RRP_TAX': 'RRP Tax',
	'configuration.metadata.fields.code.enum.ASSET_RRP_TOTAL': 'RRP (Total)',
	'configuration.metadata.fields.code.enum.ASSET_RRP_TOTAL_VAT': 'RRP (Total) VAT',
	'configuration.metadata.fields.code.enum.ASSET_RRP_VAT': 'RRP VAT',
	'configuration.metadata.fields.code.enum.ASSET_RV_AMOUNT': 'Residual Value',
	'configuration.metadata.fields.code.enum.ASSET_RV_AMOUNT_NOMINAL': 'Residual Value (Nominal)',
	'configuration.metadata.fields.code.enum.ASSET_RV_PERCENTAGE': 'Residual Value %',
	'configuration.metadata.fields.code.enum.ASSET_RV_PERCENTAGE_DEFAULT': 'Residual Value (Default) %',
	'configuration.metadata.fields.code.enum.ASSET_RV_PERCENTAGE_NOMINAL': 'Residual Value (Nominal) %',
	'configuration.metadata.fields.code.enum.ASSET_SALES_PRICE_INCL_TAX': 'Sales Price (incl. Tax)',
	'configuration.metadata.fields.code.enum.ASSET_SALE_PRICE_VAT': 'Sales Price (incl. VAT)',
	'configuration.metadata.fields.code.enum.ASSET_SECURED_PARTY': 'Secured Party',
	'configuration.metadata.fields.code.enum.ASSET_SERIAL_NUMBER_EQUIPMENT': 'Serial Number',
	'configuration.metadata.fields.code.enum.ASSET_SUBMODEL': 'Sub Model',
	'configuration.metadata.fields.code.enum.ASSET_TOTAL_DISCOUNT': 'Total Discount',
	'configuration.metadata.fields.code.enum.ASSET_TOTAL_DISCOUNT_PERCENTAGE': 'Total Discount Percentage',
	'configuration.metadata.fields.code.enum.ASSET_TOTAL_DISCOUNT_VAT': 'Total Discount VAT',
	'configuration.metadata.fields.code.enum.ASSET_TOTAL_EXTRAS_PRICE_INCL_VAT': 'Total Extras Price (incl. VAT)',
	'configuration.metadata.fields.code.enum.ASSET_TOTAL_SALES_PRICE_EXCL_VAT': 'Total Sales Price (excl. VAT)',
	'configuration.metadata.fields.code.enum.ASSET_TOTAL_SALES_PRICE_INCL_TAX': 'Total Sales Price (incl. Tax)',
	'configuration.metadata.fields.code.enum.ASSET_TOTAL_SALES_PRICE_INCL_VAT': 'Total Sales Price (incl. VAT)',
	'configuration.metadata.fields.code.enum.ASSET_EXCESS_UTILISATION_FEE_AUTOMOTIVE': 'Excess Utilisation Fee',
	'configuration.metadata.fields.code.enum.ASSET_TOTAL_USAGE_ALLOWANCE_AUTOMOTIVE': 'Usage Allowance (Total)',
	'configuration.metadata.fields.code.enum.ASSET_TOTAL_TAX': 'Total Tax',
	'configuration.metadata.fields.code.enum.ASSET_VALUATION': 'Valuation',
	'configuration.metadata.fields.code.enum.ASSET_VIN_AUTOMOTIVE': 'VIN',
	'configuration.metadata.fields.code.enum.ASSET_VIN_NON_AUTOMOTIVE': 'VIN',
	'configuration.metadata.fields.code.enum.ASSET_WEIGHT_AUTOMOTIVE': 'Weight',
	'configuration.metadata.fields.code.enum.ASSET_WEIGHT_NON_AUTOMOTIVE': 'Weight',
	'configuration.metadata.fields.code.enum.ASSET_YEAR_MANUFACTURE': 'Year of Manufacture',
	'configuration.metadata.fields.code.enum.APPLICATION_ID': 'Application ID',
	'configuration.metadata.fields.code.enum.TOTAL_SALES_PRICE_EXCL_VAT': 'Total Sales Price (excl. VAT)',
	'configuration.metadata.fields.code.enum.TOTAL_SALES_PRICE_INCL_TAX': 'Total Sales Price (incl. Tax)',
	'configuration.metadata.fields.code.enum.TOTAL_SALES_PRICE_INCL_VAT': 'Total Sales Price (incl. VAT)',
	'configuration.metadata.fields.code.enum.TERM': 'Contract Duration',
	'configuration.metadata.fields.code.enum.FIRST_PAYMENT_DATE': 'First Due Date',
	'configuration.metadata.fields.code.enum.DEPOSIT_EXCL_VAT': 'Total Deposit (excl. VAT)',
	'configuration.metadata.fields.code.enum.DEPOSIT_INCL_VAT': 'Total Deposit (incl. VAT)',
	'configuration.metadata.fields.code.enum.AMOUNT_FINANCED': 'Loan Amount',
	'configuration.metadata.fields.code.enum.FIRST_FINANCED_PAYMENT_EXCL_VAT': 'First Financed Payment (excl. VAT)',
	'configuration.metadata.fields.code.enum.FIRST_FINANCED_PAYMENT_INCL_VAT': 'First Financed Payment (incl. VAT)',
	'configuration.metadata.fields.code.enum.NON_FINANCED_REGULAR_PAYMENT_EXCL_VAT': 'Non-financed Payment (excl. VAT)',
	'configuration.metadata.fields.code.enum.NON_FINANCED_REGULAR_PAYMENT_INCL_VAT': 'Non-financed Payment (incl. VAT)',
	'configuration.metadata.fields.code.enum.UPFRONT_PAYMENT': 'Total Upfront Fee Amount',
	'configuration.metadata.fields.code.enum.TOTAL_PAYMENT_EXCL_TAX': 'Total Payment (excl. Tax)',
	'configuration.metadata.fields.code.enum.TOTAL_PAYMENT_INCL_TAX': 'Total Payment (incl. Tax)',
	'configuration.metadata.fields.code.enum.FIRST_FINANCED_PAYMENT_VAT': 'First Financed Payment VAT',
	'configuration.metadata.fields.code.enum.NON_FINANCED_REGULAR_PAYMENT_VAT': 'Non-financed Payment VAT',
	'configuration.metadata.fields.code.enum.ASSET_ROLE.AssetRole.FINANCED': 'Financed',
	'configuration.metadata.fields.code.enum.ASSET_ROLE.AssetRole.NON_FINANCED': 'Non-financed',
	'configuration.metadata.fields.code.enum.ASSET_ROLE.AssetRole.COLLATERAL': 'Collateral',
	'configuration.metadata.fields.code.enum.RESIDUAL_VALUE': 'Residual Value/Balloon',
	'configuration.metadata.fields.code.enum.ASSET_TYPE.NEW': 'New',
	'configuration.metadata.fields.code.enum.ASSET_TYPE.USED': 'Used',
	'configuration.metadata.fields.code.enum.ASSET_TYPE.DEMO': 'Demo',
	'configuration.metadata.fields.code.enum.ASSET_BODY_STYLE_AUTOMOTIVE': 'Body Style',
	'configuration.metadata.fields.code.enum.ASSET_TRANSMISSION_AUTOMOTIVE': 'Transmission',
	'configuration.metadata.fields.code.enum.ASSET_ORIGIN': 'Origin',
	'configuration.metadata.fields.code.enum.ASSET_STOCK_NO': 'Stock No',
	'configuration.metadata.fields.code.enum.ASSET_SALES_PERSON': 'Sales Person',
	'configuration.metadata.fields.code.enum.ASSET_FUEL_TYPE': 'Fuel Type',

	// comments section
	'contract.activation.page.comments.section.label': 'Comments',
	'contract.comment.version.label': 'CAM Version',
	'contract.comment.createdAt.label': 'Date/Time',
	'contract.comment.type.label': 'Type',
	'contract.comment.type.INTERNAL': 'Internal Comment',
	'contract.comment.type.OUTGOING': 'Outgoing Comment',
	'contract.comment.type.ACTIVATION': 'Activation Comment',
	'contract.comment.externalSubmitter.label': 'By',
	'contract.comment.label': 'Comment',
	'contract.comment.externalVersionNumber.label': 'External Version',
	'contract.comment.type.internal.label': 'Internal',
	// system
	'nav.menu.configuration.system.system': 'System',
	'configuration.system.displayName': 'System Configuration',
	'system.configuration.button.save.action': 'Save Configuration',
	'system.configuration.button.cancel.action': 'Cancel',
	'system.configuration.edit.title': 'Edit System Configuration',
	'system.configuration.view.title': 'View System Configuration',
	'system.configuration.title': 'System Configuration',
	'system.configuration.parameter.headerTitle': 'Header Title',
	'system.configuration.parameter.headerLogo': 'Header Logo',
	'system.configuration.parameter.headerLogo.btn.remove': 'Delete Header Logo',
	'system.configuration.parameter.headerBackgroundColour': 'Header Background Colour',
	'system.configuration.parameter.headerTextColour': 'Header Text Colour',
	'system.configuration.parameter.favIcon': 'Favicon',
	'system.configuration.parameter.favIcon.btn.remove': 'Delete Favicon',
	'system.configuration.parameter.storagePlugin': 'Document Storage Method',
	'system.configuration.upload.file.size.name.label': 'Max. Upload File Size (MB)',
	'system.configuration.general.title': 'General',
	'system.configuration.branding.title': 'Branding',
	'system.configuration.fields.title': 'Fields',
	'system.configuration.tableContent.title': 'Table Content',
	'system.configuration.table.column.name': 'Name',
	'system.configuration.table.column.code': 'Code',
	'system.configuration.table.column.version.date': 'Version Date',
	'system.configuration.table.column.value': 'System Configuration',
	'configuration.system.configuration.update.success.message': 'System configuration was successfully updated!',
	// generic decision table
	'configuration.genericDecisionTable.displayName': 'Decision Table',
	'configuration.system.genericDecisionLabel.list.label': 'Decision Table Configuration',
	'configuration.system.genericDecisionLabel.list.column.effectiveFrom': 'Effective From',
	'configuration.system.genericDecisionLabel.list.column.active': 'Active',
	'configuration.system.genericDecisionLabel.button.create': 'Create Decision Table',
	'configuration.system.genericDecisionTable.create.label': 'Create Decision Table',
	'configuration.system.genericDecisionTable.update.label': 'Edit Decision Table',
	'configuration.system.genericDecisionTable.clone.label': 'Clone Decision Table',
	'configuration.system.genericDecisionTable.view.label': 'View Decision Table',
	'configuration.system.genericDecisionTable.button.create': 'Save Decision Table',
	'configuration.system.genericDecisionTable.button.update': 'Save Decision Table',
	'configuration.system.genericDecisionTable.button.clone': 'Save Decision Table',
	'configuration.system.genericDecisionTable.section.general': 'General',
	'configuration.system.genericDecisionTable.parameter.name': 'Name',
	'configuration.system.genericDecisionTable.parameter.code': 'Code',
	'configuration.system.genericDecisionTable.parameter.active': 'Active',
	'configuration.system.genericDecisionTable.section.entries': 'Variants',
	'configuration.system.genericDecisionTable.parameter.required.name': 'Name is mandatory',
	'configuration.system.genericDecisionTable.parameter.required.code': 'Code is mandatory',

	'configuration.system.genericDecisionTable.variant.button.create': 'Create Variant',
	'configuration.system.genericDecisionTable.variant.button.update': 'Save Variant',
	'configuration.system.genericDecisionTable.variant.button.clone': 'Clone Variant',
	'configuration.system.genericDecisionTable.variant.create.label': 'Create Decision Table Variant',
	'configuration.system.genericDecisionTable.variant.update.label': 'Edit Decision Table Variant',
	'configuration.system.genericDecisionTable.variant.clone.label': 'Clone Decision Table Variant',
	'configuration.system.genericDecisionTable.variant.view.label': 'View Decision Table Variant',
	'configuration.system.genericDecisionTable.variant.modal.field.context': 'Context',
	'configuration.system.genericDecisionTable.variant.test.result.contractActivation': 'Contract Activation',

	'configuration.system.template.entity.save.message.success': 'Successfully saved %s.',
	'configuration.system.template.variant.button.save': 'Confirm Variant Change',
	'configuration.system.template.variant.modal.header': 'Select Contexts',
	'configuration.system.template.variant.modal.button.testVariant': 'Test Variant',
	'configuration.system.template.list.button.edit': 'Edit Configuration',
	'configuration.system.template.list.button.clone': 'Clone',
	'configuration.system.template.page.button.edit': 'Edit Variant',
	'configuration.system.template.page.button.clone': 'Clone Variant',
	'configuration.system.template.page.button.view': 'View Variant',
	'configuration.system.template.value.button.edit': 'Edit Value',
	'configuration.system.template.value.button.clone': 'Clone Value',
	'configuration.system.template.value.button.remove': 'Remove Value',
	'configuration.system.template.value.button.view': 'View Value',
	'configuration.system.template.page.variant.context.select': 'Context',
	'configuration.system.template.page.variant.test.result.view': 'View contract activation',
	'configuration.system.template.page.variant.test.results': 'Test Results',
	'configuration.system.template.page.variant.test.finishTitle': 'Test Execution',
	'configuration.system.template.page.variant.test.finishMessage': 'Finished test execution.',
	'configuration.system.template.page.variant.test.result.borrower': 'Borrower',
	'configuration.system.template.page.variant.test.result.customer': 'Customer',
	'configuration.system.template.page.variant.test.result.valueCode': 'Value',

	// role
	'configuration.role.displayName': 'Role',
	'configuration.system.role.parameter.name': 'Role Name',
	'configuration.system.role.parameter.required.name': 'Role Name is mandatory',
	'configuration.system.role.parameter.code': 'Code',
	'configuration.system.role.parameter.required.code': 'Code is mandatory',
	'configuration.system.role.table.parameter.name': 'Name',
	'configuration.system.role.parameter.active': 'Active',
	'configuration.system.role.parameter.type': 'Type',
	'configuration.system.role.section.general': 'General',
	'configuration.system.role.section.permissions': 'Permissions',
	'configuration.system.role.button.update': 'Save %s Role',
	'configuration.system.role.button.create': 'Create %s Role',
	'configuration.system.role.button.createSystem': 'Create System Role',
	'configuration.system.role.button.createActivation': 'Create Activation Role',
	'configuration.system.role.update.label': 'Edit %s Role',
	'configuration.system.role.create.label': 'Create Role',
	'configuration.system.role.clone.label': 'Clone %s Role',
	'configuration.system.role.view.label': 'View %s Role',
	'configuration.system.role.list.label': 'Role Configuration',
	'configuration.system.role.parameter.type.enum.CONTRACT_ACTIVATION': 'Activation',
	'configuration.system.role.parameter.type.enum.SYSTEM': 'System',
	'configuration.system.role.filter.all': 'All Roles',
	'configuration.system.role.filter.activation': 'Activation Roles',
	'configuration.system.role.filter.system': 'System Roles',

	// role assignments
	'configuration.system.role.assignment.parameter.role': 'Role',
	'configuration.system.role.assignment.parameter.role.required': 'Role is mandatory.',
	'configuration.system.role.assignment.parameter.contractAccessLevel': 'Contract Access Level',
	'configuration.system.role.assignment.parameter.contractAccessLevel.required': 'Contract Access Level is mandatory.',
	'configuration.system.role.assignment.parameter.roleType': 'Role Type',
	'configuration.system.role.assignment.parameter.userGroup': 'User Group',
	'configuration.system.role.assignment.parameter.userGroup.required': 'User Group is mandatory.',
	'configuration.system.role.assignment.parameter.active': 'Active',
	'configuration.system.role.assignment.parameter.type': 'Type',
	'configuration.system.role.assignment.section.general': 'General',
	'configuration.system.role.assignment.button.update': 'Save Assignment',
	'configuration.system.role.assignment.button.create': 'Create %s Role Assignment',
	'configuration.system.role.assignment.button.createSystem': 'Create System Role Assignment',
	'configuration.system.role.assignment.button.createActivation': 'Create Activation Role Assignment',
	'configuration.system.role.assignment.update.label': 'Edit %s Role Assignment',
	'configuration.system.role.assignment.create.label': 'Create Role',
	'configuration.system.role.assignment.view.label': 'View %s Role',
	'configuration.system.role.assignment.list.label': 'Role Assignment',
	'configuration.system.role.assignment.filter.all': 'All Roles',
	'configuration.system.role.assignment.parameter.type.enum.CONTRACT_ACTIVATION': 'Activation',
	'configuration.system.role.assignment.parameter.type.enum.SYSTEM': 'System',
	'configuration.system.role.assignment.filter.activation': 'Activation Roles',
	'configuration.system.role.assignment.filter.system': 'System Roles',
	'configuration.role.assignment.displayName': 'Role Assignment',

	// permissions
	'configuration.permissions.group.enum.ASSESSMENT': 'Contract activation',
	'configuration.permissions.group.enum.INTEGRATION': 'Integration',
	'configuration.permissions.group.enum.CHECKLIST': 'Checklist',
	'configuration.permissions.group.enum.CONTRACT_ACTIVATION': 'Contract activation',
	'configuration.permissions.group.enum.SYSTEM': 'System',
	'configuration.permissions.group.enum.USERS': 'User',
	'configuration.permissions.group.enum.VIEW_CONFIGURATION': 'Activation (view) configuration',
	'configuration.permissions.group.enum.WORKFLOW_CONFIGURATION': 'Workflow',
	'configuration.permissions.group.enum.MISCELLANEOUS': 'Miscellaneous configuration',
	'configuration.permissions.enum.ACTIVATION_CUSTOM_FIELD_CONFIGURATION': 'Custom Field Configuration',
	'configuration.permissions.enum.VIEW_CONTRACT_ACTIVATION': 'View contract activation',
	'configuration.permissions.enum.COMMENT': 'Add comment',
	'configuration.permissions.enum.EDIT_CONTRACT_ACTIVATION': 'Edit contract activation',
	'configuration.permissions.enum.CREATE_CONTRACT_ACTIVATION': 'Create contract activation',
	'configuration.permissions.enum.CANCEL_CONTRACT_ACTIVATION': 'Cancel contract activation',
	'configuration.permissions.enum.CHECKLIST_CONFIGURATION': 'Checklist configuration',
	'configuration.permissions.enum.CONTRACT_ACTIVATION_PROCESS_CONFIGURATION': 'Process configuration',
	'configuration.permissions.enum.SYSTEM_CONFIGURATION': 'System configuration',
	'configuration.permissions.enum.GENERIC_DECISION_TABLE_CONFIGURATION': 'Generic decision table configuration',
	'configuration.permissions.enum.CONDITION_TYPE_CONFIGURATION': 'Condition Type configuration',
	'configuration.permissions.enum.ROLE_CONFIGURATION': 'Role configuration',
	'configuration.permissions.enum.ROLE_ASSIGNMENT_CONFIGURATION': 'Role assignment configuration',
	'configuration.permissions.enum.USER_CONFIGURATION': 'User configuration',
	'configuration.permissions.enum.REFERENCE_DATA_CONFIGURATION': 'Reference data configuration',
	'configuration.permissions.enum.COMPLIANCE_CONFIGURATION': 'Compliance configuration',
	'configuration.permissions.enum.COMPLIANCE_WORKFLOW_REVIEWER': 'Compliance Workflow Reviewer',
	'configuration.permissions.enum.CONTRACT_ACTIVATION_UNLOCK': 'Activation Unlock',
	'configuration.permissions.enum.CONTRACT_ACTIVATION_VIEW_CONFIGURATION': 'View configuration',
	'configuration.permissions.enum.CONTRACT_ACTIVATION_POLICY_RULE_CONFIGURATION': 'Policy rule configuration',
	'configuration.permissions.enum.CONTRACT_ACCESS_LEVEL_CONFIGURATION': 'Access level configuration',
	'configuration.permissions.enum.TAG_CONFIGURATION': 'Tag configuration',
	// configuration workflow
	'configuration.workflow.page.title': 'Configuration Workflow',
	'configuration.workflow.page.table.column.createdDate': 'Date/Time',
	'configuration.workflow.page.table.column.createdBy': 'Changed By',
	'configuration.workflow.page.table.column.complianceSection': 'Configuration',
	'configuration.workflow.page.table.column.reference': 'Reference',
	'configuration.workflow.page.table.column.decisionBy': 'Reviewed By',
	'configuration.workflow.page.table.column.approvalStatus': 'Status',
	'configuration.workflow.page.view.button': 'View Configuration Version',
	'configuration.workflow.page.edit.button': 'Edit Configuration Version',
	'configuration.workflow.page.dialog.edit.title': 'Edit %s',
	'configuration.workflow.page.dialog.view.title': 'View %s',
	'configuration.workflow.page.approve.button': 'Approve',
	'configuration.workflow.page.decline.button': 'Decline',
	'configuration.workflow.enum.approvalStatus.PENDING': 'Pending',
	'configuration.workflow.enum.approvalStatus.APPROVED': 'Approved',
	'configuration.workflow.enum.approvalStatus.DECLINED': 'Declined',
	'configuration.workflow.enum.complianceSection.APPLICATION_USER': 'Application User',
	'configuration.workflow.enum.complianceSection.CHECKLIST_CONFIGURATION': 'Checklist Configuration',
	'configuration.workflow.enum.complianceSection.COMPLIANCE_CONFIGURATION': 'Compliance Configuration',
	'configuration.workflow.enum.complianceSection.ROLE_CONFIGURATION': 'Role Configuration',
	'configuration.workflow.enum.complianceSection.CONDITION_TYPE_CONFIGURATION': 'Condition Type configuration',
	'configuration.workflow.enum.complianceSection.ROLE_ASSIGNMENT_CONFIGURATION': 'Role Assignment Configuration',
	'configuration.workflow.enum.complianceSection.USER_GROUP_CONFIGURATION': 'User Group Configuration',
	'configuration.workflow.enum.complianceSection.SECRET_CONFIGURATION': 'Secret Configuration',
	'configuration.workflow.enum.complianceSection.SYSTEM_CONFIGURATION': 'System Configuration',
	'configuration.workflow.enum.complianceSection.ACCESS_LEVEL_CONFIGURATION': 'Access Level Configuration',
	'configuration.workflow.enum.complianceSection.ACCESS_LEVEL_SCHEDULE_CONFIGURATION': 'Access Level Schedule Configuration',
	'configuration.workflow.enum.complianceSection.STATUS_CONFIGURATION': 'Status Configuration',
	'configuration.workflow.enum.complianceSection.CONTRACT_ACTIVATION_VIEW_CONFIGURATION': 'Contract Activation View configuration',
	'configuration.workflow.enum.complianceSection.CUSTOMER_SECTION_VIEW_CONFIGURATION': 'Customer Section View configuration',
	'configuration.workflow.enum.complianceSection.ASSET_SECTION_VIEW_CONFIGURATION': 'Asset Section View configuration',
	'configuration.workflow.enum.complianceSection.ACTIONS_LAYOUT_VIEW_CONFIGURATION': 'Button Layout Configuration',
	'configuration.workflow.enum.complianceSection.APPLICATION_DETAILS_SECTION_VIEW_CONFIGURATION':
		'Application Details Section View configuration',
	'configuration.workflow.enum.complianceSection.ACTIONS_LAYOUT_VIEW': 'Button Layout View configuration',
	'configuration.workflow.enum.complianceSection.ACTION_CONFIGURATION': 'Action Configuration',
	'configuration.workflow.enum.complianceSection.COMPUTED_FIELD_CONFIGURATION': 'Computed Field Configuration',
	'configuration.workflow.enum.complianceSection.GENERIC_DECISION_TABLE_CONFIGURATION': 'Generic Decision Table Configuration',
	'configuration.workflow.enum.complianceSection.TAG_CONFIGURATION': 'Tag Configuration',
	'configuration.workflow.enum.complianceSection.CHECKLIST_SCHEDULE_CONFIGURATION': 'Checklist Schedule Configuration',
	'configuration.workflow.enum.complianceSection.USER_CONFIGURATION': 'User Configuration',

	// Tag Configuration
	'nav.menu.configuration.system.tag': 'Tag',
	'configuration.tag.displayName': 'Tag',
	'configuration.system.tag.list.label': 'Tag Configuration',
	'configuration.system.tag.parameter.evaluationOrder': 'Evaluation Order',
	'configuration.system.tag.parameter.min.evaluationOrder': 'Evaluation Order is positive integer',
	'configuration.system.tag.parameter.pattern.evaluationOrder': 'Evaluation Order is a numeric field',
	'configuration.system.tag.parameter.required.evaluationOrder': 'Evaluation Order is mandatory',
	'configuration.system.tag.parameter.displayOrder': 'Display Order',
	'configuration.system.tag.parameter.required.displayOrder': 'Display Order is mandatory',
	'configuration.system.tag.parameter.pattern.displayOrder': 'Display Order must be a number',
	'configuration.system.tag.parameter.name': 'Name',
	'configuration.system.tag.parameter.required.name': 'Name is mandatory',
	'configuration.system.tag.parameter.code': 'Code',
	'configuration.system.tag.parameter.required.code': 'Code is mandatory',
	'configuration.system.tag.parameter.activities': 'Events triggering Evaluation',
	'configuration.system.tag.parameter.required.activities': 'Events triggering Evaluation is mandatory (at least one selection)',
	'configuration.system.tag.parameter.visibilityMode': 'Visibility',
	'configuration.system.tag.parameter.visibilityMode.DO_NOT_SHOW': 'Do Not Show',
	'configuration.system.tag.parameter.visibilityMode.CONTRACT_ACTIVATION': 'Contract Activation',
	'configuration.system.tag.update.label': 'Edit Tag',
	'configuration.system.tag.button.create': 'Create Tag',
	'configuration.system.tag.create.label': 'Create Tag',
	'configuration.system.tag.clone.label': 'Clone Tag',
	'configuration.system.tag.view.label': 'View Tag',
	'configuration.system.tag.button.update': 'Save Tag',
	'configuration.system.tag.button.clone': 'Save Tag',
	'configuration.system.tag.section.variants': 'Variants',
	'configuration.system.tag.section.values.button.create': 'Add Value',
	'configuration.system.tag.section.values.dialog.title': 'Tag Value',
	'configuration.system.tag.section.values.dialog.field.name': 'Tag Value',
	'configuration.system.tag.section.values.dialog.field.required.name': 'Tag Value is mandatory',
	'configuration.system.tag.section.values.dialog.field.code': 'Value Code',
	'configuration.system.tag.section.values.dialog.field.required.code': 'Value Code is mandatory',
	'configuration.system.tag.section.values.name': 'Value Name',
	'configuration.system.tag.section.values.code': 'Value Code',
	'configuration.system.tag.section.values.dialog.field.colourMode': 'Colour Mode',
	'configuration.system.tag.section.values.dialog.field.colourMode.enum.NO_COLOUR': 'No Colour',
	'configuration.system.tag.section.values.dialog.field.colourMode.enum.SPECIFIC_COLOUR': 'Specific Colour',
	'configuration.system.tag.section.values.dialog.field.colour': 'Colour',
	'configuration.system.tag.section.values.dialog.field.required.colourMode': 'Colour is mandatory',
	'configuration.system.tag.variant.create.label': 'Create Tag Variant',
	'configuration.system.tag.variant.update.label': 'Edit Tag Variant',
	'configuration.system.tag.variant.clone.label': 'Clone Tag Variant',
	'configuration.system.tag.variant.view.label': 'View Tag Variant',
	'configuration.system.tag.section.values.error.duplicateCode': 'Tag Code already exists',
	'configuration.system.tag.section.values.error.duplicateName': 'Tag Value already exists',

	// Language configuration
	'configuration.localisation.language.parameter.name': 'Name',
	'configuration.localisation.language.parameter.locale': 'Locale',
	'configuration.localisation.language.parameter.dateFormat': 'Date Format',
	'configuration.localisation.language.parameter.active': 'Active',
	'configuration.localisation.language.parameter.defaultLanguage': 'Default Language',
	'configuration.language.page.create.title': 'Create Language',
	'configuration.language.page.update.title': 'Edit Language',
	'configuration.language.action.save': 'Save Language',
	'configuration.language.action.create': 'Create Language',
	'configuration.localisation.language.list.label': 'Language Configuration',
	'configuration.language.displayName': 'language',
	'configuration.workflow.enum.complianceSection.LANGUAGE_CONFIGURATION': 'Language Configuration',

	// Contract Activation View Configuration
	'configuration.contract.activation.view.displayName': 'Contract Activation View Configuration',
	'configuration.contract.activation.view.name': 'Contract Activation View',
	'configuration.contract.activation.view.list.parameter.name': 'Name',
	'configuration.contract.activation.view.view.label': 'View Contract Activation View',
	'configuration.contract.activation.view.update.label': 'Edit Contract Activation View',
	'configuration.contract.activation.view.button.save': 'Save Contract Activation View',
	'configuration.contract.activation.view.section.type.enum.STATUS_HISTORY': 'Status History',
	'configuration.contract.activation.view.section.type.enum.COMMENTS': 'Comments',
	'configuration.contract.activation.view.section.type.enum.DOCUMENTS': 'Documents',
	'configuration.contract.activation.view.section.type.enum.ASSET_DETAILS': 'Assets',
	'configuration.contract.activation.view.section.type.enum.APPLICATION_DETAILS': 'Application Details',
	'configuration.contract.activation.view.section.type.enum.ACTIONS_LAYOUT_FOOTER': 'Footer Buttons',
	'configuration.contract.activation.view.section.type.enum.CUSTOMERS': 'Customers',
	'configuration.contract.activation.view.section.type.enum.CHECKLIST': 'Checklist',
	'configuration.contract.activation.view.section.type.enum.SETTLEMENT_CONDITIONS': 'Settlement Conditions',
	'configuration.contract.activation.view.section.visibility.enum.EXPANDED': 'Expanded',
	'configuration.contract.activation.view.section.visibility.enum.COLLAPSED': 'Collapsed',
	'configuration.contract.activation.view.section.visibility.enum.HIDDEN': 'Hidden',
	'configuration.contract.activation.view.section.visibility.enum.HIDDEN_NO_DATA': 'Hidden when %s',
	'configuration.contract.activation.view.section.visibility.enum.COLLAPSED_NO_DATA': 'Collapsed when %s',
	'configuration.contract.activation.view.section.noData.enum.DOCUMENTS': 'no documents',
	'configuration.contract.activation.view.section.noData.enum.COMMENTS': 'no comments',
	'configuration.contract.activation.view.section.noData.enum.CHECKLIST': 'no checkpoints',
	'configuration.contract.activation.view.section.noData.enum.SETTLEMENT_CONDITIONS': 'no conditions',
	'configuration.contract.activation.view.list.parameter.type': 'Section',
	'configuration.contract.activation.view.list.parameter.visibility': 'Visibility',

	'configuration.system.contract-activation.activity.COMMENTED_INCOMING': 'Commented (Incoming)',
	'configuration.system.contract-activation.activity.COMMENTED_INTERNAL': 'Commented (Internal)',
	'configuration.system.contract-activation.activity.COMMENTED_OUTGOING': 'Commented (Outgoing)',
	'configuration.system.contract-activation.activity.CONTRACT_ACTIVATION_CREATED': 'Contract Activation Created',
	'configuration.system.contract-activation.activity.CONTRACT_ACTIVATION_UNLOCKED': 'Contract Activation Unlocked',
	'configuration.system.contract-activation.activity.CONTRACT_ACTIVATION_VERSION_CREATED': 'Contract Activation Version Created',
	'configuration.system.contract-activation.activity.DOCUMENT_UPLOADED': 'Document(s) Uploaded',
	'configuration.system.contract-activation.activity.EXTERNAL_CANCELLATION': 'Cancel',
	'configuration.system.contract-activation.activity.STATUS_CHANGED': 'Status Changed',
	'configuration.system.contract-activation.activity.USER_ASSIGNED': 'Assigned',
	'configuration.system.contract-activation.activity.USER_UNASSIGNED': 'Unassigned',

	// customer section view
	'configuration.customer.section.view.displayName': 'Customer Section View Configuration',
	'configuration.customer.section.view.list.parameter.name': 'Name',
	'configuration.customer.section.view.list.parameter.businessPartnerType': 'Business Partner Type',
	'configuration.customer.section.view.list.parameter.applicantTypes': 'Applicant Type',
	'configuration.customer.section.view.customerSectionApplicantType.enum.BORROWER': 'Borrower',
	'configuration.customer.section.view.customerSectionApplicantType.enum.CO_BORROWER': 'Co-Borrower',
	'configuration.customer.section.view.customerSectionApplicantType.enum.GUARANTOR': 'Guarantor',
	'configuration.customer.section.view.customerSectionApplicantType.enum.AFFILIATE': 'Affiliate',
	'configuration.customer.section.view.create.label': 'Create %s Customer Section View',
	'configuration.customer.section.view.update.label': 'Edit %s Customer Section View',
	'configuration.customer.section.view.clone.label': 'Clone %s Customer Section View',
	'configuration.customer.section.view.view.label': 'View %s Customer Section View',
	'configuration.customer.section.view.button.createIndividual': 'Add Individual View',
	'configuration.customer.section.view.button.createOrganisation': 'Add Organisation View',
	'configuration.customer.section.view.button.save': 'Save View',
	'configuration.customer.section.view.groups.label': 'Groups',
	'configuration.customer.section.view.groups.page.parameter.title': 'Group Title',
	'configuration.customer.section.view.groups.page.create': 'Create Customer Section Group',
	'configuration.customer.section.view.groups.page.update': 'Edit Customer Section Group',
	'configuration.customer.section.view.groups.page.view': 'View Customer Section Group',
	'configuration.customer.section.view.button.addGroup': 'Add Group',
	'configuration.customer.section.view.groups.page.button.addField': 'Add Field(s)',
	'configuration.customer.section.view.groups.page.button.save': 'Confirm Group Change',
	'configuration.customer.section.view.group.edit': 'Edit Group',
	'configuration.customer.section.view.group.view': 'View Group',
	'configuration.customer.section.view.group.remove': 'Remove Group',
	'configuration.customer.section.view.groups.page.addFields.modal.title': 'Add Customer Field(s)',
	'configuration.customer.section.view.request.groups.required': 'At least one group is required.',
	'configuration.customer.section.view.request.fields.required': 'At least one field is required.',

	// asset section view
	'configuration.asset.section.view.displayName': 'Assets Section View Configuration',
	'configuration.asset.section.view.update.label': 'Edit Asset Section View',
	'configuration.asset.section.view.view.label': 'View Asset Section View',
	'configuration.asset.section.view.button.save': 'Save View',
	'configuration.asset.section.view.section.layout': 'Layout',
	'configuration.asset.section.view.section.extra': 'Extra Table Columns',
	'configuration.asset.section.view.extra.parameter.column': 'Column',
	'configuration.asset.section.view.extra.parameter.width': 'Width',
	'configuration.asset.section.view.extra.addFields.button': 'Add Extra Column',
	'configuration.asset.section.view.fields.addFields.button': 'Add Asset Field(s)',
	'configuration.asset.section.view.groups.page.addFields.modal.title': 'Add Asset Field(s)',
	'configuration.asset.section.view.extra.page.addFields.modal.title': 'Add Extra Column(s)',

	// actions layout view
	'configuration.actions.layout.view.displayName': 'Button Layout Configuration',
	'configuration.actions.layout.view.update.label': 'Edit Button Layout',
	'configuration.actions.layout.view.view.label': 'View Button Layout',
	'configuration.actions.layout.view.button.save': 'Save Button Layout',
	'configuration.actions.layout.view.action.invalid.position': 'Actions can be moved across first row only.',
	'configuration.actions.layout.view.action.add.action': 'Add Button',

	// application details section view
	'configuration.application.details.section.view.displayName': 'Application Details Section View Configuration',
	'configuration.application.details.section.view.update.label': 'Edit Application Details Section View',
	'configuration.application.details.section.view.view.label': 'View Application Details Section View',
	'configuration.application.details.section.view.button.save': 'Save View',
	'configuration.application.details.section.view.section.layout': 'Layout',
	'configuration.application.details.section.view.fields.addFields.button': 'Add Field(s)',
	'configuration.application.details.section.view.groups.page.addFields.modal.title': 'Add Application Field(s)',

	// computed fields
	'configuration.computedField.displayName': 'Computed Field',
	'configuration.computedField.list.label': 'Computed Field Configuration',
	'configuration.computedField.page.create': 'Create Computed Field',
	'configuration.computedField.page.edit': 'Edit Computed Field',
	'configuration.computedField.page.view': 'View Computed Field',
	'configuration.computedField.page.selections': 'Field Selection',
	'configuration.computedField.parameter.name': 'Name',
	'configuration.computedField.parameter.code': 'Code',
	'configuration.computedField.parameter.computedFieldCategory': 'Category',
	'configuration.computedField.parameter.computedFieldType': 'Computed Field Type',
	'configuration.computedField.parameter.metadataFieldsRootName': 'List',
	'configuration.computedField.button.create': 'Create Computed Field',
	'configuration.computedField.button.save': 'Save Computed Field',
	'configuration.computedField.parameter.computedFieldType.enum.FIELD_GROUP': 'Field Group',
	'configuration.computedField.parameter.computedFieldType.enum.COMBINE_TWO_FIELDS': 'Combine 2 Fields',
	'configuration.computedField.metadataFieldsRoot.enum.EMPLOYMENT_DETAILS': 'Employment Details',
	'configuration.computedField.metadataFieldsRoot.enum.CONTACT_INFORMATION': 'Contact Information',
	'configuration.computedField.metadataFieldsRoot.enum.ADDRESS': 'Address',
	'configuration.computedField.filterBy': 'Filter By',
	'configuration.computedField.request.selections.required': 'At least one field selection is required.',
	'configuration.computedField.request.selections.combined2Fields': 'Only two fields can be combined. Please adjust your selection.',
	'configuration.computedField.request.selections.invalid':
		'Selected field(s) do not belong to the "%s" List. Please adjust your selection: %s',

	'configuration.computedField.form.list.ADDRESS.ADDRESS_STATUS': 'Address Status',
	'configuration.computedField.form.list.ADDRESS.ADDRESS_STATUS.options.CURRENT': 'Current',
	'configuration.computedField.form.list.ADDRESS.ADDRESS_STATUS.options.PREVIOUS': 'Previous',
	'configuration.computedField.form.list.ADDRESS.ADDRESS_TYPE': 'Address Type',
	'configuration.computedField.form.list.ADDRESS.ADDRESS_TYPE.options.PHYSICAL': 'Physical',
	'configuration.computedField.form.list.ADDRESS.ADDRESS_TYPE.options.POSTAL': 'Postal',
	'configuration.computedField.form.list.ADDRESS.ADDRESS_USAGE': 'Address Usage',
	'configuration.computedField.form.list.ADDRESS.ADDRESS_USAGE.options.HOME': 'Home',
	'configuration.computedField.form.list.ADDRESS.ADDRESS_USAGE.options.WORK': 'Work',
	'configuration.computedField.form.list.CONTACT_INFORMATION.CONTACT_CHANNEL': 'Contact Channel',
	'configuration.computedField.form.list.CONTACT_INFORMATION.CONTACT_CHANNEL.options.EMAIL': 'Email',
	'configuration.computedField.form.list.CONTACT_INFORMATION.CONTACT_CHANNEL.options.LANDLINE_PHONE': 'Landline Phone',
	'configuration.computedField.form.list.CONTACT_INFORMATION.CONTACT_CHANNEL.options.MOBILE_PHONE': 'Mobile Phone',
	'configuration.computedField.form.list.CONTACT_INFORMATION.CONTACT_TYPE': 'Contact Information Type',
	'configuration.computedField.form.list.CONTACT_INFORMATION.CONTACT_TYPE.options.PRIVATE': 'Private',
	'configuration.computedField.form.list.CONTACT_INFORMATION.CONTACT_TYPE.options.WORK': 'Work',
	'configuration.computedField.form.list.EMPLOYMENT.STATUS': 'Employment Details Type',
	'configuration.computedField.form.list.EMPLOYMENT.STATUS.options.CURRENT': 'Current',
	'configuration.computedField.form.list.EMPLOYMENT.STATUS.options.PREVIOUS': 'Previous',

	// Condition Type
	'configuration.condition.type.displayName': 'Approval Condition Type',
	'configuration.condition.type.notification.onChange.warning':
		'This condition type may have been used, please be careful when modifying it.',
	'configuration.condition.type.action.create': 'Create Condition Type',
	'configuration.condition.type.action.save': 'Save Condition Type',
	'configuration.condition.type.page.create.title': 'Create Condition Type Configuration',
	'configuration.condition.type.page.update.title': 'Edit Condition Type Configuration',
	'configuration.condition.type.page.view.title': 'View Condition Type Configuration',
	'configuration.system.condition.type.list.label': 'Condition Type Configuration',
	'configuration.system.condition.type.parameter.name': 'Name',
	'configuration.system.condition.type.parameter.code': 'Code',
	'configuration.system.condition.type.parameter.categories': 'Category',
	'configuration.system.condition.type.parameter.categories.enum.SETTLEMENT': 'Settlement',
	'configuration.system.condition.type.parameter.valueType': 'Value Type',
	'configuration.system.condition.type.parameter.valueType.enum.TEXT': 'Text',
	'configuration.system.condition.type.parameter.valueType.enum.PERCENTAGE': 'Percentage',
	'configuration.system.condition.type.parameter.valueType.enum.INTEGER': 'Integer',
	'configuration.system.condition.type.parameter.valueType.enum.CURRENCY_AMOUNT': 'Currency Amount',
	'configuration.system.condition.type.parameter.valueType.enum.DECIMAL': 'Decimal',

	'configuration.metadata.fields.bulk-add.modal.btn.confirm': 'Add Field(s)',
	'configuration.metadata.fields.bulk-add.modal.fields.name': 'Name',
	'configuration.metadata.fields.bulk-add.modal.fields.code': 'Code',

	// image field
	'configuration.template.image.cancelUploadButton': 'Cancel',
	'configuration.template.image.uploadFileButton': 'Upload File',
	'configuration.template.image.uploadDialogTitle': 'Attach File',
	'configuration.template.image.openUploadDialogButton': 'Attach File',
	'configuration.template.image.removeUploadedFileButton': 'Remove File',
	'configuration.template.image.invalidImageTypeMessage': 'File type is unsupported.',
	'configuration.template.image.invalidImageSizeMessage': 'File size is too large.',
	'configuration.template.image.invalidImageContentMessage': 'Invalid file content.',

	// colour-picker-field
	'configuration.template.colourPicker.closeButton': 'Close',
	'configuration.template.colourPicker.resetTitle': 'Reset to default colour',
	'configuration.template.colourPicker.selectTitle': 'Select colour',

	// language
	'configuration.system.language.parameter.name': 'Name',
	'configuration.system.language.parameter.locale': 'Locale',
	'configuration.system.language.parameter.dateFormat': 'Date Format',
	'configuration.system.language.parameter.dateTimeFormat': 'Date Time Format',
	'configuration.system.language.parameter.dateTimeFormat.invalid': 'Invalid Date Format, it must contain "dd", "MM" and "yyyy".\n',
	'configuration.system.language.parameter.defaultLanguage': 'Default Language',
};
const existingTranslationKeys = Object.keys(TRANSLATIONS_EN);

export const t = (key: string, ...args: (string | number | boolean)[]): string => {
	if (existingTranslationKeys.includes(key)) {
		const text = TRANSLATIONS_EN[key] as string;
		if (args.length > 0) {
			return args.reduce((result: string, arg) => result.replace('%s', String(arg)), text) as string;
		}
		return text;
	}
	return `???${key}???`;
};

export const tOrUndefined = (key: string, ...args: (string | number | boolean)[]): string => {
	if (existingTranslationKeys.includes(key)) {
		const text = TRANSLATIONS_EN[key] as string;
		if (args.length > 0) {
			return args.reduce((result: string, arg) => result.replace('%s', String(arg)), text) as string;
		}
		return text;
	}
};
