/*
 * Copyright 2021, Torque IT Solutions Ltd
 * www.torque-its.com
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

/**
 * DialogFooter component based on React Bootstrap Modal.Footer.
 */
export class DialogFooter extends Component {

    setRef = (el) => {
        this.footerRef = el;
        this.context.handleRef(el);
    };

    render() {
        return <Modal.Footer bsClass="modal-footer-v2" ref={this.setRef} {...this.props} />;
    }
}

DialogFooter.contextTypes = {
    firstElementFocus: PropTypes.bool,
    handleRef: PropTypes.func
};