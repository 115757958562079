import classNames from 'classnames';
import isEmpty from 'lodash/fp/isEmpty';
import PropTypes from 'prop-types';
import React, { Children, PureComponent } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationListStandalone, PaginationProvider, PaginationTotalStandalone } from '@torque-common-ui/table-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import SearchBar from './SearchBar';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/fp/isEqual';
import FontAwesomeIcon from '@torque-common-ui/fontawesome-icon';
import Glyphicon from '@torque-common-ui/glyphicon';
import { defaultOnRowClick, prepareProps, prepareState } from './helper/configuration';

export default class Table extends PureComponent {
    constructor(props) {
        super(props);
        const initialState = prepareState(this.props);

        this.state = {
            // the BootstrapTable component does not update the list of rows which need to be expanded by itself. We need to save this list in our component state and update it when the user clicks on expand.
            expandedRows: initialState.expandedRows,
            nonExpandableRows: initialState.nonExpandableRows,
            // the name of the key field does not need to be calculated on every rerender
            keyField: initialState.keyField,
            // the data provided for the table are adjusted (e.g. a new generated key is added) and need to be kept in the internal state
            tableData: initialState.tableData,
        };

        this.onTableChange = debounce(this.onTableChange, 250);
    }

    componentWillMount() {
        if (isEmpty(Children.toArray(this.props.children))) {
            throw new Error('The Table component requires at least one column to be provided as children');
        }
        const { registerTable, pageSize, defaultSortName, defaultSortOrder } = this.props;
        registerTable({
            sizePerPage: pageSize,
            sortField: defaultSortName,
            sortOrder: defaultSortOrder,
        });
    }

    componentDidUpdate(prevProps) {
        if ((!prevProps.tableData && this.props.tableData) ||
            (!isEqual(prevProps.tableData, this.props.tableData)) ||
            (prevProps.expanding !== this.props.expanding)) {
            this.setState(prepareState(this.props));
        }
    }

    onTableChange = (type, newState) => {
        const { setTableData } = this.props;
        const { page, sortField, sortOrder, searchText } = newState;
        if (type === 'search') {
            setTableData({ searchText, page: 1 });
        } else if (type === 'pagination') {
            setTableData({ page });
        } else if (type === 'sort') {
            setTableData({ sortField, sortOrder });
        }
    };

    renderSearch = (props) => {
        return (
            <div className="search-wrapper-v3">
                <Glyphicon icon="search" className="search-button-v3" />
                <SearchBar {...props} placeholder={this.props.searchPlaceHolderText} className="form-control form-control-v2" />
            </div>
        );
    }

    renderPagination = (props) => {
        return (
            <div className="pagination-wrapper-v3">
                <PaginationListStandalone {...props} dataSize={props.totalSize} />
                <PaginationTotalStandalone {...props} dataSize={props.totalSize} />
            </div>
        );
    }

    renderTable = (props) => {
        return !props.loading && props.data && props.data.length === 0 && !props.searchText ? (
            <div className="react-bootstrap-table table-v3 no-data">
                <FontAwesomeIcon icon={props.noDataIcon} className="no-data-icon-v3" />
                <div>{props.noDataIndication}</div>
            </div>
        ) : (
            <BootstrapTable
                {...props}
                data={props.data || []}
                isLoading={!props.data || props.isLoading}
            />
        );
    }

    render() {
        const { searchText, onPageChange } = this.props;
        const { extraClasses, tableProps, showPagination, showSearch, striped, bordered, paginationOptions, wrapperClasses } = prepareProps(this.props, {
            getData: () => this.state.tableData,
            getKeyField: () => this.state.keyField,
            getNonExpandableRows: () => this.state.nonExpandableRows,
            getExpandedRows: () => this.state.expandedRows,
            updateExpandedRows: (newExpandedRows) => this.setState({
                expandedRows: newExpandedRows
            }),
            onTableChange: this.onTableChange,
            onPageChange: onPageChange,
        });
        const containerClasses = classNames(wrapperClasses, extraClasses);

        if (showSearch) {
            return (
                <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                    {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider {...tableProps}>
                            {toolkitProps => (
                                <div className={containerClasses}>
                                    {this.renderSearch(toolkitProps.searchProps)}
                                    {this.renderTable({ striped, bordered, ...tableProps, ...toolkitProps.baseProps, ...paginationTableProps, searchText })}
                                    {showPagination && this.renderPagination(paginationProps)}
                                </div>
                            )}
                        </ToolkitProvider>
                    )}
                </PaginationProvider>
            );
        }

        if (showPagination && !showSearch) {
            return (
                <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                    {({ paginationProps, paginationTableProps }) => (
                        <div className={containerClasses}>
                            {this.renderTable({ striped, bordered, ...tableProps, ...paginationTableProps, searchText })}
                            {this.renderPagination(paginationProps)}
                        </div>
                    )}
                </PaginationProvider>
            );
        }

        return (
            <div className={containerClasses}>
                {this.renderTable({ striped, bordered, ...tableProps, searchText })}
            </div>
        );
    }
}

Table.propTypes = {
    tableData: PropTypes.arrayOf(PropTypes.shape({
        // we accept any data shape and there is no specific requirements for contents of it
    })).isRequired,
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    defaultSortName: PropTypes.string,
    defaultSortOrder: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    striped: PropTypes.bool.isRequired,
    bordered: PropTypes.bool.isRequired,
    maxHeight: PropTypes.string,
    wrapperClasses: PropTypes.string.isRequired,
    extraClasses: PropTypes.string,
    noDataIcon: PropTypes.string.isRequired,
    noDataText: PropTypes.string.isRequired,
    loadingDataText: PropTypes.string.isRequired,
    // page size prop limiting amount of items shown on one page
    pageSize: PropTypes.number.isRequired,
    page: PropTypes.number,
    onPageChange: PropTypes.func,
    // list of key column values corresponding to rows that should be expanded already
    expanding: PropTypes.arrayOf(PropTypes.number).isRequired,
    onExpand: PropTypes.func,
    searchText: PropTypes.string,
    sorts: PropTypes.arrayOf(PropTypes.shape({ dataField: PropTypes.string, order: PropTypes.string })),
    registerTable: PropTypes.func.isRequired,
    setTableData: PropTypes.func.isRequired,
    showHeader: PropTypes.bool.isRequired,
    searchPlaceHolderText: PropTypes.string,
    stickyColumnCount: PropTypes.number,
    stickyColumnWidths: PropTypes.arrayOf(PropTypes.number),
    totalTranslation: PropTypes.func.isRequired,
    minimumSearchRows: PropTypes.number,
    verticalAlign: PropTypes.string
};

Table.defaultProps = {
    isLoading: false,
    striped: true,
    bordered: false,
    pageSize: 20,
    page: 1,
    expanding: [],
    showHeader: true,
    minimumSearchRows: 2,
    stickyColumnCount: 1,
    stickyColumnWidths: [],
    wrapperClasses: 'react-bootstrap-table-wrapper-v3',
    extraClasses: '',
    noDataIcon: 'fa-file',
    noDataText: 'No records found.',
    loadingDataText: 'Loading...',
    verticalAlign: 'middle',
    searchPlaceHolderText: 'Search',
    totalTranslation: (from, to, totalCount) => `Results: ${from} - ${to} of ${totalCount}`,
    onRowClick: defaultOnRowClick,
    onRowRightClick: () => {},
    onExpand: () => {},
    onPageChange: () => {}
};
