import { faCogs } from '@fortawesome/free-solid-svg-icons/faCogs';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons/faAsterisk';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faCloud } from '@fortawesome/free-solid-svg-icons/faCloud';
import { faDatabase } from '@fortawesome/free-solid-svg-icons/faDatabase';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faFlag } from '@fortawesome/free-solid-svg-icons/faFlag';
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faSort } from '@fortawesome/free-solid-svg-icons/faSort';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import { faRoad } from '@fortawesome/free-solid-svg-icons/faRoad';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons/faTachometerAlt';
import { faTasks } from '@fortawesome/free-solid-svg-icons/faTasks';
import { faThList } from '@fortawesome/free-solid-svg-icons/faThList';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faUsersCog } from '@fortawesome/free-solid-svg-icons/faUsersCog';
import { faWrench } from '@fortawesome/free-solid-svg-icons/faWrench';
import { faClone } from '@fortawesome/free-solid-svg-icons/faClone';
import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import { faSignOut } from '@fortawesome/free-solid-svg-icons/faSignOut';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons/faRightFromBracket';
import { faArrowRightToBracket } from '@fortawesome/free-solid-svg-icons/faArrowRightToBracket';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons/faMailBulk';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons/faPenToSquare';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons/faCirclePlus';
import { faCalculator } from '@fortawesome/free-solid-svg-icons/faCalculator';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons/faEnvelopeOpen';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faFileText } from '@fortawesome/free-solid-svg-icons/faFileText';
import { faIdCard } from '@fortawesome/free-solid-svg-icons/faIdCard';
import { faInbox } from '@fortawesome/free-solid-svg-icons/faInbox';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { faRocket } from '@fortawesome/free-solid-svg-icons/faRocket';
import { faSquare } from '@fortawesome/free-solid-svg-icons/faSquare';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons/faCreditCard';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faBomb } from '@fortawesome/free-solid-svg-icons/faBomb';
import { faCartArrowDown } from '@fortawesome/free-solid-svg-icons/faCartArrowDown';
import { faCalendarTimes } from '@fortawesome/free-solid-svg-icons/faCalendarTimes';
import { faCalendarMinus } from '@fortawesome/free-solid-svg-icons/faCalendarMinus';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons/faCalendarCheck';
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faFileLines } from '@fortawesome/free-solid-svg-icons/faFileLines';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons/faArrowCircleRight';
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons/faArrowAltCircleRight';
import { faCaretSquareDown } from '@fortawesome/free-solid-svg-icons/faCaretSquareDown';
import { faTachometer } from '@fortawesome/free-solid-svg-icons/faTachometer';

import { library } from '@fortawesome/fontawesome-svg-core';

export const loadIcons = () => {
	library.add(
		faArrowLeft,
		faAsterisk,
		faBan,
		faBell,
		faBook,
		faCloud,
		faCogs,
		faDatabase,
		faEye,
		faFlag,
		faHeart,
		faHome,
		faList,
		faLock,
		faPencilAlt,
		faPlus,
		faRoad,
		faSave,
		faSignInAlt,
		faSignOutAlt,
		faSearch,
		faSort,
		faSync,
		faTachometerAlt,
		faTasks,
		faThList,
		faTimesCircle,
		faTrash,
		faUser,
		faUserPlus,
		faUsers,
		faUsersCog,
		faWrench,
		faClone,
		faGear,
		faSignOut,
		faRightFromBracket,
		faArrowRightToBracket,
		faMailBulk,
		faPenToSquare,
		faCirclePlus,
		faCalculator,
		faEnvelope,
		faEnvelopeOpen,
		faExclamationCircle,
		faFileText,
		faIdCard,
		faInbox,
		faInfoCircle,
		faQuestionCircle,
		faRocket,
		faSquare,
		faCreditCard,
		faClock,
		faCheckCircle,
		faBomb,
		faCartArrowDown,
		faCalendarTimes,
		faCalendarMinus,
		faCalendarCheck,
		faCalendar,
		faCheck,
		faFileLines,
		faArrowCircleRight,
		faArrowAltCircleRight,
		faCaretSquareDown,
		faTachometer
	);
};
