import TableColumn from './TableColumn';
import { sortDateTime } from './util';

export default class DateTimeColumn extends TableColumn {
    sortFunc(...args) {
        // when sortField is not defined - use dataField
        const { dataField, sortField = dataField } = this.props;
        return sortDateTime(sortField)(...args);
    }
}

DateTimeColumn.defaultProps = {
    ...TableColumn.defaultProps,
    wrapContent: true,
    dataSort: true,
    searchable: true,
    columnSelectorDisplay: false,
    columnSelectorFixed: false,
    columnSelectorDefaultDisplay: true
};
