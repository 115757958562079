import { clearAll, NOTIFICATIONS_CONTAINER_ROLE } from './notification.store';
import React, { useEffect } from 'react';

import './NotificationsContainer.scss';
import { useDispatch } from 'react-redux';

export const NotificationsContainer = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(clearAll());
		return () => {
			dispatch(clearAll());
		};
	}, []);
	return <div role={NOTIFICATIONS_CONTAINER_ROLE} className="notifications-container"></div>;
};
