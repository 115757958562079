import {
	IComplianceWorkflowPermissionCheckRequired,
	IContractActivationPermissionCheckByAlRequired,
	IContractActivationPermissionCheckRequired,
	IPermissionCheckNotRequired,
	ISystemPermissionCheckRequired,
	UserContractActivationPermission,
	UserSystemPermission,
} from '../types/Authentication.types';

export const PermissionsCheckFactory: {
	NO_PERMISSIONS: () => IPermissionCheckNotRequired;
	SYSTEM_PERMISSION: (permission: UserSystemPermission) => ISystemPermissionCheckRequired;
	CONTRACT_ACTIVATION_PERMISSION: (permission: UserContractActivationPermission) => IContractActivationPermissionCheckRequired;
	COMPLIANCE_WORKFLOW_PERMISSION: () => IComplianceWorkflowPermissionCheckRequired;
	CONTRACT_ACTIVATION_PERMISSION_BY_AL: (
		permission: UserContractActivationPermission,
		accessLevel: string
	) => IContractActivationPermissionCheckByAlRequired;
} = {
	NO_PERMISSIONS: () => ({ type: 'no permission' }),
	SYSTEM_PERMISSION: (permission: UserSystemPermission) => ({ type: 'system', permission }),
	CONTRACT_ACTIVATION_PERMISSION: (permission: UserContractActivationPermission) => ({
		type: 'contract-activation',
		permission,
	}),
	COMPLIANCE_WORKFLOW_PERMISSION: () => ({ type: 'compliance-workflow' }),
	CONTRACT_ACTIVATION_PERMISSION_BY_AL: (permission: UserContractActivationPermission, accessLevel: string) => ({
		type: 'contract-activation',
		accessLevel,
		permission,
	}),
};
