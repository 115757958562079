/*
 * Copyright 2017, Torque IT Solutions Ltd
 * www.torque-its.com
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';

/**
 * FormGroup wrapper component. Adds only div block of proper css class.
 */
export default class FormGroup extends PureComponent {
    render() {
        const {
            className,
            ...restOfProps
        } = this.props;

        const classNameWithMandatory = classnames('form-group', className);

        return (
            <div
                {...restOfProps}
                className={classNameWithMandatory}
            />
        );
    }
}

FormGroup.propTypes = {
    className: PropTypes.string
};
