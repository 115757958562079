import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import raf from 'raf';

/**
 * Sticky component based on react-sticky (6.0.1 version).
 * This component became our internal component for 2 reasons: a) There were some bugs to be fixed. b) The authors stopped supporting it.
 */
export default class StickyContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.events = [
            'resize',
            'scroll',
            'touchstart',
            'touchmove',
            'touchend',
            'pageshow',
            'load'
        ];
        this.subscribers = [];
    }

    getChildContext() {
        return {
            subscribe: this.subscribe,
            unsubscribe: this.unsubscribe,
            getParent: this.getParent
        };
    }

    componentDidMount() {
        this.events.forEach(event => window.addEventListener(event, this.notifySubscribers));
    }

    componentWillUnmount() {
        this.events.forEach(event => window.removeEventListener(event, this.notifySubscribers));
    }

    subscribe = handler => {
        this.subscribers = this.subscribers.concat(handler);
    };

    unsubscribe = handler => {
        this.subscribers = this.subscribers.filter(current => current !== handler);
    };

    notifySubscribers = evt => {
        if (!this.framePending) {
            const { currentTarget } = evt;
            raf(() => {
                this.framePending = false;
                if (this.node) {
                    const { top, bottom } = this.node.getBoundingClientRect();

                    this.subscribers.forEach(handler => handler({
                        distanceFromTop: top,
                        distanceFromBottom: bottom,
                        eventSource: currentTarget === window ? document.body : this.node
                    }));
                }
            });
            this.framePending = true;
        }
    };

    getParent = () => {
        return this.node;
    };

    setNodeRef = (node) => {
        this.node = node;
    }

    render() {
        return (
            <div
                {...this.props}
                ref={this.setNodeRef}
                onScroll={this.notifySubscribers}
                onTouchStart={this.notifySubscribers}
                onTouchMove={this.notifySubscribers}
                onTouchEnd={this.notifySubscribers}
            />
        );
    }
}

StickyContainer.childContextTypes = {
    subscribe: PropTypes.func,
    unsubscribe: PropTypes.func,
    getParent: PropTypes.func
};
