import React, { memo, useEffect } from 'react';
import { useAuth } from '../../domains/User/useAuth';

export const LogoutPage = memo(() => {
	const { arePermissionsLoaded, logout } = useAuth();

	useEffect(() => {
		if (arePermissionsLoaded) logout();
	}, []);

	return (
		<div className="p-5">
			<h4>Logged out successfully!</h4>
		</div>
	);
});

LogoutPage.displayName = 'LogoutPage';
