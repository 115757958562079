/*
 * Copyright 2018, Torque IT Solutions Ltd
 * www.torque-its.com
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';

/**
 * Row wrapper component.
 */
export default class Row extends PureComponent {
    render() {
        const {
            className,
            ...restOfProps
        } = this.props;

        const classNameWithMandatory = classnames('row', className);

        return (
            <div
                {...restOfProps}
                className={classNameWithMandatory}
            />
        );
    }
}

Row.propTypes = {
    className: PropTypes.string
};
