import './assets/css/app.scss';
import './config/dayjs';

import React, { Suspense } from 'react';
import { PageRoute, usePageRoutes } from './routes';
import { Route, Routes } from 'react-router-dom';
import { Footer } from './views/Footer';
import { Header } from './views/Header/Header';
import { SecuredElement } from './components/SecuredElement';
import { SideMenu } from './views/SideMenu';
import { usePageRouteTitle } from './utils/usePageRouteTitle';
import { APP_NAME } from './config/constants';
import { useAuth } from './domains/User/useAuth';
import { useNotificationsRegistry } from './components/NotificationsHandler';
import { ContractActivationActivityApi, ReferenceDataApi } from './api';

export const App = () => {
	const { arePermissionsLoaded, permissions } = useAuth();
	const routes = usePageRoutes();
	const registryPortal = useNotificationsRegistry();
	usePageRouteTitle({ routes, prefix: APP_NAME });
	ReferenceDataApi.useGetAllActiveVersions(undefined, {
		skip:
			!arePermissionsLoaded ||
			!(
				permissions.systemPermissions.includes('CONTRACT_ACCESS_LEVEL_CONFIGURATION') ||
				permissions.contractActivationsPermissionsPerAL.DEFAULT?.includes('VIEW_CONTRACT_ACTIVATION')
			),
	});
	ContractActivationActivityApi.useGetActivityTypesQuery(undefined, {
		skip: !arePermissionsLoaded || !permissions.systemPermissions.includes('TAG_CONFIGURATION'),
	});

	const renderRoute = (route: PageRoute) => {
		if (route.isSecured) {
			const securedElement = <SecuredElement>{route.element}</SecuredElement>;
			return <Route key={route.path} path={route.path} element={securedElement}></Route>;
		}
		return <Route key={route.path} path={route.path} element={route.element} />;
	};

	return (
		<div id="baseApp">
			{arePermissionsLoaded && <SideMenu />}
			<Header />
			<div id="content" className="content">
				<Suspense fallback="Loading...">
					<Routes>{routes.map(renderRoute)}</Routes>
				</Suspense>
			</div>
			<Footer />
			{registryPortal}
		</div>
	);
};
