/*
 * Copyright 2020, Torque IT Solutions Ltd
 * www.torque-its.com
 */

// Check if the browser supports media data collection (e.g. video via camera).
export function hasGetUserMedia() {
    return 'mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices;
}

export async function cleanStreamTracks() {
    if (window.stream && window.stream.getTracks()) {
        await window.stream.getTracks().forEach(track => {
            track.stop();
        });
    }
}

function getStream(stream) {
    if (stream) {
        const videoElement = document.querySelector('video');
        if (videoElement) {
            // Video cannot be bigger than parent
            if (videoElement.parentElement) {
                const parentWidth = videoElement.parentElement.clientWidth;
                if (parentWidth && parentWidth < videoElement.width) {
                    videoElement.width = parentWidth;
                }
            }
            window.stream = stream;
            videoElement.srcObject = stream;
            videoElement.onloadedmetadata = () => {
                videoElement.play();
            };
        }
    }
}

export async function getVideoAccessPermission({ selectedCamera, onError }) {
    cleanStreamTracks();
    let constraints;
    if (selectedCamera) {
        constraints = {
            video: {
                deviceId: {
                    exact: selectedCamera.deviceId
                }
            }
        };
    } else {
        constraints = {
            video: true
        };
    }
    await navigator.mediaDevices.getUserMedia(constraints).then(getStream).catch(onError);
}

export async function getAvailableVideoDevices({ onCamerasAvailable, onError }) {
    const devices = await navigator.mediaDevices.enumerateDevices().catch(onError);
    const availableCameras = [];
    if (devices) {
        devices.forEach(device => {
            if (device.kind === 'videoinput') {
                availableCameras.push(device);
            }
        });
    }
    onCamerasAvailable(availableCameras);
}

export function takeScreenShot({ videoId, canvasId }) {
    const videoElement = document.getElementById(videoId);
    if (videoElement && canvasId) {
        const canvas = document.getElementById(canvasId);
        if (canvas) {
            canvas.width = videoElement.clientWidth;
            canvas.height = videoElement.clientHeight;
            let canvasContext = canvas.getContext('2d');
            canvasContext.drawImage(videoElement, 0, 0, videoElement.clientWidth, videoElement.clientHeight);
            return canvas.toDataURL('image/jpeg');
        }
    }
    return videoElement;
}