import { PageRoute } from '../routes';
import { matchRoutes, RouteMatch, useLocation } from 'react-router-dom';

const hasPageRoute = (matches: RouteMatch[]) => (route: PageRoute) => matches.some((match) => match.route.path === route.path);

export const usePageRouteTitle = ({ routes, prefix }: { routes: PageRoute[]; prefix: string }) => {
	const location = useLocation();
	const matches = matchRoutes(routes, location);
	if (matches.length > 1) throw new Error('Invalid router paths configured. Please check router paths.');

	const currentPageRoute = routes.find(hasPageRoute(matches));
	if (currentPageRoute.title) {
		const title = typeof currentPageRoute.title === 'function' ? currentPageRoute.title(matches[0]?.params || {}) : currentPageRoute.title;
		document.title = prefix ? `${prefix} - ${title}` : title;
		return;
	}
	document.title = prefix;
};
