import { t } from '../../assets/i18n/translation';
import React from 'react';

import './ForbiddenPage.scss';
import { NotificationsContainer } from '../../components/NotificationsHandler';

export const ForbiddenPage = () => {
	return (
		<div className="forbidden-page">
			<h3>{t('login.authentication.forbidden')}</h3>
			<NotificationsContainer />
		</div>
	);
};
