import { Alert } from 'reactstrap';
import React from 'react';
import { clear, Notification } from './notification.store';
import { useDispatch } from 'react-redux';

export const NotificationAlert = ({ notification }: { notification: Notification }) => {
	const dispatch = useDispatch();
	const onDismiss = (id: string | number) => dispatch(clear(id));

	const renderMessage = (message: string | string[]) => {
		if (Array.isArray(message)) {
			return (
				<div>
					{message.map((msg, index) => (
						<li key={index}>{msg}</li>
					))}
				</div>
			);
		}
		return message;
	};

	return (
		<Alert id={`${notification.id}`} key={notification.id} color={notification.type} toggle={() => onDismiss(notification.id)}>
			{renderMessage(notification.message)}
		</Alert>
	);
};
