import { AnyAction, combineReducers, configureStore, isFulfilled, isRejectedWithValue } from '@reduxjs/toolkit';

import { camApi } from './api';
import notificationsReducer, {
	clear,
	errorHandlerMiddleware,
	NOTIFICATIONS_REDUCER_PATH,
	pushError,
} from './components/NotificationsHandler/notification.store';
import filterReducer, { FILTER_REDUCER_PATH } from './components/Filters/filter.store';
import variantsReducer, { CONFIGURATION_REDUCER_PATH } from './views/ConfigurationPageTemplate/configuration.store';
import recentContractActivationsReducer, {
	RECENT_CONTRACT_ACTIVATIONS_REDUCER_PATH,
} from './domains/ContractActivation/useRecentContractActivations/recentContractActivations.store';
import referenceDataReducer, { REFERENCE_DATA_REDUCER_PATH } from './domains/ReferenceData/referenceData.store';
import applicationUserReducer, { APPLICATION_USER_REDUCER_PATH, languagesFetcherMiddleware } from './domains/User/user.store';
import { extractErrorMessage } from './utils/extractErrorMessage';
import { t } from './assets/i18n/translation';
import { isAxiosQueryError } from './utils/axiosUtils';

import { panelReducer, panelSelectors } from '@torque-common-ui/collapsible-panel';
import { loadingIndicatorReducer, loadingIndicatorSelectors } from '@torque-common-ui/dialog-v2';
// eslint-disable-next-line import/no-extraneous-dependencies
import { tableReducer, tableSelectors } from '@torque-common-ui/table-v3';
import { raygunApiErrorHandler } from './api/raygunErrorHandler';
import brandingReducer, { BRANDING_REDUCER_PATH, systemSettingsMiddleware } from './domains/System/branding.store';

const rootReducer = combineReducers({
	[camApi.reducerPath]: camApi.reducer,
	[NOTIFICATIONS_REDUCER_PATH]: notificationsReducer,
	[RECENT_CONTRACT_ACTIVATIONS_REDUCER_PATH]: recentContractActivationsReducer,
	[APPLICATION_USER_REDUCER_PATH]: applicationUserReducer,
	[FILTER_REDUCER_PATH]: filterReducer,
	[REFERENCE_DATA_REDUCER_PATH]: referenceDataReducer,
	[CONFIGURATION_REDUCER_PATH]: variantsReducer,
	[BRANDING_REDUCER_PATH]: brandingReducer,

	[panelSelectors.panelReducerName]: panelReducer,
	[tableSelectors.tableReducerName]: tableReducer,
	[loadingIndicatorSelectors.loadingIndicatorReducerName]: loadingIndicatorReducer,
});

const resettableReducer = (state: ReturnType<typeof rootReducer | undefined>, action: AnyAction) => {
	if (action.type === 'store/reset') {
		return rootReducer(undefined, action);
	}
	// new reducer always expects payload
	if (action.type === 'CLEAR_CONTENT_HEIGHT') {
		action.payload = {};
	}

	return rootReducer(state, action);
};

const parseRejectedActionMessage = (action) => {
	if (isRejectedWithValue(action)) {
		const {
			meta: {
				arg: { type },
			},
		} = action;
		return {
			id: type,
			title: type || action.payload.message,
			message: extractErrorMessage(action.payload.data, action.payload.message),
		};
	}

	return { id: action.type, message: extractErrorMessage(action.payload.data, t('notification.error.message')) };
};

const store = configureStore({
	reducer: resettableReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				// Ignore these field paths in all actions
				ignoredActionPaths: ['payload.config', 'payload.request', 'error', 'meta.arg'],
			},
		})
			.concat(camApi.middleware)
			.concat(systemSettingsMiddleware)
			.concat(languagesFetcherMiddleware)
			.concat(
				errorHandlerMiddleware((api, action) => {
					if (isRejectedWithValue(action) && isAxiosQueryError(action.payload) && action.payload.code !== 401) {
						raygunApiErrorHandler(action);
						const messagePayload = parseRejectedActionMessage(action);
						api.dispatch(pushError(messagePayload));
					}
					if (isFulfilled(action)) {
						api.dispatch(clear((action as unknown as { meta: { type: string } }).meta.type));
					}
				})
			),
});

export type RootState = ReturnType<typeof store.getState>;
export const getStore = () => store;
