import { connect } from 'react-redux';
import Table from './Table';
import { getReducerStoreForTableId } from './reducer';
import { registerTable, setTableData } from './actions';
import { getTableData } from './helper/data';

const mapStateToProps = (state, ownProps) => {
    const { children, data, id } = ownProps;
    const dataTableState = getReducerStoreForTableId(state, id);
    return {
        ...dataTableState,
        tableData: getTableData(children, data, dataTableState),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const { id } = ownProps;
    return {
        registerTable: (data) => dispatch(registerTable({ id, data })),
        setTableData: (data) => dispatch(setTableData({ id, data }))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
