import { camApi } from './api';
import { JsCamBrandingConfigType } from '../types/CamConfig.types';

export const BrandingApi = camApi.injectEndpoints({
	endpoints: (build) => ({
		getBrandingConfig: build.query<JsCamBrandingConfigType, void>({
			providesTags: ['system-branding'],
			query: () => ({
				url: 'api/configuration/system/branding',
				method: 'get',
			}),
		}),
	}),
});
