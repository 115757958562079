import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import StickyComponentContainer from './own-react-sticky/StickyComponentContainer';
import NewStickyContainer from './react-sticky-el/ReactStickyElContainer';

/**
 * Container in which the sticky component is
 */
export default class StickyContainer extends PureComponent {
    render() {
        if (this.props.useNewVersion) {
            return <NewStickyContainer {...this.props} />;
        }
        return <StickyComponentContainer {...this.props} />;
    }
}

StickyContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.func
    ]),
    // Whether to use the new implementation based on react-sticky-el
    useNewVersion: PropTypes.bool.isRequired
};

StickyContainer.defaultProps = {
    useNewVersion: false
};
