import { IAccount } from '../types/Authentication.types';
import { JsLanguageType } from '../types/CamConfig.types';

const STORAGE_TOKEN_KEY = 'cam-authentication-token';
const STORAGE_USER_KEY = 'cam-user';
const STORAGE_LANGUAGES_KEY = 'cam-languages';

const getObjectFromStorage = <T>(key: string): T | undefined => {
	const rawItem = localStorage.getItem(key);
	if (!rawItem) {
		return undefined;
	}
	try {
		return JSON.parse(rawItem) as T;
	} catch (error) {
		return undefined;
	}
};
const getUserFromStorage = (): IAccount | undefined => getObjectFromStorage(STORAGE_USER_KEY) as IAccount | undefined;
const getTokenFromStorage = (): string | undefined => localStorage.getItem(STORAGE_TOKEN_KEY) as string | undefined;
const setLanguagesToStorage = (languages: JsLanguageType[]) => localStorage.setItem(STORAGE_LANGUAGES_KEY, JSON.stringify(languages));
const getLanguagesFromStorage = (): JsLanguageType[] => {
	const availableLanguages: JsLanguageType[] = getObjectFromStorage(STORAGE_LANGUAGES_KEY);
	if (!availableLanguages) {
		const languages = window.TORQUE_ITS_CAM_CONFIG.AVAILABLE_LANGUAGES;
		setLanguagesToStorage(languages);
		return languages;
	}
	return availableLanguages;
};
const setUserDataToStorage = (token: string, userData: IAccount) => {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const { token: _, permissions, ...accountData } = userData;
	localStorage.setItem(STORAGE_TOKEN_KEY, token);
	localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(accountData));
};

const resetUserDataFromStorage = () => {
	localStorage.removeItem(STORAGE_TOKEN_KEY);
	localStorage.removeItem(STORAGE_USER_KEY);
	localStorage.removeItem(STORAGE_LANGUAGES_KEY);
};

export const LocalStorageCache = {
	getUser: getUserFromStorage,
	getToken: getTokenFromStorage,
	setUserData: setUserDataToStorage,
	resetUserData: resetUserDataFromStorage,
	getLanguages: getLanguagesFromStorage,
	setLanguages: setLanguagesToStorage,
};
