import { IContractActivationView, IViewFieldEvaluation } from '../../types/ContractActivationView.types';
import { sortByField } from '../../utils/common';
import { buildFieldName, buildViewEntryEvaluationName } from '../../utils/fieldUtils';

const buildLoanFieldName = (field: IViewFieldEvaluation) => buildFieldName({ ...field, code: field?.code.replace(/^(LOAN\.)/, '') });

const toContractActivationView = (view: IContractActivationView): IContractActivationView => {
	return {
		...view,
		assetDetails: view.assetDetails?.map((asset) => ({
			...asset,
			view: {
				...asset.view,
				assetExtras: asset.view?.assetExtras?.map((extra) => ({
					...extra,
					fields: extra?.fields?.sort(sortByField('position', 'asc')).map((field) => ({
						...field,
						name: buildFieldName(field),
						entries: field?.entries.map((entry) => ({
							...entry,
							name: buildViewEntryEvaluationName(entry),
						})),
					})),
				})),
				fields: asset.view?.fields?.sort(sortByField('position', 'asc')).map((field) => ({
					...field,
					name: buildFieldName(field),
					entries: field?.entries.map((entry) => ({
						...entry,
						name: buildViewEntryEvaluationName(entry),
					})),
				})),
			},
		})),
		applicationDetails: {
			...view.applicationDetails,
			view: {
				fields: view.applicationDetails?.view?.fields.sort(sortByField('position', 'asc')).map((field) => ({
					...field,
					name: buildLoanFieldName(field),
					entries: field?.entries.map((entry) => ({
						...entry,
						name: buildViewEntryEvaluationName(entry),
					})),
				})),
			},
			businessPartners: view?.applicationDetails?.businessPartners?.map((bp) => ({
				...bp,
				view: {
					...bp.view,
					groups: bp.view?.groups?.sort(sortByField('position', 'asc')).map((group) => ({
						...group,
						fields: group?.fields.sort(sortByField('position', 'asc')).map((field) => ({
							...field,
							name: buildFieldName(field),
							entries: field?.entries.map((entry) => ({
								...entry,
								name: buildViewEntryEvaluationName(entry),
							})),
						})),
					})),
				},
			})),
			settlementConditions: view.applicationDetails?.settlementConditions.sort(sortByField('name', 'asc')),
		},
	};
};

export const ContractActivationViewMapper = {
	toContractActivationView,
};
