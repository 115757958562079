/*
 * Copyright 2021, Torque IT Solutions Ltd
 * www.torque-its.com
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Modal } from 'react-bootstrap';
import LoadingIndicator from '@torque-common-ui/loading-indicator';

/**
 * DialogBody component based on React Bootstrap Modal.Body and include
 * loading indicator to be shown when it is loading.
 */
export class DialogBody extends Component {

    componentDidUpdate() {
        if (!this.context.isLoading) {
            const bodyComponentRef = ReactDOM.findDOMNode(this.bodyPanel);
            if (bodyComponentRef) {
                // Set the offset height of this body panel in the state. This height will use to show loading indicator.
                let contentHeight = bodyComponentRef.offsetHeight;
                this.context.setContentHeight(this.context.id, contentHeight);
            }
        }
    }

    componentWillUnmount() {
        this.context.clearContentHeight(this.context.id);
    }

    setRef = (el) => {
        this.bodyPanel = el;
        this.context.handleRef(el);
    };

    render() {
        let body = null;
        let style = {};
        if (this.context.contentHeight) {
            style = { height: this.context.contentHeight + 'px' };
        }
        if (this.context.isLoading) {
            body = (
                <Modal.Body bsClass="modal-body-v2" ref={this.setRef}>
                    <LoadingIndicator id="modalBodyIndicator" appearance="medium" style={style} />
                </Modal.Body>
            );
        } else {
            body = <Modal.Body bsClass="modal-body-v2" ref={this.setRef} {...this.props} />;
        }
        return body;
    }
}

DialogBody.contextTypes = {
    id: PropTypes.string,
    isLoading: PropTypes.bool,
    contentHeight: PropTypes.number,
    setContentHeight: PropTypes.func,
    clearContentHeight: PropTypes.func,
    firstElementFocus: PropTypes.bool,
    handleRef: PropTypes.func
};
