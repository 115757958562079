/*
 * Copyright 2021, Torque IT Solutions Ltd
 * www.torque-its.com
 */
import TableColumn from './TableColumn';
import { sortInputTextForField } from './util';

export default class InputTextColumn extends TableColumn {
    sortFunc(...args) {
        // when sortField is not defined - use dataField
        const { dataField, sortField = dataField } = this.props;
        return sortInputTextForField(sortField, ...args);
    }
}

InputTextColumn.defaultProps = {
    ...TableColumn.defaultProps,
    wrapContent: true,
    dataSort: true,
    searchable: true,
    columnSelectorDisplay: false,
    columnSelectorFixed: false,
    columnSelectorDefaultDisplay: true
};
