import { IRecentContractActivation } from '../../types/Contract.types';
import { t } from '../../assets/i18n/translation';
import { ROUTE_PATH } from '../../routes';
import { useAuth } from '../../domains/User/useAuth';
import { useRecentContractActivations } from '../../domains/ContractActivation/useRecentContractActivations';
import { generatePath, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import {
	IComplianceWorkflowPermissionCheckRequired,
	IContractActivationPermissionCheckRequired,
	IPermissionCheckNotRequired,
	ISystemPermissionCheckRequired,
} from '../../types/Authentication.types';
import { CONTRACT_ACTIVATION_PERMISSION, SYSTEM_PERMISSION } from '../../config/constants';
import { PermissionsCheckFactory } from '../../utils/permissionsCheckFactory';

export interface IMenu {
	defaultSelected: IMenuItem['id'];
	children: IMenuItem[];
}

export interface IMenuItem {
	id: string;
	to?: string;
	icon?: string;
	label?: string;
	children?: IMenuItem[];
	permissionsCheck:
		| ISystemPermissionCheckRequired
		| IContractActivationPermissionCheckRequired
		| IPermissionCheckNotRequired
		| IComplianceWorkflowPermissionCheckRequired;
}

export interface IMenuConfigProps {
	recent: IRecentContractActivation[];
	navigate: (route: string) => void;
}

const renderRecentMenuItems = (
	recentContractActivations: IRecentContractActivation[],
	navigate: IMenuConfigProps['navigate']
): IMenuItem[] => {
	if (!recentContractActivations.length) return [];

	const renderRecentContractActivationMenuItem = (contract: IRecentContractActivation): IMenuItem => ({
		id: `recent-contract-activation-${contract.applicationId}-${contract.versionNumber}`,
		to: generatePath(ROUTE_PATH.CONTRACT_ACTIVATION, {
			applicationId: String(contract.applicationId),
			version: contract.versionNumber,
		}),
		icon: 'fa-file-text',
		label: `${contract.applicationId} v${contract.versionNumber} ${contract.borrowerFullName}`,
		permissionsCheck: PermissionsCheckFactory.CONTRACT_ACTIVATION_PERMISSION(CONTRACT_ACTIVATION_PERMISSION.VIEW_CONTRACT_ACTIVATION),
	});

	return [
		...recentContractActivations.map(renderRecentContractActivationMenuItem),
		{
			id: 'recentContractActivationSearch',
			icon: 'fa-search',
			label: t('nav.menu.contractActivation.search'),
			to: ROUTE_PATH.CONTRACT_ACTIVATION_WORKFLOW_SEARCH,
			permissionsCheck: PermissionsCheckFactory.CONTRACT_ACTIVATION_PERMISSION(CONTRACT_ACTIVATION_PERMISSION.VIEW_CONTRACT_ACTIVATION),
		},
	];
};

const renderConfigurationSystemMenuItems = (navigate: IMenuConfigProps['navigate']): IMenuItem[] => {
	return [
		{
			id: 'configurationSystemDecisionTable',
			label: t('nav.menu.configuration.system.genericDecisionTable'),
			to: ROUTE_PATH.GENERIC_DECISION_TABLE_CONFIGURATION_LIST,
			permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.GENERIC_DECISION_TABLE_CONFIGURATION),
		},
		{
			id: 'configurationSystemSecret',
			label: t('nav.menu.configuration.system.secret'),
			to: ROUTE_PATH.SECRETS_CONFIGURATION,
			permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.SYSTEM_CONFIGURATION),
		},
		{
			id: 'configurationSystemPlugin',
			label: t('nav.menu.configuration.system.plugin'),
			to: ROUTE_PATH.PLUGINS_CONFIGURATION_LIST,
			permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.SYSTEM_CONFIGURATION),
		},
		{
			id: 'configurationSystemSystem',
			label: t('nav.menu.configuration.system.system'),
			to: ROUTE_PATH.SYSTEM_CONFIGURATION_LIST,
			permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.SYSTEM_CONFIGURATION),
		},
	];
};

const renderConfigurationUserMenuItems = (navigate: IMenuConfigProps['navigate']): IMenuItem[] => {
	return [
		{
			id: 'configurationSystemUser',
			label: t('nav.menu.configuration.user.user'),
			to: ROUTE_PATH.USER_CONFIGURATION_LIST,
			permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.USER_CONFIGURATION),
		},
		{
			id: 'configurationSystemUserGroup',
			label: t('nav.menu.configuration.user.user.group'),
			to: ROUTE_PATH.USER_GROUP_CONFIGURATION_LIST,
			permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.USER_CONFIGURATION),
		},
		{
			id: 'configurationSystemRoleAssignment',
			label: t('nav.menu.configuration.system.role.assignment'),
			to: ROUTE_PATH.ROLE_ASSIGNMENT_CONFIGURATION_LIST,
			permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.ROLE_ASSIGNMENT_CONFIGURATION),
		},
		{
			id: 'configurationSystemAccessLevel',
			label: t('nav.menu.configuration.system.accessLevel'),
			to: ROUTE_PATH.ACCESS_LEVEL_CONFIGURATION_LIST,
			permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACCESS_LEVEL_CONFIGURATION),
		},
		{
			id: 'configurationSystemAccessLevelSchedule',
			label: t('nav.menu.configuration.system.accessLevelSchedule'),
			to: ROUTE_PATH.ACCESS_LEVEL_SCHEDULE_CONFIGURATION_LIST,
			permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACCESS_LEVEL_CONFIGURATION),
		},
		{
			id: 'configurationSystemRole',
			label: t('nav.menu.configuration.system.role'),
			to: ROUTE_PATH.ROLE_CONFIGURATION_LIST,
			permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.ROLE_CONFIGURATION),
		},
	];
};

const renderConfigurationWorkflowMenuItems = (navigate: IMenuConfigProps['navigate']): IMenuItem[] => {
	return [
		{
			id: 'tag',
			label: t('nav.menu.configuration.system.tag'),
			to: ROUTE_PATH.TAG_CONFIGURATION_LIST,
			permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.TAG_CONFIGURATION),
		},
		{
			id: 'referenceData',
			label: t('configuration.referenceData.displayName'),
			to: ROUTE_PATH.REFERENCE_DATA_CONFIGURATION_LIST,
			permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.REFERENCE_DATA_CONFIGURATION),
		},
		{
			id: 'contractActivationStatus',
			label: t('nav.menu.configuration.workflow.status'),
			to: ROUTE_PATH.CONTRACT_ACTIVATION_STATUS_CONFIGURATION,
			permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACTIVATION_PROCESS_CONFIGURATION),
		},
		{
			id: 'contractActivationAction',
			label: t('nav.menu.configuration.workflow.action'),
			to: ROUTE_PATH.CONTRACT_ACTIVATION_ACTION_CONFIGURATION,
			permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACTIVATION_PROCESS_CONFIGURATION),
		},
		{
			id: 'configurationCheckpoint',
			label: t('nav.menu.checkpoint'),
			to: ROUTE_PATH.CHECKPOINT_CONFIGURATION_LIST,
			permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CHECKLIST_CONFIGURATION),
		},
		{
			id: 'configurationConditionType',
			label: t('nav.menu.configuration.workflow.condition.type'),
			to: ROUTE_PATH.CONDITION_TYPE_CONFIGURATION_LIST,
			permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONDITION_TYPE_CONFIGURATION),
		},
		{
			id: 'configurationChecklistSchedule',
			label: t('nav.menu.checklist.schedule'),
			to: ROUTE_PATH.CHECKLIST_SCHEDULE_CONFIGURATION_LIST,
			permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CHECKLIST_CONFIGURATION),
		},
	];
};

const renderConfigurationViewMenuItems = (navigate: IMenuConfigProps['navigate']): IMenuItem[] => {
	return [
		{
			id: 'contractActivationView',
			label: t('nav.menu.configuration.view.contract.activation'),
			to: ROUTE_PATH.CONTRACT_ACTIVATION_VIEW_CONFIGURATION_LIST,
			permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACTIVATION_VIEW_CONFIGURATION),
		},
		{
			id: 'actionsLayoutView',
			label: t('nav.menu.configuration.view.actions.layout'),
			to: ROUTE_PATH.ACTIONS_LAYOUT_VIEW_CONFIGURATION_LIST,
			permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACTIVATION_VIEW_CONFIGURATION),
		},
		{
			id: 'applicationDetailsSectionView',
			label: t('nav.menu.configuration.view.application.details.section'),
			to: ROUTE_PATH.APPLICATION_DETAILS_SECTION_VIEW_CONFIGURATION_LIST,
			permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACTIVATION_VIEW_CONFIGURATION),
		},
		{
			id: 'assetSectionView',
			label: t('nav.menu.configuration.view.asset.section'),
			to: ROUTE_PATH.ASSET_SECTION_VIEW_CONFIGURATION_LIST,
			permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACTIVATION_VIEW_CONFIGURATION),
		},
		{
			id: 'customerSectionView',
			label: t('nav.menu.configuration.view.customer.section'),
			to: ROUTE_PATH.CUSTOMER_SECTION_VIEW_CONFIGURATION_LIST,
			permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACTIVATION_VIEW_CONFIGURATION),
		},
		{
			id: 'computedField',
			label: t('nav.menu.configuration.view.computed.field'),
			to: ROUTE_PATH.COMPUTED_FIELDS_CONFIGURATION_LIST,
			permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.CONTRACT_ACTIVATION_VIEW_CONFIGURATION),
		},
	];
};

const renderMiscellaneusMenuItems = (navigate: IMenuConfigProps['navigate']): IMenuItem[] => {
	return [
		{
			id: 'complianceSectionConfiguration',
			label: t('nav.menu.configuration.miscellaneus.compliance'),
			to: ROUTE_PATH.COMPLIANCE_CONFIGURATION_LIST,
			permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.COMPLIANCE_CONFIGURATION),
		},
		{
			id: 'activationCustomFieldConfiguration',
			label: t('nav.menu.configuration.miscellaneus.activationCustomField'),
			to: ROUTE_PATH.ACTIVATION_CUSTOM_FIELDS_CONFIGURATION_LIST,
			permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.ACTIVATION_CUSTOM_FIELD_CONFIGURATION),
		},
	];
};

const renderLocalisationMenuItems = (navigate: IMenuConfigProps['navigate']): IMenuItem[] => {
	return [
		{
			id: 'languageConfiguration',
			label: t('nav.menu.configuration.localisation.language'),
			to: ROUTE_PATH.LANGUAGE_CONFIGURATION_LIST,
			permissionsCheck: PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.SYSTEM_CONFIGURATION),
		},
	];
};

const renderConfigurationMenuItems = (navigate: IMenuConfigProps['navigate']): IMenuItem[] => {
	return [
		{
			id: 'configurationUsers',
			icon: 'fa-cog',
			label: t('nav.menu.configuration.user'),
			children: renderConfigurationUserMenuItems(navigate),
			permissionsCheck: PermissionsCheckFactory.NO_PERMISSIONS(),
		},
		{
			id: 'configurationCompliance',
			icon: 'fa-cog',
			label: t('nav.menu.configuration.miscellaneus'),
			children: renderMiscellaneusMenuItems(navigate),
			permissionsCheck: PermissionsCheckFactory.NO_PERMISSIONS(),
		},
		{
			id: 'configurationWorkflow',
			icon: 'fa-cog',
			label: t('nav.menu.configuration.workflow'),
			children: renderConfigurationWorkflowMenuItems(navigate),
			permissionsCheck: PermissionsCheckFactory.NO_PERMISSIONS(),
		},
		{
			id: 'configurationView',
			icon: 'fa-cog',
			label: t('nav.menu.configuration.view'),
			children: renderConfigurationViewMenuItems(navigate),
			permissionsCheck: PermissionsCheckFactory.NO_PERMISSIONS(),
		},
		{
			id: 'configurationSystem',
			icon: 'fa-cog',
			label: t('nav.menu.configuration.system'),
			children: renderConfigurationSystemMenuItems(navigate),
			permissionsCheck: PermissionsCheckFactory.NO_PERMISSIONS(),
		},
		{
			id: 'configurationLocalisation',
			icon: 'fa-cog',
			label: t('nav.menu.configuration.localisation'),
			children: renderLocalisationMenuItems(navigate),
			permissionsCheck: PermissionsCheckFactory.NO_PERMISSIONS(),
		},
	];
};

const renderWorkFlowMenuItems = (navigate: IMenuConfigProps['navigate']): IMenuItem[] => {
	return [
		{
			id: 'workflow',
			icon: 'fa-clone',
			label: t('nav.menu.workflow'),
			to: ROUTE_PATH.CONTRACT_ACTIVATION_WORKFLOW,
			permissionsCheck: PermissionsCheckFactory.CONTRACT_ACTIVATION_PERMISSION(CONTRACT_ACTIVATION_PERMISSION.VIEW_CONTRACT_ACTIVATION),
		},
		{
			id: 'complianceWorkflow',
			icon: 'fa-clone',
			label: t('nav.menu.compliance.workflow'),
			to: ROUTE_PATH.CONFIG_WORKFLOW_LIST,
			permissionsCheck: PermissionsCheckFactory.COMPLIANCE_WORKFLOW_PERMISSION(),
		},
	];
};

export const useMenu: () => IMenu | undefined = () => {
	const navigate = useNavigate();
	const { permissions, hasAuthority } = useAuth();
	const { recent } = useRecentContractActivations();

	const filterByPermissions = (items: IMenuItem[]): IMenuItem[] =>
		items
			// Check all required permissions
			.filter((item) => hasAuthority(item.permissionsCheck))
			.map((item) => {
				if (!item.children || !item.children.length) return item;

				const children = filterByPermissions(item.children || []);
				// Clean up all higher-level menu items if list is empty
				if (item.to == null && !children.length) {
					return undefined;
				}
				return { ...item, children };
			})
			.filter((item) => item != null);

	return useMemo(() => {
		if (!permissions) return undefined;

		const menuItems: IMenuItem[] = [
			{
				id: 'dashboard',
				icon: 'fa-tachometer',
				label: t('nav.menu.dashboard'),
				to: ROUTE_PATH.DASHBOARD,
				permissionsCheck: PermissionsCheckFactory.NO_PERMISSIONS(),
			},
			{
				id: 'contractActivation',
				icon: 'fa-calculator',
				label: t('nav.menu.contractActivation'),
				children: renderRecentMenuItems(recent, navigate),
				permissionsCheck: PermissionsCheckFactory.CONTRACT_ACTIVATION_PERMISSION(CONTRACT_ACTIVATION_PERMISSION.VIEW_CONTRACT_ACTIVATION),
			},
			{
				id: 'workflowSection',
				icon: 'fa-clone',
				label: t('nav.menu.workflow'),
				children: renderWorkFlowMenuItems(navigate),
				permissionsCheck: PermissionsCheckFactory.NO_PERMISSIONS(),
			},
			{
				id: 'configuration',
				icon: 'fa-cog',
				label: t('nav.menu.configuration'),
				children: renderConfigurationMenuItems(navigate),
				permissionsCheck: PermissionsCheckFactory.NO_PERMISSIONS(),
			},
		];

		const getDefaultSelectedItem = () => {
			switch (true) {
				case hasAuthority(PermissionsCheckFactory.SYSTEM_PERMISSION(SYSTEM_PERMISSION.SYSTEM_CONFIGURATION)):
					return 'configuration';
				case hasAuthority(PermissionsCheckFactory.CONTRACT_ACTIVATION_PERMISSION(CONTRACT_ACTIVATION_PERMISSION.VIEW_CONTRACT_ACTIVATION)):
					return 'workflowSection';
				default:
					return '';
			}
		};

		return {
			defaultSelected: getDefaultSelectedItem(),
			children: filterByPermissions(menuItems),
		} as IMenu;
	}, [permissions, recent]);
};
