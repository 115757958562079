import { createSlice } from '@reduxjs/toolkit';
import { IContractActivation, IRecentContractActivation } from '../../../types/Contract.types';
import { contractsApi } from '../../../api';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

interface IRecentContractActivationStore {
	current?: IContractActivation;
	recent: IRecentContractActivation[];
}

const initialState: IRecentContractActivationStore = {
	recent: [],
};

const recentContractActivationsStore = createSlice({
	name: 'recentContractActivation',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(contractsApi.endpoints.getRecentContracts.matchFulfilled, (state, action) => {
			state.recent = action.payload;
		});
		builder.addMatcher(contractsApi.endpoints.getContractView.matchFulfilled, (state, action) => {
			state.current = action.payload;
		});
	},
});

export const RECENT_CONTRACT_ACTIVATIONS_REDUCER_PATH = recentContractActivationsStore.name;
export const useRecentContractActivationsSelector = (): IRecentContractActivationStore =>
	useSelector((state: RootState) => state[RECENT_CONTRACT_ACTIVATIONS_REDUCER_PATH]);

export default recentContractActivationsStore.reducer;
