import { connect } from 'react-redux';
import CollapsiblePanel from './CollapsiblePanel';
import { getReducerStoreForPanelId } from './reducer';
import { registerPanel, unregisterPanel, setOpenedState } from './actions';

const mapStateToProps = (state, ownProps) => {
    const { id } = ownProps;
    const panelState = getReducerStoreForPanelId(state, id);
    return Object.assign({}, ownProps, panelState);
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const { id, collapsed } = ownProps;
    return {
        registerPanel: () => dispatch(registerPanel({ id, isOpened: !collapsed })),
        unregisterPanel: () => dispatch(unregisterPanel({ id, isOpened: !collapsed })),
        setOpenedState: (isOpened) => dispatch(setOpenedState({ id, isOpened }))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollapsiblePanel);
