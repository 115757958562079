import { ICustomerSectionViewVersion } from '../../types/CustomerSectionView.types';
import { buildFieldCode, buildFieldName, isComputedMetadataField, isActivationCustomField } from '../../utils/fieldUtils';
import { sortByField } from '../../utils/common';
import { IMetadataField } from '../../types/MetaData.types';
import { ApplicantType } from '../../types/BusinessParter.types';

const ApplicantTypePriority: Record<ApplicantType, number> = {
	BORROWER: 1,
	CO_BORROWER: 2,
	AFFILIATE: 3,
	GUARANTOR: 4,
};

const getActive = <TField extends IMetadataField>(field: TField) => {
	if (field.fieldType === 'CUSTOM') return field.referenceDataValue?.active;
	if (isActivationCustomField(field)) return field.activationCustomField?.active;
	if (isComputedMetadataField(field)) return field.computedField.active;
	return field.active;
};

const toCustomerSectionViewGroupField = <TField extends IMetadataField & { orderIndex?: number }>(field: TField) => ({
	...field,
	code: buildFieldCode(field),
	name: buildFieldName(field, true),
	active: getActive(field),
});

const toCustomerSectionViewVersion = (response: ICustomerSectionViewVersion) => ({
	...response,
	applicantTypes: response.applicantTypes?.sort((a1, a2) => ApplicantTypePriority[a1] - ApplicantTypePriority[a2]),
	groups: response.groups
		.map((group) => ({
			...group,
			fields: group.fields.map(toCustomerSectionViewGroupField).sort(sortByField('position', 'asc')),
		}))
		.sort(sortByField('position', 'asc')),
});

const toCustomerSectionViewVersions = (versions: ICustomerSectionViewVersion[]) => versions.map(toCustomerSectionViewVersion);

export const CustomerSectionViewMapper = {
	toCustomerSectionViewVersion,
	toCustomerSectionViewVersions,
	toCustomerSectionViewGroupField,
};
