/*
 * Copyright 2017, Torque IT Solutions Ltd
 * www.torque-its.com
 */
import isNull from 'lodash/fp/isNull';
import isUndefined from 'lodash/fp/isUndefined';

// Short remark about null values and empty strings in inputs:
// in our state we still need to store "null" value,
// but if we provide null to react's <input/>, there gonna be some issues
// The point is, when we initially say to input component that we have it's value
// and we provide it, that means that our input component is controller
// (from outside, aka from redux state), but as soon as input component will receive
// null, that would mean for it that, value prop is no longer provided, then
// it will complain that input component changed from controlled to uncontrolled,
// which is fair enough, you lost control on it by giving "null" :)
// at this point we need some kind of safe getter if value is "null" or "undefined"
export function undefinedAndNullToEmptyString(value) {
    if (isNull(value) || isUndefined(value)) {
        return '';
    }

    return value;
}

// This function responsible for converting empty string to null
// In order to send in API proper null value and not ""
// This should be used before we update value from form in redux store
export function emptyStringToNull(value) {
    if (value === '') {
        return null;
    }

    return value;
}
