import React, { ReactNode } from 'react';
import { StickyContainer } from 'src/components/SharedLibrary';

type ScrollableContentProps = {
	t?: () => string;
	hasToolbar?: boolean;
	isCreditProposalSubPage?: boolean;
	children: ReactNode;
};

const ScrollableContent = (props: ScrollableContentProps) => {
	return (
		<div className="scrollable-content">
			<StickyContainer useNewVersion containerId="scrollable-content">
				{props.children}
				{/*<TrqConfirmationDialog
                    t={t}
                    notificationsPanel={<AppMessagesContainer />}
                    store={{
                        getState: () => {}
                    }}
                />*/}
			</StickyContainer>
		</div>
	);
};

export default ScrollableContent;
