import React, { memo, useEffect, useState } from 'react';
import ScrollableContent from 'src/components/ScrollableContent/ScrollableContent';
import { t } from 'src/assets/i18n/translation';
import { Glyphicon } from 'src/components/SharedLibrary';
import { Link, useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '../../components/Button';
import { Alert, Form } from 'reactstrap';
import { InputFormField } from '../../components/InputFormField';
import { useAuth } from '../../domains/User/useAuth';

import './LoginFormPage.scss';
import { ROUTE_PATH } from '../../routes';

export const LoginFormPage = () => {
	const navigate = useNavigate();
	const [visible, setVisible] = useState(false);
	const onDismiss = () => setVisible(false);
	const { login, error, arePermissionsLoaded, isError } = useAuth();

	useEffect(() => {
		if (arePermissionsLoaded) navigate(ROUTE_PATH.DEFAULT_REDIRECT);
	}, [arePermissionsLoaded]);

	useEffect(() => {
		setVisible(!!error);
	}, [isError]);

	const { control, handleSubmit } = useForm<{ userName: string; password: string }>({});

	const handleLoginSubmit: SubmitHandler<{ userName: string; password: string }> = async ({ userName, password }) => {
		await login({ username: userName, password });
	};

	const renderErrorMessage = (errorMessage: string) => (
		<Alert className="login-options alert" isOpen={visible} toggle={onDismiss} color="danger">
			{errorMessage}
		</Alert>
	);

	const renderLoginInputField = () => (
		<InputFormField
			name="userName"
			control={control}
			translations={{ label: 'login.userName', error: { required: 'user.login.username.required' } }}
			rules={{ required: true }}
			icon={<Glyphicon icon="user" />}
		/>
	);

	const renderPasswordInputField = () => (
		<InputFormField
			name="password"
			type="password"
			control={control}
			translations={{ label: 'login.userPassword', error: { required: 'user.login.password.required' } }}
			rules={{ required: true }}
			icon={<Glyphicon icon="lock" />}
		/>
	);

	return (
		<ScrollableContent>
			<div id="loginOptionsScreen" className="login-form-container login-options-container">
				{renderErrorMessage(error)}
				<div className="login-options ui-panel">
					<Form id="loginForm" name="form" role="form" className="login-form" onSubmit={handleSubmit(handleLoginSubmit)}>
						<p className="title">{t('login.title')}</p>

						{renderLoginInputField()}

						<div className="clearfix" />

						{renderPasswordInputField()}

						<div className="clearfix" />

						<div className="login-actions">
							<Link to="/forgotPassword">{t('login.toForgotPassword.link')}</Link>
							<div className="button-group right">
								<Button type="submit" className="pull-right" icon="chevron-right" title={t('login.button')}>
									{t('login.button')}
								</Button>
							</div>
						</div>
						<div className="clearfix" />
					</Form>
				</div>
			</div>
		</ScrollableContent>
	);
};

LoginFormPage.displayName = 'LoginFormPage';
