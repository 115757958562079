/*
 * Copyright 2021, Torque IT Solutions Ltd
 * www.torque-its.com
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';

/**
 * ExpandableRow is used for showing information in expand component of the table.
 */
export default class ExpandableRow extends PureComponent {
    render() {
        const { id, rendered, label, labelClassName, wrapperClassName, rowClassName, value, tooltip, children } = this.props;
        if (!rendered) {
            return null;
        }
        let labelComponent;
        if (label) {
            labelComponent = (
                <Col className={labelClassName}>
                    <ControlLabel className="expandable-row-label">{label}</ControlLabel>
                </Col>
            );
        }
        // FormGroup is only rendered whether there is either label or value
        let formGroup;
        if (label || value) {
            formGroup = (
                <FormGroup controlId={id}>
                    {labelComponent}
                    <Col id={id} className={wrapperClassName}>
                        <span title={tooltip} className="expandable-row-value">{value}</span>
                    </Col>
                </FormGroup>
            );
        }
        return (
            <Col xs={12} className={rowClassName}>
                {formGroup}
                {children}
            </Col>
        );
    }
}

ExpandableRow.propTypes = {
    id: PropTypes.string,
    rendered: PropTypes.bool,
    label: PropTypes.string,
    labelClassName: PropTypes.string,
    wrapperClassName: PropTypes.string,
    rowClassName: PropTypes.string,
    value: PropTypes.string,
    tooltip: PropTypes.string,
    children: PropTypes.node
};

ExpandableRow.defaultProps = {
    rendered: true,
    tooltip: ''
};
