import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../domains/User/useAuth';
import { ROUTE_PATH } from '../../routes';

export const SecuredElement: React.FC<{ children: React.ReactElement }> = ({ children }) => {
	const location = useLocation();
	const { account } = useAuth();

	if (account) {
		return children;
	}
	return <Navigate to={{ pathname: ROUTE_PATH.LOGIN, search: location.search }} replace state={{ from: location }} />;
};
