import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Media from 'react-media';
import { forEach } from 'lodash';
import MenuGroup from './MenuGroup';

// Container class to hold application menu.
// The menu should consist of one or many menu groups
class MenuContainer extends Component {

    render() {
        const { useBurger, className, ...props } = this.props;
        let burgerSubMenuItems = [];
        let menuItems = [];
        if (props.children && props.children.length > 0) {
            forEach(props.children, (child) => {
                const childProps = child.props;
                if (childProps) {
                    const menuItem = {
                        menuId: childProps.menuId,
                        name: childProps.title,
                        to: childProps.to,
                        toBeShown: childProps.toBeShown,
                        onClick: childProps.onClick,
                        icon: childProps.icon,
                        path: childProps.path,
                        subMenus: childProps.menuItems
                    };
                    if (!childProps.toBeShownNextBurgerMenu) {
                        burgerSubMenuItems.push(menuItem);
                    } else {
                        menuItems.push(menuItem);
                    }
                }
            });
        }
        const menu = (
            <Media query="(max-width: 1280px)">
                {
                    matches => matches && useBurger ?
                        (
                            <ul className="trq-menu-bar-v2" style={props.style}>
                                <MenuGroup
                                    menuItems={burgerSubMenuItems}
                                    menuId="burgerMenu"
                                    icon="justify burgerMenu"
                                    className={className}
                                />
                                {menuItems.map(function(menuItem) {
                                    return (
                                        <MenuGroup
                                            menuItems={menuItem.subMenus}
                                            menuId={menuItem.menuId}
                                            icon={menuItem.icon}
                                            className={className}
                                        />
                                    );
                                })}
                            </ul>
                        )
                        :
                        (
                            <ul className="trq-menu-bar-v2" {...props} />
                        )
                }
            </Media>
        );
        return menu;
    }
}

MenuContainer.propTypes = {
    useBurger: PropTypes.bool,
    className: PropTypes.string
};

MenuContainer.defaultProps = {
    useBurger: true
};

export default MenuContainer;
