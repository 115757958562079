/*
 * Copyright 2018, Torque IT Solutions Ltd
 * www.torque-its.com
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';

/**
 * Col wrapper component.
 */
export default class Col extends PureComponent {
    render() {
        const {
            className,
            xs,
            md,
            ...restOfProps
        } = this.props;

        const xsClassName = xs ? `col-xs-${xs}` : null;
        const mdClassName = md ? `col-md-${md}` : null;
        const classNameForWrapper = classnames(className, xsClassName, mdClassName);

        return (
            <div
                {...restOfProps}
                className={classNameForWrapper}
            />
        );
    }
}

Col.propTypes = {
    className: PropTypes.string,
    xs: PropTypes.number,
    md: PropTypes.number
};
