import { AnyAction, createSlice, isFulfilled, Middleware } from '@reduxjs/toolkit';
import { authApi } from '../../api';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { IAccount, IPermissionBean } from '../../types/Authentication.types';
import { t } from '../../assets/i18n/translation';
import { LocalStorageCache } from '../../utils/localStorageCache';
import { LanguageExtraApi } from '../../api/configuration/language';
import { JsLanguageType } from '../../types/CamConfig.types';
import { BrandingApi } from '../../api/branding';

interface IApplicationUserStore {
	account?: IAccount;
	permissions?: IPermissionBean;
	error?: string;
	languages: JsLanguageType[];
	defaultLanguage: JsLanguageType;
	language: JsLanguageType;
}

const languages = window.TORQUE_ITS_CAM_CONFIG.AVAILABLE_LANGUAGES;
const defaultLanguage = languages.find((language) => language.defaultLanguage);

const initialState: IApplicationUserStore = {
	account: LocalStorageCache.getUser(),
	languages,
	defaultLanguage,
	language: defaultLanguage,
};

const applicationUserStore = createSlice({
	name: 'applicationUser',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
			state.account = action.payload;
			state.permissions = action.payload.permissions;
			state.error = undefined;
		});
		builder.addMatcher(authApi.endpoints.login.matchRejected, (state, action) => {
			return { ...initialState, error: action.payload.code === 401 ? t('login.authentication.error') : action.payload.message };
		});

		builder.addMatcher(authApi.endpoints.loginWithToken.matchFulfilled, (state, action) => {
			state.account = action.payload;
			state.permissions = action.payload.permissions;
			state.error = undefined;
		});
		builder.addMatcher(authApi.endpoints.loginWithToken.matchRejected, (state, action) => {
			return { ...initialState, error: action.payload.code === 401 ? t('login.authentication.error') : action.payload.message };
		});

		builder.addMatcher(authApi.endpoints.getPermissions.matchFulfilled, (state, action) => {
			state.permissions = action.payload.permissions;
			state.error = undefined;
		});
		builder.addMatcher(authApi.endpoints.getPermissions.matchRejected, () => initialState);
		builder.addMatcher(LanguageExtraApi.endpoints.getAvailableLanguages.matchFulfilled, (state, action) => {
			window.TORQUE_ITS_CAM_CONFIG.AVAILABLE_LANGUAGES = action.payload;
			LocalStorageCache.setLanguages(action.payload);
			state.languages = action.payload;
			state.defaultLanguage = action.payload.find((language) => language.defaultLanguage);
			state.language = state.defaultLanguage;
		});
	},
});

export const languagesFetcherMiddleware: Middleware = (store) => (next) => (action: AnyAction) => {
	if (isFulfilled(action)) {
		const { endpointName } = action.meta.arg as AnyAction['meta']['arg'] & { endpointName?: string };
		// if language configuration updated we need to refetch available languages
		if (['createEntityForLanguage', 'createVersionForLanguage', 'decideOnVersionForLanguage'].includes(endpointName)) {
			store.dispatch(LanguageExtraApi.endpoints.getAvailableLanguages.initiate(undefined, { forceRefetch: true }) as unknown as AnyAction);
		}
	}
	return next(action);
};

export const APPLICATION_USER_REDUCER_PATH = applicationUserStore.name;
export const useApplicationUserSelector = (): IApplicationUserStore =>
	useSelector((state: RootState) => state[APPLICATION_USER_REDUCER_PATH]);

export const useLanguageSelector = (): JsLanguageType => useSelector((state: RootState) => state[APPLICATION_USER_REDUCER_PATH].language);
export default applicationUserStore.reducer;
