// This middleware allows us to write action creators that return a function
// instead of an action. The thunk can be used to take control over the dispatch
// (delay the dispatch of an action, or dispatch only if a certain condition is
// met.) The inner function receives the store methods dispatch, getState and
// possible extra arguments as parameters.
//
// We are implementing our own thunk middleware in order to support two kind of
// actions: function and object containing a function.
//
// This gives us the possibility to support meta actions: actions as an object
// having a meta field and a function to execute. Those actions may have
// specific handlings somewhere else (e.g. in another middleware).
//
// It's based on the redux-thunk implementation:
// https://github.com/gaearon/redux-thunk/blob/master/src/index.js
function createThunkMiddleware(extraArgument) {
    return store => next => action => {
        const { dispatch, getState } = store;
        if (typeof action === 'function') {
            return action(dispatch, getState, extraArgument);
        }

        const { payload } = action;
        if (payload && payload.callback) {
            if (typeof payload.callback === 'function') {
                return payload.callback(dispatch, getState, extraArgument);
            } else {
                throw new Error('Expected payload callback to be a function.');
            }
        }

        return next(action);
    };
}

const thunk = createThunkMiddleware();
thunk.withExtraArgument = createThunkMiddleware;

export default thunk;
