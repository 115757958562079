import { DecideOnVersionRequest, GenericBaseQuery, IComplianceEntityApi, INestedItemProps } from '../types/Api';
import { IEntityVariant } from '../types/Common.types';
import {
	ComplianceSectionType,
	IComplianceEntityVersion,
	ICreateComplianceEntity,
	ICreateComplianceEntityVersion,
	IUpdateComplianceEntityVersion,
} from '../types/Compliance.types';
import { capitalizeFirstLetter, compareByField } from './common';
import { API_TAGS, camApi } from '../api';
import { generatePath } from 'react-router-dom';
import { pushSuccess } from '../components/NotificationsHandler';
import { t } from '../assets/i18n/translation';
import { MutationExtraOptions } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export const handleOnCacheEntryAdded =
	<T>(
		displayName: string,
		messageTemplate = 'configuration.system.template.entity.save.message.success'
	): MutationExtraOptions<(typeof API_TAGS)[number], unknown, T, GenericBaseQuery>['onCacheEntryAdded'] =>
	async (arg, api) => {
		try {
			await api.cacheDataLoaded;
			// timeout is set in order to wait until notification containers change
			// For example when Mutation Page opened it has own notification container
			// once save action is successful Mutation Page will be closed along with success message,
			// so we need to wait some time until Mutation Page is redirected to List Page and notification container is changed
			setTimeout(() => api.dispatch(pushSuccess({ id: displayName, message: t(messageTemplate, displayName) })), 100);
		} catch (err) {
			// error is ignored and has another place to be handled
		}
	};

export const compareComplianceEntityVersions = <TEntity extends IComplianceEntityVersion>(
	a: TEntity,
	b: TEntity,
	comparator: ((a: TEntity, b: TEntity) => number) | { field: keyof TEntity; order?: 'asc' | 'desc' }
): number => {
	if (a.parentId === b.parentId) {
		if (a.approvalStatus === 'PENDING') {
			return 1;
		}
		if (b.approvalStatus === 'PENDING') {
			return -1;
		}
	}
	return typeof comparator === 'function'
		? comparator(a, b)
		: compareByField(
				comparator.field as string,
				a as Record<typeof comparator.field, string>,
				b as Record<typeof comparator.field, string>,
				comparator.order || 'desc'
			);
};

const sortByParentEntity = <TEntity extends IComplianceEntityVersion>(
	entities: TEntity[],
	comparator: ((a: TEntity, b: TEntity) => number) | { field: keyof TEntity; order?: 'asc' | 'desc' }
): TEntity[] => {
	const result: Record<TEntity['parentId'], TEntity[]> = entities.reduce(
		(acc, entity) => {
			if (!acc[entity.parentId]) {
				acc[entity.parentId] = [];
			}
			acc[entity.parentId].push(entity);
			return acc;
		},
		{} as Record<TEntity['parentId'], TEntity[]>
	);

	Object.entries(result).forEach(([key, value]) => {
		result[key] = (value as TEntity[]).sort((a, b) => compareComplianceEntityVersions(a, b, comparator));
	});

	return (Object.values(result) as TEntity[][])
		.sort((e1, e2) => {
			const approvedVersion1 = e1[0];
			const approvedVersion2 = e2[0];

			if (e1.length === 1 && e2.length > 1) {
				return 1;
			}
			if (e1.length > 1 && e2.length === 1) {
				return -1;
			}
			return compareComplianceEntityVersions(approvedVersion1, approvedVersion2, comparator);
		})
		.flatMap((entity) => entity);
};

const parseInvalidationTags = <
	TCreateEntity extends ICreateComplianceEntity,
	TCreateEntityVersion extends ICreateComplianceEntityVersion,
	TUpdateEntityVersion extends IUpdateComplianceEntityVersion,
>(
	result: void,
	error: unknown,
	request: TCreateEntity | TCreateEntityVersion | TUpdateEntityVersion | DecideOnVersionRequest,
	mutationKey: 'createVersion' | 'updateVersion' | 'createEntity' | 'decideOnVersion',
	invalidateTags: ComplianceTagsInvalidationType<TCreateEntity, TCreateEntityVersion, TUpdateEntityVersion>
) => {
	if (typeof invalidateTags === 'function') {
		return invalidateTags(result, error, request);
	}
	if (Array.isArray(invalidateTags)) {
		return invalidateTags;
	}
	if (mutationKey === 'createEntity') {
		return parseInvalidationTags(result, error, request, mutationKey, invalidateTags.createEntity);
	}
	if (mutationKey === 'createVersion') {
		return parseInvalidationTags(result, error, request, mutationKey, invalidateTags.createVersion);
	}
	if (mutationKey === 'updateVersion') {
		return parseInvalidationTags(result, error, request, mutationKey, invalidateTags.updateVersion);
	}
	if (mutationKey === 'decideOnVersion') {
		return parseInvalidationTags(result, error, request, mutationKey, invalidateTags.decideOnVersion);
	}
	return [];
};

export type EntityFormatter<T> = (result: T) => T;
export type RequestFormatter<T, R = unknown> = (request: T) => R;
export type TagsInvalidationType<T> =
	| ((result: void, error: unknown, request: T) => (typeof API_TAGS)[number][])
	| (typeof API_TAGS)[number][];
export type ComplianceTagsInvalidationType<
	TCreateEntity extends ICreateComplianceEntity,
	TCreateEntityVersion extends ICreateComplianceEntityVersion,
	TUpdateEntityVersion extends IUpdateComplianceEntityVersion,
> =
	| TagsInvalidationType<TCreateEntity | TCreateEntityVersion | TUpdateEntityVersion | DecideOnVersionRequest>
	| {
			createVersion?: TagsInvalidationType<TCreateEntityVersion>;
			updateVersion?: TagsInvalidationType<TUpdateEntityVersion>;
			createEntity?: TagsInvalidationType<TCreateEntity>;
			decideOnVersion?: TagsInvalidationType<DecideOnVersionRequest>;
	  };

export interface IComplianceEntityApiCreatorProps<
	TEntity extends IComplianceEntityVersion,
	TCreateEntity extends ICreateComplianceEntity,
	TCreateEntityVersion extends ICreateComplianceEntityVersion,
	TUpdateEntityVersion extends IUpdateComplianceEntityVersion,
> {
	entityName: string;
	displayName: string;
	pathName: string;
	cacheKey: (typeof API_TAGS)[number];
	cacheTTL?: {
		getAllActiveVersions?: number;
		getAllActiveAndPendingVersions?: number;
		getActiveVersion?: number;
		getVersion?: number;
	};
	hasVersioning?: boolean;
	section?: ComplianceSectionType;
	nestedItems?: INestedItemProps[];

	comparator: ((a: TEntity, b: TEntity) => number) | { field: keyof TEntity; order?: 'asc' | 'desc' };
	formatter?: {
		request?: {
			createVersion?: RequestFormatter<TCreateEntityVersion>;
			updateVersion?: RequestFormatter<TUpdateEntityVersion>;
			createEntity?: RequestFormatter<TCreateEntity>;
			decideOnVersion?: RequestFormatter<DecideOnVersionRequest, DecideOnVersionRequest>;
		};
		response?: {
			getAllActiveVersions?: EntityFormatter<TEntity[]>;
			getAllActiveAndPendingVersions?: EntityFormatter<TEntity[]>;
			getActiveVersion?: EntityFormatter<TEntity>;
			getVersion?: EntityFormatter<TEntity>;
		};
	};
	invalidateTags?: ComplianceTagsInvalidationType<TCreateEntity, TCreateEntityVersion, TUpdateEntityVersion>;
}
// generates RTK Query endpoints for the following entity
// Provides get all active and get all active and pending versions calls
// Provides get active and get version calls
// Provides create new entity, create version, update version calls
// Has sorting behaviour
// Has transformation for request and response
// Invalidates the tags or provides new tags in cache
export const createComplianceEntityApi = <
	TEntity extends IComplianceEntityVersion,
	TCreateEntity extends ICreateComplianceEntity,
	TCreateEntityVersion extends ICreateComplianceEntityVersion,
	TUpdateEntityVersion extends IUpdateComplianceEntityVersion,
	TVariantEntity extends IEntityVariant = IEntityVariant,
>({
	entityName,
	displayName,
	pathName,
	cacheKey,
	cacheTTL,
	comparator,
	formatter,
	section,

	hasVersioning = true,
	nestedItems = [],
	invalidateTags,
}: IComplianceEntityApiCreatorProps<TEntity, TCreateEntity, TCreateEntityVersion, TUpdateEntityVersion>): IComplianceEntityApi<
	TEntity,
	TCreateEntity,
	TCreateEntityVersion,
	TUpdateEntityVersion,
	TVariantEntity
> => {
	const getAllActiveVersions: string = `getAllActiveVersionsFor${capitalizeFirstLetter(entityName)}` as const;
	const getAllActiveAndPendingVersions: string = `getAllActiveAndPendingVersionsFor${capitalizeFirstLetter(entityName)}` as const;
	const getActiveVersion: string = `getActiveVersionFor${capitalizeFirstLetter(entityName)}` as const;
	const getVersion: string = `getVersionFor${capitalizeFirstLetter(entityName)}` as const;
	const createVersion: string = `createVersionFor${capitalizeFirstLetter(entityName)}` as const;
	const updateVersion: string = `updateVersionFor${capitalizeFirstLetter(entityName)}` as const;
	const createEntity: string = `createEntityFor${capitalizeFirstLetter(entityName)}` as const;
	const decideOnVersion: string = `decideOnVersionFor${capitalizeFirstLetter(entityName)}` as const;
	const getVariant: string = `getVariantFor${capitalizeFirstLetter(entityName)}` as const;

	const complianceEntityApi = camApi.injectEndpoints({
		endpoints: (build) => ({
			[getAllActiveVersions]: build.query<TEntity[], { activeOnly: boolean } | undefined>({
				providesTags: (result) => (result ? [...result.map(({ versionId }) => ({ type: cacheKey, id: versionId })), cacheKey] : [cacheKey]),
				query: () => ({ url: `api/${pathName}`, method: 'get' }),
				keepUnusedDataFor: cacheTTL?.getAllActiveVersions,
				transformResponse: (result, meta, request = { activeOnly: true }): TEntity[] => {
					let entities = 'items' in result ? (result.items as TEntity[]) : (result as unknown as TEntity[]);
					if (formatter?.response?.getAllActiveVersions) {
						entities = formatter.response.getAllActiveVersions(entities);
					}
					return [...entities]
						.filter((entity) => (request.activeOnly ? entity.active ?? true : true))
						.sort((a, b) =>
							typeof comparator === 'function'
								? comparator(a, b)
								: compareByField(
										comparator.field as string,
										a as unknown as { [x: string]: string },
										b as unknown as { [x: string]: string },
										comparator.order || 'desc'
									)
						);
				},
			}),

			[getAllActiveAndPendingVersions]: build.query<TEntity[], void>({
				providesTags: (result) => (result ? [...result.map(({ versionId }) => ({ type: cacheKey, id: versionId })), cacheKey] : [cacheKey]),
				query: () => ({ url: `api/${pathName}/versions`, method: 'get' }),
				keepUnusedDataFor: cacheTTL?.getAllActiveAndPendingVersions,
				transformResponse: (result): TEntity[] => {
					let entities = 'items' in result ? (result.items as TEntity[]) : (result as unknown as TEntity[]);
					if (formatter?.response?.getAllActiveAndPendingVersions) {
						entities = formatter.response.getAllActiveAndPendingVersions(entities);
					}
					return sortByParentEntity(entities, comparator);
				},
			}),
			[getActiveVersion]: build.query<TEntity, TEntity['parentId']>({
				providesTags: (result, error, id) => [{ type: cacheKey, id }],
				query: (id) => ({
					url: generatePath(`api/${pathName}/:id`, { id }),
					method: 'get',
				}),
				keepUnusedDataFor: cacheTTL?.getActiveVersion,
				transformResponse: (result): TEntity => {
					if (formatter?.response?.getActiveVersion) {
						return formatter.response.getActiveVersion(result as unknown as TEntity);
					}
					return result as unknown as TEntity;
				},
			}),
			[getVersion]: build.query<TEntity, { parentId: TEntity['parentId']; versionId: TEntity['versionId'] }>({
				query: ({ parentId, versionId }) => ({
					url: generatePath(`api/${pathName}/:parentId/versions/:versionId`, { versionId, parentId }),
					method: 'get',
				}),
				keepUnusedDataFor: cacheTTL?.getVersion,
				providesTags: (result, error, { versionId }) => [{ type: cacheKey, id: versionId }],
				transformResponse: (result): TEntity => {
					if (formatter?.response?.getVersion) {
						return formatter.response.getVersion(result as unknown as TEntity);
					}
					return result as unknown as TEntity;
				},
			}),
			[createVersion]: build.mutation<void, TCreateEntityVersion>({
				invalidatesTags: (result, error, request) => {
					const keys = [cacheKey];
					if (invalidateTags) {
						keys.push(...parseInvalidationTags(result, error, request, 'createVersion', invalidateTags));
					}
					return keys;
				},
				query: (request) => ({
					url: generatePath(`api/${pathName}/:parentId`, { parentId: request.parentId }),
					method: 'put',
					data: formatter?.request?.createVersion ? formatter.request.createVersion(request) : request,
				}),
				onCacheEntryAdded: handleOnCacheEntryAdded(displayName),
			}),
			[updateVersion]: build.mutation<void, TUpdateEntityVersion>({
				query: (request) => ({
					url: generatePath(`api/${pathName}/:parentId/versions/:versionId`, {
						versionId: request.versionId,
						parentId: request.parentId,
					}),
					method: 'post',
					data: formatter?.request?.updateVersion ? formatter.request.updateVersion(request) : request,
				}),
				invalidatesTags: (result, error, request) => {
					const keys = ['compliance-configuration-workflow', cacheKey, { type: cacheKey, id: request.versionId }];
					if (invalidateTags) {
						keys.push(...parseInvalidationTags(result, error, request, 'updateVersion', invalidateTags));
					}
					return keys;
				},
				onCacheEntryAdded: handleOnCacheEntryAdded(displayName),
			}),
			[createEntity]: build.mutation<void, TCreateEntity>({
				query: (request) => ({
					url: `api/${pathName}`,
					method: 'put',
					data: formatter?.request?.createEntity ? formatter.request.createEntity(request) : request,
				}),
				invalidatesTags: (result, error, request) => {
					const keys = [cacheKey];
					if (invalidateTags) {
						keys.push(...parseInvalidationTags(result, error, request, 'createEntity', invalidateTags));
					}
					return keys;
				},
				onCacheEntryAdded: handleOnCacheEntryAdded(displayName),
			}),
			[decideOnVersion]: build.mutation<void, DecideOnVersionRequest>({
				query: (request) => {
					const { versionId, parentId, status } = request;
					return {
						url: `api/${pathName}/${parentId}/versions/${versionId}/decide`,
						method: 'post',
						data: { approvalStatus: formatter?.request?.decideOnVersion ? formatter.request.decideOnVersion(request).status : status },
					};
				},
				invalidatesTags: (result, error, request) => {
					const keys = ['compliance-configuration-workflow', cacheKey];
					if (invalidateTags) {
						keys.push(...parseInvalidationTags(result, error, request, 'decideOnVersion', invalidateTags));
					}
					return keys;
				},
			}),
		}),
	});

	if (nestedItems.find((item) => item.name === 'variants')) {
		complianceEntityApi.injectEndpoints({
			endpoints: (build) => ({
				[getVariant]: build.query<
					TVariantEntity,
					{
						versionId: TEntity['versionId'];
						parentId: TEntity['parentId'];
						variantId: TVariantEntity['id'];
					}
				>({
					query: ({ versionId, variantId, parentId }) => {
						return {
							url: `api/${pathName}/${parentId}/versions/${versionId}/variants/${variantId}`,
							method: 'get',
						};
					},
				}),
			}),
		});
	}

	return {
		useGetAllActiveVersions: complianceEntityApi[`use${capitalizeFirstLetter(getAllActiveVersions)}Query`],
		useGetAllActiveAndPendingVersions: hasVersioning
			? complianceEntityApi[`use${capitalizeFirstLetter(getAllActiveAndPendingVersions)}Query`]
			: complianceEntityApi[`use${capitalizeFirstLetter(getAllActiveVersions)}Query`],
		useGetActiveVersion: complianceEntityApi[`use${capitalizeFirstLetter(getActiveVersion)}Query`],
		useGetVersion: complianceEntityApi[`use${capitalizeFirstLetter(getVersion)}Query`],
		useCreateVersion: complianceEntityApi[`use${capitalizeFirstLetter(createVersion)}Mutation`],
		useUpdateVersion: hasVersioning
			? complianceEntityApi[`use${capitalizeFirstLetter(updateVersion)}Mutation`]
			: complianceEntityApi[`use${capitalizeFirstLetter(createVersion)}Mutation`],
		useCreateEntity: complianceEntityApi[`use${capitalizeFirstLetter(createEntity)}Mutation`],
		useDecideOnVersion: complianceEntityApi[`use${capitalizeFirstLetter(decideOnVersion)}Mutation`],
		useGetVariant: complianceEntityApi[`use${capitalizeFirstLetter(getVariant)}Query`],
		useLazyGetVariant: complianceEntityApi[`useLazy${capitalizeFirstLetter(getVariant)}Query`],

		complianceSection: section || 'unknown',
		nestedItems,
		__getNativeApi: (): typeof complianceEntityApi => complianceEntityApi,
	} as IComplianceEntityApi<TEntity, TCreateEntity, TCreateEntityVersion, TUpdateEntityVersion, TVariantEntity>;
};
