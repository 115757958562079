/**
 * ObjectUtils: we copy this code from version 1.0.0-beta.6 of 
 * https://github.com/primefaces/primereact/blob/1.0.0-beta.6/src/components/utils/ObjectUtils.js
 * 
 * Due to some changes from Primeface React that will happen in the future might break our component,
 * so we decided to copy ObjectUtils to this files to prevent issues in the future.
 */
export default class ObjectUtils {

    static equals(obj1, obj2) {
        return this.equalsByValue(obj1, obj2);
    }
    static equalsByValue(obj1, obj2) {
        if (obj1 === null && obj2 === null) {
            return true;
        }
        if (obj1 === null || obj2 === null) {
            return false;
        }
        if (obj1 === obj2) {
            delete obj1._$visited; // eslint-disable-line no-underscore-dangle
            return true;
        }
        if (typeof obj1 === 'object' && typeof obj2 === 'object') {
            obj1._$visited = true; // eslint-disable-line no-underscore-dangle
            for (var p in obj1) {
                if (p === '_$visited') {
                    continue;
                }
                if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) {
                    return false;
                }
                switch (typeof (obj1[p])) {
                    case 'object':
                        if ((obj1[p] && obj1[p]._$visited) || !this.equals(obj1[p], obj2[p])) { // eslint-disable-line no-underscore-dangle
                            return false;
                        }
                        break;
                    case 'function':
                        if (typeof (obj2[p]) === 'undefined' || (p !== 'compare' && obj1[p].toString() !== obj2[p].toString())) {
                            return false;
                        }
                        break;
                    default:
                        if (obj1[p] !== obj2[p]) {
                            return false;
                        }
                        break;
                }
            }

            for (var pp in obj2) {
                if (typeof (obj1[pp]) === 'undefined') {
                    return false;
                }
            }

            delete obj1._$visited; // eslint-disable-line no-underscore-dangle
            return true;
        }

        return false;
    }
    static resolveFieldData(data, field) {
        if (data && field) {
            if (field.indexOf('.') === -1) {
                return data[field];
            }
            else {
                let fields = field.split('.');
                let value = data;
                for (var i = 0, len = fields.length; i < len; ++i) {
                    value = value[fields[i]];
                }
                return value;
            }
        }
        else {
            return null;
        }
    }
    static filter(value, fields, filterValue) {
        var filteredItems = [];

        if (value) {
            for (let item of value) {
                for (let field of fields) {
                    if (this.resolveFieldData(item, field) !== null) {
                        let normalizedStr = String(this.resolveFieldData(item, field)).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''); // Normalize and remove accents
                        let normalizedSearch = filterValue.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                        if (normalizedStr.indexOf(normalizedSearch) > -1) {
                            filteredItems.push(item);
                            break;    
                        }
                    }
                }
            }
        }

        return filteredItems;
    }
}