import { IComputedMetaDataField, IActivationCustomField, IMetadataField } from '../types/MetaData.types';
import { IComputedFieldSelection, IComputedFieldSelectionOption } from '../types/ComputedField.types';
import { IViewFieldEntryEvaluation, IViewFieldEvaluation } from '../types/ContractActivationView.types';
import { t, tOrUndefined } from '../assets/i18n/translation';
import { IReferenceDataExtended } from '../types/ReferenceData';

export const capitalize = (s) => {
	if (typeof s !== 'string') return '';
	return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const buildNameFromCode = (code: string) => code.split(/[_.]/g).map(capitalize).join(' ');

export const buildViewEntryEvaluationName = (entry: IViewFieldEntryEvaluation, withInactiveFlag = false) => {
	if (entry.name) return entry.name;

	let translation: string, isActive: boolean | undefined;
	if ('referenceDataValue' in entry && entry.referenceDataValue) {
		isActive = entry.referenceDataValue.active;
		translation = entry.referenceDataValue.name;
	} else {
		const code = entry.code;
		translation = tOrUndefined(`configuration.metadata.fields.code.enum.${code}`) ?? buildNameFromCode(entry.code);
	}

	if (withInactiveFlag && isActive === false) {
		return t('configuration.template.page.select.option.inactive', translation);
	}
	return translation;
};

export const isComputedMetadataField = (field: unknown): field is IComputedMetaDataField =>
	typeof field === 'object' &&
	'fieldType' in field &&
	field.fieldType === 'COMPUTED' &&
	'computedField' in field &&
	Object.keys(field.computedField).length > 1;

export const isActivationCustomField = (field: unknown): field is IActivationCustomField =>
	typeof field === 'object' &&
	'fieldType' in field &&
	field.fieldType === 'ACTIVATION_CUSTOM' &&
	'activationCustomField' in field &&
	Object.keys(field.activationCustomField).length > 1;

/**
 * The default translation takes the field code, replaces _ and . with an
 * empty space and capitalize the first character.
 * e.g.: ADDRESS.CITY -> Address City
 */
export const defaultCodeTranslation = <T extends IMetadataField | IViewFieldEvaluation>(field: T, withInactiveFlag = false) => {
	let translation: string, isActive: boolean | undefined;
	if (field.fieldType === 'CUSTOM' && 'referenceDataValue' in field && field.referenceDataValue) {
		isActive = field.referenceDataValue.active;
		translation = field.referenceDataValue.name;
	} else if (field.fieldType === 'CUSTOM' && 'entries' in field && field.entries) {
		const firstRefDataEntry = field.entries?.find((entry) => entry.referenceDataValue?.name);
		if (firstRefDataEntry) {
			return buildViewEntryEvaluationName(firstRefDataEntry, withInactiveFlag);
		}
	} else if (field.fieldType === 'ACTIVATION_CUSTOM') {
		isActive = (field as IActivationCustomField).activationCustomField.active;
		translation = (field as IActivationCustomField).activationCustomField.name;
	} else if (isComputedMetadataField(field)) {
		isActive = field.computedField.active;
		translation = field.computedField.name;
	} else {
		isActive = undefined;
		translation = buildNameFromCode(field.code);
	}

	if (withInactiveFlag && isActive === false) {
		return t('configuration.template.page.select.option.inactive', translation);
	}
	return translation;
};

export const buildFieldName = <T extends IMetadataField | IViewFieldEvaluation>(field: T, withInactiveFlag = false) => {
	if (field.name) {
		if (withInactiveFlag && 'active' in field && field.active === false) {
			return t('configuration.template.page.select.option.inactive', field.name);
		}
		return field.name;
	}

	if (field.fieldType === 'CUSTOM') {
		// custom field do not have a translation since they already
		// have a field name
		return defaultCodeTranslation(field, withInactiveFlag);
	}
	if (field.fieldType === 'ACTIVATION_CUSTOM') {
		return (field as IActivationCustomField).activationCustomField.name;
	}
	const code = field.code;
	const i18nName = tOrUndefined(`configuration.metadata.fields.code.enum.${code}`);
	return i18nName ?? defaultCodeTranslation(field, withInactiveFlag);
};

const findCode = (field: IMetadataField) => {
	if (field.fieldType === 'CUSTOM') {
		const { referenceDataValue } = field;
		if ('referenceDataType' in referenceDataValue && referenceDataValue) {
			switch (referenceDataValue.referenceDataType) {
				case 'CUSTOM_FIELD_CUSTOMER_ADDRESS':
					return referenceDataValue.code ? `ADDRESS.CUSTOM.${referenceDataValue.code}` : referenceDataValue.code;
				case 'CUSTOM_FIELD_CUSTOMER_EMPLOYMENT':
					return referenceDataValue.code ? `EMPLOYMENT.CUSTOM.${referenceDataValue.code}` : referenceDataValue.code;
				case 'CUSTOM_FIELD_CUSTOMER_PAYMENT':
					return referenceDataValue.code ? `PAYMENT.CUSTOM.${referenceDataValue.code}` : referenceDataValue.code;
			}
		}
		if ((referenceDataValue as IReferenceDataExtended).referenceDataType === 'ASSET_TAX_TYPE') {
			return referenceDataValue.code;
		}
		return referenceDataValue.code ? `CUSTOM.${referenceDataValue.code}` : referenceDataValue.code;
	}
	if (field.fieldType === 'ACTIVATION_CUSTOM') {
		return (field as IActivationCustomField).activationCustomField.code;
	}
	if (isComputedMetadataField(field)) {
		return field.computedField.code;
	}

	return field.code;
};

export const buildFieldCode = (
	field: IMetadataField | IComputedMetaDataField | IComputedFieldSelection | IComputedFieldSelectionOption
) => {
	const code = findCode(field);
	return code;
	// extract the data from the option value to find the code
	// const extractedValue = field.optionValue ? extractCodeFromOptionValue(field.optionValue) : {};
	// return findCode(extractedValue);
};
