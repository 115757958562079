/**
* Utils to work with the position and size of elements in the DOM tree
*
**/
import { forEach } from 'lodash';

export function getOuterHeight(el, margin) {
    let height = el.offsetHeight;
    if (margin) {
        let style = getComputedStyle(el);
        height += parseFloat(style.marginTop) + parseFloat(style.marginBottom);
    }
    return height;
}

export function getZindex(zindex) {
    zindex = zindex || 1000;
    return zindex++;
}

export function setRelativePosition(element, target) {
    let elementDimensions = element.offsetParent ? { width: element.outerWidth, height: element.outerHeight } : getHiddenElementDimensions(element);
    let targetHeight = getOuterHeight(target, true);
    let targetWidth = target.offsetWidth;
    let targetOffset = target.getBoundingClientRect();
    let viewport = getViewport();
    let top, left;

    if ((targetOffset.top + targetHeight + elementDimensions.height) > viewport.height) {
        top = -1 * (elementDimensions.height);
    }
    else {
        top = targetHeight;
    }

    if ((targetOffset.left + elementDimensions.width) > viewport.width) {
        left = targetWidth - elementDimensions.width;
    }
    else {
        left = 0;
    }

    element.style.top = top + 'px';
    element.style.left = left + 'px';
}

export function getViewport() {
    let win = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0],
        w = win.innerWidth || e.clientWidth || g.clientWidth,
        h = win.innerHeight || e.clientHeight || g.clientHeight;
    return { width: w, height: h };
}

export function getHiddenElementDimensions(element) {
    let dimensions = {};
    element.style.visibility = 'hidden';
    element.style.display = 'block';
    dimensions.width = element.offsetWidth;
    dimensions.height = element.offsetHeight;
    element.style.display = 'none';
    element.style.visibility = 'visible';
    return dimensions;
}

export function hasClass(element, className) {
    if (element.classList) {
        return element.classList.contains(className);
    } else {
        return new RegExp('(^| )' + className + '( |$)', 'gi').test(element.className);
    }
}

/**
 * It will return the first element that can receive focus, unless an specific attribute (eg 'data-focus') is sent.
 * The 'ignoreAttribute' is used to skip the focus (eg 'data-ignore-focus').
 * 
 * @param {*} componentRef 
 * @param {*} attribute 
 * @param {*} ignoreAttribute 
 */
export function getFirstFocusableElement({ componentRef, attribute, ignoreAttribute }) {
    if (componentRef) {
        let foundElements = componentRef.getElementsByTagName('*');
        if (foundElements && foundElements.length > 0) {
            let firstFocusableElement;
            let focusableByAttribute;
            forEach(foundElements, el => {
                if (ignoreAttribute && el.getAttribute(ignoreAttribute)) {
                    return true;
                }
                if ((el.tagName.toUpperCase() === 'INPUT' && ((el.type !== 'hidden' && !el.readOnly && !el.classList.contains('ui-dropdown-filter')) || el.classList.contains('trq-dropdown-internal-component')))
                    || (el.tagName.toUpperCase() === 'SELECT' && el.type !== 'hidden' && !el.readOnly && !el.classList.contains('trq-dropdown-internal-component'))
                    || (el.tagName.toUpperCase() === 'TEXTAREA' && !el.readOnly)
                    || (el.tagName.toUpperCase() === 'BUTTON' && !el.readOnly)
                    || el.tagName.toUpperCase() === 'A') {
                    if (!firstFocusableElement) {
                        firstFocusableElement = el;
                        if (!attribute) {
                            return false;
                        }
                    }
                    const attributeValue = el.getAttribute(attribute);
                    if (attributeValue && attributeValue !== 'false') {
                        focusableByAttribute = el;
                        return false;
                    }
                }
                return true;
            });
            return focusableByAttribute ? focusableByAttribute : firstFocusableElement;
        }
    }
    return null;
}