import { IComputedFieldSelectionOption, IComputedFieldConfigVersion, IComputedFieldSelection } from '../../types/ComputedField.types';
import { buildFieldCode, buildFieldName } from '../../utils/fieldUtils';
import { sortByField } from '../../utils/common';

const toComputedFieldSelection = (data: IComputedFieldSelection): IComputedFieldSelection => {
	return {
		...data,
		name: buildFieldName(data, true),
		code: buildFieldCode(data),
		active: 'referenceDataValue' in data ? data.referenceDataValue.active : undefined,
	};
};

const toComputedFieldConfigVersion = (data: IComputedFieldConfigVersion): IComputedFieldConfigVersion => {
	return {
		...data,
		selections: data.selections?.map(toComputedFieldSelection).sort(sortByField('position', 'asc')),
	};
};

const toComputedFieldSelectionOption = (data: IComputedFieldSelectionOption): IComputedFieldSelectionOption => {
	return {
		...data,
		name: buildFieldName(data, true),
		code: buildFieldCode(data),
		active: 'referenceDataValue' in data ? data.referenceDataValue?.active : undefined,
	};
};

export const ComputedFieldMapper = {
	toComputedFieldSelection,
	toComputedFieldConfigVersion,
	toComputedFieldSelectionOption,
};
