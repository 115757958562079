import { useApplicationUserSelector } from './user.store';
import { useLogin } from '../../api';
import { hasAuthorityFactory } from './hasAuthorityFactory';
import { useMemo } from 'react';
import { IComplianceUser } from '../../types/Compliance.types';
import { useComplianceSections } from '../Compliance/useComplianceSections';

export const useAuth = () => {
	const { account, permissions, error } = useApplicationUserSelector();
	const { login, logout, isLoading } = useLogin();
	const { levelBySection, areSectionsAvailable, sections } = useComplianceSections();

	const { hasAuthority, hasSystemAuthority, hasContractActivationAuthority, hasComplianceWorkflowAuthority } = useMemo(
		() => hasAuthorityFactory(permissions, { levelBySection, areSectionsAvailable }),
		[permissions, levelBySection]
	);

	return {
		isLogging: isLoading,
		arePermissionsLoaded: Boolean(account && permissions && (areSectionsAvailable ? sections.length : true)),
		isError: !error,

		login,
		logout,
		error,
		account,
		permissions,
		complianceLevelBySection: levelBySection,

		hasAuthority,
		hasSystemAuthority,
		hasContractActivationAuthority,
		hasComplianceWorkflowAuthority,
		// method to check if the user has created the entity
		hasBeenCreatedByMe: ({ createdBy }: { createdBy: IComplianceUser }) => account && createdBy && account.id === createdBy.id,
	};
};
