import { createApi } from '@reduxjs/toolkit/query/react';
import { IContract } from '../types/Contract.types';
import camConfig from '../config/camConfig';
import { axiosBaseQuery, createAxiosClientApi } from '../utils/axiosUtils';

export const axiosApi = createAxiosClientApi({
	baseUrl: camConfig().getApiUrlRoot(),
});

export const API_TAGS: readonly string[] = [
	'available-languages',
	'compliance-configuration-workflow',
	'contract-activation-workflow',
	'contract-activation-recent',
	'contract-activation-actions',
	'contract-activation-statuses',
	'configuration-actions-layout-view',
	'contract-activation-statuses-icons',
	'contract-activation',
	'contract-activation-activity',
	'contract-activation-activity-types',
	'configuration-compliance',
	'configuration-checkpoint',
	'configuration-system-access-levels',
	'configuration-system-access-level',
	'configuration-system-access-level-schedules',
	'configuration-system-access-level-schedule',
	'configuration-system-generic-decision-tables',
	'configuration-system-roles',
	'configuration-system-roles-assignments',
	'configuration-system-permissions',
	'configuration-system-secrets',
	'configuration-system-secret',
	'configuration-system-plugins',
	'configuration-system-plugin',
	'configuration-action-plugins',
	'configuration-tags',
	'configuration-user',
	'configuration-user-groups',
	'configuration-action-plugins',
	'configuration-activation-progress-plugins',
	'configuration-document-storage-plugins',
	'configuration-contract-activation-view',
	'configuration-checklist-schedules',
	'configuration-reference-data',
	'user-permissions',
	'reference-data',
	'configuration-users',
	'system-configurations',
	'configuration-computed-fields',
	'configuration-condition-type',
	'customer-data-field-filters',
	'customer-data-field-root',
	'customer-section-group',
	'configuration-asset-section-view',
	'asset-section-fields',
	'asset-extra-fields',
	'configuration-application-details-section-view',
	'application-details-section-fields',
	'system-branding',
] as const;

export const camApi = createApi({
	reducerPath: 'camApi',
	tagTypes: API_TAGS,
	baseQuery: axiosBaseQuery({
		axiosClientApi: axiosApi,
	}),
	endpoints: (build) => ({
		version: build.query<IContract[], void>({
			query: () => ({ url: 'api/version', method: 'get' }),
		}),
	}),
});
