import React, { Children } from 'react';
import classnames from 'classnames';
import isFunction from 'lodash/fp/isFunction';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import find from 'lodash/fp/find';
import isString from 'lodash/fp/isString';
import isNumber from 'lodash/fp/isNumber';
import times from 'lodash/times';
import constant from 'lodash/constant';
import { caretRenderCreator } from './sorting';
import { getTitleString, sortEvaluated } from '../util';

// A default width will be used for sticky columns if stickyColumnWidths is not defined
const DEFAULT_STICKY_COLUMN_WIDTH = 130;

/**
 * When Table columns are defined via TableColumn as children of the first - we should map these components
 * to plain objects which internal BootstrapTable accepts. This complication is due to the past approach of
 * BootstrapTable.
 */
export function mapChildrenToColumns(tableChildren = [], stickyColumnCount = 1, stickyColumnWidths = []) {
    const columnWidths = stickyColumnCount > 0 && stickyColumnWidths.length === 0 ? times(stickyColumnCount, constant(DEFAULT_STICKY_COLUMN_WIDTH)) : stickyColumnWidths;
    let stickyColumnThreshold = stickyColumnCount;
    let firstStickyIndex = -1;
    let stickyLeft = 0;
    return Children.map(tableChildren, (child, index) => {
        const { dataField, children, width, hidden, dataAlign, headerAlign, headerText, headerTitle, title,
            columnClassName, wrapContent, textOverflow, multiLine, dataSort, formatExtraData, verticalAlign, headerClasses, headerStyle } = child.props;
        // Here, both children and headerText prop may hold column header text
        // First it was children prop only. With CPMS-318, we introduce a new prop
        // headerText, you may still provide header content as a child element when defining column
        const headerContent = headerText || children;
        const { renderValue, renderLabel, renderTitle, sortFunc } = extractRenderMethods(child);

        // tooltip, if implicit false was given - we should not have any fallback logic.
        // if true should be passed along at the end in case renderTitle, renderValue, to still take value from cell
        const customTitle = title === false ? title : (renderTitle || title);
        if (child.props.hidden) {
            stickyColumnThreshold += 1;
        }
        // First column is usually hidden
        const isSticky = !child.props.hidden && index < stickyColumnThreshold;
        if (firstStickyIndex < 0 && isSticky) {
            firstStickyIndex = index;
        }
        let stickyStyle = {};
        if (isSticky) {
            stickyLeft += index > firstStickyIndex ? columnWidths[index - firstStickyIndex - 1] : 0;
            stickyStyle = {
                left: stickyLeft,
                minWidth: columnWidths[index - firstStickyIndex],
                zIndex: (index + 2)
            };
        }
        // BootstrapTable compatible columns object should be returned
        return {
            // generic column props
            hidden,
            dataField,
            // rendered cell, it's formatters and styles
            formatter: renderLabel,
            formatExtraData, 
            text: <span className="header-content">{headerContent}</span>,
            title: customTitle,
            classes: classnames('cell-content', columnClassName, {
                wrap: wrapContent,
                overflow: textOverflow,
                multiLine: multiLine,
                'top-align': verticalAlign === 'top',
                'middle-align': verticalAlign === 'middle',
                'bottom-align': verticalAlign === 'bottom',
                'sticky-column': isSticky
            }),
            style: stickyStyle,
            align: dataAlign,
            // rendered header, it's formatters and styles
            headerTitle: () => headerTitle || headerContent,
            headerClasses: classnames(headerClasses || 'header-text-v3', {
                'sticky-column': isSticky
            }),
            headerAlign,
            headerStyle: headerStyle ? {
                ...headerStyle, ...stickyStyle
            } : {
                // width of the column is only affected when being set in header styles
                width,
                ...stickyStyle
            },
            // internal value for table
            filterValue: renderValue,
            // sorting related
            sort: dataSort,
            sortFunc,
            sortCaret: caretRenderCreator,
        };
    });
}

export const defaultRenderValue = (value) => value;
const getRenderTitle = (renderTitle) => (cell, row, ...others) => {
    const title = getTitleString(renderTitle(cell, row, ...others));
    if (isNumber(title) || isString(title)) {
        return title;
    }
    const defaultTitle = defaultRenderValue(cell);
    return isNumber(defaultTitle) || isString(defaultTitle) ? defaultTitle : '';
};

/**
 * Methods like renderValue, renderLabel and others, can be defined in multiple ways
 * - as prop on component
 * - as instance method on TableColumn component thereby sharing logic across the tables
 * - or to have some fallback logic, for example when label and title by default should use renderValue method when such defined
 * 
 * @param {*} child 
 */
export function extractRenderMethods(child) {
    const { columnClassName, wrapContent, textOverflow, multiLine, verticalAlign, renderValue, renderLabel, renderTitle, sortFunc, filterValue, shouldBeHighlighted } = child.props;
    // column instance with it's functions
    const instance = child.type.prototype;

    const renderValueInstance = instance.renderValue ? instance.renderValue.bind(child) : undefined;
    const renderLabelInstance = instance.renderLabel ? instance.renderLabel.bind(child) : undefined;
    const renderTitleInstance = instance.renderTitle ? instance.renderTitle.bind(child) : undefined;
    const sortFuncFromInstance = instance.sortFunc ? instance.sortFunc.bind(child) : undefined;
    // custom
    const customRenderValue = renderValue || renderValueInstance || defaultRenderValue;
    const customRenderLabel = renderLabel || renderLabelInstance || customRenderValue;
    const customRenderTitle = getRenderTitle(renderTitle || renderTitleInstance || customRenderValue);
    const customSortEvaluated = (a, b, order, dataField, rowA, rowB) => {
        return sortEvaluated(customRenderValue, defaultRenderValue, dataField, rowA, rowB, order, a, b);
    };
    const customSortFunc = sortFunc || sortFuncFromInstance || customSortEvaluated;
    const customRenderValueWithFilterValue = renderValueInstance || filterValue || renderValue || defaultRenderValue;

    // this will take label renderer and title render in order to format
    // string or HTML that should be displayed in table
    const renderLabelWithTitle = (...args) => {
        let title = customRenderTitle(...args);
        // ignore it if result is not string or number
        if (!isString(title) && !isNumber(title)) {
            title = null;
        }
        const highlighted = isFunction(shouldBeHighlighted) ? !!shouldBeHighlighted(...args) : false;
        const className = classnames('cell-content', columnClassName, {
            highlighted,
            wrap: wrapContent,
            overflow: textOverflow,
            multiLine: multiLine,
            'top-align': verticalAlign === 'top',
            'middle-align': verticalAlign === 'middle',
            'bottom-align': verticalAlign === 'bottom',
        });
        return (
            <span
                className={className}
                title={title}
                children={customRenderLabel(...args)}
            />
        );
    };

    /**
     * The functions responsible for the rendering need to handle the case that the given row is not defined.
     * This happens for example when a search is made on a subsequent table page: some of the rendered rows
     * should not be shown any more because they are filtered out by the search: the row parameter is then null.
     * In this case, no need to call the custom render method, just return null.
     */
    return {
        renderValue: (cell, row, ...others) => row ? customRenderValueWithFilterValue(cell, row, ...others) : null,
        renderLabel: (cell, row, ...others) => row ? renderLabelWithTitle(cell, row, ...others) : null,
        renderTitle: (cell, row, ...others) => row ? customRenderTitle(cell, row, ...others) : null,
        sortFunc: customSortFunc
    };
}

/**
 * Get key field.
 */
export function getKeyField(tableChildren = []) {
    const keyChildren = extractKeyChildren(tableChildren);
    return get('dataField')(keyChildren);
}

/**
 * Extract the children having a key property
 * @param {*} tableChildren 
 */
function extractKeyChildren(tableChildren) {
    return flow(
        Children.toArray,
        map(get('props')),
        find(['isKey', true])
    )(tableChildren);
}

/**
 * Check whether the given table has a 'key' column
 * @param {*} tableChildren 
 */
export function hasAKeyField(tableChildren = []) {
    const keyChildren = extractKeyChildren(tableChildren);
    return !!keyChildren;
}
