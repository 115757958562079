import React from 'react';
import { useAuth } from '../../domains/User/useAuth';
import { Navigate } from 'react-router-dom';
import { ROUTE_PATH } from '../../routes';

export const PreloadingPage = () => {
	const { account } = useAuth();

	if (!account) {
		return <Navigate to={ROUTE_PATH.LOGIN} />;
	}
	return <div />;
};
