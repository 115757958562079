import { UserContractActivationPermission, UserSystemPermission } from '../types/Authentication.types';
import { LocalStorageCache } from '../utils/localStorageCache';

export const AUTHORITIES = {
	ADMIN: 'ROLE_ADMIN',
	USER: 'ROLE_USER',
};

export const messages = {
	DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_NAME = 'CAM';

export const DISPLAY_DATE_TIME_FORMAT = 'dd/MM/yyyy hh:mm a';
export const INTERNAL_INPUT_DATE_FORMAT = 'yyyy-MM-dd';
export const INPUT_DATE_FORMAT = 'dd/MM/yyyy';

export const SYSTEM_PERMISSION: Record<UserSystemPermission, UserSystemPermission> = {
	ACTIVATION_CUSTOM_FIELD_CONFIGURATION: 'ACTIVATION_CUSTOM_FIELD_CONFIGURATION',
	CONTRACT_ACTIVATION_PROCESS_CONFIGURATION: 'CONTRACT_ACTIVATION_PROCESS_CONFIGURATION',
	CONTRACT_ACTIVATION_VIEW_CONFIGURATION: 'CONTRACT_ACTIVATION_VIEW_CONFIGURATION',
	CONTRACT_ACTIVATION_POLICY_RULE_CONFIGURATION: 'CONTRACT_ACTIVATION_POLICY_RULE_CONFIGURATION',
	CHECKLIST_CONFIGURATION: 'CHECKLIST_CONFIGURATION',
	COMPLIANCE_CONFIGURATION: 'COMPLIANCE_CONFIGURATION',
	SYSTEM_CONFIGURATION: 'SYSTEM_CONFIGURATION',
	ROLE_CONFIGURATION: 'ROLE_CONFIGURATION',
	ROLE_ASSIGNMENT_CONFIGURATION: 'ROLE_ASSIGNMENT_CONFIGURATION',
	USER_CONFIGURATION: 'USER_CONFIGURATION',
	REFERENCE_DATA_CONFIGURATION: 'REFERENCE_DATA_CONFIGURATION',
	CONTRACT_ACCESS_LEVEL_CONFIGURATION: 'CONTRACT_ACCESS_LEVEL_CONFIGURATION',
	GENERIC_DECISION_TABLE_CONFIGURATION: 'GENERIC_DECISION_TABLE_CONFIGURATION',
	COMPLIANCE_WORKFLOW_REVIEWER: 'COMPLIANCE_WORKFLOW_REVIEWER',
	TAG_CONFIGURATION: 'TAG_CONFIGURATION',
	CONTRACT_ACTIVATION_UNLOCK: 'CONTRACT_ACTIVATION_UNLOCK',
	CONDITION_TYPE_CONFIGURATION: 'CONDITION_TYPE_CONFIGURATION',
};

export const CONTRACT_ACTIVATION_PERMISSION: Record<UserContractActivationPermission, UserContractActivationPermission> = {
	VIEW_CONTRACT_ACTIVATION: 'VIEW_CONTRACT_ACTIVATION',
	EDIT_CONTRACT_ACTIVATION: 'EDIT_CONTRACT_ACTIVATION',
	CANCEL_CONTRACT_ACTIVATION: 'CANCEL_CONTRACT_ACTIVATION',
	COMMENT: 'COMMENT',
};
