import { HexColour } from '../types/Common.types';

export const isHexColour = (v: string): v is HexColour => v && v.startsWith('#') && (v.length === 7 || v.length === 4);

export const getHexColour = (colour: string): HexColour => (isHexColour(colour) ? colour : `#${colour as string}`);

export const removeHexSign = (hexColour: string) => hexColour?.replace(`#`, ``);

export const newShade = (hexColor: HexColour, magnitude = 0) => {
	hexColor = hexColor.replace(`#`, ``);
	if (hexColor.length === 6) {
		const decimalColor = parseInt(hexColor, 16);
		let r = (decimalColor >> 16) + magnitude;
		if (r > 255) r = 255;
		if (r < 0) r = 0;

		let g = (decimalColor & 0x0000ff) + magnitude;
		if (g > 255) g = 255;
		if (g < 0) g = 0;

		let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
		if (b > 255) b = 255;
		if (b < 0) b = 0;

		return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
	}

	return hexColor;
};
