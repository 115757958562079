/*
 * Copyright 2017, Torque IT Solutions Ltd
 * www.torque-its.com
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';

/**
 * Form wrapper component. Adds only form block of css class.
 */
export default class FormHorizontal extends PureComponent {
    render() {
        const {
            className,
            ...restOfProps
        } = this.props;

        const classNameWithMandatory = classnames('form-horizontal', className);

        return (
            <form
                {...restOfProps}
                className={classNameWithMandatory}
            />
        );
    }
}

FormHorizontal.propTypes = {
    className: PropTypes.string
};
