import TableColumn from './TableColumn';

export default class ActionColumn extends TableColumn {

}

ActionColumn.defaultProps = {
    ...TableColumn.defaultProps,
    dataField: '',
    title: false,
    isDummyField: true,
    dataAlign: 'right',
    headerAlign: 'right',
    searchable: false,
    wrapContent: false,
    textOverflow: false,
    dataSort: false,
    columnSelectorDisplay: false,
    columnSelectorFixed: true,
    columnSelectorDefaultDisplay: false
};
