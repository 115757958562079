import PropTypes from 'prop-types';
import React, { Component } from 'react';
import StickyContainer from './component/StickyContainer';

class StickyComponentContainer extends Component {
    constructor(props) {
        super(props);
        this.node = null;
    }

    getChildContext() {
        return {
            getContainer: () => this.node
        };
    }

    setNodeRef = (node) => {
        this.node = node;
    }

    render() {
        let style = this.props.style;
        if (this.context.isInsideDialog) {
            // apply auto overflowY as the sticky component listens to the scroll event of its container
            style = Object.assign({}, style, {overflowY: 'auto'});
        }
        return (
            <StickyContainer style={style}
                className={this.props.className}
                ref={this.setNodeRef}
            >
                {this.props.children}
            </StickyContainer>
        );
    }
}

StickyComponentContainer.propTypes = {
    className: PropTypes.string,
    isInsideDialog: PropTypes.bool,
    getParent: PropTypes.func,
    style: PropTypes.shape(),
    children: PropTypes.node.isRequired
};

StickyComponentContainer.contextTypes = {
    isInsideDialog: PropTypes.bool
};

StickyComponentContainer.childContextTypes = {
    getContainer: PropTypes.func
};

export default StickyComponentContainer;
