/*
 * Copyright 2021, Torque IT Solutions Ltd
 * www.torque-its.com
 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { find, forEach } from 'lodash';
import classNames from 'classnames';

import { DialogBody } from './DialogBody';
import { DialogFooter } from './DialogFooter';
import { domUtils } from '@torque-common-ui/utils';

/**
 * Dialog component based on React Bootstrap Modal.
 */
export default class Dialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            childrenRefs: [],
            firstElementFocused: false,
        };
    }

    getChildContext() {
        return {
            id: this.props.id,
            isInsideDialog: true,
            isLoading: this.props.isLoading,
            contentHeight: this.props.contentHeight,
            setContentHeight: this.props.setContentHeight,
            clearContentHeight: this.props.clearContentHeight,
            firstElementFocus: this.props.firstElementFocus,
            handleRef: this.handleRef,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.onEnter);
    }

    componentDidUpdate(prevProps) {
        this.defineDefaultActions(prevProps);
        if (
            prevProps.dialogDisplayed !== this.props.dialogDisplayed &&
            !this.props.dialogDisplayed
        ) {
            document.body.classList.remove('modal-regular');
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onEnter);
    }

    onKeyDown = (button) => (e) => {
        // When 'enter' key is pressed
        if (button && e && e.keyCode === 13) {
            e.preventDefault();
            button.focus();
            button.click();
        }
    };

    defineDefaultActions = (prevProps) => {
        if (!this.props.dialogDisplayed && prevProps.dialogDisplayed) {
            this.setState({
                firstElementFocused: false,
            });
        }
        if (
            this.props.dialogDisplayed &&
            this.state.childrenRefs &&
            this.state.childrenRefs.length > 0
        ) {
            let defaultButton;
            let inputFields = [];
            let firstElementToFocus;
            forEach(this.state.childrenRefs, (childRef) => {
                if (childRef.props && childRef.props.children) {
                    const child = ReactDOM.findDOMNode(childRef);
                    let childElements = child.getElementsByTagName('*');
                    if (childElements && childElements.length > 0) {
                        forEach(childElements, (el) => {
                            // In order to add the default action (Enter key press), the element must be an input considering exclusions related to dropdown component
                            if (
                                el.tagName.toUpperCase() === 'INPUT' &&
                                ((el.type !== 'hidden' &&
                                    !el.readOnly &&
                                    !el.classList.contains('ui-dropdown-filter')) ||
                                    el.classList.contains('trq-dropdown-internal-component'))
                            ) {
                                inputFields.push(el);
                            } else if (
                                !defaultButton &&
                                el.tagName.toUpperCase() === 'BUTTON' &&
                                el.attributes &&
                                el.getAttribute('data-default')
                            ) {
                                defaultButton = el;
                            }
                        });
                    }
                    // Get first focusable element after loading is over
                    if (
                        this.props.firstElementFocus &&
                        !firstElementToFocus &&
                        !this.props.isLoading &&
                        (prevProps.isLoading || !this.state.firstElementFocused)
                    ) {
                        firstElementToFocus = domUtils.getFirstFocusableElement({
                            componentRef: child,
                            attribute: 'data-focus',
                            ignoreAttribute: 'data-ignore-focus',
                        });
                        if (firstElementToFocus) {
                            this.setState({
                                firstElementFocused: true,
                            });
                        }
                    }
                }
            });
            if (defaultButton) {
                if (
                    !this.props.ignoreDefaultButtonStyle &&
                    !defaultButton.classList.contains('default-button')
                ) {
                    defaultButton.classList.add('default-button');
                }
                forEach(inputFields, (inputField) => {
                    // Avoid listener to be added multiple times
                    if (
                        !find(inputField.classList, (clazz) => {
                            return clazz === 'onKeyDownSet';
                        })
                    ) {
                        inputField.classList.add('onKeyDownSet');
                        inputField.addEventListener('keydown', this.onKeyDown(defaultButton));
                    }
                });
            }
            if (firstElementToFocus) {
                firstElementToFocus.focus();
            }
        }
    };

    handleRef = (ref) => {
        let childrenRefs = [];
        if (ref) {
            childrenRefs = this.state.childrenRefs;
            childrenRefs.push(ref);
        }
        this.setState({
            childrenRefs,
        });
    };

    storeModalReference = (modal) => {
        this.modal = modal;
    };

    onEnter = () => {
        if (!this.props.ignoreContainer) {
            document.body.classList.remove('modal-regular');
            const hasScrollbars = document.body.scrollHeight > window.innerHeight;
            if (hasScrollbars) {
                document.body.classList.add('modal-in-scrollbar');
            } else {
                document.body.classList.remove('modal-in-scrollbar');
            }
            if (this.props.container) {
                const modal = ReactDOM.findDOMNode(this.modal);
                const rect = this.props.container.getBoundingClientRect();
                if (modal) {
                    modal.style.position = 'absolute';
                    modal.style.top = rect.top + window.scrollY;
                    modal.style.left = rect.left + window.scrollX;
                    modal.style.width = this.props.container.offsetWidth;
                    modal.style.height = this.props.container.offsetHeight;

                    const modalBackdrop = modal.getElementsByClassName('modal-backdrop');
                    if (modalBackdrop) {
                        modalBackdrop[0].style.position = 'absolute';
                    }

                    const fadeModal = modal.getElementsByClassName('fade modal');
                    if (fadeModal) {
                        fadeModal[0].style.position = 'absolute';
                    }
                }
            } else {
                document.body.classList.add('modal-regular');
            }
        }
    };

    render() {
        const {
            children,
            className,
            dialogDisplayed,
            headerDisplay,
            id,
            showValidatedInHeader,
            title,
            validatedText,
        } = this.props;

        if (!dialogDisplayed) {
            return null;
        }

        let header;
        if (headerDisplay) {
            if (showValidatedInHeader) {
                header = (
                    <div className="modal-header-v2">
                        <span>
                            <h4 className="modal-header-title-v2">{title}</h4>
                            <span className="glyphicons check glyphicons-green modal-header-title-right-v2">
                                <i />
                            </span>
                            <span className="modal-header-validated-text">{validatedText}</span>
                        </span>
                    </div>
                );
            } else {
                header = (
                    <Modal.Header bsClass="modal-header-v2">
                        <Modal.Title bsClass="modal-title-v2">{title}</Modal.Title>
                    </Modal.Header>
                );
            }
        }

        return (
            <Modal
                id={id}
                dialogClassName={classNames(className, 'modal-dialog-v2')}
                show={dialogDisplayed}
                backdrop="static"
                keyboard={false}
                ref={this.storeModalReference}
                onEnter={this.onEnter}
            >
                {header}
                {children}
            </Modal>
        );
    }
}

Dialog.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
    dialogDisplayed: PropTypes.bool,
    children: PropTypes.node.isRequired,
    isLoading: PropTypes.bool,
    contentHeight: PropTypes.number,
    setContentHeight: PropTypes.func,
    clearContentHeight: PropTypes.func,
    headerDisplay: PropTypes.bool,
    showValidatedInHeader: PropTypes.bool,
    validatedText: PropTypes.string,
    firstElementFocus: PropTypes.bool,
    ignoreDefaultButtonStyle: PropTypes.bool,
    container: PropTypes.shape(),
    ignoreContainer: PropTypes.bool, // container feature is disabled and CSS classes not set to <body>
};

Dialog.defaultProps = {
    className: 'default-dialog-size',
    isLoading: false,
    headerDisplay: true,
    showValidatedInHeader: false,
    validatedText: 'Validated',
    firstElementFocus: true,
    ignoreDefaultButtonStyle: false,
    ignoreContainer: false,
};

Dialog.childContextTypes = {
    id: PropTypes.string,
    isInsideDialog: PropTypes.bool,
    isLoading: PropTypes.bool,
    contentHeight: PropTypes.number,
    setContentHeight: PropTypes.func.isRequired,
    clearContentHeight: PropTypes.func.isRequired,
    firstElementFocus: PropTypes.bool,
    handleRef: PropTypes.func,
};

Dialog.Body = DialogBody;
Dialog.Footer = DialogFooter;
