import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useLoginWithTokenMutation } from 'src/api';
import { t } from 'src/assets/i18n/translation';
import { pushError } from 'src/components/NotificationsHandler';
import { useAuth } from 'src/domains/User/useAuth';
import { ROUTE_PATH } from 'src/routes';

export function getUrlVars(location) {
	const queryString = location.search === '' ? location.hash : location.search;

	// eslint-disable-next-line prefer-const
	let vars = {},
		hash;
	if (queryString) {
		const hashes = queryString.slice(1).split('&');
		for (let i = 0; i < hashes.length; i++) {
			hash = hashes[i].split('=');
			vars[hash[0]] = hash[1];
		}
		return vars;
	} else {
		return false;
	}
}

export const LoginTokenAuthPage = memo(() => {
	const [loginWithToken] = useLoginWithTokenMutation();
	const { pluginIdentityId } = useParams();
	const { arePermissionsLoaded } = useAuth();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	useEffect(() => {
		if (arePermissionsLoaded) {
			navigate(ROUTE_PATH.DEFAULT_REDIRECT);
		}
	}, [arePermissionsLoaded]);
	const searchParams = getUrlVars(window.location);
	useEffect(() => {
		(async () => {
			const response = await loginWithToken({ pluginIdentity: pluginIdentityId, authInfo: searchParams });
			if ('error' in response) {
				const message = t('loginWithToken.error.message.default');
				setTimeout(() => dispatch(pushError({ id: 'loginWithToken', message })), 100);
				navigate(ROUTE_PATH.LOGIN);
			}
		})();
	}, []);
	return (
		<div>
			<h3>{t('inProcess.page.text')}</h3>
		</div>
	);
});

LoginTokenAuthPage.displayName = 'LoginTokenAuthPage';
