/*
 * Copyright 2020, Torque IT Solutions Ltd
 * www.torque-its.com
 */
import { find, forEach } from 'lodash';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = require('moment-timezone');
const MomentRange = extendMoment(Moment);

const dateFormatServerParsable = 'YYYY-MM-DD';
const yearFormatServerParsable = 'YYYY';
export const hourMinuteFormatServerParsable = 'HH:mm';
export const hourMinuteSecondFormatServerParsable = hourMinuteFormatServerParsable + ':ss';

export function getDateFormat(locale, languageConfig) {
    if (locale && languageConfig && languageConfig.length > 0) {
        let config = find(languageConfig, { localeBcp47Code: locale });
        if (config && config.dateFormat) {
            return config.dateFormat.toUpperCase();
        }
    }
    return dateFormatServerParsable;
}

// format a date string to send to server
export function formatMomentDateWithServerParsableFormat(momentDate) {
    if (momentDate) {
        return momentDate.format(dateFormatServerParsable);
    }
    return '';
}

// Get the year from moment date object
export function getFormatedYear(momentDate) {
    if (momentDate) {
        return momentDate.format(yearFormatServerParsable);
    }
    return '';
}

// format a date time string to send to server
export function formatMomentDateTimeWithServerParsableFormat(momentDate) {
    if (momentDate) {
        return momentDate.utc().toISOString();
    }
    return '';
}

// format a date string (from server) with user configured locale
export function formatDateString(dateString, locale, dateFormat) {
    let output = '';
    if (dateString) {
        if (!locale || !dateFormat) {
            output = moment(dateString).format(dateFormatServerParsable);
        } else {
            let momentDate = moment(dateString).locale(locale);
            output = momentDate.format(dateFormat);
        }
    }
    return output;
}

// format a utc datetime string to date time with user configured timezone and locale
export function formatUtcDateTimeStringToDateTime(utcDateTimeString, timezone, locale, dateFormat, timeFormat) {
    let output = '';
    timeFormat = timeFormat ? timeFormat : hourMinuteFormatServerParsable;
    if (utcDateTimeString) {
        if (!timezone || !locale || !dateFormat) {
            output = moment.utc(utcDateTimeString).format(dateFormatServerParsable + ' ' + timeFormat);
        } else {
            let dateTime = moment.utc(utcDateTimeString).locale(locale).tz(timezone);
            output = dateTime.format(dateFormat) + ' ' + dateTime.format(timeFormat);
        }
    }
    return output;
}

// format a utc datetime string to date with user configured timezone and locale
export function formatUtcDateTimeStringToDate(utcDateTimeString, timezone, locale, dateFormat) {
    let output = '';
    if (utcDateTimeString) {
        if (!timezone || !locale || !dateFormat) {
            output = moment.utc(utcDateTimeString).format(dateFormatServerParsable);
        } else {
            let dateTime = moment.utc(utcDateTimeString).locale(locale).tz(timezone);
            output = dateTime.format(dateFormat);
        }
    }
    return output;
}

// construct moment date time object from server replied utc datetime string
export function getDateTimeFromString(utcDateTimeString) {
    return moment.utc(utcDateTimeString);
}

// construct moment date object from server replied utc date string
export function getDateFromString(utcDateString) {
    return moment(utcDateString);
}

// convert date input result (date text) to moment with configured timezone
export function parseDateInputResultWithUserTimeZone(value, timeZone) {
    if (value && timeZone) {
        const momentDate = moment(value);
        const year = momentDate.year();
        const month = momentDate.month();
        const dateOfMonth = momentDate.date();
        return moment.tz([year, month, dateOfMonth], timeZone);
    }
    return null;
}

// get current date time in utc
export function getCurrentUtcDateTime() {
    return moment.utc();
}

// get current date time in timezone
export function getCurrentTimezoneDateTime(timezone) {
    if (timezone) {
        return moment.utc().tz(timezone);
    } else {
        return getCurrentUtcDateTime();
    }
}

// get the first date of current month
export function getFirstDateOfThisMonth() {
    return moment().startOf('month');
}

// get the end date of current month
export function getEndDateOfThisMonth() {
    return moment().endOf('month');
}

// check whether date ranges overlap
export function checkRangesOverlap(ranges) {
    let momentRanges = [];
    forEach(ranges, (r) => {
        momentRanges.push(MomentRange.range(r.fromDate, r.toDate));
    });
    for (let i = 0; i < momentRanges.length; i++) {
        for (let j = i + 1; j < momentRanges.length; j++) {
            if (momentRanges[i].overlaps(momentRanges[j], { adjacent: true })) {
                return true;
            }
        }
    }
    return false;
}

export function getDateTimeFormat(timeZone, locale, dateFormat, errorMessage) {
    if (!dateFormat) {
        return '';
    }
    const exampleDate = new Date(2043, 8, 27, 16, 58); // sample date for display purposes
    const isDateFormatValid = /[_\W]*((dd[_\W]*MM[_\W]*yyyy)|(dd[_\W]*yyyy[_\W]*MM)|(MM[_\W]*dd[_\W]*yyyy)|(MM[_\W]*yyyy[_\W]*dd)|(yyyy[_\W]*dd[_\W]*MM)|(yyyy[_\W]*MM[_\W]*dd))[_\W]*/.test(dateFormat);
    const finalDateTime = isDateFormatValid ? formatUtcDateTimeStringToDateTime(exampleDate.toUTCString(), timeZone, locale, dateFormat.toUpperCase()) : errorMessage;
    return finalDateTime;
}

export const renderDateTime = (isSecondsIncluded, timeZone, locale, languageConfig) => (dateTime) => {
    if (!dateTime) {
        return null;
    }
    const timeFormat = isSecondsIncluded ? hourMinuteSecondFormatServerParsable : null;
    const dateFormat = getDateFormat(locale, languageConfig);
    return formatUtcDateTimeStringToDateTime(dateTime, timeZone, locale, dateFormat, timeFormat);
};

export const sortDateTimeFrom = (a, b, order) => {
    let comparisonResult = 0;
    comparisonResult = a.effectiveFromDate.localeCompare(b.effectiveFromDate);
    return order === 'asc' ? comparisonResult : comparisonResult * -1;
};
