/* this file is mostly copied from default animation of rc-collapse.openAnimationFactory.js*/
import cssAnimation from 'css-animation';

/* add autoScrollIntoView property to the original function */
function animate(node, show, transitionName, done, autoScrollIntoView) {
    let height;
    return cssAnimation(node, transitionName, {
        start() {
            if (!show) {
                node.style.height = `${node.offsetHeight}px`;
            } else {
                height = node.offsetHeight;
                node.style.height = 0;
            }
        },
        active() {
            node.style.height = `${show ? height : 0}px`;
        },
        end() {
            node.style.height = '';
            done();
            /* add codes here, if it is expanding a bottom panel and contents not shown, scroll it into view */
            if (autoScrollIntoView && show) {
                const top = node.getBoundingClientRect().top;
                if (top + node.offsetHeight > getViewportHeight()) {
                    node.scrollIntoView();
                }
            }
        },
    });
}

/* add autoScrollIntoView property */
function animation(autoScrollIntoView) {
    return {
        enter(node, done) {
            return animate(node, true, 'rc-collapse-anim', done, autoScrollIntoView);
        },
        leave(node, done) {
            return animate(node, false, 'rc-collapse-anim', done, autoScrollIntoView);
        },
    };
}

/* add util function to get view port height */
function getViewportHeight() {
    let win = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0],
        h = win.innerHeight || e.clientHeight || g.clientHeight;
    return h;
}

export default animation;
