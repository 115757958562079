import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import classnames from 'classnames';

class SearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.searchText,
        };
        this.deboncedKeyup = debounce(this.onKeyup, props.delay);
    }

    onKeyup = () => {
        const { onSearch } = this.props;
        onSearch(this.state.value);
    };

    onChangeValue = (e) => {
        this.setState({ value: e.target.value });
    };

    render() {
        const { className, placeholder, searchText, tableId } = this.props;

        return (
            <div className="search-bar">
                <input
                    id={`search-bar-${tableId}`}
                    type="text"
                    onKeyUp={this.deboncedKeyup}
                    onChange={this.onChangeValue}
                    className={classnames('form-control form-control-v2', className)}
                    defaultValue={searchText}
                    placeholder={placeholder || SearchBar.defaultProps.placeholder}
                />
            </div>
        );
    }
}

SearchBar.propTypes = {
    onSearch: PropTypes.func.isRequired,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    delay: PropTypes.number,
    searchText: PropTypes.string,
    searchPerformed: PropTypes.bool,
    tableId: PropTypes.string,
};

SearchBar.defaultProps = {
    className: '',
    placeholder: 'Search',
    delay: 250,
    searchText: '',
    searchPerformed: false,
    tableId: '0',
};

export default SearchBar;
