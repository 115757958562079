/*
 * Copyright 2018, Torque IT Solutions Ltd
 * www.torque-its.com
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';

/**
 * UiPanel wrapper component.
 */
export default class UiPanel extends PureComponent {
    render() {
        const {
            id,
            className,
            header,
            children
        } = this.props;

        const classNameWithMandatory = classnames('panel panel-default', className);
        let headerComponent = null;
        if (header) {
            headerComponent = (
                <div className="panel-heading">
                    {header}
                </div>
            );
        }
        return (
            <div
                className={classNameWithMandatory}
            >
                {headerComponent}
                <div className="panel-body" id={id}>
                    {children}
                </div>
            </div>
        );
    }
}

UiPanel.propTypes = {
    className: PropTypes.string,
    header: PropTypes.string,
    children: PropTypes.node,
    id: PropTypes.string
};
