import { IComplianceEntityApi, INestedItemProps } from '../../types/Api';
import {
	IAccessLevel,
	IAccessLevelSchedule,
	ICreateAccessLevelScheduleRequest,
	ICreateAccessLevelScheduleVersionRequest,
	IUpdateAccessLevelScheduleVersionRequest,
} from '../../types/AccessLevel.types';
import { createComplianceEntityApi } from '../../utils/api';
import { compareDesc, parseISO } from 'date-fns';
import {
	IContractActivationAction,
	IContractActivationStatus,
	ICreateContractActivationAction,
	ICreateContractActivationActionVersion,
	IUpdateContractActivationActionVersion,
} from '../../types/Contract.types';
import {
	ICheckpoint,
	ICheckpointVariant,
	ICreateCheckpointRequest,
	ICreateCheckpointVersionRequest,
	IUpdateCheckpointVersionRequest,
} from '../../types/Checkpoint.types';
import {
	ICompliance,
	ICreateComplianceEntity,
	ICreateComplianceEntityRequest,
	ICreateComplianceEntityVersion,
	ICreateComplianceEntityVersionRequest,
	IUpdateComplianceEntityVersionRequest,
} from '../../types/Compliance.types';
import { t } from '../../assets/i18n/translation';
import {
	ICreateGenericDecisionTableRequest,
	ICreateGenericDecisionTableVersionRequest,
	IGenericDecisionTable,
	IUpdateGenericDecisionTableVersionRequest,
} from '../../types/GenericTable.types';
import { IPlugin, IPluginInfo, IUpdatePluginInfoRequest } from '../../types/Plugin.types';
import { ICreateRoleRequest, ICreateRoleVersionRequest, IRole, IUpdateRoleVersionRequest } from '../../types/Role.types';
import { ICreateSecret, ICreateSecretVersion, ISecret, IUpdateSecretVersion } from '../../types/Secret.types';
import { ISystemConfiguration } from '../../types/SystemConfiguration.types';
import { ICreateUserGroup, ICreateUserGroupVersion, IUpdateUserGroupVersion, IUserGroup } from '../../types/UserGroup.types';
import { ICreateUpdateTag, ITag } from '../../types/Tag.types';
import {
	ICreateRoleAssignmentRequest,
	ICreateRoleAssignmentVersionRequest,
	IRoleAssignment,
	IUpdateRoleAssignmentVersionRequest,
} from 'src/types/RoleAssignment.types';
import { compareByField } from 'src/utils/common';
import { IContractActivationViewVersion, IUpdateContractActivationViewVersionRequest } from '../../types/ContractActivationView.types';
import { IChecklistSchedule, ICreateUpdateChecklistScheduleRequest } from '../../types/Checklist.types';
import { ICreateUpdateUserVersion, IUserVersion } from '../../types/User';
import { ICreateUpdateCustomerSectionViewVersion, ICustomerSectionViewVersion } from '../../types/CustomerSectionView.types';

import { authProviderHandlesCredentials } from 'src/pages/UserConfiguration/util';
import { CustomerSectionViewMapper } from '../../domains/CustomerSectionView/CustomerSectionViewMapper';
import { IComputedFieldConfigVersion, ICreateUpdateComputedFieldConfigVersion } from '../../types/ComputedField.types';
import { ComputedFieldMapper } from '../../domains/ComputedField/ComputedFieldMapper';
import { IConditionType, ICreateConditionTypeRequest, IUpdateConditionTypeVersionRequest } from '../../types/ConditionType.types';
import {
	IActivationCustomFieldVersion,
	ICreateActivationCustomFieldRequest,
	IUpdateActivationCustomFieldVersionRequest,
} from 'src/types/ActivationCustomField.types';
import { ICreateUpdateReferenceDataRequest, IReferenceData } from '../../types/ReferenceData';
import { ReferenceDataMapper } from '../../domains/ReferenceData/ReferenceDataMapper';
import { IAssetSectionViewVersion, ICreateUpdateAssetSectionViewVersion } from '../../types/Asset.types';
import { AssetSectionViewMapper } from '../../domains/AssetSectionView/AssetSectionViewMapper';
import { ApplicationDetailsSectionViewMapper } from '../../domains/ApplicationDetails/ApplicationDetailsSectionViewMapper';
import {
	IApplicationDetailsSectionViewVersion,
	ICreateUpdateApplicationDetailsSectionViewVersion,
} from '../../types/ApplicationDetails.types';
import { ICreateLanguageRequest, ILanguage, IUpdateLanguageVersionRequest } from 'src/types/Language.types';
import { IActionsLayoutViewVersion } from '../../types/ActionsLayoutView.types';

export * from './plugins';
export * from './statusIcons';
export * from './activity';

const VALUES_ITEM: INestedItemProps = {
	name: 'values',
	type: 'eager',
};
const VARIANTS_ITEM: INestedItemProps = {
	name: 'variants',
	type: 'lazy',
};
const GROUPS_ITEM: INestedItemProps = {
	name: 'groups',
	type: 'eager',
};

export const AccessLevelsApi: IComplianceEntityApi<
	IAccessLevel,
	Omit<IAccessLevel, 'id'>,
	IAccessLevel,
	IAccessLevel
> = createComplianceEntityApi({
	entityName: 'accessLevel',
	displayName: t('configuration.accessLevel.displayName'),
	pathName: 'configuration/access-levels',
	cacheKey: 'configuration-system-access-levels',
	comparator: { field: 'name', order: 'asc' },
	section: 'ACCESS_LEVEL_CONFIGURATION',
});
export const AccessLevelSchedulesApi: IComplianceEntityApi<
	IAccessLevelSchedule,
	ICreateAccessLevelScheduleRequest,
	ICreateAccessLevelScheduleVersionRequest,
	IUpdateAccessLevelScheduleVersionRequest
> = createComplianceEntityApi({
	entityName: 'accessLevelSchedule',
	displayName: t('configuration.accessLevelSchedule.displayName'),
	pathName: 'configuration/access-levels/schedules',
	cacheKey: 'configuration-system-access-level-schedules',
	comparator: (a, b) => compareDesc(parseISO(a.effectiveFrom), parseISO(b.effectiveFrom)),
	section: 'ACCESS_LEVEL_SCHEDULE_CONFIGURATION',
});
export const ActionsApi: IComplianceEntityApi<
	IContractActivationAction,
	ICreateContractActivationAction,
	ICreateContractActivationActionVersion,
	IUpdateContractActivationActionVersion
> = createComplianceEntityApi({
	entityName: 'contractActivationAction',
	displayName: t('configuration.contractActivationAction.displayName'),
	pathName: 'configuration/contract-activation-action',
	cacheKey: 'contract-activation-actions',
	comparator: { field: 'name', order: 'asc' },
	formatter: {
		request: {
			createEntity: (action) => ({ ...action, type: action.isButton ? 'BUTTON' : 'DEFAULT' }),
			createVersion: (action) => ({ ...action, type: action.isButton ? 'BUTTON' : 'DEFAULT' }),
			updateVersion: (action) => ({ ...action, type: action.isButton ? 'BUTTON' : 'DEFAULT' }),
		},
	},
	section: 'ACTION_CONFIGURATION',
});
export const ChecklistSchedulesApi: IComplianceEntityApi<
	IChecklistSchedule,
	ICreateUpdateChecklistScheduleRequest,
	ICreateUpdateChecklistScheduleRequest,
	ICreateUpdateChecklistScheduleRequest
> = createComplianceEntityApi({
	entityName: 'checklistSchedule',
	displayName: t('configuration.checklist.schedule.displayName'),
	pathName: 'configuration/checklist/schedules',
	cacheKey: 'configuration-checklist-schedules',
	comparator: (a, b) => {
		const effectiveFromComparison = compareDesc(parseISO(a.effectiveFrom), parseISO(b.effectiveFrom));
		if (effectiveFromComparison !== 0) {
			return effectiveFromComparison;
		}
		return compareByField('description', a, b, 'asc');
	},
	formatter: {
		response: {
			getAllActiveVersions: (schedules) =>
				schedules.map((schedule) => ({ ...schedule, entries: schedule.entries.sort((a, b) => compareByField('orderIndex', a, b, 'asc')) })),
			getActiveVersion: (schedule) => ({
				...schedule,
				entries: schedule.entries.sort((a, b) => compareByField('orderIndex', a, b, 'asc')),
			}),
			getAllActiveAndPendingVersions: (schedules) =>
				schedules.map((schedule) => ({ ...schedule, entries: schedule.entries.sort((a, b) => compareByField('orderIndex', a, b, 'asc')) })),
			getVersion: (schedule) => ({ ...schedule, entries: schedule.entries.sort((a, b) => compareByField('orderIndex', a, b, 'asc')) }),
		},
	},
	section: 'CHECKLIST_SCHEDULE_CONFIGURATION',
});
export const CheckpointApi: IComplianceEntityApi<
	ICheckpoint,
	ICreateCheckpointRequest,
	ICreateCheckpointVersionRequest,
	IUpdateCheckpointVersionRequest,
	ICheckpointVariant
> = createComplianceEntityApi({
	entityName: 'checkpoint',
	displayName: t('configuration.checkpoint.displayName'),
	pathName: 'configuration/checkpoint',
	cacheKey: 'configuration-checkpoint',
	comparator: { field: 'name', order: 'asc' },
	nestedItems: [VARIANTS_ITEM],
	section: 'CHECKLIST_CONFIGURATION',
});
export const ComplianceApi: IComplianceEntityApi<
	ICompliance,
	ICreateComplianceEntityRequest,
	ICreateComplianceEntityVersionRequest,
	IUpdateComplianceEntityVersionRequest
> = createComplianceEntityApi({
	entityName: 'complianceSection',
	displayName: t('configuration.complianceSection.displayName'),
	pathName: 'configuration/compliance/sections',
	cacheKey: 'configuration-compliance',
	comparator: (a, b) =>
		t(`configuration.workflow.enum.complianceSection.${a.complianceSection}`).localeCompare(
			t(`configuration.workflow.enum.complianceSection.${b.complianceSection}`)
		),
	section: 'COMPLIANCE_CONFIGURATION',
});
export const ContractActivationViewApi: IComplianceEntityApi<
	IContractActivationViewVersion,
	never,
	IUpdateContractActivationViewVersionRequest,
	IUpdateContractActivationViewVersionRequest
> = createComplianceEntityApi({
	entityName: 'contractActivationView',
	displayName: t('configuration.contract.activation.view.displayName'),
	pathName: 'configuration/contract-activation/view',
	cacheKey: 'configuration-contract-activation-view',
	cacheTTL: {
		getAllActiveVersions: Number.MAX_SAFE_INTEGER,
	},
	section: 'CONTRACT_ACTIVATION_VIEW_CONFIGURATION',
	comparator: () => 0,
	invalidateTags: ['contract-activation'],
	formatter: {
		request: {
			updateVersion: (request) => ({
				...request,
				sections: request.sections
					.map((section, index) => ({
						...section,
						position: index,
					}))
					.sort((a, b) => compareByField('position', a, b, 'asc')),
			}),
			createVersion: (request) => ({
				...request,
				sections: request.sections
					.map((section, index) => ({
						...section,
						position: index,
					}))
					.sort((a, b) => compareByField('position', a, b, 'asc')),
			}),
		},
	},
});
export const ComputedFieldsApi: IComplianceEntityApi<
	IComputedFieldConfigVersion,
	ICreateUpdateComputedFieldConfigVersion,
	ICreateUpdateComputedFieldConfigVersion,
	ICreateUpdateComputedFieldConfigVersion
> = createComplianceEntityApi({
	entityName: 'computedField',
	displayName: t('configuration.computedField.displayName'),
	pathName: 'configuration/computed-fields',
	cacheKey: 'configuration-computed-fields',
	comparator: { field: 'name', order: 'asc' },
	section: 'COMPUTED_FIELD_CONFIGURATION',
	formatter: {
		response: {
			getActiveVersion: ComputedFieldMapper.toComputedFieldConfigVersion,
			getVersion: ComputedFieldMapper.toComputedFieldConfigVersion,
		},
	},
});
export const CustomerSectionViewApi: IComplianceEntityApi<
	ICustomerSectionViewVersion,
	ICreateUpdateCustomerSectionViewVersion,
	ICreateUpdateCustomerSectionViewVersion,
	ICreateUpdateCustomerSectionViewVersion
> = createComplianceEntityApi({
	entityName: 'customerSectionView',
	displayName: t('configuration.customer.section.view.displayName'),
	pathName: 'configuration/customer-section/view',
	cacheKey: 'configuration-customer-section-view',
	cacheTTL: {
		getAllActiveVersions: Number.MAX_SAFE_INTEGER,
	},
	section: 'CUSTOMER_SECTION_VIEW_CONFIGURATION',
	comparator: { field: 'name', order: 'asc' },
	nestedItems: [GROUPS_ITEM],
	invalidateTags: ['contract-activation'],
	formatter: {
		response: {
			getAllActiveVersions: CustomerSectionViewMapper.toCustomerSectionViewVersions,
			getAllActiveAndPendingVersions: CustomerSectionViewMapper.toCustomerSectionViewVersions,
			getActiveVersion: CustomerSectionViewMapper.toCustomerSectionViewVersion,
			getVersion: CustomerSectionViewMapper.toCustomerSectionViewVersion,
		},
	},
});
export const ActivationCustomFieldApi: IComplianceEntityApi<
	IActivationCustomFieldVersion,
	ICreateActivationCustomFieldRequest,
	ICreateActivationCustomFieldRequest,
	IUpdateActivationCustomFieldVersionRequest
> = createComplianceEntityApi({
	entityName: 'activationCustomField',
	displayName: t('configuration.customfield.displayName'),
	pathName: 'configuration/activation-custom-field',
	cacheKey: 'configuration-activation-custom-field',
	cacheTTL: {
		getAllActiveVersions: Number.MAX_SAFE_INTEGER,
	},
	section: 'ACTIVATION_CUSTOM_FIELD_CONFIGURATION',
	comparator: { field: 'name', order: 'asc' },
});
export const AssetSectionViewApi: IComplianceEntityApi<
	IAssetSectionViewVersion,
	ICreateUpdateAssetSectionViewVersion,
	ICreateUpdateAssetSectionViewVersion,
	ICreateUpdateAssetSectionViewVersion
> = createComplianceEntityApi({
	entityName: 'assetSectionView',
	displayName: t('configuration.asset.section.view.displayName'),
	pathName: 'configuration/asset-details/view',
	cacheKey: 'configuration-asset-section-view',
	invalidateTags: ['contract-activation'],
	cacheTTL: {
		getAllActiveVersions: Number.MAX_SAFE_INTEGER,
	},
	section: 'ASSET_SECTION_VIEW_CONFIGURATION',
	comparator: { field: 'name', order: 'asc' },
	formatter: {
		response: {
			getAllActiveVersions: AssetSectionViewMapper.toAssetSectionViewVersions,
			getAllActiveAndPendingVersions: AssetSectionViewMapper.toAssetSectionViewVersions,
			getActiveVersion: AssetSectionViewMapper.toAssetSectionViewVersion,
			getVersion: AssetSectionViewMapper.toAssetSectionViewVersion,
		},
	},
});
export const ActionsLayoutViewApi: IComplianceEntityApi<
	IActionsLayoutViewVersion,
	IActionsLayoutViewVersion,
	IActionsLayoutViewVersion,
	IActionsLayoutViewVersion
> = createComplianceEntityApi({
	entityName: 'actionsLayoutView',
	displayName: t('configuration.actions.layout.view.displayName'),
	pathName: 'configuration/actions-layout/view',
	cacheKey: 'configuration-actions-layout-view',
	cacheTTL: {
		getAllActiveVersions: Number.MAX_SAFE_INTEGER,
	},
	section: 'ACTIONS_LAYOUT_VIEW_CONFIGURATION',
	comparator: { field: 'name', order: 'asc' },
});
export const ApplicationDetailsSectionViewApi: IComplianceEntityApi<
	IApplicationDetailsSectionViewVersion,
	ICreateUpdateApplicationDetailsSectionViewVersion,
	ICreateUpdateApplicationDetailsSectionViewVersion,
	ICreateUpdateApplicationDetailsSectionViewVersion
> = createComplianceEntityApi({
	entityName: 'applicationDetailsSectionView',
	displayName: t('configuration.application.details.section.view.displayName'),
	pathName: 'configuration/application-details/view',
	cacheKey: 'configuration-application-details-section-view',
	cacheTTL: {
		getAllActiveVersions: Number.MAX_SAFE_INTEGER,
	},
	section: 'APPLICATION_DETAILS_SECTION_VIEW_CONFIGURATION',
	comparator: { field: 'name', order: 'asc' },
	invalidateTags: ['contract-activation'],
	formatter: {
		response: {
			getAllActiveVersions: ApplicationDetailsSectionViewMapper.toApplicationDetailsSectionViewVersions,
			getAllActiveAndPendingVersions: ApplicationDetailsSectionViewMapper.toApplicationDetailsSectionViewVersions,
			getActiveVersion: ApplicationDetailsSectionViewMapper.toApplicationDetailsSectionViewVersion,
			getVersion: ApplicationDetailsSectionViewMapper.toApplicationDetailsSectionViewVersion,
		},
	},
});
export const ConditionTypeApi: IComplianceEntityApi<
	IConditionType,
	ICreateConditionTypeRequest,
	ICreateConditionTypeRequest,
	IUpdateConditionTypeVersionRequest
> = createComplianceEntityApi({
	entityName: 'conditionType',
	displayName: t('configuration.condition.type.displayName'),
	pathName: 'configuration/condition-type',
	cacheKey: 'configuration-condition-type',
	cacheTTL: {
		getAllActiveVersions: Number.MAX_SAFE_INTEGER,
	},
	section: 'CONDITION_TYPE_CONFIGURATION',
	comparator: { field: 'name', order: 'asc' },
});
export const LanguageApi: IComplianceEntityApi<ILanguage, ICreateLanguageRequest, ICreateLanguageRequest, IUpdateLanguageVersionRequest> =
	createComplianceEntityApi({
		entityName: 'language',
		displayName: t('configuration.language.displayName'),
		pathName: 'configuration/language',
		cacheKey: 'configuration-language',
		cacheTTL: {
			getAllActiveVersions: Number.MAX_SAFE_INTEGER,
		},
		section: 'LANGUAGE_CONFIGURATION',
		comparator: { field: 'name', order: 'asc' },
	});
export const GenericDecisionTableApi: IComplianceEntityApi<
	IGenericDecisionTable,
	ICreateGenericDecisionTableRequest,
	ICreateGenericDecisionTableVersionRequest,
	IUpdateGenericDecisionTableVersionRequest
> = createComplianceEntityApi({
	entityName: 'genericDecisionTable',
	displayName: t('configuration.genericDecisionTable.displayName'),
	pathName: 'configuration/generic-decision-tables',
	cacheKey: 'configuration-system-generic-decision-tables',
	comparator: { field: 'name', order: 'asc' },
	nestedItems: [VARIANTS_ITEM],
	section: 'GENERIC_DECISION_TABLE_CONFIGURATION',
});
export const PluginsApi: IComplianceEntityApi<
	IPlugin | IPluginInfo,
	ICreateComplianceEntity,
	ICreateComplianceEntityVersion,
	IUpdatePluginInfoRequest
> = createComplianceEntityApi({
	entityName: 'plugins',
	displayName: t('configuration.plugin.displayName'),
	pathName: 'configuration/plugins',
	cacheKey: 'configuration-system-plugins',
	comparator: { field: 'displayName', order: 'asc' },
	hasVersioning: false,
	formatter: {
		response: {
			getAllActiveVersions: (plugins) => plugins.map((plugin) => ({ ...plugin, parentId: plugin.id })),
			getActiveVersion: (plugin) => ({ ...plugin, parentId: plugin.id }),
		},
	},
});
export const ReferenceDataApi: IComplianceEntityApi<
	IReferenceData,
	ICreateUpdateReferenceDataRequest,
	ICreateUpdateReferenceDataRequest,
	ICreateUpdateReferenceDataRequest
> = createComplianceEntityApi({
	entityName: 'referenceData',
	displayName: t('configuration.referenceData.displayName'),
	pathName: 'reference-data',
	cacheKey: 'configuration-reference-data',
	comparator: { field: 'name', order: 'asc' },
	nestedItems: [VALUES_ITEM],
	hasVersioning: false,
	formatter: {
		response: {
			getAllActiveVersions: ReferenceDataMapper.toReferenceDataList,
			getAllActiveAndPendingVersions: ReferenceDataMapper.toReferenceDataList,
			getActiveVersion: ReferenceDataMapper.toReferenceData,
			getVersion: ReferenceDataMapper.toReferenceData,
		},
		request: {},
	},
	section: 'REFERENCE_DATA_CONFIGURATION',
});
export const RolesApi: IComplianceEntityApi<IRole, ICreateRoleRequest, ICreateRoleVersionRequest, IUpdateRoleVersionRequest> =
	createComplianceEntityApi({
		entityName: 'role',
		displayName: t('configuration.role.displayName'),
		pathName: 'configuration/system-roles',
		cacheKey: 'configuration-system-roles',
		comparator: { field: 'name', order: 'asc' },
		section: 'ROLE_CONFIGURATION',
		invalidateTags: ['user-permissions'],
	});
export const RoleAssignmentsApi: IComplianceEntityApi<
	IRoleAssignment,
	ICreateRoleAssignmentRequest,
	ICreateRoleAssignmentVersionRequest,
	IUpdateRoleAssignmentVersionRequest
> = createComplianceEntityApi({
	entityName: 'roleAssignement',
	displayName: t('configuration.role.assignment.displayName'),
	pathName: 'configuration/system-roles/assignments',
	cacheKey: 'configuration-system-roles-assignments',
	comparator: (a, b) => {
		const userGroupComparison = compareByField('name', a.userGroup, b.userGroup, 'asc');
		if (userGroupComparison !== 0) {
			return userGroupComparison;
		}
		const roleComparison = compareByField('name', a.role, b.role, 'asc');
		if (roleComparison !== 0) {
			return roleComparison;
		}
		return compareByField('roleType', a, b, 'asc');
	},
	section: 'ROLE_ASSIGNMENT_CONFIGURATION',
	invalidateTags: ['user-permissions'],
	formatter: {
		request: {
			createEntity: (roleAssignment) => ({
				...roleAssignment,
				role: { id: roleAssignment.role },
				userGroup: { id: roleAssignment.userGroup },
				accessLevel: roleAssignment.accessLevel ? { id: roleAssignment.accessLevel } : undefined,
			}),
			createVersion: (roleAssignment) => ({
				...roleAssignment,
				role: { id: roleAssignment.role },
				userGroup: { id: roleAssignment.userGroup },
				accessLevel: roleAssignment.accessLevel ? { id: roleAssignment.accessLevel } : undefined,
			}),
			updateVersion: (roleAssignment) => ({
				...roleAssignment,
				role: { id: roleAssignment.role },
				userGroup: { id: roleAssignment.userGroup },
				accessLevel: roleAssignment.accessLevel ? { id: roleAssignment.accessLevel } : undefined,
			}),
		},
	},
});
export const SecretsApi: IComplianceEntityApi<ISecret, ICreateSecret, ICreateSecretVersion, IUpdateSecretVersion> =
	createComplianceEntityApi({
		entityName: 'secret',
		displayName: t('configuration.secret.displayName'),
		pathName: 'configuration/system/secret',
		cacheKey: 'configuration-system-secrets',
		comparator: { field: 'name', order: 'asc' },
		section: 'SECRET_CONFIGURATION',
	});
export const StatusesApi: IComplianceEntityApi<
	IContractActivationStatus,
	Omit<IContractActivationStatus, 'id'>,
	Omit<IContractActivationStatus, 'id'>,
	IContractActivationStatus
> = createComplianceEntityApi({
	entityName: 'statuses',
	displayName: t('configuration.contractActivationStatus.displayName'),
	pathName: 'configuration/contract-activation-status',
	cacheKey: 'contract-activation-statuses',
	comparator: { field: 'displayOrder', order: 'desc' },
	formatter: {
		request: {
			createEntity: (status) => ({
				...status,
				colour: status.colourMode === 'NO_COLOUR' ? undefined : status.colour.replace('#', ''),
				displayOrder: +status.displayOrder,
				start: status.start || false,
			}),
			createVersion: (status) => ({
				...status,
				colour: status.colourMode === 'NO_COLOUR' ? undefined : status.colour.replace('#', ''),
				displayOrder: +status.displayOrder,
				start: status.start || false,
			}),
			updateVersion: (status) => ({
				...status,
				colour: status.colourMode === 'NO_COLOUR' ? undefined : status.colour.replace('#', ''),
				displayOrder: +status.displayOrder,
				start: status.start || false,
			}),
		},
	},
	section: 'STATUS_CONFIGURATION',
});
export const SystemConfigurationsApi: IComplianceEntityApi<ISystemConfiguration, never, ISystemConfiguration, ISystemConfiguration> =
	createComplianceEntityApi({
		entityName: 'systemConfiguration',
		displayName: t('configuration.system.displayName'),
		pathName: 'configuration/system',
		cacheKey: 'system-configurations',
		invalidateTags: ['system-branding'],
		comparator: { field: 'name', order: 'asc' },
		formatter: {
			request: {
				createVersion: (request) => ({ ...request, uploadFileSize: +request.uploadFileSize }),
				updateVersion: (request) => ({ ...request, uploadFileSize: +request.uploadFileSize }),
			},
		},
		section: 'SYSTEM_CONFIGURATION',
	});
export const UsersApi: IComplianceEntityApi<IUserVersion, ICreateUpdateUserVersion, ICreateUpdateUserVersion, ICreateUpdateUserVersion> =
	createComplianceEntityApi({
		entityName: 'users',
		displayName: t('configuration.user.displayName'),
		pathName: 'configuration/users',
		cacheKey: 'configuration-users',
		comparator: { field: 'login', order: 'asc' },
		section: 'APPLICATION_USER',
		invalidateTags: ['user-permissions'],
		formatter: {
			response: {
				getAllActiveVersions: (users) =>
					users.map((user) => ({ ...user, locked: user.locked ?? false, name: `${user.firstName} ${user.lastName}` })),
				getAllActiveAndPendingVersions: (users) =>
					users.map((user) => ({ ...user, locked: user.locked ?? false, name: `${user.firstName} ${user.lastName}` })),
				getActiveVersion: (user) => ({ ...user, locked: user.locked ?? false, name: `${user.firstName} ${user.lastName}` }),
				getVersion: (user) => ({ ...user, locked: user.locked ?? false, name: `${user.firstName} ${user.lastName}` }),
			},
			request: {
				// trim all user string fields
				createEntity: (user) => {
					const {
						login,
						firstName,
						lastName,
						email,
						preventPasswordExpiry,
						requireChangePassword,
						password,
						passwordConfirmation,
						locked,
						authenticationProvider,
						userGroups,
						...rest
					} = user;
					const areCredentialsHandled = authProviderHandlesCredentials(authenticationProvider?.id);
					return {
						login: login.trim(),
						firstName: firstName.trim(),
						lastName: lastName.trim(),
						preventPasswordExpiry: areCredentialsHandled ? preventPasswordExpiry ?? false : undefined,
						requireChangePassword: areCredentialsHandled ? requireChangePassword ?? false : undefined,
						locked: areCredentialsHandled ? locked ?? false : undefined,
						email: email.trim(),
						password: areCredentialsHandled && password ? window.btoa(password) : undefined,
						authenticationProvider,
						userGroups: userGroups?.map((userGroup) => ({ id: userGroup.parentId })),
						...rest,
					};
				},
				createVersion: (user) => {
					const {
						login,
						firstName,
						lastName,
						email,
						preventPasswordExpiry,
						requireChangePassword,
						locked,
						password,
						passwordConfirmation,
						authenticationProvider,
						userGroups,
						...rest
					} = user;
					const areCredentialsHandled = authProviderHandlesCredentials(authenticationProvider?.id);
					return {
						login: login.trim(),
						firstName: firstName.trim(),
						lastName: lastName.trim(),
						email: email.trim(),
						password: undefined,
						preventPasswordExpiry: areCredentialsHandled ? preventPasswordExpiry ?? false : undefined,
						authenticationProvider,
						userGroups: userGroups?.map((userGroup) => ({ id: userGroup.parentId })),
						...rest,
					};
				},
				updateVersion: (user) => {
					const {
						login,
						firstName,
						lastName,
						email,
						locked,
						preventPasswordExpiry,
						requireChangePassword,
						password,
						passwordConfirmation,
						userGroups,
						...rest
					} = user;
					return {
						login: login.trim(),
						firstName: firstName.trim(),
						lastName: lastName.trim(),
						email: email.trim(),
						password: undefined,
						preventPasswordExpiry: preventPasswordExpiry ?? false,
						userGroups: userGroups?.map((userGroup) => ({ id: userGroup.parentId })),
						...rest,
					};
				},
			},
		},
	});
export const UserGroupsApi: IComplianceEntityApi<IUserGroup, ICreateUserGroup, ICreateUserGroupVersion, IUpdateUserGroupVersion> =
	createComplianceEntityApi({
		entityName: 'userGroups',
		displayName: t('configuration.user.group.displayName'),
		pathName: 'configuration/users/groups',
		cacheKey: 'configuration-user-groups',
		comparator: { field: 'name', order: 'asc' },
		section: 'USER_GROUP_CONFIGURATION',
		formatter: {
			request: {
				createEntity: (group) => ({ ...group, applicationUsers: group.applicationUsers ?? [] }),
				createVersion: (group) => ({ ...group, applicationUsers: group.applicationUsers ?? [] }),
				updateVersion: (group) => ({ ...group, applicationUsers: group.applicationUsers ?? [] }),
			},
		},
		invalidateTags: ['user-permissions'],
	});
export const TagsApi: IComplianceEntityApi<ITag, ICreateUpdateTag, ICreateUpdateTag, ICreateUpdateTag> = createComplianceEntityApi({
	entityName: 'tags',
	displayName: t('configuration.tag.displayName'),
	pathName: 'configuration/tags',
	cacheKey: 'configuration-tags',
	comparator: { field: 'evaluationOrder', order: 'asc' },
	section: 'TAG_CONFIGURATION',
	nestedItems: [VARIANTS_ITEM, VALUES_ITEM],
	formatter: {
		request: {
			createEntity: (tag) => ({
				...tag,
				values: tag.values.map((value) => {
					return { ...value, colour: value.colourMode === 'NO_COLOUR' ? undefined : value.colour.replace('#', '') };
				}),
			}),
			createVersion: (tag) => ({
				...tag,
				values: tag.values.map((value) => {
					return { ...value, colour: value.colourMode === 'NO_COLOUR' ? undefined : value.colour.replace('#', '') };
				}),
			}),
			updateVersion: (tag) => ({
				...tag,
				values: tag.values.map((value) => {
					return { ...value, colour: value.colourMode === 'NO_COLOUR' ? undefined : value.colour.replace('#', '') };
				}),
			}),
		},
	},
});
