import React from 'react';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/fp/isUndefined';

export default class StickyComponentContainer extends React.Component {
    state = {
        index: !isUndefined(this.props.containerId) ? this.props.containerId : Math.floor((Math.random() * 10000) + 1)
    };

    render() {
        return (
            <div
                id={`sticky-container-id-${this.state.index}`}
                className={this.props.className}
                style={this.props.style}
            >
                {this.props.children}
            </div>
        );
    }
}

StickyComponentContainer.propTypes = {
    containerId: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.shape(),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};
